import React, { FC, useCallback, useContext, useMemo } from 'react';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { UrlQueryDataGrid } from '../UrlQueryDataGrid/UrlQueryDataGrid';
import { useAutoFetch } from '../../contexts/Fetcher';
import { GridCellParams, GridColumns, GridRowData, GridRowParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { getDateObject } from '../../helpers/dateFormat';
import { CustomerProductPlanType } from '../../models/CustomerProductPlanType';
import { getEnumIsFilterOperator, getEnumIsNotFilterOperator } from '../../filterOperators/enumFilterOperator';
import { CustomerRoutes } from '../../routers/CustomerRoutes';
import { config } from '../../config';
import { PageHeader } from '../PageHeader/PageHeader';
import { LicenseExpiry } from '../LicenseExpiry/LicenseExpiry';
import { ScheduledJobs } from '../ScheduledJobs/ScheduledJobs';
import { createStatusChipComponent } from '../StatusChip/StatusChip';
import { EasyFilter } from '../EasyFilter/EasyFilter';
import { useFreeTextFilter } from '../../hooks/useFreeTextFilter';
import { IQuickFiltersProps } from '../EasyFilter/QuickFilters';
import { getDateAfterFilterOperator, getDateBeforeFilterOperator } from '../../filterOperators/dateFilterOperators';
import Email from '@mui/icons-material/Email';
import { CustomerProductsFetcher } from '../../fetchers/ProductsFetcher';
import { useTranslation } from 'react-i18next';
import { getLicenseStatus } from '../../helpers/licenseStatus';
import styled from '@mui/styles/styled';
import { IUserDetails } from '../../models/UserDetails';

const RenderExpiration = (params: GridCellParams) => {
  if (typeof params.value !== 'number') return null;
  return <LicenseExpiry expiry={params.value} type={params.row.type} />;
};

const RenderStatus = createStatusChipComponent({
  valid: ['common.licenseStatus.valid', 'lime'],
  expired: ['common.licenseStatus.expired', 'default'],
  suspended: ['common.licenseStatus.suspended', 'red'],
  expiresSoon: ['common.licenseStatus.expiresSoon', 'orange'],
});

const NotAssigned = styled('span')(({ theme }) => ({
  color: theme.palette.text.disabled,
}));

const ProductsContent: FC = () => {
  const { data } = useContext(CustomerProductsFetcher.Context);
  const { t } = useTranslation();

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'amount',
        headerName: t('products.table.amountHeader'),
        width: 90,
      },
      {
        field: 'product',
        headerName: t('products.table.productHeader'),
        flex: 1,
      },
      {
        field: 'plan',
        headerName: t('products.table.planHeader'),
        flex: 2,
      },
      {
        field: 'type',
        headerName: t('common.tableHeader.type'),
        width: 130,
        filterOperators: [
          getEnumIsFilterOperator(Object.values(CustomerProductPlanType)),
          getEnumIsNotFilterOperator(Object.values(CustomerProductPlanType)),
        ],
      },
      {
        field: 'expires',
        headerName: t('common.tableHeader.expires'),
        width: 200,
        renderCell: RenderExpiration,
        filterOperators: [getDateBeforeFilterOperator(), getDateAfterFilterOperator()],
      },
      {
        field: 'users',
        headerName: t('common.tableHeader.assignedTo'),
        width: 150,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          const assignees: IUserDetails[] = params.value;
          const { length } = assignees;
          if (length === 0) {
            return <NotAssigned>{t('products.table.notAssigned')}</NotAssigned>;
          } else if (length === 1) {
            const user = assignees[0];
            return (
              <span>
                {user.firstName} {user.lastName}
              </span>
            );
          } else {
            const tooltipContent = (
              <div>
                {assignees.map((user) => {
                  return (
                    <div key={user.id}>
                      {user.firstName} {user.lastName}
                    </div>
                  );
                })}
              </div>
            );
            return (
              <Tooltip title={tooltipContent}>
                <span>{t('products.table.assigned', { count: length })}</span>
              </Tooltip>
            );
          }
        },
      },
      {
        field: 'status',
        headerName: t('common.tableHeader.status'),
        width: 130,
        renderCell: RenderStatus,
        filterOperators: [
          getEnumIsFilterOperator({
            [t('common.licenseStatus.valid')]: ['valid', 'expiresSoon'],
            [t('common.licenseStatus.expired')]: ['expired'],
            [t('common.licenseStatus.suspended')]: ['suspended'],
            [t('common.licenseStatus.expiresSoon')]: ['expiresSoon'],
          }),
          getEnumIsNotFilterOperator({
            [t('common.licenseStatus.valid')]: ['valid', 'expiresSoon'],
            [t('common.licenseStatus.expired')]: ['expired'],
            [t('common.licenseStatus.suspended')]: ['suspended'],
            [t('common.licenseStatus.expiresSoon')]: ['expiresSoon'],
          }),
        ],
      },
      {
        field: 'valid',
        hide: true,
        sortable: false,
      },
    ],
    []
  );

  const rows = useMemo<GridRowData[]>(() => {
    if (!data) return [];
    return data.customerProducts.map((singleCustomerProduct) => {
      const isPerpetual = singleCustomerProduct.customerProduct.plan.type === CustomerProductPlanType.Perpetual;
      return {
        id: singleCustomerProduct.customerProduct.customerProduct.id,
        amount: singleCustomerProduct.customerProduct.customerProduct.amount,
        product: singleCustomerProduct.customerProduct.product.name,
        plan: singleCustomerProduct.customerProduct.plan.name,
        type: singleCustomerProduct.customerProduct.plan.type,
        expires: singleCustomerProduct.expiry,
        status: getLicenseStatus(
          singleCustomerProduct.suspended,
          singleCustomerProduct.expiry,
          singleCustomerProduct.customerProduct.plan.type
        ),
        valid:
          !singleCustomerProduct.suspended &&
          (isPerpetual || getDateObject(singleCustomerProduct.expiry).isAfter(dayjs())),
        users: singleCustomerProduct.users,
      };
    });
  }, [data]);

  const quickFilters = useMemo<IQuickFiltersProps['quickFilters']>(
    () =>
      new Map([
        [
          t('common.quickFilter.valid'),
          {
            filterField: 'valid',
            filterOp: 'equals',
            filterVal: 'true',
            isDefault: true,
          },
        ],
        [
          t('common.quickFilter.expireSoon'),
          {
            filterField: 'status',
            filterOp: 'equals',
            filterVal: 'expiresSoon',
            sortBy: 'expires',
            sortOrder: 'asc',
            isVisible: rows.some((row) => row.status === 'expiresSoon'),
          },
        ],
      ]),
    [rows]
  );

  const filteredRows = useFreeTextFilter(rows, columns);

  const getRowLink = useCallback((params: GridRowParams) => {
    return CustomerRoutes.ProductUsers(params.row.id);
  }, []);

  if (!data) return null;
  return (
    <div>
      <EasyFilter quickFilters={quickFilters} />

      <UrlQueryDataGrid
        columns={columns}
        rows={filteredRows}
        disableExtendRowFullWidth={false}
        autoHeight={true}
        getRowLink={getRowLink}
        disableSelectionOnClick={true}
      />
      {data.pendingCronJobs.length > 0 ? (
        <>
          <PageHeader title={t('products.scheduledHeader')} />
          <ScheduledJobs jobs={data.pendingCronJobs} />
        </>
      ) : null}
    </div>
  );
};

export const Products: FC = () => {
  useAutoFetch(CustomerProductsFetcher);
  const { t } = useTranslation();

  return (
    <>
      <PageHeader title={t('products.header')}>
        <Button variant="contained" color="primary" href={`mailto:${config.salesEmail}`} endIcon={<Email />}>
          {t('products.contactSalesButton')}
        </Button>
      </PageHeader>
      <ProductsContent />
    </>
  );
};
