import React, { FC } from 'react';
import styled from '@mui/styles/styled';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';

interface IProps {
  items: Array<{
    amount?: number;
    name?: string;
  }>;
}

const InactiveItem = styled('div')(({ theme }) => ({
  color: theme.palette.text.disabled,
  fontStyle: 'italic',
}));

export const CollapsedContentsWithTooltip: FC<IProps> = ({ children, items }) => {
  const { t } = useTranslation();

  const tooltipContent = (
    <div>
      {items.map(({ amount, name }, index) => {
        return typeof name === 'undefined' ? (
          <InactiveItem key={`${amount}-${name}-${index}`}>
            {typeof amount !== 'undefined' ? <>{amount}&times; | </> : null}
            {t('component.collapsedContentsWithTooltip.nonExisting')}
          </InactiveItem>
        ) : (
          <div key={`${amount}-${name}-${index}`}>
            {typeof amount !== 'undefined' ? <>{amount}&times; | </> : null}
            {name}
          </div>
        );
      })}
    </div>
  );

  if (items.length === 0) {
    return <span>{children}</span>;
  }
  return (
    <Tooltip title={tooltipContent}>
      <span>{children}</span>
    </Tooltip>
  );
};
