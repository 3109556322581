import { config } from '../config';
import { LogLevel } from '../types/logLevel';

const consoleMethods: Record<LogLevel, keyof typeof console> = {
  [LogLevel.Error]: 'error',
  [LogLevel.Warning]: 'warn',
  [LogLevel.Info]: 'info',
  [LogLevel.Http]: 'log',
  [LogLevel.Verbose]: 'debug',
  [LogLevel.Debug]: 'debug',
};

const consoleColors: Record<LogLevel, [string, string]> = {
  [LogLevel.Error]: ['%c Error ', 'background: #f02222; color: #fff'],
  [LogLevel.Warning]: ['%c Warning ', 'background: #f0c022; color: #000'],
  [LogLevel.Info]: ['%c Info ', 'background: #a0a0d0; color: #fff'],
  [LogLevel.Http]: ['%c HTTP ', 'background: #a0d0a0; color: #000'],
  [LogLevel.Verbose]: ['%c Verbose ', 'background: #fff; color: #d022d0'],
  [LogLevel.Debug]: ['%c Debug ', 'background: #fff; color: #22a080'],
};

type ConsoleFunction = (...args: any[]) => void;

const isConsoleConstructor = (
  value: NodeJS.ConsoleConstructor | ConsoleFunction
): value is NodeJS.ConsoleConstructor => {
  return !!(value as any).prototype;
};

const log = (level: LogLevel, ...args: any[]) => {
  if (level <= config.logLevel) {
    const consoleMethod = console[consoleMethods[level]];
    if (!isConsoleConstructor(consoleMethod)) {
      (consoleMethod as ConsoleFunction)(...consoleColors[level], ...args);
    }
  }
};

export const logger = {
  error: (...args: any[]) => log(LogLevel.Error, ...args),
  warning: (...args: any[]) => log(LogLevel.Warning, ...args),
  info: (...args: any[]) => log(LogLevel.Info, ...args),
  http: (...args: any[]) => log(LogLevel.Http, ...args),
  verbose: (...args: any[]) => log(LogLevel.Verbose, ...args),
  debug: (...args: any[]) => log(LogLevel.Debug, ...args),
};
