import { createFetcher } from '../contexts/Fetcher';
import { useCallback } from 'react';
import { useMachineApi } from '../hooks/useMachineApi';

export const MachineFetcher = createFetcher(() => {
  const { getMachineDetails } = useMachineApi();
  return useCallback(
    ({ customerId, userId, machineId }: { customerId: number; userId: number; machineId: number }) => {
      return getMachineDetails([customerId, userId, machineId]);
    },
    [getMachineDetails]
  );
});
