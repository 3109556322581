import React, { ChangeEventHandler, FC, useCallback } from 'react';
import { GridFilterInputValueProps } from '@mui/x-data-grid';
import { SelectProps } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { useTranslation } from 'react-i18next';

export const FileSizeInput: FC<GridFilterInputValueProps> = ({ item, applyValue }) => {
  const { t } = useTranslation();
  const [, size, unit] = (item.value ?? '').match(/^([0-9.]+)([A-Z]+)$/) ?? [null, 0, 'KB'];

  const onInputChange: ChangeEventHandler = useCallback(
    (event) => {
      if (event.currentTarget instanceof HTMLInputElement) {
        applyValue({ ...item, value: `${event.currentTarget.value}${unit}` });
      }
    },
    [unit, item]
  );

  const onUnitChange: SelectProps['onChange'] = useCallback(
    (event) => {
      applyValue({ ...item, value: `${size}${event.target.value}` });
    },
    [size, item]
  );

  const mouseUp = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  return (
    <Grid container>
      <Grid item xs={8}>
        <FormControl>
          <InputLabel>{t('component.fileSizeInput.valueLabel')}</InputLabel>
          <Input placeholder="Size" value={size} onChange={onInputChange} />
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl>
          <InputLabel>{t('component.fileSizeInput.unitLabel')}</InputLabel>
          <Select value={unit} onChange={onUnitChange} onMouseUp={mouseUp} onClick={mouseUp} native>
            <option value={'KB'}>KB</option>
            <option value={'MB'}>MB</option>
            <option value={'GB'}>GB</option>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};
