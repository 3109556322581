import React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import PlaylistAdd from '@mui/icons-material/PlaylistAdd';
import { UseFormReturn } from 'react-hook-form';
import { combineCn } from '../../helpers/combineCn';
import { Colors } from '../../colors';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { alpha } from '@mui/material';

interface IProps<DataType extends unknown, ValueType extends unknown> {
  form: UseFormReturn;
  name: string;
  label: string;
  data: DataType[];
  compare: (dataItem: DataType, valueItem: ValueType) => boolean;
  getItemKey: (dataItem: DataType) => number | string;
  getItemLabel: (dataItem: DataType) => string | React.ReactElement;
  getValueAmount: (value: ValueType) => number;
  valueGenerator: (dataItem: DataType, amount: number) => ValueType;
  availableListLabel?: string;
  chosenListLabel?: string;
  classNames?: {
    itemsList?: string;
  };
}

const useStyles = makeStyles((theme) =>
  createStyles({
    transferList: {
      overflow: 'auto',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      overflow: 'hidden',
    },
    listHint: {
      backgroundColor: `${Colors.Navy}3b`,
      color: theme.palette.text.primary,
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    },
  })
);

const AmountButton = styled(Button)(({ theme }) => ({
  width: '40px',
  '&.Mui-disabled': {
    borderLeftColor: alpha(theme.palette.primary.main, 0.5),
  },
}));

const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  '& .MuiButtonGroup-grouped': {
    minWidth: '30px',
  },
  [`& ${AmountButton}`]: {
    borderLeft: '1px solid rgba(255, 191, 0, 0.5)',
  },
  marginRight: theme.spacing(1),
}));

export const TransferListWithAmount = <DataType extends unknown, ValueType extends unknown>({
  form,
  name,
  label,
  data,
  compare,
  getItemKey,
  getItemLabel,
  getValueAmount,
  valueGenerator,
  availableListLabel,
  chosenListLabel,
  classNames = {},
}: IProps<DataType, ValueType>): JSX.Element => {
  const { t } = useTranslation();
  const styles = useStyles();
  const chosenValues: ValueType[] = form.watch(name) ?? [];

  availableListLabel = availableListLabel ?? t('component.transferList.availableLabel');
  chosenListLabel = chosenListLabel ?? t('component.transferList.chosenLabel');

  const handleIncreaseAmount = (item: DataType) => () => {
    const idx = chosenValues.findIndex((value) => compare(item, value));
    if (idx >= 0) {
      form.setValue(name, [
        ...chosenValues.slice(0, idx),
        valueGenerator(item, getValueAmount(chosenValues[idx]) + 1),
        ...chosenValues.slice(idx + 1),
      ]);
    } else {
      form.setValue(name, [...chosenValues, valueGenerator(item, 1)]);
    }
  };

  const handleDecreaseAmount = (item: DataType) => () => {
    const idx = chosenValues.findIndex((value) => compare(item, value));
    if (idx >= 0) {
      if (getValueAmount(chosenValues[idx]) <= 1) {
        form.setValue(
          name,
          chosenValues.filter((value) => !compare(item, value))
        );
      } else {
        form.setValue(name, [
          ...chosenValues.slice(0, idx),
          valueGenerator(item, getValueAmount(chosenValues[idx]) - 1),
          ...chosenValues.slice(idx + 1),
        ]);
      }
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <FormLabel>{label}:</FormLabel>
      </Grid>
      <Grid item xs={6}>
        <Paper className={styles.paper}>
          <div className={styles.listHint}>{availableListLabel}:</div>
          <List
            className={combineCn({ [styles.transferList]: true, [classNames.itemsList ?? '']: !!classNames.itemsList })}
          >
            {data.map((item) => {
              if (chosenValues.find((value) => compare(item, value))) return null;

              return (
                <ListItem key={getItemKey(item)} onClick={handleIncreaseAmount(item)}>
                  <ListItemIcon>
                    <IconButton size="large">
                      <PlaylistAdd />
                    </IconButton>
                  </ListItemIcon>
                  <ListItemText>{getItemLabel(item)}</ListItemText>
                </ListItem>
              );
            })}
          </List>
        </Paper>
      </Grid>

      <Grid item xs={6}>
        <Paper className={styles.paper}>
          <div className={styles.listHint}>{chosenListLabel}:</div>
          <List
            className={combineCn({ [styles.transferList]: true, [classNames.itemsList ?? '']: !!classNames.itemsList })}
          >
            {chosenValues.map((value) => {
              const item = data.find((item) => compare(item, value));
              if (!item) return null;
              return (
                <ListItem key={getItemKey(item)}>
                  <ListItemIcon>
                    <StyledButtonGroup>
                      <Button variant="outlined" size="small" onClick={handleDecreaseAmount(item)}>
                        -
                      </Button>
                      <AmountButton variant="outlined" size="small" disabled>
                        {getValueAmount(value)}
                      </AmountButton>
                      <Button variant="outlined" size="small" onClick={handleIncreaseAmount(item)}>
                        +
                      </Button>
                    </StyledButtonGroup>
                  </ListItemIcon>
                  <ListItemText>{getItemLabel(item)}</ListItemText>
                </ListItem>
              );
            })}
          </List>
        </Paper>
      </Grid>
    </Grid>
  );
};
