import React, { ChangeEvent, FC, useCallback, useEffect } from 'react';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useHistory, useParams } from 'react-router-dom';
import { AdminRoutes } from '../../routers/AdminRoutes';
import { combineComponents } from '../../contexts/combineComponents';
import { ProductToolsFetcher } from '../../fetchers/ProductTools';
import { ProductTools } from './ProductTools';
import { ProductPackages } from './ProductPackages';
import { ProductPackagesFetcher } from '../../fetchers/ProductPackagesFetcher';
import { ProductPlans } from './ProductPlans';
import { ProductPlansFetcher } from '../../fetchers/ProductPlansFetcher';
import { ProductsFetcher } from '../../fetchers/ProductsFetcher';
import { ProductsList } from './ProductsList';
import { useTranslation } from 'react-i18next';
import { AdminRouteLink, useIsAdminRouteAllowed } from '../RouteLink/RouteLink';
import styled from '@mui/styles/styled';

export type ProductsTab = 'products' | 'plans' | 'packages' | 'addons' | 'tools';

interface IUrlParams {
  tab?: ProductsTab;
}

const Providers = combineComponents(
  ProductToolsFetcher.WMF,
  ProductPackagesFetcher.WMF,
  ProductPlansFetcher.WMF,
  ProductsFetcher.WMF
);

const TabsContainer = styled('div')({
  display: 'flex',
});
const StyledTabs = styled(Tabs)({
  flexGrow: 1,
});
const ButtonContainer = styled('div')({
  flex: 1,
  textAlign: 'right',
});

export const AdminProducts: FC = () => {
  const { tab } = useParams<IUrlParams>();
  const history = useHistory();
  const { t } = useTranslation();

  const isProductsAllowed = useIsAdminRouteAllowed('ProductsTab', 'products');
  const isPlansAllowed = useIsAdminRouteAllowed('ProductsTab', 'plans');
  const isPackagesAllowed = useIsAdminRouteAllowed('ProductsTab', 'packages');
  const isToolsAllowed = useIsAdminRouteAllowed('ProductsTab', 'tools');

  const onChange = useCallback((e: ChangeEvent<unknown>, tab: ProductsTab) => {
    history.push(AdminRoutes.ProductsTab(tab));
  }, []);

  useEffect(() => {
    if (typeof tab === 'undefined') history.replace(AdminRoutes.ProductsTab('products'));
  }, [typeof tab]);

  return (
    <div>
      <TabsContainer>
        <StyledTabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={onChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {isProductsAllowed ? <Tab label={t('adminProducts.products.tabTitle')} value={'products'} /> : null}
          {isPlansAllowed ? <Tab label={t('adminProducts.plans.tabTitle')} value={'plans'} /> : null}
          {isPackagesAllowed ? <Tab label={t('adminProducts.packages.tabTitle')} value={'packages'} /> : null}
          {isPackagesAllowed ? <Tab label={t('adminProducts.addons.tabTitle')} value={'addons'} /> : null}
          {isToolsAllowed ? <Tab label={t('adminProducts.tools.tabTitle')} value={'tools'} /> : null}
        </StyledTabs>
        <ButtonContainer>
          <Button
            variant="contained"
            color="primary"
            component={AdminRouteLink}
            route="ProductsTabNewItem"
            params={[tab as ProductsTab]}
            keepQuery
          >
            {tab === 'tools' ? t('adminProducts.tools.addButton') : null}
            {tab === 'packages' ? t('adminProducts.packages.addButton') : null}
            {tab === 'addons' ? t('adminProducts.addons.addButton') : null}
            {tab === 'plans' ? t('adminProducts.plans.addButton') : null}
            {tab === 'products' ? t('adminProducts.products.addButton') : null}
          </Button>
        </ButtonContainer>
      </TabsContainer>

      <Providers>
        {tab === 'tools' ? <ProductTools /> : null}
        {tab === 'packages' ? <ProductPackages packagesType={tab} /> : null}
        {tab === 'addons' ? <ProductPackages packagesType={tab} /> : null}
        {tab === 'plans' ? <ProductPlans /> : null}
        {tab === 'products' ? <ProductsList /> : null}
      </Providers>
    </div>
  );
};
