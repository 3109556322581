import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ReleasesFetcher } from '../../fetchers/ReleasesFetcher';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { ProductReleaseForm } from './ProductReleaseForm';
import { FieldValues, useForm } from 'react-hook-form';
import { AdminRoutes } from '../../routers/AdminRoutes';
import { useIntParams } from '../../hooks/useIntParams';
import { useIsRoute } from '../../hooks/useIsRoute';
import { ICreateVersionPayload, useReleasesApi } from '../../hooks/useReleasesApi';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { ProductInstallers } from './ProductInstallers';
import { IAwsFileMetadata } from '../../hooks/useDownloadApi';
import { ProductReleaseVersionForm } from './ProductReleaseVersionForm';
import { useTranslation } from 'react-i18next';
import { NotificationContext } from '../../contexts/NotificationContext';
import { getInstallerVersion } from '../../helpers/getInstallerVersion';
import { UploadingState, useS3Upload } from '../../hooks/useS3Upload';
import { useEnumState } from '../../hooks/useEnumState';
import { ConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialog';
import { usePreventSimultaneousCallback } from '../../hooks/usePreventSimultaneousCallback';
import { Checkbox, FormControlLabel } from '@mui/material';
import { styled } from '@mui/styles';
import { DefaultTheme } from '@mui/styles/defaultTheme';
import party, { Circle, Rect } from 'party-js';

interface IUrlParams {
  productId: string;
}

interface IProps {
  closeDialog: () => void;
}

enum Step {
  File,
  Version,
  Details,
}

const DisabledContent = styled('div')<DefaultTheme, { disabled: boolean }>(({ theme, ...props }) => ({
  pointerEvents: props.disabled ? 'none' : 'auto',
  opacity: props.disabled ? theme.palette.action.disabledOpacity : '1',
}));

export const NewReleaseDialog: FC<IProps> = ({ closeDialog }) => {
  const { data, onDataUpdate } = useContext(ReleasesFetcher.Context);
  const { productId } = useIntParams<IUrlParams>();
  const versionForm = useForm<FieldValues>({
    mode: 'all',
    defaultValues: {
      version: '',
      emailOption: 'none',
      customEmails: '',
      marketingNotes: '',
    },
  });
  const detailsForm = useForm<FieldValues>({
    mode: 'all',
    defaultValues: {
      changelogNotes: '',
      forceUpdate: false,
      pinCode: '',
    },
  });
  const { createVersion } = useReleasesApi();
  const handleError = useApiErrorHandler();
  const [step, setStep] = useState<Step>(Step.File);
  const [installer, setInstaller] = useState<null | IAwsFileMetadata>(null);
  const { t } = useTranslation();
  const { addNotification } = useContext(NotificationContext);
  const version = versionForm.watch('version');
  const [progress, setProgress] = useState(0);
  const [isAborting, setAborting, cancelAborting] = useEnumState(false, true, false);
  const [fileless, setFileless] = useState(false);

  const {
    upload,
    uploadingState,
    reset: resetUpload,
  } = useS3Upload({
    authEndpoint: `/releases/doc/product/${productId}/version/${version}`,
    onProgress: setProgress,
  });

  const open = useIsRoute(AdminRoutes.NewReleaseProductVersion);

  const alreadyReleasedVersions = useMemo(() => {
    if (!data || data.releases.length === 0) return null;
    return data.releases[0].versions.reduce((versionNumbers, version) => {
      return [...versionNumbers, ...version.installer.map((installer) => installer.version)];
    }, [] as string[]);
  }, [data]);
  const versionInUse = alreadyReleasedVersions?.includes(version) ?? false;

  useEffect(() => {
    if (!open) {
      resetUpload();
      setStep(Step.File);
      setInstaller(null);
      cancelAborting();
      detailsForm.reset({
        changelogNotes: '',
        forceUpdate: false,
        pinCode: '',
        version: '',
        filePath: '',
      });
      versionForm.reset({
        version: '',
        emailOption: 'none',
        customEmails: '',
        marketingNotes: '',
      });
    }
  }, [open]);

  const onVersionFormSubmit = useCallback(() => {
    setStep(Step.Details);
  }, []);

  const startTheParty = useCallback(async () => {
    try {
      const halfWidth = Math.min(window.innerWidth / 2);
      const halfHeight = Math.min(window.innerHeight / 2);

      const rect1 = new Rect(0, 0, halfWidth, halfHeight);
      const rect2 = new Rect(halfWidth, 0, halfWidth, halfHeight);
      const rect3 = new Rect(0, halfHeight, halfWidth, halfHeight);
      const rect4 = new Rect(halfWidth, halfHeight, halfWidth, halfHeight);

      const rects = [rect1, rect2, rect3, rect4];

      for (const rect of rects) {
        party.confetti(rect, {
          count: party.variation.range(20, 40),
        });

        await new Promise((res) => setTimeout(res, 250));
      }

      await new Promise((res) => setTimeout(res, 250));
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }, []);

  const endTheParty = useCallback(async () => {
    try {
      await new Promise((res) => setTimeout(res, 250));

      const confettiCircle = new Circle(
        window.innerWidth / 2,
        window.innerHeight / 2,
        Math.min(window.innerWidth, window.innerHeight) / 2
      );

      party.confetti(confettiCircle, {
        count: 50,
      });
      await new Promise((res) => setTimeout(res, 250));
      party.confetti(confettiCircle, {
        count: 150,
      });
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }, []);

  const createRelease = usePreventSimultaneousCallback(async () => {
    if (!fileless && !installer) {
      setStep(Step.File);
      return;
    }
    if (uploadingState === UploadingState.IN_PROGRESS) {
      setStep(Step.Details);
      return;
    }

    const detailsData = detailsForm.getValues();
    const versionData = versionForm.getValues();

    const file = detailsData.docs?.[0];
    if (file && !(await upload(file))) {
      return;
    }

    const payload: ICreateVersionPayload = {
      changelogNotes: detailsData.changelogNotes,
      forceUpdate: detailsData.forceUpdate,
      pinCode: parseInt(detailsData.pinCode),

      marketingNotes: versionData.marketingNotes,
      emailOption: versionData.emailOption,
      customEmails: versionData.customEmails,

      filePath: installer ? installer.Key : undefined,
    };

    try {
      const newData = await createVersion([productId, versionData.version], payload);
      onDataUpdate(newData);
      await startTheParty();
      addNotification({
        severity: 'success',
        message: t('releases.addDialog.success', { version: versionData.version }),
      });
      closeDialog();
      await endTheParty();
    } catch (e) {
      handleError(e);
    }
  }, [productId, fileless, installer, upload, uploadingState]);

  const onDetailsFormSubmit = useCallback(() => {
    if (!fileless && !installer) {
      setStep(Step.File);
    } else if (!versionForm.formState.isValid) {
      setStep(Step.Version);
    } else {
      createRelease().then();
    }
  }, [installer, fileless, versionForm.formState.isValid, createRelease]);

  const onTabChange = useCallback((_, step: number) => {
    setStep(step);
  }, []);

  useEffect(() => {
    if (installer) setStep(Step.Version);
  }, [!!installer]);

  useEffect(() => {
    if (fileless) {
      setInstaller(null);
    }
  }, [fileless]);

  useEffect(() => {
    if (installer) {
      const version = getInstallerVersion(installer.Key);

      if (version) {
        versionForm.setValue('version', version);
        detailsForm.setValue('version', version);
      } else {
        versionForm.setValue('version', '');
        detailsForm.setValue('version', '');
      }
      detailsForm.setValue('filePath', installer.Key);
      versionForm.trigger('version');
    }
  }, [installer]);

  const isValid = useMemo(() => {
    return (fileless || installer) && versionForm.formState.isValid && detailsForm.formState.isValid;
  }, [installer, fileless, versionForm.formState.isValid, detailsForm.formState.isValid]);

  const abortOrClose = uploadingState === UploadingState.IN_PROGRESS ? setAborting : closeDialog;

  if (!data) return null;
  return (
    <>
      <Dialog open={open} onClose={abortOrClose} disableEnforceFocus fullWidth maxWidth="md">
        <DialogTitle>{t('releases.addDialog.title')}</DialogTitle>
        <DialogContent>
          <Typography mb={1}>
            {fileless
              ? t('releases.addDialog.withoutFile')
              : installer
              ? t('releases.addDialog.installerFile', { name: installer.Key })
              : t('releases.addDialog.chooseInstaller')}
          </Typography>

          <Tabs
            value={step}
            indicatorColor="primary"
            textColor="primary"
            onChange={onTabChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label={t('releases.addDialog.stepFile')} value={Step.File} />
            <Tab label={t('releases.addDialog.stepVersion')} value={Step.Version} />
            <Tab label={t('releases.addDialog.stepDetails')} value={Step.Details} />
          </Tabs>

          <Box marginTop={1} display={step === Step.File ? 'block' : 'none'}>
            <DisabledContent disabled={fileless}>
              <ProductInstallers onInstallerChange={setInstaller} />
            </DisabledContent>
            <FormControlLabel
              label={t('releases.addDialog.withoutFile') as string}
              control={<Checkbox value={fileless} onChange={(event, checked) => setFileless(checked)} />}
            />
          </Box>

          <Box marginTop={1} display={step === Step.Version ? 'block' : 'none'}>
            <ProductReleaseVersionForm
              alreadyReleased={alreadyReleasedVersions ?? []}
              onSubmit={onVersionFormSubmit}
              control={versionForm.control}
              handleSubmit={versionForm.handleSubmit}
              formState={versionForm.formState}
              watch={versionForm.watch}
              trigger={versionForm.trigger}
            />
          </Box>

          <Box marginTop={1} display={step === Step.Details ? 'block' : 'none'}>
            {version && !versionInUse ? (
              <ProductReleaseForm
                onSubmit={onDetailsFormSubmit}
                control={detailsForm.control}
                handleSubmit={detailsForm.handleSubmit}
                formState={detailsForm.formState}
                watch={detailsForm.watch}
                upload={upload}
                uploadProgress={progress}
                uploadState={uploadingState}
              />
            ) : (
              <ProductReleaseForm
                onSubmit={onDetailsFormSubmit}
                control={detailsForm.control}
                handleSubmit={detailsForm.handleSubmit}
                formState={detailsForm.formState}
                watch={detailsForm.watch}
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={abortOrClose}>{t('common.dialog.close')}</Button>

          <Button
            color="primary"
            variant="contained"
            onClick={detailsForm.handleSubmit(createRelease)}
            disabled={!isValid || uploadingState === UploadingState.IN_PROGRESS}
          >
            {t('releases.addDialog.confirm')}
          </Button>
        </DialogActions>

        <ConfirmationDialog
          open={uploadingState === UploadingState.IN_PROGRESS && isAborting}
          title={t('releases.addDialog.abortionDialog.title')}
          message={t('releases.addDialog.abortionDialog.message')}
          confirm={closeDialog}
          abort={cancelAborting}
        />
      </Dialog>
    </>
  );
};
