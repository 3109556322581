import { useEffect, useRef } from 'react';
import { useDocumentVisible } from './useDocumentVisible';

interface IOptions {
  enabled?: boolean;
  interval: number;
  onlyWhenVisible?: boolean;
  triggerWhenVisible?: boolean;
  callback: () => Promise<any>;
}

/**
 * This hook is designed to perform regular API calls to check whether some
 * data has changed, especially in order to be able to handle transitional
 * nature of some states
 */
export const useLoopedInterval = ({
  enabled = true,
  interval,
  onlyWhenVisible = true,
  triggerWhenVisible = true,
  callback,
}: IOptions) => {
  const isVisible = useDocumentVisible();
  const scheduleIdRef = useRef(0);

  useEffect(() => {
    if (enabled && triggerWhenVisible && isVisible) {
      callback().then();
    }
  }, [callback, enabled && triggerWhenVisible && isVisible]);

  useEffect(() => {
    if (!enabled) return;
    if (onlyWhenVisible && !isVisible) return;

    let timeout = 0;
    const scheduleTimeout = () => {
      const scheduleId = ++scheduleIdRef.current;

      timeout = window.setTimeout(async () => {
        await callback();
        // scheduleTimeout shouldn't be called if there was an update while waiting for callback response
        if (scheduleId === scheduleIdRef.current) {
          scheduleTimeout();
        }
      }, interval);
    };

    scheduleTimeout();

    return () => {
      ++scheduleIdRef.current;
      clearTimeout(timeout);
    };
  }, [enabled, interval, callback, onlyWhenVisible && !isVisible]);
};
