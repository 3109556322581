import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './languages/en.json';
import pl from './languages/pl.json';

const languageItem = localStorage.getItem('language');

export const initializeI18n = () => {
  i18n.use(initReactI18next).init({
    resources: {
      en: { translation: en },
      pl: { translation: pl },
    },
    lng: languageItem ? JSON.parse(languageItem) : 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });
};
