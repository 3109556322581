import { useContext, useMemo } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { AccountType } from '../types/AccountType';

export const useAccount = (accountType: AccountType | null) => {
  const authContext = useContext(AuthContext);

  const [account, setAccount] = useMemo(() => {
    if (accountType === AccountType.User) return [authContext.user, authContext.setUser];
    if (accountType === AccountType.Customer) return [authContext.customer, authContext.setCustomer];
    if (accountType === AccountType.Admin) return [authContext.admin, authContext.setAdmin];
    return [null, () => void 0];
  }, [accountType, authContext]);

  return { account, setAccount };
};
