import React, { FC, useContext, useMemo } from 'react';
import { ActivityAccountFetcher } from '../../fetchers/ActitityAccountFetcher';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { MapWithMarkers } from './MapWithMarkers';
import { displayGeoCoordinate } from '../../helpers/displayGeoCoordinate';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';

interface IUrlParams {
  ip: string;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    flag: {
      marginRight: theme.spacing(1.5),
      fontSize: 20,
      boxShadow: '#00000060 4px 4px 12px',
    },
  })
);

const AnalyticsActivityAccountContent: FC = () => {
  const styles = useStyles();
  const { data } = useContext(ActivityAccountFetcher.Context);
  const { t } = useTranslation();

  const markers = useMemo(() => {
    if (!data) return null;
    return [
      {
        name: data.query,
        coords: [data.lat, data.lon] as [number, number],
      },
    ];
  }, [data]);

  if (!markers || !data) return null;

  return (
    <Paper>
      <Box p={1}>
        <MapWithMarkers markers={markers} />
      </Box>
      <Divider />
      <Box p={1}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>{t('analytics.table.ipHeader')}</TableCell>
              <TableCell>{data.query}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('analytics.table.locationHeader')}</TableCell>
              <TableCell>
                <ReactCountryFlag
                  className={styles.flag}
                  countryCode={data.countryCode}
                  svg
                  style={{
                    width: 'auto',
                  }}
                />
                {data.city} ({data.regionName})
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('analytics.table.latitudeHeader')}</TableCell>
              <TableCell>{displayGeoCoordinate(data.lat, 'latitude')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('analytics.table.longitudeHeader')}</TableCell>
              <TableCell>{displayGeoCoordinate(data.lon, 'longitude')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('analytics.table.ispHeader')}</TableCell>
              <TableCell>{data.isp}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('analytics.table.timezoneHeader')}</TableCell>
              <TableCell>{data.timezone}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Paper>
  );
};

export const AnalyticsActivityAccount: FC = () => {
  const { ip } = useParams<IUrlParams>();
  return (
    <ActivityAccountFetcher.WAF ip={ip}>
      <AnalyticsActivityAccountContent />
    </ActivityAccountFetcher.WAF>
  );
};
