import React, { FC, useCallback, useContext, useMemo } from 'react';
import { PageHeader } from '../PageHeader/PageHeader';
import { UrlQueryDataGrid } from '../UrlQueryDataGrid/UrlQueryDataGrid';
import { GridColumns, GridRowParams } from '@mui/x-data-grid';
import { AdminRoutes } from '../../routers/AdminRoutes';
import { getDateValueFormatter } from '../../valueFormatters/DateValueFormatter';
import { DateFormat } from '../../helpers/dateFormat';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { getStringEqualsOperator } from '../../filterOperators/stringEqualsOperator';
import { getGreaterThanOperator } from '../../filterOperators/greaterThanOperator';
import { getLessThanOperator } from '../../filterOperators/lessThanOperator';
import { getStringDiffersOperator } from '../../filterOperators/stringDiffersOperator';
import { getDateAfterFilterOperator, getDateBeforeFilterOperator } from '../../filterOperators/dateFilterOperators';
import { CustomerFetcher } from '../Customer/CustomerFetchers';
import { AddCustomerDialog } from '../EditCustomerDialog/AddCustomerDialog';
import { CustomersFetcher } from '../../fetchers/CustomersFetcher';
import { EasyFilter } from '../EasyFilter/EasyFilter';
import { IQuickFiltersProps } from '../EasyFilter/QuickFilters';
import { CellContentTooltip } from '../CellContentTooltip/CellContentTooltip';
import { createStatusChipComponent } from '../StatusChip/StatusChip';
import { useDefaultSorting } from '../../hooks/useDefaultSorting';
import { useTranslation } from 'react-i18next';
import { useCloseDialog } from '../../hooks/useCloseDialog';
import { useIsRoute } from '../../hooks/useIsRoute';
import { getStringContainsOperator } from '../../filterOperators/stringContainsOperator';
import { AdminRouteLink, useIsAdminRouteAllowed } from '../RouteLink/RouteLink';

const Status = createStatusChipComponent({
  unverified: ['common.accountStatus.unverified', 'orange'],
  archived: ['common.accountStatus.archived', 'default'],
  mfaEnabled: ['common.accountStatus.mfaEnabled', 'lime'],
});

const CustomersContent: FC = () => {
  const { data } = useContext(CustomersFetcher.Context);
  const { onDataUpdate } = useContext(CustomerFetcher.Context);
  const newCustomerDialog = useIsRoute(AdminRoutes.NewCustomer);
  const { t } = useTranslation();

  const closeDialog = useCloseDialog(AdminRoutes.Customers());

  const quickFilters = useMemo<IQuickFiltersProps['quickFilters']>(
    () =>
      new Map([
        [
          t('common.quickFilter.unverified'),
          {
            filterField: 'verified',
            filterOp: 'equals',
            filterVal: 'false',
          },
        ],
        [
          t('common.quickFilter.archived'),
          {
            filterField: 'archived',
            filterOp: 'equals',
            filterVal: 'true',
          },
        ],
        [
          t('common.quickFilter.mfaEnabled'),
          {
            filterField: 'MFAEnabled',
            filterOp: 'equals',
            filterVal: 'true',
          },
        ],
      ]),
    []
  );

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'id',
        headerName: t('common.tableHeader.id'),
        width: 90,
        filterOperators: [getStringEqualsOperator(), getGreaterThanOperator(), getLessThanOperator()],
      },
      {
        field: 'firstName',
        headerName: t('common.tableHeader.firstName'),
        width: 130,
        filterOperators: [getStringContainsOperator(), getStringEqualsOperator(), getStringDiffersOperator()],
        renderCell: (params) => <CellContentTooltip>{params.value}</CellContentTooltip>,
      },
      {
        field: 'lastName',
        headerName: t('common.tableHeader.lastName'),
        flex: 1,
        filterOperators: [getStringContainsOperator(), getStringEqualsOperator(), getStringDiffersOperator()],
        renderCell: (params) => <CellContentTooltip>{params.value}</CellContentTooltip>,
      },
      {
        field: 'company',
        headerName: t('common.tableHeader.company'),
        flex: 1,
        filterOperators: [getStringContainsOperator(), getStringEqualsOperator(), getStringDiffersOperator()],
        renderCell: (params) => <CellContentTooltip>{params.value}</CellContentTooltip>,
      },
      {
        field: 'email',
        headerName: t('common.tableHeader.email'),
        flex: 2,
        filterOperators: [getStringContainsOperator(), getStringEqualsOperator(), getStringDiffersOperator()],
        renderCell: (params) => {
          const { archived, verified, MFAEnabled } = params.row;

          if (archived || !verified || MFAEnabled) {
            const parts = [];
            archived && parts.push(t('common.accountStatus.archived'));
            !verified && parts.push(t('common.accountStatus.unverified'));
            MFAEnabled && parts.push(t('common.accountStatus.mfaEnabled'));
            parts.push(params.value);
            return (
              <Tooltip title={parts.join(' | ')}>
                <span>
                  {params.value}{' '}
                  {verified === false ? (
                    <Status {...params} value={'unverified'} />
                  ) : MFAEnabled ? (
                    <Status {...params} value={'mfaEnabled'} />
                  ) : null}
                </span>
              </Tooltip>
            );
          } else {
            return <CellContentTooltip>{params.value}</CellContentTooltip>;
          }
        },
      },
      {
        field: 'createdBy',
        headerName: t('customers.table.representativeHeader'),
        width: 180,
        filterOperators: [getStringContainsOperator()],
        renderCell: (params) => <CellContentTooltip>{params.value}</CellContentTooltip>,
      },
      {
        field: 'created',
        headerName: t('common.tableHeader.created'),
        valueFormatter: getDateValueFormatter(DateFormat.DateMedium),
        width: 130,
        filterOperators: [getDateBeforeFilterOperator(), getDateAfterFilterOperator()],
      },
      {
        field: 'archived',
        headerName: t('common.tableHeader.archived'),
        hide: true,
        hideable: false,
        sortable: false,
      },
      {
        field: 'verified',
        headerName: t('common.tableHeader.verified'),
        hide: true,
        hideable: false,
        sortable: false,
      },
      {
        field: 'MFAEnabled',
        headerName: t('common.tableHeader.mfaEnabled'),
        hide: true,
        hideable: false,
        sortable: false,
      },
    ],
    []
  );

  const onRowClick = useCallback((params: GridRowParams) => {
    if (typeof params.id === 'number') {
      onDataUpdate(params.row.original);
    }
  }, []);

  const getRowLink = useCallback((params: GridRowParams) => {
    return AdminRoutes.Customer(params.row.id);
  }, []);
  const isGetRowLinkAllowed = useIsAdminRouteAllowed('Customer');

  const rows = useMemo(() => {
    if (!data) return null;
    return data.data.map((row) => {
      return {
        id: row.customer.id,
        firstName: row.customer.firstName,
        lastName: row.customer.lastName,
        company: row.customer.company,
        email: row.customer.email,
        createdBy: row.createdBy ? `${row.createdBy.firstName} ${row.createdBy.lastName}` : '-',
        created: row.customer.created,
        verified: row.customer.verified,
        archived: row.customer.archived,
        original: row,
        MFAEnabled: row.customer.MFAEnabled,
      };
    });
  }, [data]);

  if (!rows || !data) return null;

  return (
    <div>
      <EasyFilter quickFilters={quickFilters} />

      <UrlQueryDataGrid
        columns={columns}
        rows={rows}
        disableExtendRowFullWidth={false}
        autoHeight={true}
        disableSelectionOnClick={true}
        paginationMode={'server'}
        filterMode={'server'}
        sortingMode={'server'}
        onRowClick={onRowClick}
        getRowLink={isGetRowLinkAllowed ? getRowLink : void 0}
        rowCount={data.page.data.total}
        getRowClassName={(params) => (params.row.archived ? 'Mui-disabled' : '')}
      />
      <AddCustomerDialog open={newCustomerDialog} closeDialog={closeDialog} />
    </div>
  );
};

export const Customers: FC = () => {
  const { t } = useTranslation();
  const preventRender = useDefaultSorting('id', 'desc');
  if (preventRender) return null;

  return (
    <CustomersFetcher.WAF>
      <PageHeader title={t('customers.title')}>
        <Button
          component={AdminRouteLink}
          route="NewCustomer"
          params={[]}
          keepQuery
          variant="contained"
          color="primary"
        >
          {t('customers.addButton')}
        </Button>
      </PageHeader>
      <CustomersContent />
    </CustomersFetcher.WAF>
  );
};
