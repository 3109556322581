import React, { FC, useCallback, useContext, useEffect } from 'react';
import { useAutoFetch } from '../../contexts/Fetcher';
import { ProductsFetcher } from '../../fetchers/ProductsFetcher';
import { ReleasesFetcher } from '../../fetchers/ReleasesFetcher';
import { PageHeader } from '../PageHeader/PageHeader';
import { useIntParams } from '../../hooks/useIntParams';
import { useHistory } from 'react-router-dom';
import { AdminRoutes } from '../../routers/AdminRoutes';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { ProductVersions } from './ProductVersions';
import { useTranslation } from 'react-i18next';
import { AdminRouteLink } from '../RouteLink/RouteLink';

interface IUrlParams {
  productId?: string;
}

export const Releases: FC = () => {
  const { data: productsData } = useContext(ProductsFetcher.Context);
  useAutoFetch(ProductsFetcher);
  const { productId } = useIntParams<IUrlParams>();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    if (productsData && !productId) {
      history.replace(AdminRoutes.ReleasesProduct(productsData.products[0].id));
    }
  }, [productsData, productId]);

  const onProductChange = useCallback((event, value: number) => {
    history.push(AdminRoutes.ReleasesProduct(value));
  }, []);

  if (!productsData) return null;
  return (
    <div>
      <PageHeader title={t('releases.header')}>
        {productId ? (
          <Button
            component={AdminRouteLink}
            route="NewReleaseProductVersion"
            params={[productId]}
            variant="contained"
            color="primary"
          >
            {t('releases.addButton')}
          </Button>
        ) : null}
      </PageHeader>

      <Tabs
        value={productId}
        indicatorColor="primary"
        textColor="primary"
        onChange={onProductChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        {productsData.products.map((product) => {
          return <Tab key={product.id} value={product.id} label={product.name} />;
        })}
      </Tabs>

      <ReleasesFetcher.WAF>
        <ProductVersions />
      </ReleasesFetcher.WAF>
    </div>
  );
};
