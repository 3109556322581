import React, { createContext, FC, useCallback, useMemo, useRef } from 'react';

interface IFetcherInvalidatorContext {
  subscribe: (callback: () => void) => () => void;
  trigger: () => void;
}

type FetcherInvalidatorListener = () => void;

const defaultContextValue: IFetcherInvalidatorContext = {
  subscribe: () => () => void 0,
  trigger: () => void 0,
};

export const FetcherInvalidatorContext = createContext<IFetcherInvalidatorContext>(defaultContextValue);

export const FetcherInvalidatorProvider: FC = ({ children }) => {
  const listeners = useRef<FetcherInvalidatorListener[]>([]);

  const subscribe = useCallback((listener) => {
    listeners.current = [...listeners.current, listener];
    return () => {
      listeners.current = listeners.current.filter((l) => l !== listener);
    };
  }, []);

  const trigger = useCallback(() => {
    listeners.current.forEach((listener) => listener());
  }, []);

  const contextValue = useMemo(() => ({ subscribe, trigger }), [subscribe, trigger]);

  return <FetcherInvalidatorContext.Provider value={contextValue}>{children}</FetcherInvalidatorContext.Provider>;
};
