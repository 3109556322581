import React, { FC, useCallback, useContext, useEffect } from 'react';
import { CloseDialogFunc } from '../../hooks/useCloseDialog';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IProductPlanFormPayload, ProductPlanForm } from './ProductPlanForm';
import { useForm } from 'react-hook-form';
import { useProductPlansApi } from '../../hooks/useProductPlansApi';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { ProductPlansFetcher } from '../../fetchers/ProductPlansFetcher';
import { ProductPlanFetcher } from '../../fetchers/ProductPlanFetcher';
import { useAutoFetch } from '../../contexts/Fetcher';
import { DangerousButton } from '../CustomButtons/DangerousButton';
import { useLocation } from 'react-router-dom';
import { AdminRoutes } from '../../routers/AdminRoutes';
import { ConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialog';
import { useIsRoute } from '../../hooks/useIsRoute';
import { useTranslation } from 'react-i18next';
import { AdminRouteLink } from '../RouteLink/RouteLink';

interface IProps {
  planId?: number;
  closeDialog: CloseDialogFunc;
}

export const EditProductPlanDialog: FC<IProps> = ({ planId, closeDialog }) => {
  const form = useForm();
  const { updatePlan, deletePlan } = useProductPlansApi();
  const handleError = useApiErrorHandler();
  const { onRefresh } = useContext(ProductPlansFetcher.Context);
  const { data: planData } = useContext(ProductPlanFetcher.Context);
  useAutoFetch(ProductPlanFetcher, !!planId, planData?.plan.id !== planId);
  const { search } = useLocation();
  const isDeleting = useIsRoute(AdminRoutes.ProductsTabDeleteItem);
  const { t } = useTranslation();

  useEffect(() => {
    if (!planData) return;
    form.reset({
      name: planData.plan.name,
      type: planData.plan.type,
      price: (planData.plan.price / 100).toFixed(2),
      duration: planData.plan.duration.toString(10),
      productCode: planData.plan?.productCode ?? '',
      productId: planData.products[0].id,
      packages: planData.plan.packages.data,
      watermark: planData.plan.watermark,
      customerPackageDefaultProperties: planData.plan.customerPackageDefaultProperties,
    });
  }, [planData]);

  const toggleArchive = useCallback(
    async (archived: boolean) => {
      if (!planData) return;
      try {
        const result = await updatePlan([planData.plan.id], { archived });
        await onRefresh();
        closeDialog(result.id);
      } catch (e) {
        handleError(e);
      }
    },
    [planData?.plan.id, updatePlan]
  );

  const onArchive = useCallback(() => toggleArchive(true), [toggleArchive]);

  const onUnArchive = useCallback(() => toggleArchive(false), [toggleArchive]);

  const onSubmit = useCallback(
    async (data: IProductPlanFormPayload) => {
      if (!planData) return;
      try {
        const result = await updatePlan([planData.plan.id], {
          name: data.name,
          duration: parseFloat(data.duration),
          price: parseInt(data.price, 10) * 100,
          type: data.type,
          productCode: data.productCode,
          watermark: data.watermark,
          packages: { data: data.packages },
          customerPackageDefaultProperties: data.customerPackageDefaultProperties,
        });
        await onRefresh();
        closeDialog(result.id);
      } catch (e) {
        handleError(e);
      }
    },
    [planData]
  );

  const onDelete = useCallback(async () => {
    if (!planData) return;
    try {
      await deletePlan([planData.plan.id]);
      await onRefresh();
      closeDialog();
    } catch (e) {
      handleError(e);
    }
  }, [planData?.plan.id]);

  return (
    <Dialog open={!!planId} onClose={closeDialog} maxWidth="md">
      <DialogTitle>{t('adminProducts.plans.editDialog.title', { id: planData?.plan.id })}</DialogTitle>
      <DialogContent>
        {planData ? <ProductPlanForm form={form} onSubmit={onSubmit} editPlanId={planData.plan.id} /> : null}
      </DialogContent>

      <DialogActions>
        <span>
          <Button onClick={closeDialog}>{t('common.dialog.close')}</Button>
        </span>
        {planData?.plan.archived ? (
          <span>
            <Button variant="outlined" onClick={onUnArchive}>
              {t('adminProducts.plans.editDialog.unArchive')}
            </Button>
          </span>
        ) : (
          <span>
            <Button variant="outlined" onClick={onArchive}>
              {t('adminProducts.plans.editDialog.archive')}
            </Button>
          </span>
        )}
        {planData ? (
          <span>
            <DangerousButton
              component={AdminRouteLink}
              route="ProductsTabDeleteItem"
              params={['plans', planData.plan.id]}
              keepQuery
            >
              {t('common.button.delete')}
            </DangerousButton>
          </span>
        ) : null}

        <span>
          <Button variant="contained" color="primary" onClick={form.handleSubmit(onSubmit)}>
            {t('adminProducts.plans.editDialog.confirm')}
          </Button>
        </span>
      </DialogActions>

      {planData ? (
        <ConfirmationDialog
          open={isDeleting}
          title={t('adminProducts.plans.deleteDialog.title')}
          confirm={onDelete}
          abort={`${AdminRoutes.ProductsTabEditItem('plans', planData.plan.id)}${search}`}
          message={t('adminProducts.plans.deleteDialog.message', { name: planData.plan.name })}
          dangerous
        />
      ) : null}
    </Dialog>
  );
};
