export enum Colors {
  Yellow = '#FFBF00',
  Primary = '#FFBF00',

  Coral = '#4AA3A8',
  Secondary = '#4AA3A8',

  White = '#ffffff',
  Black = '#000000',
  Blue = '#5AAAFA',
  Lime = '#8cd211',
  Orange = '#ff9300',
  Red = '#FF1800',
  Navy = '#172532',
  DeepNavy = '#000F1C',
}
