import { ICustomerData } from './CustomerData';
import { IUserDetails } from './UserDetails';
import { IAdminDetails } from './AdminDetails';

export type IShortAdminData = Pick<IAdminDetails, 'firstName' | 'lastName' | 'email' | 'id'>;
export type IShortCustomerData = Pick<ICustomerData, 'lastName' | 'firstName' | 'email' | 'id' | 'company'>;

export interface IUserAccountResponse {
  user: Pick<IUserDetails, 'firstName' | 'lastName' | 'verified' | 'updated' | 'email' | 'phoneNumber'>;
  customer: IShortCustomerData;
  admin: Pick<IAdminDetails, 'lastName' | 'email' | 'id' | 'firstName'> & { zendeskId: IAdminDetails['zendesk'] };
}

export interface ICustomerAccountResponse {
  createdBy: IShortAdminData;
  customer: Omit<
    ICustomerData,
    'createdBy' | 'notes' | 'password' | 'role' | 'hubSpotId' | 'serviceDeskAccount' | 'stripeAccount'
  >;
}

export interface IAdminAccountResponse {
  admin: Omit<IAdminDetails, 'password'>;
}

export type IAccountResponse = IUserAccountResponse | ICustomerAccountResponse | IAdminAccountResponse;

export const isUserAccountResponse = (resp: IAccountResponse): resp is IUserAccountResponse =>
  'user' in resp && 'customer' in resp;

export const isCustomerAccountResponse = (resp: IAccountResponse): resp is ICustomerAccountResponse =>
  'createdBy' in resp && 'customer' in resp;

export const isAdminAccountResponse = (resp: IAccountResponse): resp is IAdminAccountResponse =>
  'admin' in resp && !('customer' in resp) && !('user' in resp);
