import React, { FC, useCallback, useContext, useMemo } from 'react';
import { Control, FormState, UseFormHandleSubmit } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import { ICustomerUpdatePayload } from '../../hooks/useCustomersApi';
import { ICustomerData } from '../../models/CustomerData';
import { createFetcher } from '../../contexts/Fetcher';
import { useAdminsApi } from '../../hooks/useAdminsApi';
import { SelectController } from '../InputControllers/SelectController';
import { countries } from '../../helpers/countries';
import { useTranslation } from 'react-i18next';
import { TextInputController } from '../InputControllers/TextInputController';
import { CheckboxController } from '../InputControllers/CheckboxController';

interface IProps {
  customer?: ICustomerData;
  onSubmit: (fields: ICustomerUpdatePayload) => void;
  control: Control;
  handleSubmit: UseFormHandleSubmit<ICustomerUpdatePayload>;
  formState: FormState<ICustomerUpdatePayload>;
}

const AdminsFetcher = createFetcher(() => {
  const { getAdmins } = useAdminsApi();
  return useCallback(async () => {
    const result = await getAdmins([], {
      page: 1,
      per: 1000,
      filter: {
        filterField: 'archived',
        filterOp: 'eq',
        filterValue: 'false',
      },
      sort: {
        sortBy: 'firstName',
        sortOrder: 'asc',
      },
    });
    return result.data;
  }, [getAdmins]);
});

const countryItems = countries.map((country) => ({
  label: country.name,
  value: country.code,
}));

const EditCustomerFormContent: FC<IProps> = ({ customer, onSubmit, control, handleSubmit, formState: { errors } }) => {
  const { data } = useContext(AdminsFetcher.Context);
  const { t } = useTranslation();

  const representativeItems = useMemo(() => {
    if (!data) return [];
    return data.map((admin) => ({
      value: admin.id,
      label: `${admin.firstName} ${admin.lastName}`,
    }));
  }, [data]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={6}>
          <TextInputController
            control={control}
            errors={errors}
            defaultValue={customer?.firstName ?? ''}
            label={t('common.formLabel.firstName')}
            rules={{ required: true }}
            name="firstName"
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextInputController
            control={control}
            errors={errors}
            defaultValue={customer?.lastName ?? ''}
            label={t('common.formLabel.lastName')}
            rules={{ required: true }}
            name="lastName"
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextInputController
            control={control}
            errors={errors}
            defaultValue={customer?.email ?? ''}
            label={t('common.formLabel.email')}
            rules={{ required: true }}
            name="email"
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextInputController
            control={control}
            errors={errors}
            defaultValue={customer?.phoneNumber ?? ''}
            label={t('common.formLabel.phoneNumber')}
            name="phoneNumber"
          />
        </Grid>

        <Grid item xs={12} lg={4}>
          <TextInputController
            control={control}
            errors={errors}
            defaultValue={customer?.company ?? ''}
            label={t('common.formLabel.company')}
            name="company"
          />
        </Grid>

        <Grid item xs={12} lg={4}>
          <SelectController
            control={control}
            errors={errors}
            items={representativeItems}
            label={t('editCustomerDialog.formLabel.representative')}
            name={'createdBy'}
            defaultValue={customer?.createdBy ?? ''}
            rules={{ required: true }}
          />
        </Grid>

        <Grid item xs={12} lg={4}>
          <TextInputController
            control={control}
            errors={errors}
            defaultValue={customer?.hubSpotId ?? ''}
            label={t('editCustomerDialog.formLabel.hubSpot')}
            name="hubSpotId"
          />
        </Grid>

        <Grid item xs={12}>
          <TextInputController
            control={control}
            errors={errors}
            defaultValue={customer?.address ?? ''}
            label={t('editCustomerDialog.formLabel.address')}
            name="address"
          />
        </Grid>

        <Grid item xs={12} lg={8}>
          <TextInputController
            control={control}
            errors={errors}
            defaultValue={customer?.billingAddress ?? ''}
            label={t('editCustomerDialog.formLabel.billingAddress')}
            name="billingAddress"
          />
        </Grid>

        <Grid item xs={12} lg={4}>
          <SelectController
            control={control}
            errors={errors}
            items={countryItems}
            label={t('editCustomerDialog.formLabel.country')}
            name={'billingAddressCountry'}
            defaultValue={customer?.billingAddressCountry ?? ''}
          />
        </Grid>

        <Grid item xs={12}>
          <TextInputController
            control={control}
            errors={errors}
            defaultValue={customer?.notes ?? ''}
            label={t('editCustomerDialog.formLabel.notes')}
            name="notes"
            InputProps={{
              multiline: true,
              rows: 4,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <CheckboxController
            control={control}
            errors={errors}
            defaultValue={customer?.MFAEnabled ?? false}
            label={t('editCustomerDialog.formLabel.enableMfa')}
            name="MFAEnabled"
          />
        </Grid>
      </Grid>
    </form>
  );
};

export const EditCustomerForm: FC<IProps> = (props) => {
  return (
    <AdminsFetcher.WAF>
      <EditCustomerFormContent {...props} />
    </AdminsFetcher.WAF>
  );
};
