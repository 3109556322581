import React, { FC } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { RouteWrapper } from '../components/RouteWrapper';
import { DashboardLayout } from '../layouts/DashboardLayout';
import { CustomerOverview } from '../components/CustomerOverview/CustomerOverview';
import { NotFoundComponent } from '../components/NotFound';
import { Users } from '../components/Users/Users';
import { User } from '../components/User/User';
import { LegacyRedirector } from './LegacyRedirector';
import { Products } from '../components/Products/Products';
import { Invoices } from '../components/Invoices/Invoices';
import { Documentation } from '../components/Documentation';
import { SupportPage } from '../components/SupportPage/SupportPage';
import { ViewAccount } from '../components/ViewAccount/ViewAccount';
import { CloudLive } from '../components/CloudLive/CloudLive';
import { ProductUsers } from '../components/ProductUsers/ProductUsers';
import { CustomerProductsFetcher } from '../fetchers/ProductsFetcher';
import { LiveRegionContextProvider } from '../contexts/LiveRegionContext';
import { customerRoot, CustomerRoutes } from './CustomerRoutes';
import { Downloads } from '../components/Downloads';
import { DownloadsProductContentPage, DownloadsProductExamplesPage, DownloadsProductLensPage, DownloadsProductToolsPage } from '../components/DownloadsProductAddons';
import { DownloadsProductInstallerPage } from '../components/DownloadProductInstallerPage/DownloadsProductInstallerPage';

/* eslint prettier/prettier: ["error", { printWidth: 1000 }] */

const ProvidersProducts = [CustomerProductsFetcher.WMF];
const ProvidersLiveRegion = [LiveRegionContextProvider];

export const CustomerRouter: FC = () => {
  return (
    <Switch>
      <Redirect exact from={customerRoot} to={CustomerRoutes.Overview()} />

      <RouteWrapper exact path={CustomerRoutes.Overview()} component={CustomerOverview} layout={DashboardLayout} />
      <RouteWrapper exact path={CustomerRoutes.Users()} component={Users} layout={DashboardLayout} />
      <RouteWrapper exact path={CustomerRoutes.NewUser()} component={Users} layout={DashboardLayout} />
      <RouteWrapper exact path={CustomerRoutes.User()} component={User} layout={DashboardLayout} />
      <RouteWrapper exact path={CustomerRoutes.UserProduct()} component={User} layout={DashboardLayout} />
      <RouteWrapper exact path={CustomerRoutes.UserLicenseOffline()} component={User} layout={DashboardLayout} />
      <RouteWrapper exact path={CustomerRoutes.UserLicenseRelease()} component={User} layout={DashboardLayout} />
      <RouteWrapper exact path={CustomerRoutes.UserLicenseUnassign()} component={User} layout={DashboardLayout} />
      <RouteWrapper exact path={CustomerRoutes.Products()} component={Products} layout={DashboardLayout} providers={ProvidersProducts} />

      <RouteWrapper exact path={CustomerRoutes.ProductUsers()} component={ProductUsers} layout={DashboardLayout} providers={ProvidersProducts} />
      <RouteWrapper exact path={CustomerRoutes.Invoices()} component={Invoices} layout={DashboardLayout} />
      <RouteWrapper exact path={CustomerRoutes.Documentation()} component={Documentation} layout={DashboardLayout} />
      <RouteWrapper exact path={CustomerRoutes.DocumentationProduct()} component={Documentation} layout={DashboardLayout} />
      <RouteWrapper exact path={CustomerRoutes.Support()} component={SupportPage} layout={DashboardLayout} />
      <RouteWrapper exact path={CustomerRoutes.Account()} component={ViewAccount} layout={DashboardLayout} />
      <RouteWrapper exact path={CustomerRoutes.LivePreview()} component={CloudLive} layout={DashboardLayout} providers={ProvidersLiveRegion} />
      <RouteWrapper exact path={CustomerRoutes.Downloads()} component={Downloads} layout={DashboardLayout} />
      <RouteWrapper exact path={CustomerRoutes.DownloadsProduct()} component={Downloads} layout={DashboardLayout} />
      <RouteWrapper exact path={CustomerRoutes.DownloadsProductLens()} component={DownloadsProductLensPage} layout={DashboardLayout} />
      <RouteWrapper exact path={CustomerRoutes.DownloadsProductVersion()} component={Downloads} layout={DashboardLayout} />
      <RouteWrapper exact path={CustomerRoutes.DownloadsProductContent()} component={DownloadsProductContentPage} layout={DashboardLayout} />
      <RouteWrapper exact path={CustomerRoutes.DownloadsProductExamples()} component={DownloadsProductExamplesPage} layout={DashboardLayout} />
      <RouteWrapper exact path={CustomerRoutes.DownloadsProductTools()} component={DownloadsProductToolsPage} layout={DashboardLayout} />
      <RouteWrapper exact path={CustomerRoutes.DownloadsProductInstaller()} component={DownloadsProductInstallerPage} layout={DashboardLayout} />

      <LegacyRedirector from={`${customerRoot}/user`} queryParams={['userId']} to={({ userId }) => CustomerRoutes.User(userId)} />

      <RouteWrapper path={customerRoot} component={NotFoundComponent} layout={DashboardLayout} />
    </Switch>
  );
};
