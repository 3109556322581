import React from 'react';
import Button from '@mui/material/Button';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
      },
      '&$disabled': {
        opacity: theme.palette.action.disabledOpacity,
        color: theme.palette.error.contrastText,
      },
    },
    disabled: {},
  })
);

export const DangerousButton: typeof Button = ({ ...props }) => {
  const styles = useStyles();
  return <Button {...props} className={styles.button} classes={{ disabled: styles.disabled }} />;
};
