import React, { FC, useCallback, useContext, useMemo } from 'react';
import { UrlQueryDataGrid } from '../UrlQueryDataGrid/UrlQueryDataGrid';
import { LicenseContext } from '../../contexts/LicenseContext';
import { OfflineAccessStepsDialog } from '../Licenses/OfflineAccessStepsDialog';
import { ReleaseLicenseStepsDialog } from '../Licenses/ReleaseLicenseStepsDialog';
import { GridColumns } from '@mui/x-data-grid';
import { LicenseExpiry } from '../LicenseExpiry/LicenseExpiry';
import { getDateValueFormatter } from '../../valueFormatters/DateValueFormatter';
import { DateFormat } from '../../helpers/dateFormat';
import { getEnumIsFilterOperator, getEnumIsNotFilterOperator } from '../../filterOperators/enumFilterOperator';
import { getLicenseMode, LicenseMode } from '../../helpers/licenseMode';
import Button from '@mui/material/Button';
import { createStatusChipComponent } from '../StatusChip/StatusChip';
import { IAssignedPackage } from '../../models/CustomerLicense';
import { CustomerRoutes } from '../../routers/CustomerRoutes';
import { useIntParams } from '../../hooks/useIntParams';
import { useIsRoute } from '../../hooks/useIsRoute';
import { useCloseDialog } from '../../hooks/useCloseDialog';
import { useUsersApi } from '../../hooks/useUsersApi';
import { UserFetcher } from './User';
import { ConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialog';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { RowButtonsContainer } from '../RowButtonsContainer/RowButtonsContainer';
import styled from '@mui/styles/styled';
import { CellContentTooltip } from '../CellContentTooltip/CellContentTooltip';
import { useTranslation } from 'react-i18next';
import { getLicenseStatus } from '../../helpers/licenseStatus';
import { KeepSearchLink } from '../KeepSearchLink/KeepSearchLink';
import { LicenseModeChip } from '../LicenseModeChip/LicenseModeChip';
import { Chip } from '@mui/material';
import { EasyFilter } from '../EasyFilter/EasyFilter';
import { IQuickFiltersProps } from '../EasyFilter/QuickFilters';
import { getBoolFilterOperator } from '../../filterOperators/boolFilterOperator';
import { ifError } from '../../helpers/ifError';

const StatusRenderer = createStatusChipComponent({
  valid: ['common.licenseStatus.valid', 'lime'],
  suspended: ['common.licenseStatus.suspended', 'red'],
  expiresSoon: ['common.licenseStatus.expiresSoon', 'orange'],
  expired: ['common.licenseStatus.expired', 'default'],
});

interface IProps {
  packages: IAssignedPackage[];
  allPackages: IAssignedPackage[];
}

interface IUrlParams {
  userId: string;
  productId: string;
  id?: string;
}

const NotInUse = styled('span')(({ theme }) => ({
  color: theme.palette.text.disabled,
}));

export const UserLicenses: FC<IProps> = ({ packages, allPackages }) => {
  const { userId, productId, id } = useIntParams<IUrlParams>();
  const { t } = useTranslation();

  const { onRefresh } = useContext(UserFetcher.Context);
  const {
    unassignLicense: apiUnassignLicense,
    offlineRelease,
    offlineReleaseWithAddons,
    onlineRelease,
  } = useUsersApi();

  const isOfflineDialogOpen = useIsRoute(CustomerRoutes.UserLicenseOffline);
  const isReleaseDialogOpen = useIsRoute(CustomerRoutes.UserLicenseRelease);
  const isUnassignDialogOpen = useIsRoute(CustomerRoutes.UserLicenseUnassign);

  const closeDialog = useCloseDialog(CustomerRoutes.UserProduct(userId, productId));

  const handleError = useApiErrorHandler();

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'license',
        headerName: t('common.tableHeader.license'),
        flex: 1,
        renderCell: ({ value, row }) => {
          return (
            <CellContentTooltip>
              {row.isAddon ? <Chip color="info" label={t('assignLicenses.addonChip')} /> : null} {value}
            </CellContentTooltip>
          );
        },
      },
      {
        field: 'expiration',
        headerName: t('common.tableHeader.expires'),
        width: 190,
        renderCell: ({ row }) => {
          return <LicenseExpiry type={row.type} expiry={row.expiration} />;
        },
        valueFormatter: getDateValueFormatter(DateFormat.DateMedium),
      },
      {
        field: 'status',
        headerName: t('common.tableHeader.status'),
        width: 120,
        renderCell: StatusRenderer,
        filterOperators: [
          getEnumIsFilterOperator({
            [t('common.licenseStatus.valid')]: ['valid', 'expiresSoon'],
            [t('common.licenseStatus.expiresSoon')]: ['expiresSoon'],
            [t('common.licenseStatus.expired')]: ['expired'],
            [t('common.licenseStatus.suspended')]: ['suspended'],
          }),
          getEnumIsNotFilterOperator({
            [t('common.licenseStatus.valid')]: ['valid', 'expiresSoon'],
            [t('common.licenseStatus.expiresSoon')]: ['expiresSoon'],
            [t('common.licenseStatus.expired')]: ['expired'],
            [t('common.licenseStatus.suspended')]: ['suspended'],
          }),
        ],
      },
      {
        field: 'inUseOn',
        headerName: t('common.tableHeader.inUseOn'),
        width: 120,
        renderCell: (params) => {
          if (params.value === t('common.notInUse')) return <NotInUse>{params.value}</NotInUse>;
          return <CellContentTooltip>{params.value}</CellContentTooltip>;
        },
      },
      {
        field: 'mode',
        headerName: t('common.tableHeader.mode'),
        width: 120,
        filterOperators: [
          getEnumIsFilterOperator({
            [t('common.tableMode.notInUse')]: [LicenseMode.NotInUse],
            [t('common.tableMode.online')]: [LicenseMode.Online],
            [t('common.tableMode.offline')]: [LicenseMode.OfflineFile, LicenseMode.OfflineDirector],
          }),
          getEnumIsNotFilterOperator({
            [t('common.tableMode.notInUse')]: [LicenseMode.NotInUse],
            [t('common.tableMode.online')]: [LicenseMode.Online],
            [t('common.tableMode.offline')]: [LicenseMode.OfflineFile, LicenseMode.OfflineDirector],
          }),
        ],
        renderCell: LicenseModeChip,
      },
      {
        field: 'Buttons',
        headerName: t('common.tableHeader.buttons'),
        renderHeader: () => ' ',
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          return (
            <RowButtonsContainer>
              {params.row.mode === LicenseMode.OfflineFile ? (
                <Button
                  component={KeepSearchLink}
                  to={CustomerRoutes.UserLicenseOffline(userId, productId, params.row.id)}
                  variant="outlined"
                  color="primary"
                >
                  {t('user.licenses.offlineDownloadButton')}
                </Button>
              ) : null}

              {params.row.mode === LicenseMode.NotInUse ? null : (
                <Button
                  component={KeepSearchLink}
                  to={CustomerRoutes.UserLicenseRelease(userId, productId, params.row.id)}
                  variant="outlined"
                  color="primary"
                >
                  {t('user.licenses.releaseButton')}
                </Button>
              )}

              {params.row.mode === LicenseMode.NotInUse ? (
                <Button
                  component={KeepSearchLink}
                  to={CustomerRoutes.UserLicenseUnassign(userId, productId, params.row.id)}
                  variant="outlined"
                  color="primary"
                >
                  {t('user.licenses.unAssignButton')}
                </Button>
              ) : null}
            </RowButtonsContainer>
          );
        },
        width: 280,
      },
      {
        field: 'isAddon',
        headerName: t('licenses.filter.isAddon'),
        hide: true,
        hideable: false,
        sortable: false,
        filterOperators: [getBoolFilterOperator()],
      },
    ],
    [userId, productId]
  );
  const rows = useMemo(() => {
    return packages.map((userPackage) => {
      const mode = ifError(
        () => getLicenseMode(userPackage.licenses.machine, userPackage.licenses, userPackage.licenses.offlineLicense),
        LicenseMode.Error
      );

      return {
        id: ifError(() => userPackage.userPackage.id, 0),
        license: ifError(() => `${userPackage.licenses.package.name} | ${userPackage.plan.name}`, '-'),
        offlineLicense: ifError(() => userPackage.licenses.offlineLicense, undefined),
        type: ifError(() => userPackage.plan.type, undefined),
        expiration: ifError(() => userPackage.licenses.expiresAt, 0),
        status: ifError(
          () =>
            getLicenseStatus(
              userPackage.licenses.license.suspended,
              userPackage.licenses.expiresAt,
              userPackage.plan.type
            ),
          ''
        ),
        inUseOn: ifError(
          () => (mode === LicenseMode.NotInUse ? t('common.notInUse') : userPackage.licenses.machine?.name),
          undefined
        ),
        mode,
        isAddon: ifError(() => userPackage.licenses.package.isAddon, false),
      };
    });
  }, [packages]);

  const pack = packages.find((pack) => pack.userPackage.id === id);

  const packagesOnSameLicense = useMemo(() => {
    if (!pack) return [];

    return allPackages
      .filter((packageItem) => packageItem.licenses.offlineLicense === pack.licenses.offlineLicense)
      .map((packageItem) => ({
        package: packageItem.licenses.package,
        userPackage: packageItem.userPackage,
        plan: packageItem.plan,
      }));
  }, [pack, allPackages]);

  const onOfflineRelease = useCallback(
    async (file: File) => {
      if (!id) return;
      try {
        if (file.name.toLowerCase().endsWith('.pxrelease')) {
          await offlineReleaseWithAddons(
            [userId],
            file,
            packagesOnSameLicense
              .filter((packageObj) => !packageObj.package.isAddon)
              .map((packageObj) => packageObj.userPackage.id),
            packagesOnSameLicense
              .filter((packageObj) => packageObj.package.isAddon)
              .map((packageObj) => packageObj.userPackage.id)
          );
        } else {
          await offlineRelease([userId, id], file);
        }
        await onRefresh();
      } catch (e) {
        handleError(e);
      }
    },
    [offlineRelease, offlineReleaseWithAddons, packagesOnSameLicense, userId, pack, id]
  );

  const onOnlineRelease = useCallback(async () => {
    if (!id) return;
    try {
      await onlineRelease([userId, id]);
      closeDialog();
      await onRefresh();
    } catch (e) {
      handleError(e);
    }
  }, [onlineRelease, userId, id]);

  const onUnassign = useCallback(async () => {
    if (!id) return;
    try {
      await apiUnassignLicense([userId, id]);
      await onRefresh();
      closeDialog();
    } catch (e) {
      handleError(e);
    }
  }, [apiUnassignLicense, userId, id]);

  const anyAddons = rows.some(({ isAddon }) => isAddon);

  const quickFilters = useMemo<IQuickFiltersProps['quickFilters']>(() => {
    return new Map([
      [
        t('licenses.filter.basePackages'),
        {
          filterField: 'isAddon',
          filterOp: 'equals',
          filterVal: 'false',
          isVisible: anyAddons,
        },
      ],
      [
        t('licenses.filter.addonPackages'),
        {
          filterField: 'isAddon',
          filterOp: 'equals',
          filterVal: 'true',
          isVisible: anyAddons,
        },
      ],
    ]);
  }, [anyAddons]);

  return (
    <div>
      <EasyFilter quickFilters={quickFilters} textFilter={false} />

      <UrlQueryDataGrid
        columns={columns}
        rows={rows}
        disableExtendRowFullWidth={false}
        autoHeight={true}
        disableSelectionOnClick={true}
      />

      {pack ? (
        <LicenseContext.Provider
          value={{
            license: pack.licenses.license,
            offlineLicense: pack.licenses.offlineLicense,
            machine: pack.licenses.machine,
            plan: pack.plan,
            packageDetails: pack.licenses.package,
            userPackageDetails: pack.userPackage,
            onOfflineRelease,
            onOnlineRelease,
            getLicenseRoute: CustomerRoutes.UserLicenseOffline,
            releaseLicenseRoute: CustomerRoutes.UserLicenseRelease,
            packagesOnSameLicense,
          }}
        >
          <OfflineAccessStepsDialog open={isOfflineDialogOpen} onClose={closeDialog} />
          <ReleaseLicenseStepsDialog open={isReleaseDialogOpen} onClose={closeDialog} />
          <ConfirmationDialog
            open={isUnassignDialogOpen}
            title={t('user.licenses.unAssignDialog.title')}
            message={t('user.licenses.unAssignDialog.message')}
            confirmLabel={t('user.licenses.unAssignDialog.confirm')}
            confirm={onUnassign}
            abort={closeDialog}
          />
        </LicenseContext.Provider>
      ) : null}
    </div>
  );
};
