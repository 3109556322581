export const plural = (quantity: number, singularForm: string, pluralForm?: string): string => {
  if (quantity === 1) return singularForm;
  if (!pluralForm) {
    if (singularForm.match(/(ch|sh|[sx])$/)) {
      pluralForm = `${singularForm}es`;
    } else if (
      singularForm.endsWith('y') &&
      !['a', 'e', 'i', 'o', 'u'].includes(singularForm.substr(singularForm.length - 2, 1))
    ) {
      pluralForm = singularForm.replace(/y$/, 'ies');
    } else {
      pluralForm = `${singularForm}s`;
    }
  }
  return pluralForm;
};
