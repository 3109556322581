import React, { FC, useCallback, useContext, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { AssignLicenses } from './AssignLicenses';
import { UserFetcher } from '../User/User';
import { IAssignLicensePayload, useUsersApi } from '../../hooks/useUsersApi';
import { useTranslation } from 'react-i18next';

interface IProps {
  open: boolean;
  closeDialog: () => void;
}

export const AssignLicensesDialog: FC<IProps> = ({ open, closeDialog }) => {
  const { data, onRefresh } = useContext(UserFetcher.Context);
  const assignLicensesPayloadRef = useRef<IAssignLicensePayload[]>([]);
  const { assignLicense } = useUsersApi();
  const [isAnyLicenseToSubmit, setAnyLicenseToSubmit] = useState(false);
  const { t } = useTranslation();

  const updateLicensesPayload = useCallback((data: IAssignLicensePayload[]) => {
    assignLicensesPayloadRef.current = data;
    setAnyLicenseToSubmit(data.length > 0);
  }, []);

  const submitAssignLicenses = useCallback(async () => {
    if (!data || assignLicensesPayloadRef.current.length === 0) return;
    for (const payload of assignLicensesPayloadRef.current) {
      await assignLicense([data.user.id], payload);
    }
    await onRefresh();
    closeDialog();
  }, [data?.user.id, assignLicense, closeDialog]);

  if (!data) return null;
  return (
    <Dialog open={open} onClose={closeDialog} maxWidth="md">
      <DialogTitle>{t('assignLicenses.title')}</DialogTitle>
      <DialogContent>
        <AssignLicenses availableLicenses={data.unassignedLicenses} onFormPayloadUpdate={updateLicensesPayload} />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>{t('common.dialog.close')}</Button>
        <Button variant="contained" color="primary" onClick={submitAssignLicenses} disabled={!isAnyLicenseToSubmit}>
          {t('common.dialog.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
