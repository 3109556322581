import { createFetcher } from '../contexts/Fetcher';
import { useAnalyticsApi } from '../hooks/useAnalyticsApi';
import { useCallback } from 'react';

export const ActivityAccountFetcher = createFetcher(() => {
  const { getAccountActivity } = useAnalyticsApi();
  return useCallback(
    ({ ip }: { ip: string }) => {
      return getAccountActivity([], ip);
    },
    [getAccountActivity]
  );
});
