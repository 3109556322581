import { useCallback, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { useAuthApi } from './useAuthApi';
import { AccountContext } from '../contexts/AccountContext';
import { AccountType } from '../types/AccountType';
import { GlobalContext } from '../contexts/GlobalContext';

export enum AuthErrors {
  Unauthorized,
  Unexpected,
  NotImplemented,
  SecondFactorNeeded,
}

export interface IBackendAccountData {
  email: string;
  name: string;
  phone: string;
}

interface IBaseUserSignInResponse {
  refreshToken: {
    MFAEnabled: boolean;
    expiresAt: string;
    string: string;
  };
}

export interface ICustomerUserSignInResponse extends IBaseUserSignInResponse {
  flags: {
    showEula: boolean;
  };
  parentAccount: IBackendAccountData;
  userAccount: IBackendAccountData;
}
export type ICustomerAdminSignInResponse = IBaseUserSignInResponse;

export interface ITokenResponse {
  accessToken: string;
  expiresAt: string;
  refreshToken: string;
}

const isCustomerUserSignInResponse = (response: IBaseUserSignInResponse): response is ICustomerUserSignInResponse => {
  return 'userAccount' in response;
};

export const useAuth = () => {
  const accountType = useContext(AccountContext);
  const { setSignInFastTrack } = useContext(GlobalContext);
  const { setUser, setCustomer, setAdmin, auth2faCache, setAuth2faCache } = useContext(AuthContext);

  const { authenticate: apiAuthenticate, obtainToken, signOut, renewMfa: renewMfaApi } = useAuthApi();

  const handleAuthResponse = useCallback(
    async (response: ITokenResponse, email: string, userData?: IBackendAccountData) => {
      if (typeof response === 'number') {
        return response;
      } else {
        setAuth2faCache(null);
        setSignInFastTrack(null);
        if (accountType === AccountType.User) {
          setUser({
            accountData: userData!,
            tokenData: response,
            email,
          });
        } else if (accountType === AccountType.Customer) {
          setCustomer({
            tokenData: response,
            email,
          });
        } else if (accountType === AccountType.Admin) {
          setAdmin({ tokenData: response, email });
        }
      }
    },
    []
  );

  const oneFactorAuth = useCallback(
    async (token: string, email: string, userData?: IBackendAccountData) => {
      const tokenResponse = await obtainToken(token);
      if (typeof tokenResponse === 'number') {
        return tokenResponse;
      } else {
        handleAuthResponse(tokenResponse, email, userData);
      }
    },
    [obtainToken, handleAuthResponse]
  );

  const twoFactorAuth = useCallback(
    async (code: string) => {
      if (!auth2faCache) {
        return AuthErrors.Unexpected;
      }
      const tokenResponse = await obtainToken(auth2faCache.refreshToken, code);
      if (typeof tokenResponse === 'number') {
        return tokenResponse;
      } else {
        return handleAuthResponse(tokenResponse, auth2faCache.email, auth2faCache.userData);
      }
    },
    [obtainToken, handleAuthResponse, auth2faCache]
  );

  const authenticate = useCallback(
    async (email: string, password: string) => {
      const result = await apiAuthenticate(email, password);

      if (typeof result === 'number') {
        return result;
      } else {
        if (result.refreshToken.MFAEnabled) {
          setAuth2faCache({
            email,
            refreshToken: result.refreshToken.string,
            userData: isCustomerUserSignInResponse(result) ? result.userAccount : undefined,
          });
          return AuthErrors.SecondFactorNeeded;
        } else {
          await oneFactorAuth(
            result.refreshToken.string,
            email,
            isCustomerUserSignInResponse(result) ? result.userAccount : undefined
          );
        }
        return true;
      }
    },
    [apiAuthenticate]
  );

  const logOut: () => Promise<void> = useCallback(async () => {
    await signOut();

    if (accountType === AccountType.User) {
      setUser(null);
    } else if (accountType === AccountType.Customer) {
      setCustomer(null);
    } else if (accountType === AccountType.Admin) {
      setAdmin(null);
    }
  }, []);

  const renewMfa = useCallback(async () => {
    if (!auth2faCache) {
      return AuthErrors.Unexpected;
    }
    return await renewMfaApi(auth2faCache.refreshToken);
  }, [renewMfaApi]);

  return {
    authenticate,
    logOut,
    twoFactorAuth,
    renewMfa,
  };
};
