import styled from '@mui/styles/styled';

export const Changelog = styled('div')(({ theme }) => ({
  '& a': {
    color: theme.palette.primary.main,
    '&:visited': {
      color: theme.palette.secondary.main,
    },
  },
  '& h1,& h2,& h3,& h4,& h5,& h6': {
    margin: 0,
    marginBottom: theme.spacing(2),
  },
  '& h1': theme.typography.h1,
  '& h2': theme.typography.h2,
  '& h3': theme.typography.h3,
  '& h4': theme.typography.h4,
  '& h5': theme.typography.h5,
  '& h6': theme.typography.h6,
}));
