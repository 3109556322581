import { composeFetcher, createFetcherWrapper } from '../contexts/Fetcher';
import { LivePreviewFetcher } from '../components/CloudLive/CloudLive';
import { useIntParams } from '../hooks/useIntParams';
import { useCallback, useContext } from 'react';
import { useAdminLiveApi } from '../hooks/useAdminLiveApi';
import { LiveRegionContext } from '../contexts/LiveRegionContext';

const LiveContext = LivePreviewFetcher.Context;

interface IUrlParams {
  customerId: string;
}

const LiveWrapper = createFetcherWrapper(LiveContext, () => {
  const { customerId } = useIntParams<IUrlParams>();
  const { getCustomerChannelsList, getCustomerChannelsListSilent } = useAdminLiveApi();
  const { region } = useContext(LiveRegionContext);

  return useCallback(
    async ({ silent = false }) => {
      if (!region) return [];
      if (silent) {
        return getCustomerChannelsListSilent([customerId, region]);
      }
      return getCustomerChannelsList([customerId, region]);
    },
    [customerId, getCustomerChannelsList, getCustomerChannelsListSilent, region]
  );
});

export const CustomerLiveFetcher = composeFetcher(LiveContext, LiveWrapper);
