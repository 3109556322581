import React, { FC, useCallback, useContext, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { CloseDialogFunc } from '../../hooks/useCloseDialog';
import { useForm } from 'react-hook-form';
import { IProductFormPayload, ProductForm } from './ProductForm';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { useProductsApi } from '../../hooks/useProductsApi';
import { ProductsFetcher } from '../../fetchers/ProductsFetcher';
import { IProductsResponse } from '../../models/ProductsResponse';
import { Single } from '../../types/single';
import { DangerousButton } from '../CustomButtons/DangerousButton';
import { useLocation } from 'react-router-dom';
import { AdminRoutes } from '../../routers/AdminRoutes';
import { useIsRoute } from '../../hooks/useIsRoute';
import { ConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialog';
import { useTranslation } from 'react-i18next';
import { AdminRouteLink } from '../RouteLink/RouteLink';

interface IProps {
  product?: Single<IProductsResponse['products']>;
  closeDialog: CloseDialogFunc;
}

export const EditProductDialog: FC<IProps> = ({ product, closeDialog }) => {
  const form = useForm();
  const handleError = useApiErrorHandler();
  const { deleteProduct, updateProduct } = useProductsApi();
  const { onRefresh } = useContext(ProductsFetcher.Context);
  const { search } = useLocation();
  const isDeleting = useIsRoute(AdminRoutes.ProductsTabDeleteItem);
  const { t } = useTranslation();

  useEffect(() => {
    form.reset({
      name: product?.name ?? '',
      plans: product?.plans.filter((plan) => plan).map((plan) => plan!.id) ?? [],
    });
  }, [product]);

  const onSubmit = useCallback(
    async (payload: IProductFormPayload) => {
      if (!product) return;
      try {
        const result = await updateProduct([product.id], payload);
        await onRefresh();
        closeDialog(result.id);
      } catch (e) {
        handleError(e);
      }
    },
    [product, updateProduct]
  );

  const onDelete = useCallback(async () => {
    if (!product) return;
    try {
      await deleteProduct([product.id]);
      await onRefresh();
      closeDialog();
    } catch (e) {
      handleError(e);
    }
  }, [product]);

  return (
    <Dialog open={!!product} onClose={closeDialog} maxWidth="md">
      <DialogTitle>{t('adminProducts.products.editDialog.title', { id: product?.id })}</DialogTitle>
      <DialogContent>
        <ProductForm form={form} onSubmit={onSubmit} isEdit />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>{t('common.dialog.close')}</Button>

        {product ? (
          <DangerousButton
            component={AdminRouteLink}
            route="ProductsTabDeleteItem"
            params={['products', product.id]}
            keepQuery
          >
            {t('common.button.delete')}
          </DangerousButton>
        ) : null}

        <Button variant="contained" color="primary" onClick={form.handleSubmit(onSubmit)}>
          {t('adminProducts.products.editDialog.confirm')}
        </Button>
      </DialogActions>

      {product ? (
        <ConfirmationDialog
          open={isDeleting}
          title={t('adminProducts.products.deleteDialog.title')}
          dangerous
          confirm={onDelete}
          abort={`${AdminRoutes.ProductsTabEditItem('products', product.id)}${search}`}
          message={t('adminProducts.products.deleteDialog.message', { name: product.name })}
        />
      ) : null}
    </Dialog>
  );
};
