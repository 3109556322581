import React, { FC } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { RouteWrapper } from '../components/RouteWrapper';
import { DashboardLayout } from '../layouts/DashboardLayout';
import { NotFoundComponent } from '../components/NotFound';
import { Licenses } from '../components/Licenses/Licenses';
import { UserOverview } from '../components/UserOverview';
import { Downloads } from '../components/Downloads';
import { DownloadsProductContentPage, DownloadsProductExamplesPage, DownloadsProductLensPage, DownloadsProductToolsPage } from '../components/DownloadsProductAddons';
import { Documentation } from '../components/Documentation';
import { CloudLive } from '../components/CloudLive/CloudLive';
import { SupportPage } from '../components/SupportPage/SupportPage';
import { ApiEvents } from '../components/ApiEvents/ApiEvents';
import { config } from '../config';
import { ViewAccount } from '../components/ViewAccount/ViewAccount';
import { LiveRegionContextProvider } from '../contexts/LiveRegionContext';
import { userRoot, UserRoutes } from './UserRoutes';
import { DownloadsProductInstallerPage } from '../components/DownloadProductInstallerPage/DownloadsProductInstallerPage';

/* eslint prettier/prettier: ["error", { printWidth: 1000 }] */

const ProvidersLiveRegion = [LiveRegionContextProvider];

export const UserRouter: FC = () => {
  return (
    <Switch>
      <Redirect exact from={userRoot} to={UserRoutes.Overview()} />

      <Redirect exact from={UserRoutes.DownloadLegacy()} to={UserRoutes.Downloads()} />

      <RouteWrapper exact path={UserRoutes.Overview()} component={UserOverview} layout={DashboardLayout} />
      <RouteWrapper exact path={UserRoutes.OverviewProduct()} component={UserOverview} layout={DashboardLayout} />
      <RouteWrapper exact path={UserRoutes.Licenses()} component={Licenses} layout={DashboardLayout} />
      <RouteWrapper exact path={UserRoutes.RequestOffline()} component={Licenses} layout={DashboardLayout} />
      <RouteWrapper exact path={UserRoutes.LicenseOffline()} component={Licenses} layout={DashboardLayout} />
      <RouteWrapper exact path={UserRoutes.LicenseRelease()} component={Licenses} layout={DashboardLayout} />
      <RouteWrapper exact path={UserRoutes.Downloads()} component={Downloads} layout={DashboardLayout} />

      <RouteWrapper exact path={UserRoutes.DownloadsProduct()} component={Downloads} layout={DashboardLayout} />
      <RouteWrapper exact path={UserRoutes.DownloadsProductLens()} component={DownloadsProductLensPage} layout={DashboardLayout} />
      <RouteWrapper exact path={UserRoutes.DownloadsProductVersion()} component={Downloads} layout={DashboardLayout} />
      <RouteWrapper exact path={UserRoutes.DownloadsProductContent()} component={DownloadsProductContentPage} layout={DashboardLayout} />
      <RouteWrapper exact path={UserRoutes.DownloadsProductExamples()} component={DownloadsProductExamplesPage} layout={DashboardLayout} />
      <RouteWrapper exact path={UserRoutes.DownloadsProductTools()} component={DownloadsProductToolsPage} layout={DashboardLayout} />
      <RouteWrapper exact path={UserRoutes.DownloadsProductInstaller()} component={DownloadsProductInstallerPage} layout={DashboardLayout} />
      <RouteWrapper exact path={UserRoutes.Documentation()} component={Documentation} layout={DashboardLayout} />
      <RouteWrapper exact path={UserRoutes.DocumentationProduct()} component={Documentation} layout={DashboardLayout} />

      <RouteWrapper exact path={UserRoutes.LivePreview()} component={CloudLive} layout={DashboardLayout} providers={ProvidersLiveRegion} />

      <RouteWrapper exact path={UserRoutes.Support()} component={SupportPage} layout={DashboardLayout} />

      <RouteWrapper exact path={UserRoutes.Account()} component={ViewAccount} layout={DashboardLayout} />

      {config.enableDeveloperTools ? <RouteWrapper exact path={UserRoutes.Events()} component={ApiEvents} layout={DashboardLayout} /> : null}

      <RouteWrapper path={userRoot} component={NotFoundComponent} layout={DashboardLayout} />
    </Switch>
  );
};
