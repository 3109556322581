import { useApi } from './useApi';
import { IPaginatedContent } from '../models/PaginatedContent';
import { IAdminDetails } from '../models/AdminDetails';
import { AdminsEndpoints } from './api/endpoints/admins';
import { ApiRequest, useEndpoint, usePaginatedGetEndpoint, useSimpleEndpoint } from './api/apiEndpoint';

export type IAdminUpdatePayload = Pick<
  IAdminDetails,
  'firstName' | 'lastName' | 'email' | 'phoneNumber' | 'notes' | 'role' | 'MFAEnabled'
>;

export const useAdminsApi = () => {
  const { del, get, post, put } = useApi();

  const getAdmins = usePaginatedGetEndpoint(
    AdminsEndpoints.Admins,
    get as ApiRequest<typeof get, IPaginatedContent<IAdminDetails>>
  );

  const getAdmin = useSimpleEndpoint(AdminsEndpoints.Admin, get as ApiRequest<typeof get, IAdminDetails>);

  const reInvite = useSimpleEndpoint(AdminsEndpoints.ReInvite, post as ApiRequest<typeof post, void>);

  const updateAdmin = useEndpoint(
    (put, payload: Partial<IAdminUpdatePayload>) => put(payload),
    AdminsEndpoints.Admin,
    put as ApiRequest<typeof put, IAdminDetails>
  );

  const createAdmin = useEndpoint(
    (post, payload: IAdminUpdatePayload, pageSize?: number) => post(payload, { params: { per: pageSize } }),
    AdminsEndpoints.InviteAdmin,
    post as ApiRequest<typeof post, IPaginatedContent<IAdminDetails>>
  );

  const deleteAdmin = useSimpleEndpoint(AdminsEndpoints.Admin, del);

  const reassignZendeskId = useSimpleEndpoint(
    AdminsEndpoints.ReassignZendeskId,
    post as ApiRequest<typeof post, IAdminDetails>
  );

  return {
    getAdmins,
    getAdmin,
    reInvite,
    updateAdmin,
    createAdmin,
    deleteAdmin,
    reassignZendeskId,
  };
};
