import React, { FC } from 'react';
import { IRunningLiveStackDetails } from '../../hooks/useLiveApi';
import { useTranslation } from 'react-i18next';
import { ConnectionDetail } from './ConnectionDetail';
import Hub from '@mui/icons-material/Hub';

interface IProps {
  details: IRunningLiveStackDetails;
}

export const DataConnections: FC<IProps> = ({ details }) => {
  const { t } = useTranslation();

  return (
    <div>
      {details.datahub ? (
        <ConnectionDetail label={t('live.stack.dataConnections.datahub')} ip={details.datahub.ip} Icon={Hub} />
      ) : null}
    </div>
  );
};
