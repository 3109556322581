import { useApi } from './useApi';
import { ILiveRegionsResponse } from '../models/LiveRegionsResponse';
import { ApiRequest, useEndpoint, useSimpleEndpoint } from './api/apiEndpoint';
import { AdminLiveEndpoints } from './api/endpoints/adminLive';
import { ILiveStack } from './useLiveApi';
import { ILiveStackAccessResponse } from '../models/LiveStackAccessResponse';

export interface ICreateLiveStackPayload {
  userId: number;
  cameraId: number;
  subscriptionPlan: number;
  protocol: 'SRT';
  capacityReservation: boolean;
  allowListCIDR?: string;
}

export const useAdminLiveApi = () => {
  const { del, get, post } = useApi();
  const { get: silentGet } = useApi({ indicateLoading: false });

  const getAllStacksList = useSimpleEndpoint(
    AdminLiveEndpoints.AllLiveStacks,
    get as ApiRequest<typeof get, ILiveStack[]>
  );

  const getAllStacksListSilent = useSimpleEndpoint(
    AdminLiveEndpoints.AllLiveStacks,
    silentGet as ApiRequest<typeof silentGet, ILiveStack[]>
  );

  const getCustomerChannelsList = useSimpleEndpoint(
    AdminLiveEndpoints.CustomerLiveStacks,
    get as ApiRequest<typeof get, ILiveStack[]>
  );

  const getCustomerChannelsListSilent = useSimpleEndpoint(
    AdminLiveEndpoints.CustomerLiveStacks,
    silentGet as ApiRequest<typeof silentGet, ILiveStack[]>
  );

  const createLiveStack = useEndpoint(
    (post, payload: ICreateLiveStackPayload) => post(payload),
    AdminLiveEndpoints.CustomerLiveStacks,
    post as ApiRequest<typeof post, void>
  );

  const removeLiveStack = useSimpleEndpoint(AdminLiveEndpoints.CustomerLiveStack, del as ApiRequest<typeof del, void>);

  const start = useSimpleEndpoint(AdminLiveEndpoints.StartLiveChannel, post);

  const stop = useSimpleEndpoint(AdminLiveEndpoints.StopLiveChannel, post);

  const getAccessData = useSimpleEndpoint(
    AdminLiveEndpoints.CustomerLiveStackAccess,
    get as ApiRequest<typeof get, ILiveStackAccessResponse>
  );

  const getAccessDataSilent = useSimpleEndpoint(
    AdminLiveEndpoints.CustomerLiveStackAccess,
    silentGet as ApiRequest<typeof silentGet, ILiveStackAccessResponse>
  );

  const getCustomerRegions = useSimpleEndpoint(
    AdminLiveEndpoints.LiveRegions,
    get as ApiRequest<typeof get, ILiveRegionsResponse>
  );

  const getRegions = useSimpleEndpoint(
    AdminLiveEndpoints.GlobalLiveRegions,
    get as ApiRequest<typeof get, ILiveRegionsResponse>
  );

  return {
    getAllStacksList,
    getAllStacksListSilent,
    getCustomerChannelsList,
    getCustomerChannelsListSilent,
    createLiveStack,
    removeLiveStack,
    start,
    stop,
    getAccessData,
    getAccessDataSilent,
    getCustomerRegions,
    getRegions,
  };
};
