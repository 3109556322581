import { getStringEqualsOperator } from './stringEqualsOperator';
import { GridFilterOperator } from '@mui/x-data-grid';
import { t } from 'i18next';

export const getStringContainsOperator = (): GridFilterOperator => ({
  value: 'contains',
  label: t('dataGrid.filterOperatorContains'),
  InputComponent: getStringEqualsOperator().InputComponent,
  getApplyFilterFn: (filterItem) => {
    return (params) => {
      return `${params.value}`.toLowerCase().includes(filterItem.value.toLowerCase());
    };
  },
});
