export const displayGeoCoordinate = (angle: number, dimension: 'latitude' | 'longitude'): string => {
  const dir =
    dimension === 'latitude' && angle > 0
      ? 'N'
      : dimension === 'latitude'
      ? 'S'
      : dimension === 'longitude' && angle > 0
      ? 'E'
      : 'W';

  let val = Math.abs(angle);
  const degrees = Math.trunc(Math.abs(val));

  val = (val - degrees) * 60;
  const minutes = Math.trunc(val);

  val = (val - minutes) * 60;
  const seconds = Math.round(val);

  return `${degrees}°${minutes}'${seconds}" ${dir}`;
};
