import React, { FC, useCallback, useContext, useMemo } from 'react';
import { PageHeader } from '../PageHeader/PageHeader';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { DateFormat } from '../../helpers/dateFormat';
import { useTranslation } from 'react-i18next';
import { Date } from '../Date/Date';
import { useIsRoute } from '../../hooks/useIsRoute';
import Email from '@mui/icons-material/Email';
import { EditAdminDialog } from '../EditAdminDialog/EditAdminDialog';
import { useCloseDialog } from '../../hooks/useCloseDialog';
import { isAdminAccountResponse, isCustomerAccountResponse, isUserAccountResponse } from '../../models/AccountResponse';
import { AdminRouteLink } from '../RouteLink/RouteLink';
import { AdminRoutes } from '../../routers/AdminRoutes';
import { AdminFetcher } from '../Admin/Admin';
import { IAdminDetails } from '../../models/AdminDetails';
import { AccountFetcher } from '../../fetchers/AccountFetcher';
import { styled } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import { useAccountApi } from '../../hooks/useAccountApi';
import { useEnumState } from '../../hooks/useEnumState';
import { ConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialog';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { useAuth } from '../../hooks/useAuth';
import { AccountContext } from '../../contexts/AccountContext';
import { useAccount } from '../../hooks/useAccount';
import { GlobalContext } from '../../contexts/GlobalContext';

enum EMfaState {
  Closed,
  EnableOpen,
  DisableOpen,
}

const TitleCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.text.disabled,
  width: '23vw',
}));

const PrimaryText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(0.5),
}));

const ViewAccountContent: FC = () => {
  const { t } = useTranslation();
  const { data, onRefresh, onDataUpdate, isInvalidated, invalidateData } = useContext(AccountFetcher.Context);
  const { setMfaEnabled } = useAccountApi();
  const isEdit = useIsRoute(AdminRoutes.AccountEdit);
  const [mfaState, closeMfaDialog, openEnableMfaDialog, openDisableMfaDialog] = useEnumState(
    EMfaState.Closed,
    EMfaState.Closed,
    EMfaState.EnableOpen,
    EMfaState.DisableOpen
  );
  const handleApiError = useApiErrorHandler();
  const { logOut } = useAuth();

  const accountType = useContext(AccountContext)!;
  const { account } = useAccount(accountType);
  const { setSignInFastTrack } = useContext(GlobalContext);

  const closeDialog = useCloseDialog(AdminRoutes.Account());

  const adminFetcherContextValue = useMemo(
    () => ({
      data: data && isAdminAccountResponse(data) ? { ...data.admin, password: '' } : null,
      onDataUpdate: (data: IAdminDetails) => {
        onDataUpdate({
          admin: data,
        });
      },
      isInvalidated,
      onRefresh: async () => {
        const newData = await onRefresh();
        if (newData && isAdminAccountResponse(newData)) {
          return { ...newData.admin, password: '' };
        }
        return null;
      },
      isLoading: false,
      invalidateData,
    }),
    [data, invalidateData]
  );

  const onEnableMfa = useCallback(async () => {
    try {
      await setMfaEnabled([], { MFAEnabled: true });
      setSignInFastTrack({
        accountType,
        email: account!.email,
      });
      await logOut();
    } catch (e) {
      handleApiError(e);
    }
  }, [setMfaEnabled]);

  const onDisableMfa = useCallback(async () => {
    try {
      await setMfaEnabled([], { MFAEnabled: false });
      setSignInFastTrack({
        accountType,
        email: account!.email,
      });
      await logOut();
    } catch (e) {
      handleApiError(e);
    }
  }, [setMfaEnabled]);

  if (!data) return null;

  const mfaSupported = isCustomerAccountResponse(data) || isAdminAccountResponse(data);
  const mfaEnabled =
    (isCustomerAccountResponse(data) && data.customer.MFAEnabled) ||
    (isAdminAccountResponse(data) && data.admin.MFAEnabled);

  const email = isCustomerAccountResponse(data)
    ? data.customer.email
    : isAdminAccountResponse(data)
    ? data.admin.email
    : data.user.email;

  return (
    <>
      <PageHeader title={t('viewAccount.header')}>
        {isAdminAccountResponse(data) ? (
          <Button component={AdminRouteLink} route="AccountEdit" params={[]} variant="contained" color="primary">
            {t('common.button.edit')}
          </Button>
        ) : null}
        {isCustomerAccountResponse(data) ? (
          <Button
            component="a"
            variant="contained"
            color="primary"
            endIcon={<Email />}
            href={`mailto:${data.createdBy.email}`}
          >
            {t('viewAccount.contactAdminToEdit')}
          </Button>
        ) : null}
        {isUserAccountResponse(data) ? (
          <Button
            component="a"
            variant="contained"
            color="primary"
            endIcon={<Email />}
            href={`mailto:${data.customer.email}`}
          >
            {t('viewAccount.contactAdminToEdit')}
          </Button>
        ) : null}
      </PageHeader>

      <Table>
        {isAdminAccountResponse(data) ? (
          <TableBody>
            <TableRow>
              <TitleCell>{t('common.tableHeader.name')}</TitleCell>
              <TableCell>
                {data.admin.firstName} {data.admin.lastName}
              </TableCell>
            </TableRow>
            <TableRow>
              <TitleCell>{t('common.tableHeader.email')}</TitleCell>
              <TableCell>{data.admin.email}</TableCell>
            </TableRow>
            <TableRow>
              <TitleCell>{t('common.tableHeader.phone')}</TitleCell>
              <TableCell>{data.admin.phoneNumber}</TableCell>
            </TableRow>
            <TableRow>
              <TitleCell>{t('viewAccount.lastUpdateLabel')}</TitleCell>
              <TableCell>
                <Date date={data.admin.updated} format={DateFormat.DateWithFullTime} />
              </TableCell>
            </TableRow>
          </TableBody>
        ) : null}

        {isCustomerAccountResponse(data) ? (
          <TableBody>
            <TableRow>
              <TitleCell>{t('common.tableHeader.name')}</TitleCell>
              <TableCell>
                {data.customer.firstName} {data.customer.lastName}
              </TableCell>
            </TableRow>
            <TableRow>
              <TitleCell>{t('common.tableHeader.email')}</TitleCell>
              <TableCell>{data.customer.email}</TableCell>
            </TableRow>
            <TableRow>
              <TitleCell>{t('common.tableHeader.phone')}</TitleCell>
              <TableCell>{data.customer.phoneNumber}</TableCell>
            </TableRow>
            <TableRow>
              <TitleCell>{t('viewAccount.createdByLabel')}</TitleCell>
              <TableCell>
                {data.createdBy.firstName} {data.createdBy.lastName}
              </TableCell>
            </TableRow>
            <TableRow>
              <TitleCell>{t('common.tableHeader.company')}</TitleCell>
              <TableCell>{data.customer.company}</TableCell>
            </TableRow>
            <TableRow>
              <TitleCell>{t('viewAccount.addressLabel')}</TitleCell>
              <TableCell>{data.customer.address}</TableCell>
            </TableRow>
            <TableRow>
              <TitleCell>{t('viewAccount.billingAddressLabel')}</TitleCell>
              <TableCell>{data.customer.billingAddress}</TableCell>
            </TableRow>
            <TableRow>
              <TitleCell>{t('viewAccount.countryLabel')}</TitleCell>
              <TableCell>{data.customer.billingAddressCountry}</TableCell>
            </TableRow>
            <TableRow>
              <TitleCell>{t('viewAccount.lastUpdateLabel')}</TitleCell>
              <TableCell>
                <Date date={data.customer.updated} format={DateFormat.DateWithFullTime} />
              </TableCell>
            </TableRow>
          </TableBody>
        ) : null}

        {isUserAccountResponse(data) ? (
          <TableBody>
            <TableRow>
              <TitleCell>{t('common.tableHeader.name')}</TitleCell>
              <TableCell>
                {data.user.firstName} {data.user.lastName}
              </TableCell>
            </TableRow>
            <TableRow>
              <TitleCell>{t('common.tableHeader.email')}</TitleCell>
              <TableCell>{data.user.email}</TableCell>
            </TableRow>
            <TableRow>
              <TitleCell>{t('common.tableHeader.phone')}</TitleCell>
              <TableCell>{data.user.phoneNumber}</TableCell>
            </TableRow>
            <TableRow>
              <TitleCell>{t('viewAccount.lastUpdateLabel')}</TitleCell>
              <TableCell>
                <Date date={data.user.updated} format={DateFormat.DateWithFullTime} />
              </TableCell>
            </TableRow>
          </TableBody>
        ) : null}
      </Table>

      {mfaSupported ? (
        <Box mt={5}>
          <PageHeader title={t('viewAccount.settingsHeader')} />

          <Table>
            <TableBody>
              <TableRow>
                <TitleCell>
                  <PrimaryText>{t('viewAccount.2fa.header')}</PrimaryText>
                  <Typography>{t('viewAccount.2fa.description')}</Typography>
                </TitleCell>
                <TableCell>
                  {mfaEnabled ? (
                    <Button variant="text" color="error" onClick={openDisableMfaDialog}>
                      {t('viewAccount.2fa.disable')}
                    </Button>
                  ) : (
                    <Button variant="contained" color="primary" onClick={openEnableMfaDialog}>
                      {t('viewAccount.2fa.enable')}
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <ConfirmationDialog
            open={mfaState === EMfaState.EnableOpen}
            title={t('viewAccount.2fa.enableDialog.title')}
            message={t('viewAccount.2fa.enableDialog.message', { email })}
            confirm={onEnableMfa}
            abort={closeMfaDialog}
            confirmLabel={t('viewAccount.2fa.enableDialog.confirm')}
            abortLabel={t('viewAccount.2fa.enableDialog.cancel')}
          />

          <ConfirmationDialog
            open={mfaState === EMfaState.DisableOpen}
            title={t('viewAccount.2fa.disableDialog.title')}
            message={t('viewAccount.2fa.disableDialog.message')}
            confirm={onDisableMfa}
            abort={closeMfaDialog}
            confirmLabel={t('viewAccount.2fa.disableDialog.confirm')}
            abortLabel={t('viewAccount.2fa.disableDialog.cancel')}
          />
        </Box>
      ) : null}

      {isAdminAccountResponse(data) ? (
        <AdminFetcher.Context.Provider value={adminFetcherContextValue}>
          <EditAdminDialog open={isEdit} closeDialog={closeDialog} />
        </AdminFetcher.Context.Provider>
      ) : null}
    </>
  );
};

export const ViewAccount: FC = () => {
  return (
    <AccountFetcher.WAF>
      <ViewAccountContent />
    </AccountFetcher.WAF>
  );
};
