import React, { FC } from 'react';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { ErrorPage } from '../ErrorPage/ErrorPage';
import { Link } from 'react-router-dom';
import { useHomeUrl } from '../../hooks/useHomeUrl';

export const NotFoundComponent: FC = () => {
  const { t } = useTranslation();
  const homeUrl = useHomeUrl();

  return (
    <ErrorPage title={t('errorPage.notFound.title')} message={t('errorPage.notFound.message')}>
      <Button variant="contained" color="primary" component={Link} to={homeUrl}>
        {t('errorPage.button.backHome')}
      </Button>
    </ErrorPage>
  );
};
