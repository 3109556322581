import React, { FC, useCallback, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IAdminUpdatePayload, useAdminsApi } from '../../hooks/useAdminsApi';
import { EditAdminForm } from './EditAdminForm';
import { AdminsFetcher } from '../Admins/Admins';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { usePageSize } from '../../hooks/usePageSize';
import { useTranslation } from 'react-i18next';
import { NotificationContext } from '../../contexts/NotificationContext';

interface IProps {
  open: boolean;
  closeDialog: () => void;
}

export const AddAdminDialog: FC<IProps> = ({ open, closeDialog }) => {
  const { data, onDataUpdate } = useContext(AdminsFetcher.Context);
  const { createAdmin } = useAdminsApi();
  const apiErrorHandler = useApiErrorHandler();
  const pageSize = usePageSize();
  const { t } = useTranslation();
  const { addNotification } = useContext(NotificationContext);

  const { control, handleSubmit, formState, reset } = useForm();

  useEffect(() => {
    reset({
      role: 'admin',
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      notes: '',
    });
  }, [open]);

  const onSubmit = useCallback(
    async (fields: IAdminUpdatePayload) => {
      if (!data) return;
      try {
        const newData = await createAdmin([], fields, pageSize);
        addNotification({
          message: t('editAdminDialog.add.invitationSent'),
          severity: 'success',
        });
        onDataUpdate(newData);
        closeDialog();
      } catch (e) {
        apiErrorHandler(e);
      }
    },
    [createAdmin, closeDialog]
  );

  if (!data) return null;
  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>{t('editAdminDialog.add.title')}</DialogTitle>
      <DialogContent>
        <EditAdminForm onSubmit={onSubmit} {...{ control, handleSubmit, formState }} />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>{t('common.dialog.close')}</Button>
        <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
          {t('editAdminDialog.add.confirmButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
