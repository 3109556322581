import { createFetcher } from '../../contexts/Fetcher';
import { ICustomerUserUrlParams } from './CustomerUser';
import { useCallback } from 'react';
import { useCustomersApi } from '../../hooks/useCustomersApi';
import { useCustomerUsersApi } from '../../hooks/useCustomerUsersApi';
import { useIntParams } from '../../hooks/useIntParams';

export const CustomerUserFetcher = createFetcher(() => {
  const { customerId, userId } = useIntParams<ICustomerUserUrlParams>();
  const { getCustomerUsers } = useCustomersApi();

  return useCallback(async () => {
    const result = await getCustomerUsers([customerId], {
      page: 1,
      per: 1,
      filter: {
        filterField: 'id',
        filterOp: 'eq',
        filterValue: userId.toString(),
      },
    });
    return result.data[0];
  }, [getCustomerUsers, customerId, userId]);
});

export const CustomerUserPackagesFetcher = createFetcher(() => {
  const { customerId, userId } = useIntParams<ICustomerUserUrlParams>();
  const { getPackages } = useCustomerUsersApi();
  return useCallback(() => {
    return getPackages([customerId, userId]);
  }, [customerId, userId]);
});
