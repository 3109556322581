import { useCallback, useRef } from 'react';

export const usePreventSimultaneousCallback = (callback: () => Promise<any>, deps: any[]) => {
  const isProcessing = useRef(false);

  const executeCallback = useCallback(async () => {
    if (isProcessing.current) return;

    isProcessing.current = true;
    try {
      await callback();
      isProcessing.current = false;
    } catch (e) {
      isProcessing.current = false;
      throw e;
    }
  }, deps);

  return executeCallback;
};
