import { useCallback, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { dateFormat, DateFormat } from '../helpers/dateFormat';
import { useTranslation } from 'react-i18next';

export const useFormattedNow = (format: DateFormat) => {
  const {
    i18n: { language },
  } = useTranslation();

  const [formattedNow, setFormattedNow] = useState(dateFormat(dayjs(), format, language));
  const formattedNowRef = useRef(formattedNow);

  const updateFormattedNow = useCallback((value: string) => {
    if (formattedNowRef.current !== value) {
      formattedNowRef.current = value;
      setFormattedNow(value);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      updateFormattedNow(dateFormat(dayjs(), format, language));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [format]);

  return formattedNow;
};
