import React, { CSSProperties, FC } from 'react';
import { Control, FormState, UseFormHandleSubmit, Controller, UseFormWatch, FieldValues } from 'react-hook-form';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import { TextInputController } from '../InputControllers/TextInputController';
import { IProductReleaseApiData } from '../../hooks/useReleasesApi';
import { CheckboxController } from '../InputControllers/CheckboxController';
import { RichTextEditor } from '../RichTextEditor/RichTextEditor';
import { useTranslation } from 'react-i18next';
import { UploadingState } from '../../hooks/useS3Upload';
import { FileInputController } from '../InputControllers/FileInputController';
import { Progressbar } from '../Progressbar/Progressbar';

export interface IProductReleaseForm extends Omit<IProductReleaseApiData, 'pinCode' | 'docFile'> {
  pinCode: string;
}

interface IProps {
  onSubmit: (fields: IProductReleaseForm) => void;
  control: Control;
  handleSubmit: UseFormHandleSubmit<IProductReleaseApiData>;
  formState: FormState<IProductReleaseForm>;
  watch: UseFormWatch<FieldValues>;
}

interface IPropsWithUpload extends IProps {
  upload: (file: File) => void;
  uploadProgress: number;
  uploadState: UploadingState;
}

const isPropsWithUpload = (props: IProps): props is IPropsWithUpload => {
  return typeof (props as any).upload === 'function';
};

export const ProductReleaseForm: FC<IProps | IPropsWithUpload> = (props) => {
  const { onSubmit, control, handleSubmit, formState, watch } = props;
  const { t } = useTranslation();
  const installerFilePath = watch('filePath');
  const version = watch('version');
  const docsFile = watch('docs');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormLabel>{t('releases.formLabel.changelog')}</FormLabel>
          <Controller
            name={'changelogNotes'}
            control={control}
            render={({ field }) => <RichTextEditor onChange={field.onChange} value={field.value} />}
          />
        </Grid>

        <Grid item xs={12}>
          <CheckboxController
            control={control}
            errors={formState.errors}
            label={t('releases.formLabel.forceUpdate')}
            name="forceUpdate"
          />
        </Grid>

        <Grid item xs={12}>
          {t('releases.formLabel.releaseVersion')}: {version}
        </Grid>

        <Grid item xs={12}>
          {t('releases.formLabel.filePath')}: {installerFilePath}
        </Grid>

        {isPropsWithUpload(props) ? (
          <Grid item xs={12}>
            <FileInputController control={control} label={t('releases.formLabel.docs')} name="docs" />
            <Box component="span" ml={1}>
              {docsFile?.[0]?.name ?? null}
            </Box>
            {props.uploadState === UploadingState.IN_PROGRESS ? (
              <Box marginTop={1} marginBottom={1}>
                <Progressbar style={{ '--progress': `${props.uploadProgress}%` } as CSSProperties} />
              </Box>
            ) : null}
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <TextInputController
            control={control}
            errors={formState.errors}
            rules={{ required: true }}
            inputType="number"
            label={t('releases.formLabel.pinCode')}
            name="pinCode"
          />
        </Grid>
      </Grid>
    </form>
  );
};
