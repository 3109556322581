import { apiEndpoint } from '../apiEndpoint';

export const LiveEndpoints = {
  LiveStacks: apiEndpoint<[region: string]>('/live/stack/:region'),
  LiveStack: apiEndpoint<[region: string, stackId: string]>('/live/stack/:region/:stack'),
  LiveStackAccess: apiEndpoint<[region: string, stackId: string]>('/live/stack/:region/:stack/access'),
  LiveStackStart: apiEndpoint<[region: string, stackId: string]>('/live/stack/:region/:stack/start'),
  LiveStackStop: apiEndpoint<[region: string, stackId: string]>('/live/stack/:region/:stack/stop'),
  LiveRegions: apiEndpoint<[]>('/live/regions'),
};
