import { useParams } from 'react-router-dom';
import { useMemo } from 'react';

export const useDecodedParams = <T>() => {
  const params = useParams<T>();
  return useMemo<T>(() => {
    return Object.entries(params).reduce((memo, [key, value]) => {
      memo[key as keyof T] = value ? decodeURIComponent(value) : value;
      return memo;
    }, {} as T);
  }, [params]);
};
