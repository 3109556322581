import React, { FC, useCallback, useContext } from 'react';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IUpdateUserPayload } from '../../hooks/useUsersApi';
import { NotificationContext } from '../../contexts/NotificationContext';
import { CustomerUserFetcher } from './CustomerUserFetchers';
import { useCustomerUsersApi } from '../../hooks/useCustomerUsersApi';
import { EditUserForm } from '../EditUserDialog/EditUserForm';
import { useTranslation } from 'react-i18next';
import { joiResolver } from '@hookform/resolvers/joi';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { userFormSchema } from '../../formSchemas/userFormSchema';

interface IProps {
  open: boolean;
  closeDialog: () => void;
}

export const CustomerUserEditDialog: FC<IProps> = ({ open, closeDialog }) => {
  const { data, onDataUpdate } = useContext(CustomerUserFetcher.Context);
  const { updateUser } = useCustomerUsersApi();
  const { addNotification } = useContext(NotificationContext);
  const { t } = useTranslation();
  const handleApiError = useApiErrorHandler();

  const { control, handleSubmit, formState } = useForm({
    resolver: joiResolver(userFormSchema),
  });

  const onSubmit = useCallback(
    async (fields: IUpdateUserPayload) => {
      if (!data) return;
      const payload: Partial<IUpdateUserPayload> = {};
      let anythingToUpdate = false;
      for (const [field, value] of Object.entries(fields) as [
        keyof IUpdateUserPayload,
        IUpdateUserPayload[keyof IUpdateUserPayload]
      ][]) {
        if (value !== data[field]) {
          anythingToUpdate = true;
          payload[field] = value;
        }
      }
      if (anythingToUpdate) {
        try {
          const newUserDetails = await updateUser([data.customerId, data.id], payload);
          onDataUpdate(newUserDetails);
          closeDialog();
        } catch (e) {
          handleApiError(e);
        }
      } else {
        addNotification({
          severity: 'warning',
          message: t('customerUser.editDialog.nothingChanged'),
        });
      }
    },
    [data, updateUser, closeDialog]
  );

  if (!data) return null;
  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>{t('customerUser.editDialog.title')}</DialogTitle>
      <DialogContent>
        <EditUserForm onSubmit={onSubmit} user={data} {...{ control, handleSubmit, formState }} />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>{t('common.dialog.close')}</Button>
        <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
          {t('customerUser.editDialog.confirmButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
