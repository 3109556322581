import React, { FC, useCallback, useEffect } from 'react';
import { GridFilterInputValueProps } from '@mui/x-data-grid';
import { SelectProps } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import styled from '@mui/styles/styled';

type OptArr<T> = T | T[];
type PossibleValuesMap = Record<string, OptArr<string | boolean | number>>;

const StyledFormControl = styled(FormControl)({
  marginTop: 0,
});

export const getFilterEnumInput = (possibleValues: PossibleValuesMap) => {
  const FilterEnumInput: FC<GridFilterInputValueProps> = ({ item, applyValue }) => {
    const onChange: SelectProps['onChange'] = useCallback(
      (event) => {
        applyValue({ ...item, value: event.target.value });
      },
      [item]
    );

    useEffect(() => {
      if (!item.value) {
        const firstValue = Object.values(possibleValues)[0];
        applyValue({ ...item, value: Array.isArray(firstValue) ? firstValue.join(',') : firstValue });
      }
    }, [item.operatorValue]);

    return (
      <StyledFormControl variant="filled">
        <InputLabel>Value</InputLabel>
        <Select value={item.value} onChange={onChange} native>
          {Object.entries(possibleValues).map(([key, value]) => (
            <option value={Array.isArray(value) ? value.join(',') : value.toString()} key={key}>
              {key}
            </option>
          ))}
        </Select>
      </StyledFormControl>
    );
  };
  return FilterEnumInput;
};
