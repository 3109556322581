import React, { FC, useContext } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { LoadingContext } from '../contexts/LoadingContext';
import { Notifications } from '../components/Notifications';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundImage: `linear-gradient(45.05deg, ${theme.palette.background.paper} 0%, ${theme.palette.background.default} 100%)`,
      height: '100vh',
      color: theme.palette.text.primary,
    },
    backdrop: {
      zIndex: 9999,
    },
    logo: {
      maxHeight: '30vh',
      objectFit: 'contain',
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(5),
      boxSizing: 'border-box',
    },
    container: {
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
    },
    gridContainer: {
      justifyContent: 'center',
    },
  })
);

export const AuthLayout: FC = ({ children }) => {
  const styles = useStyles();
  const { isLoading } = useContext(LoadingContext);

  return (
    <div className={styles.root}>
      {children}
      <Notifications />
      <Backdrop open={isLoading} className={styles.backdrop}>
        <CircularProgress />
      </Backdrop>
    </div>
  );
};
