import React, { FC, useContext } from 'react';
import { RedirectProps } from 'react-router';
import { Redirect, useLocation } from 'react-router-dom';
import { GlobalContext } from '../contexts/GlobalContext';
import { GuestRoutes } from './GuestRouter';

export const RedirectToAuth: FC<Omit<RedirectProps, 'to'>> = (props) => {
  const { pathname, search } = useLocation();
  const { signInFastTrack } = useContext(GlobalContext);

  const url = `${pathname}${search ?? ''}`;
  const searchParams = new URLSearchParams({ redirect: url });

  const to = signInFastTrack ? GuestRoutes.Password(signInFastTrack.accountType) : GuestRoutes.SignIn();

  if (signInFastTrack) {
    searchParams.set('email', signInFastTrack.email);
  }

  return <Redirect {...props} to={`${to}?${searchParams}`} />;
};
