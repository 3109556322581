import { useCallback } from 'react';
import axios, { AxiosResponse } from 'axios';
import { IRolesResponse } from '../../models/RolesResponse';
import { IServerSidePaginatedFetcherParams } from '../../contexts/Fetcher';
import { Single } from '../../types/single';

interface IApiEndpoint<Params extends Array<any>> {
  template: string;
  path: (...args: Params) => string;
}

export const apiEndpoint = <Params extends Array<any>>(template: string): IApiEndpoint<Params> => {
  const path = (...args: Params): string => {
    const argsCpy = [...args];
    return template.replaceAll(/:[^/]+/g, (val) => argsCpy.shift() ?? val);
  };
  return { template, path };
};

export type ApiRequest<Request extends (...args: any[]) => any, Return> = (
  ...args: Parameters<Request>
) => Promise<AxiosResponse<Return>>;

const useWrapExec = <Return, ExtraParams extends Array<any>, RestRequestParams extends Array<any>>(
  request: (path: string, ...restRequestParams: RestRequestParams) => Promise<AxiosResponse<Return>>,
  exec: (request: (...args: RestRequestParams) => Promise<Return>, ...extraParams: ExtraParams) => Promise<Return>
) => {
  return useCallback(
    (path: string, ...extraParams: ExtraParams) => {
      return exec(async (...args: RestRequestParams) => {
        return (await request(path, ...args)).data;
      }, ...extraParams);
    },
    [request]
  );
};

export const useEndpoint = <
  Return,
  Params extends Array<any>,
  ExtraParams extends Array<any>,
  RestRequestParameters extends Array<any>
>(
  exec: (request: (...args: RestRequestParameters) => Promise<Return>, ...extraParams: ExtraParams) => Promise<Return>,
  endpoint: IApiEndpoint<Params>,
  request: (path: string, ...params: RestRequestParameters) => Promise<AxiosResponse<Return>>
) => {
  const wrappedExec = useWrapExec(request, exec);

  return useCallback(
    (params: Params, ...extraParams: ExtraParams) => wrappedExec(endpoint.path(...params), ...extraParams),
    [request, endpoint]
  );
};

export const useSimpleEndpoint = <Return, Params extends Array<any>>(
  endpoint: IApiEndpoint<Params>,
  request: (path: string) => Promise<AxiosResponse<Return>>
) => {
  return useEndpoint((request) => request(), endpoint, request);
};

export const usePaginatedGetEndpoint = <Return, Params extends Array<any>>(
  endpoint: IApiEndpoint<Params>,
  get: (...params: Parameters<typeof axios.get>) => Promise<AxiosResponse<Return>>
) => {
  return useEndpoint(
    (get, { page, per, filter, sort }: IServerSidePaginatedFetcherParams) =>
      get({ params: { page, per, ...filter, ...sort } }),
    endpoint,
    get
  );
};

export type RoutesEndpoints<Routes extends Record<string, (...args: any) => string>> = {
  [RouteName in keyof Routes]: Array<
    Single<IRolesResponse['endpoints']> & {
      routeParams?: Parameters<Routes[RouteName]>;
    }
  >;
};
