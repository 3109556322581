import React, { FC } from 'react';
import { GridCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import Chip from '@mui/material/Chip';

const BoolChipComponent: FC<{ value?: boolean }> = ({ value }) => {
  const { t } = useTranslation();
  if (typeof value !== 'boolean') return <span>-</span>;
  return <Chip color={value ? 'primary' : 'default'} label={t(`common.bool.${value.toString()}`)} />;
};

export const BoolChip: FC<GridCellParams> = (params) => {
  return <BoolChipComponent value={params.value} />;
};
