import { useEffect, useRef, useState } from 'react';

export const useCountdown = (target: number | null, updateInterval: number | null = null) => {
  const startTimestamp = useRef(0);
  const [remaining, setRemaining] = useState(0);

  useEffect(() => {
    const now = (startTimestamp.current = Date.now());

    if (!target || target < now) return;

    setRemaining(target - now);

    const timeout = window.setTimeout(() => {
      if (Date.now() >= target) {
        setRemaining(0);
      }
    }, target - now);

    return () => clearTimeout(timeout);
  }, [target]);

  useEffect(() => {
    if (target && updateInterval) {
      const updateRemainingTime = () => {
        const now = Date.now();
        setRemaining(target < now ? 0 : target - now);
      };
      updateRemainingTime();
      const interval = window.setInterval(updateRemainingTime, updateInterval);

      return () => clearInterval(interval);
    }
  }, [target, updateInterval]);

  return remaining;
};
