import React, { FC, MouseEvent, useCallback, useEffect, useState, KeyboardEvent } from 'react';
import { Editor, EditorState, RichUtils, DraftHandleValue } from 'draft-js';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Code from '@mui/icons-material/Code';
import FormatBold from '@mui/icons-material/FormatBold';
import FormatItalic from '@mui/icons-material/FormatItalic';
import FormatListBulleted from '@mui/icons-material/FormatListBulleted';
import FormatListNumbered from '@mui/icons-material/FormatListNumbered';
import FormatQuote from '@mui/icons-material/FormatQuote';
import FormatUnderlined from '@mui/icons-material/FormatUnderlined';
import Visibility from '@mui/icons-material/Visibility';
import { useEnumState } from '../../hooks/useEnumState';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import { useTranslation } from 'react-i18next';
import 'draft-js/dist/Draft.css';

const useStyles = makeStyles((theme) =>
  createStyles({
    activeBtn: {
      background: theme.palette.primary.light,
      color: theme.palette.common.white,
      '&:hover': {
        color: theme.palette.primary.light,
      },
    },
    htmlInput: {
      fontFamily: 'monospace',
    },
  })
);

interface IProps {
  value: string;
  onChange: (value: string) => void;
  error?: string;
}

export const RichTextEditor: FC<IProps> = ({ value, onChange, error }) => {
  const { t } = useTranslation();
  const [editorState, setEditorState] = useState(EditorState.createWithContent(stateFromHTML(value)));
  const [codeMode, setCodeMode, setRichMode] = useEnumState(false, true, false);
  const [editorStateHtml, setEditorStateHtml] = useState(stateToHTML(editorState.getCurrentContent()));

  const styles = useStyles();

  const inlineStyle = editorState.getCurrentInlineStyle();

  useEffect(() => {
    const html = stateToHTML(editorState.getCurrentContent());
    setEditorStateHtml(html);
    onChange(html);
  }, [editorState]);

  useEffect(() => {
    if (codeMode) return;
    if (value !== editorStateHtml) {
      setEditorState(EditorState.createWithContent(stateFromHTML(value)));
    }
  }, [value, codeMode]);

  const onHtmlChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  }, []);

  const handleKeyCommand = useCallback((command: string, editorState: EditorState): DraftHandleValue => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  }, []);

  const onBold = useCallback((e: MouseEvent) => {
    e.preventDefault();
    setEditorState((state) => RichUtils.toggleInlineStyle(state, 'BOLD'));
  }, []);

  const onItalic = useCallback((e: MouseEvent) => {
    e.preventDefault();
    setEditorState((state) => RichUtils.toggleInlineStyle(state, 'ITALIC'));
  }, []);

  const onUnderlined = useCallback((e: MouseEvent) => {
    e.preventDefault();
    setEditorState((state) => RichUtils.toggleInlineStyle(state, 'UNDERLINE'));
  }, []);

  const onQuote = useCallback((e: MouseEvent) => {
    e.preventDefault();
    setEditorState((state) => RichUtils.toggleBlockType(state, 'blockquote'));
  }, []);

  const onBulletList = useCallback((e: MouseEvent) => {
    e.preventDefault();
    setEditorState((state) => RichUtils.toggleBlockType(state, 'unordered-list-item'));
  }, []);
  const onNumberedList = useCallback((e: MouseEvent) => {
    e.preventDefault();
    setEditorState((state) => RichUtils.toggleBlockType(state, 'ordered-list-item'));
  }, []);

  const onH1 = useCallback((e: MouseEvent) => {
    e.preventDefault();
    setEditorState((state) => RichUtils.toggleBlockType(state, 'header-one'));
  }, []);

  const onH2 = useCallback((e: MouseEvent) => {
    e.preventDefault();
    setEditorState((state) => RichUtils.toggleBlockType(state, 'header-two'));
  }, []);

  const onH3 = useCallback((e: MouseEvent) => {
    e.preventDefault();
    setEditorState((state) => RichUtils.toggleBlockType(state, 'header-three'));
  }, []);

  const onH4 = useCallback((e: MouseEvent) => {
    e.preventDefault();
    setEditorState((state) => RichUtils.toggleBlockType(state, 'header-four'));
  }, []);

  const onH5 = useCallback((e: MouseEvent) => {
    e.preventDefault();
    setEditorState((state) => RichUtils.toggleBlockType(state, 'header-five'));
  }, []);

  const onH6 = useCallback((e: MouseEvent) => {
    e.preventDefault();
    setEditorState((state) => RichUtils.toggleBlockType(state, 'header-six'));
  }, []);

  const onParagraph = useCallback((e: MouseEvent) => {
    e.preventDefault();
    setEditorState((state) => RichUtils.toggleBlockType(state, 'paragraph'));
  }, []);

  const onCode = useCallback((e: MouseEvent) => {
    e.preventDefault();
    setEditorState((state) => RichUtils.toggleBlockType(state, 'code-block'));
  }, []);

  const onTab = useCallback((event: KeyboardEvent) => {
    setEditorState((state) => {
      return RichUtils.onTab(event, state, 3);
    });
  }, []);

  return (
    <div>
      <Box marginBottom={1}>
        <ButtonGroup>
          <IconButton className={codeMode ? '' : styles.activeBtn} onClick={setRichMode} size="large">
            <Visibility />
          </IconButton>
          <IconButton className={codeMode ? styles.activeBtn : ''} onClick={setCodeMode} size="large">
            <Code />
          </IconButton>
        </ButtonGroup>
      </Box>

      {codeMode ? (
        <div>
          <TextField
            fullWidth
            label={t('component.richTextEditor.htmlLabel')}
            type="text"
            InputProps={{
              multiline: true,
              rows: 4,
              className: styles.htmlInput,
            }}
            value={value}
            onChange={onHtmlChange}
          />
          {error ? <FormHelperText error>{error}</FormHelperText> : null}
        </div>
      ) : (
        <div>
          <Box marginBottom={1}>
            <ButtonGroup>
              <Button onMouseDown={onH1}>H1</Button>
              <Button onMouseDown={onH2}>H2</Button>
              <Button onMouseDown={onH3}>H3</Button>
              <Button onMouseDown={onH4}>H4</Button>
              <Button onMouseDown={onH5}>H5</Button>
              <Button onMouseDown={onH6}>H6</Button>
              <Button onMouseDown={onParagraph}>{t('component.richTextEditor.paragraph')}</Button>
              <Button onMouseDown={onCode}>{t('component.richTextEditor.code')}</Button>
            </ButtonGroup>
          </Box>

          <div>
            <ButtonGroup>
              <IconButton className={inlineStyle.has('BOLD') ? styles.activeBtn : ''} onMouseDown={onBold} size="large">
                <FormatBold />
              </IconButton>
              <IconButton
                className={inlineStyle.has('ITALIC') ? styles.activeBtn : ''}
                onMouseDown={onItalic}
                size="large"
              >
                <FormatItalic />
              </IconButton>
              <IconButton
                className={inlineStyle.has('UNDERLINE') ? styles.activeBtn : ''}
                onMouseDown={onUnderlined}
                size="large"
              >
                <FormatUnderlined />
              </IconButton>
              <IconButton className={inlineStyle.has('') ? styles.activeBtn : ''} onMouseDown={onQuote} size="large">
                <FormatQuote />
              </IconButton>
              <IconButton
                className={inlineStyle.has('') ? styles.activeBtn : ''}
                onMouseDown={onBulletList}
                size="large"
              >
                <FormatListBulleted />
              </IconButton>
              <IconButton
                className={inlineStyle.has('') ? styles.activeBtn : ''}
                onMouseDown={onNumberedList}
                size="large"
              >
                <FormatListNumbered />
              </IconButton>
            </ButtonGroup>
          </div>

          <Paper>
            <Box padding={1}>
              <Editor
                editorState={editorState}
                onChange={setEditorState}
                handleKeyCommand={handleKeyCommand}
                onTab={onTab}
              />
            </Box>
          </Paper>

          {error ? <FormHelperText error>{error}</FormHelperText> : null}
        </div>
      )}
    </div>
  );
};
