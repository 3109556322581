import React, { FC, useCallback, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ProductToolForm } from './ProductToolForm';
import { IProductToolPayload, useProductToolsApi } from '../../hooks/useProductToolsApi';
import { CloseDialogFunc } from '../../hooks/useCloseDialog';
import { IProductPlanPackageTool } from '../../models/ProductPlanPackageTool';
import { DangerousButton } from '../CustomButtons/DangerousButton';
import { AdminRoutes } from '../../routers/AdminRoutes';
import { useLocation } from 'react-router-dom';
import { ConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialog';
import { useIsRoute } from '../../hooks/useIsRoute';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { ProductToolsFetcher } from '../../fetchers/ProductTools';
import { useTranslation } from 'react-i18next';
import { AdminRouteLink } from '../RouteLink/RouteLink';

interface IProps {
  tool?: IProductPlanPackageTool;
  closeDialog: CloseDialogFunc;
}

export const EditProductToolDialog: FC<IProps> = ({ tool, closeDialog }) => {
  const form = useForm();
  const { search } = useLocation();
  const isDeleting = useIsRoute(AdminRoutes.ProductsTabDeleteItem);
  const handleError = useApiErrorHandler();
  const { updateTool, deleteTool } = useProductToolsApi();
  const { data, onRefresh } = useContext(ProductToolsFetcher.Context);
  const { t } = useTranslation();

  useEffect(() => {
    form.reset({
      key: tool?.key ?? '',
      filter: tool?.filter ?? false,
    });
  }, [tool]);

  const onSubmit = useCallback(
    async (payload: IProductToolPayload) => {
      if (!tool || !data) return;
      try {
        const updatedTool = await updateTool([tool.id], payload);
        await onRefresh();
        closeDialog(updatedTool.id);
      } catch (e) {
        handleError(e);
      }
    },
    [tool, data]
  );

  const onDelete = useCallback(async () => {
    if (!tool) return;
    try {
      await deleteTool([tool.id]);
      await onRefresh();
      closeDialog();
    } catch (e) {
      handleError(e);
    }
  }, [tool?.id]);

  return (
    <Dialog open={!!tool} onClose={closeDialog}>
      <DialogTitle>{t('adminProducts.tools.editDialog.title', { id: tool?.id })}</DialogTitle>
      <DialogContent>{tool ? <ProductToolForm form={form} onSubmit={onSubmit} /> : null}</DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>{t('common.dialog.close')}</Button>
        {tool ? (
          <DangerousButton
            component={AdminRouteLink}
            route="ProductsTabDeleteItem"
            params={['tools', tool.id]}
            keepQuery
          >
            {t('common.button.delete')}
          </DangerousButton>
        ) : null}

        <Button variant="contained" color="primary" onClick={form.handleSubmit(onSubmit)}>
          {t('adminProducts.tools.editDialog.confirm')}
        </Button>
      </DialogActions>

      {tool ? (
        <ConfirmationDialog
          open={isDeleting}
          title={t('adminProducts.tools.deleteDialog.title')}
          confirm={onDelete}
          abort={`${AdminRoutes.ProductsTabEditItem('tools', tool.id)}${search}`}
          message={t('adminProducts.tools.deleteDialog.message', { name: tool.key })}
          dangerous
        />
      ) : null}
    </Dialog>
  );
};
