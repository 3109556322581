import React, { FC, useRef } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { IAdminOverviewResponse } from '../../models/AdminOverviewResponse';
import { DateFormat } from '../../helpers/dateFormat';
import { useInjectedAnchors } from '../../hooks/useInjectedAnchors';
import { useShowMore } from '../../hooks/useShowMore';
import { combineCn } from '../../helpers/combineCn';
import { Single } from '../../types/single';
import { useTranslation } from 'react-i18next';
import { Date } from '../Date/Date';
import { Changelog } from '../Changelog/Changelog';
import { AdminRouteLink } from '../RouteLink/RouteLink';
import { ButtonWithChevron } from '../ButtonWithChevron/ButtonWithChevron';
import styled from '@mui/styles/styled';

interface IProps {
  release: Single<IAdminOverviewResponse['releases']>;
}

enum ShowMoreState {
  INITIAL,
  HIDDEN,
  VISIBLE,
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      overflow: 'hidden',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      padding: theme.spacing(2),
      textAlign: 'center',
    },
    content: {
      padding: theme.spacing(2),
    },
    changelog: {
      '& a[data-toggle]': {
        display: 'none',
      },
    },
    changelogCollapsed: {
      maxHeight: 200,
      overflow: 'hidden',
    },
    showMore: {
      cursor: 'pointer',
    },
    releasesButtonContainer: {
      textAlign: 'right',
    },
  })
);

const FlexSpace = styled('div')({
  flex: 1,
});

const StyledAdminRouteLink = styled(AdminRouteLink)(({ theme }) => ({
  textDecoration: 'none',
  padding: theme.spacing(2),
}));

export const AdminOverviewRelease: FC<IProps> = ({ release }) => {
  const styles = useStyles();
  const changelogRef = useRef<HTMLDivElement>(null);
  const dialogChangelogRef = useRef<HTMLDivElement>(null);
  useInjectedAnchors(changelogRef);
  useInjectedAnchors(dialogChangelogRef);
  const { t } = useTranslation();
  const { showMore, setShowMore, setHideMore } = useShowMore(changelogRef, 200);

  return (
    <Paper className={styles.root}>
      <div className={styles.header}>
        <Typography variant="h4">{release.version}</Typography>
        <Typography variant="body1">{release.product?.name ?? '-'}</Typography>
      </div>
      <div className={styles.content}>
        <Typography variant="h6">
          {t('adminOverview.release.last')}: <Date date={release.released} format={DateFormat.DateMedium} />
        </Typography>

        <Box mt={4} mb={4}>
          <Changelog
            dangerouslySetInnerHTML={{ __html: release.changelog }}
            ref={changelogRef}
            className={combineCn({
              [styles.changelog]: true,
              [styles.changelogCollapsed]: showMore !== ShowMoreState.INITIAL,
            })}
          />
          {showMore !== ShowMoreState.INITIAL ? (
            <Button onClick={setShowMore}>{t('adminOverview.showMore')}</Button>
          ) : null}
        </Box>
      </div>

      <FlexSpace />

      {release.product ? (
        <StyledAdminRouteLink route="ReleasesProduct" params={[release.product.id]}>
          <ButtonWithChevron>{t('adminOverview.release.goToReleases')}</ButtonWithChevron>
        </StyledAdminRouteLink>
      ) : null}

      <Dialog open={showMore === ShowMoreState.VISIBLE} onClose={setHideMore}>
        <DialogTitle>
          <Typography variant="h4">{release.product?.name ?? '-'}</Typography>
          <Typography variant="subtitle1">{release.version}</Typography>
        </DialogTitle>
        <DialogContent>
          <Changelog
            dangerouslySetInnerHTML={{ __html: release.changelog }}
            ref={dialogChangelogRef}
            className={combineCn({
              [styles.changelog]: true,
            })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={setHideMore}>{t('common.dialog.close')}</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};
