import { parseSize } from '../helpers/parseSize';
import { FileSizeInput } from '../components/FileSizeInput';
import { GridCellParams, GridFilterItem } from '@mui/x-data-grid';
import { t } from 'i18next';

export const getFileSizeGreaterThan = () => ({
  label: t('filterOperator.fileSizeGreaterThan'),
  value: 'gt',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    const value = parseSize(typeof filterItem.value === 'string' ? filterItem.value : '');
    return (params: GridCellParams) => (typeof params.value === 'number' ? params.value : 0) > value;
  },
  InputComponent: FileSizeInput,
});

export const getFileSizeLessThan = () => ({
  label: t('filterOperator.fileSizeLessThan'),
  value: 'lt',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    const value = parseSize(typeof filterItem.value === 'string' ? filterItem.value : '');
    return (params: GridCellParams) => (typeof params.value === 'number' ? params.value : 0) < value;
  },
  InputComponent: FileSizeInput,
});
