import React, { FC, useCallback, useContext, useMemo } from 'react';
import { CustomerProductsFetcher } from './CustomerFetchers';
import { UrlQueryDataGrid } from '../UrlQueryDataGrid/UrlQueryDataGrid';
import { GridColumns, GridRowData, GridRowParams } from '@mui/x-data-grid';
import { ScheduledJobs } from '../ScheduledJobs/ScheduledJobs';
import { DateFormat } from '../../helpers/dateFormat';
import { getDateValueFormatter } from '../../valueFormatters/DateValueFormatter';
import { LicenseExpiry } from '../LicenseExpiry/LicenseExpiry';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { AdminRoutes } from '../../routers/AdminRoutes';
import { CustomerNewProduct } from './CustomerNewProduct';
import { getStringEqualsOperator } from '../../filterOperators/stringEqualsOperator';
import { getGreaterThanOperator } from '../../filterOperators/greaterThanOperator';
import { getLessThanOperator } from '../../filterOperators/lessThanOperator';
import { getStringDiffersOperator } from '../../filterOperators/stringDiffersOperator';
import { getEnumIsFilterOperator, getEnumIsNotFilterOperator } from '../../filterOperators/enumFilterOperator';
import { getDateAfterFilterOperator, getDateBeforeFilterOperator } from '../../filterOperators/dateFilterOperators';
import { useAutoFetch } from '../../contexts/Fetcher';
import { useTranslation } from 'react-i18next';
import { createStatusChipComponent } from '../StatusChip/StatusChip';
import { getLicenseStatus } from '../../helpers/licenseStatus';
import { AdminRouteLink, useIsAdminRouteAllowed } from '../RouteLink/RouteLink';
import { useCloseDialog } from '../../hooks/useCloseDialog';
import { useIsRoute } from '../../hooks/useIsRoute';
import { useIntParams } from '../../hooks/useIntParams';

const useStyles = makeStyles((theme) =>
  createStyles({
    scheduledPaper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    scheduledHeader: {
      padding: theme.spacing(2),
    },
    buttonWrapper: {
      padding: `${theme.spacing(1)} 0`,
    },
  })
);

interface IUrlParams {
  customerId: string;
}

const StatusChip = createStatusChipComponent({
  valid: ['common.licenseStatus.valid', 'lime'],
  expired: ['common.licenseStatus.expired', 'default'],
  expiresSoon: ['common.licenseStatus.expiresSoon', 'orange'],
  suspended: ['common.licenseStatus.suspended', 'red'],
});

export const CustomerProducts: FC = () => {
  const { data } = useContext(CustomerProductsFetcher.Context);
  const styles = useStyles();
  const { customerId } = useIntParams<IUrlParams>();
  const isNewProductDialogOpen = useIsRoute(AdminRoutes.CustomerNewTabItem);
  useAutoFetch(CustomerProductsFetcher);
  const { t } = useTranslation();

  const closeDialog = useCloseDialog(AdminRoutes.CustomerTab(customerId, 'products'));

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'id',
        headerName: t('common.tableHeader.id'),
        width: 80,
        filterOperators: [getStringEqualsOperator(), getGreaterThanOperator(), getLessThanOperator()],
      },
      {
        field: 'amount',
        headerName: t('customer.products.table.amountHeader'),
        width: 100,
        filterOperators: [
          getStringEqualsOperator(),
          getStringDiffersOperator(),
          getGreaterThanOperator(),
          getLessThanOperator(),
        ],
      },
      {
        field: 'product',
        headerName: t('customer.products.table.productHeader'),
        flex: 2,
      },
      {
        field: 'plan',
        headerName: t('customer.products.table.planHeader'),
        flex: 3,
      },
      {
        field: 'type',
        headerName: t('common.tableHeader.type'),
        width: 120,
        filterOperators: [
          getEnumIsFilterOperator(['subscription', 'perpetual']),
          getEnumIsNotFilterOperator(['subscription', 'perpetual']),
        ],
      },
      {
        field: 'expires',
        headerName: t('common.tableHeader.expires'),
        renderCell: (params) => {
          return <LicenseExpiry type={params.row.type} expiry={params.row.expires} />;
        },
        width: 190,
        filterOperators: [getDateBeforeFilterOperator(), getDateAfterFilterOperator()],
      },
      {
        field: 'status',
        headerName: t('common.tableHeader.status'),
        width: 110,
        filterOperators: [
          getEnumIsFilterOperator({
            [t('common.licenseStatus.valid')]: ['valid', 'expiresSoon'],
            [t('common.licenseStatus.expiresSoon')]: ['expiresSoon'],
            [t('common.licenseStatus.expired')]: ['expired'],
            [t('common.licenseStatus.suspended')]: ['suspended'],
          }),
          getEnumIsNotFilterOperator({
            [t('common.licenseStatus.valid')]: ['valid', 'expiresSoon'],
            [t('common.licenseStatus.expiresSoon')]: ['expiresSoon'],
            [t('common.licenseStatus.expired')]: ['expired'],
            [t('common.licenseStatus.suspended')]: ['suspended'],
          }),
        ],
        renderCell: StatusChip,
      },
      {
        field: 'created',
        headerName: t('common.tableHeader.created'),
        valueFormatter: getDateValueFormatter(DateFormat.DateMedium),
        width: 130,
        filterOperators: [getDateBeforeFilterOperator(), getDateAfterFilterOperator()],
      },
    ],
    []
  );

  const rows = useMemo<GridRowData[]>(() => {
    if (!data) return [];
    return data.customerProducts.map((customerProduct) => ({
      id: customerProduct.customerProduct.id,
      amount: customerProduct.customerProduct.amount,
      product: customerProduct.product.name,
      plan: customerProduct.plan.name,
      type: customerProduct.plan.type,
      expires: customerProduct.expires,
      status: getLicenseStatus(customerProduct.suspended, customerProduct.expires, customerProduct.plan.type),
      created: customerProduct.customerProduct.created,
    }));
  }, [data]);

  const getRowLink = useCallback(
    (params: GridRowParams) => AdminRoutes.CustomerProduct(customerId, params.row.id),
    [customerId]
  );
  const isGetRowLinkAllowed = useIsAdminRouteAllowed('CustomerProduct');

  if (!data) return null;
  return (
    <div>
      <div className={styles.buttonWrapper}>
        <Button
          component={AdminRouteLink}
          route="CustomerNewTabItem"
          params={[customerId, 'products']}
          keepQuery
          variant="contained"
          color="primary"
        >
          {t('customer.products.addButton')}
        </Button>
      </div>

      {data.pendingJobs.length > 0 ? (
        <Paper className={styles.scheduledPaper}>
          <Typography variant="h4" className={styles.scheduledHeader}>
            {t('customer.products.scheduled')}
          </Typography>
          <Divider />
          <ScheduledJobs jobs={data.pendingJobs} />
        </Paper>
      ) : null}

      <UrlQueryDataGrid
        columns={columns}
        rows={rows}
        disableExtendRowFullWidth={false}
        autoHeight={true}
        disableSelectionOnClick={true}
        getRowLink={isGetRowLinkAllowed ? getRowLink : void 0}
      />

      <CustomerNewProduct open={isNewProductDialogOpen} closeDialog={closeDialog} />
    </div>
  );
};
