import React, { FC } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ProductSingleContent } from './ProductSingleContent';
import { IOverviewProduct } from '../../models/UserOverview';
import { useTranslation } from 'react-i18next';

interface IProps {
  product: IOverviewProduct;
}

export const ProductContent: FC<IProps> = ({ product }) => {
  const { t } = useTranslation();
  if (product.latestContent.length === 0) return null;

  return (
    <div>
      <Box marginTop={3} marginBottom={3}>
        <Typography variant={'h3'}>{t('userOverview.latestContentHeader')}</Typography>
      </Box>

      <Grid container spacing={3}>
        {product.latestContent.map((content) => {
          return <ProductSingleContent productDetails={product.product} content={content} key={content.zipFile} />;
        })}
      </Grid>
    </div>
  );
};
