import React, { FC, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { OverviewReleases } from './OverviewReleases';
import { OverviewLicenses } from './OverviewLicenses';
import { UserRoutes } from '../../routers/UserRoutes';
import { useInjectedAnchors } from '../../hooks/useInjectedAnchors';
import { IOverviewProduct } from '../../models/UserOverview';
import styled from '@mui/styles/styled';
import { ShowMoreState, useShowMore } from '../../hooks/useShowMore';
import { ButtonWithChevronWithLink } from '../ButtonWithChevron/ButtonWithChevron';
import { useTranslation } from 'react-i18next';
import { Changelog } from '../Changelog/Changelog';

interface IProps {
  product: IOverviewProduct;
}

const useStyles = makeStyles((theme) => {
  return createStyles({
    paper: {
      height: '100%',
      padding: `${theme.spacing(2)} 11%`,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    tableContainer: {
      maxHeight: 300,
      overflowY: 'auto',
    },
  });
});

const ReleaseNotes = styled(Changelog)(({ theme }) => ({
  '&.collapsed': {
    maxHeight: 200,
    overflow: 'hidden',
    position: 'relative',
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: 50,
      background: `linear-gradient(to bottom, transparent, ${theme.palette.background.paper})`,
    },
  },
}));

export const ProductOverview: FC<IProps> = ({ product }) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const changelogRef = useRef<HTMLDivElement>(null);
  const dialogChangelogRef = useRef<HTMLDivElement>(null);
  useInjectedAnchors(changelogRef);
  const { showMore, setShowMore, setHideMore } = useShowMore(changelogRef, 200);

  const latestVersion = product.latestVersion;
  const latestInstaller = latestVersion?.installer[0];

  return (
    <Grid container spacing={3}>
      {latestVersion && latestInstaller ? (
        <Grid item xs={12} lg={4}>
          <Paper className={styles.paper}>
            <div>
              <Typography variant="h4" textAlign="center">
                {t('userOverview.productOverview.title')}
              </Typography>
              <Typography variant="subtitle1" textAlign="center" marginBottom={2}>
                {t('userOverview.productOverview.releaseVersion', { version: latestVersion.version })}
              </Typography>

              <ReleaseNotes
                dangerouslySetInnerHTML={{ __html: latestInstaller.changelog }}
                ref={changelogRef}
                className={showMore !== ShowMoreState.INITIAL ? 'collapsed' : void 0}
              />

              {showMore !== ShowMoreState.INITIAL ? (
                <Button onClick={setShowMore}>{t('common.readMore')}</Button>
              ) : null}

              {latestInstaller ? (
                <Dialog open={showMore === ShowMoreState.VISIBLE} onClose={setHideMore} maxWidth="md" fullWidth>
                  <DialogTitle>
                    {product.product.name} {latestVersion.version}
                  </DialogTitle>
                  <DialogContent>
                    <ReleaseNotes
                      dangerouslySetInnerHTML={{ __html: latestInstaller.changelog }}
                      ref={dialogChangelogRef}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={setHideMore}>{t('common.dialog.close')}</Button>
                  </DialogActions>
                </Dialog>
              ) : null}
            </div>

            <ButtonWithChevronWithLink to={UserRoutes.DownloadsProduct(product.product.id)}>
              {t('userOverview.productOverview.goToDownloads')}
            </ButtonWithChevronWithLink>
          </Paper>
        </Grid>
      ) : null}

      {latestInstaller && latestVersion && latestVersion.installer.length > 0 ? (
        <Grid item xs={12} lg={4}>
          <Paper className={styles.paper}>
            <div>
              <Typography variant="h4" textAlign="center">
                {latestInstaller.version}
              </Typography>
              <Typography variant="subtitle1" textAlign="center" marginBottom={2}>
                {t('userOverview.productOverview.latestRelease')}
              </Typography>

              <div className={styles.tableContainer}>
                <OverviewReleases product={product} />
              </div>
            </div>

            <ButtonWithChevronWithLink to={UserRoutes.DownloadsProduct(product.product.id)}>
              {t('userOverview.productOverview.goToDownloads')}
            </ButtonWithChevronWithLink>
          </Paper>
        </Grid>
      ) : null}
      <Grid item xs={12} lg={4}>
        <Paper className={styles.paper}>
          <div>
            <Typography variant="h4" textAlign="center">
              {t('userOverview.productOverview.licensesInUseValue', {
                used: product.machines.licensesInUse,
                total: product.machines.licensesAvailable,
              })}
            </Typography>
            <Typography variant="subtitle1" textAlign="center" marginBottom={2}>
              {t('userOverview.productOverview.licensesInUse')}
            </Typography>

            <div className={styles.tableContainer}>
              <OverviewLicenses product={product} />
            </div>
          </div>

          <ButtonWithChevronWithLink to={UserRoutes.Licenses()}>
            {t('userOverview.productOverview.goToLicenses')}
          </ButtonWithChevronWithLink>
        </Paper>
      </Grid>
    </Grid>
  );
};
