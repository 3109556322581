import React, { FC, useCallback } from 'react';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { IAwsFileMetadata } from '../../hooks/useDownloadApi';
import { useDateFormat } from '../../hooks/useDateFormat';
import { displayFilesize } from '../../helpers/displayFilesize';
import GetApp from '@mui/icons-material/GetApp';
import { useDownload } from '../../hooks/useDownload';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { DateFormat } from '../../helpers/dateFormat';
import { useTranslation } from 'react-i18next';

interface IProps {
  file: IAwsFileMetadata;
}

export const SingleDownloadProductTool: FC<IProps> = ({ file }) => {
  const modified = useDateFormat(file.LastModified, DateFormat.DateMedium);
  const download = useDownload();
  const apiErrorHandler = useApiErrorHandler();
  const { t } = useTranslation();

  const onDownload = useCallback(async () => {
    try {
      await download(file.Key);
    } catch (e) {
      apiErrorHandler(e, t('downloads.downloadFailureMessage'));
    }
  }, [download, file.Key]);

  return (
    <TableRow>
      <TableCell>{modified}</TableCell>
      <TableCell>{file.Key}</TableCell>
      <TableCell>{displayFilesize(file.Size)}</TableCell>
      <TableCell>
        <IconButton onClick={onDownload} size="large">
          <GetApp />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};
