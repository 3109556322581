import React, { FC, useCallback, useContext, useState } from 'react';
import { useCustomersApi } from '../../hooks/useCustomersApi';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { CustomerFetcher } from '../Customer/CustomerFetchers';
import { CustomerProductFetcher } from './CustomerProduct';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { Trans, useTranslation } from 'react-i18next';
import { Dayjs } from 'dayjs';
import { useScheduledJobsApi } from '../../hooks/useScheduledJobsApi';
import { TogglableDateFormPartial } from '../TogglableDateFormPartial/TogglableDateFormPartial';

interface IProps {
  open: boolean;
  closeDialog: () => void;
}

export const CustomerProductSuspend: FC<IProps> = ({ open, closeDialog }) => {
  const { suspendCustomerProduct } = useCustomersApi();
  const { scheduleSuspendProduct } = useScheduledJobsApi();
  const { data: customerData } = useContext(CustomerFetcher.Context);
  const { data: customerProductData, onRefresh } = useContext(CustomerProductFetcher.Context);
  const handleError = useApiErrorHandler();
  const { t } = useTranslation();
  const [schedule, setSchedule] = useState<Dayjs | null>(null);

  const suspendNow = useCallback(async () => {
    if (!customerData || !customerProductData) return;
    try {
      await suspendCustomerProduct([customerData.customer.id, customerProductData.customerProduct.id]);
      await onRefresh();
      closeDialog();
    } catch (e) {
      handleError(e);
    }
  }, [suspendCustomerProduct, customerProductData, customerData]);

  const suspendScheduled = useCallback(async () => {
    if (!schedule || !customerData || !customerProductData) return;
    try {
      await scheduleSuspendProduct(schedule.valueOf() / 1000, {
        customerId: customerData.customer.id.toString(),
        customerProductId: customerProductData.customerProduct.id.toString(),
      });
      await onRefresh();
      closeDialog();
    } catch (e) {
      handleError(e);
    }
  }, [schedule, scheduleSuspendProduct, customerProductData, customerData]);

  if (!customerData || !customerProductData) return null;
  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>{t('customerProduct.suspendDialog.title')}</DialogTitle>
      <DialogContent>
        <Trans i18nKey="customerProduct.suspendDialog.message">
          <Typography paragraph />
          <Typography paragraph />
        </Trans>
        <Grid container>
          <TogglableDateFormPartial
            value={schedule}
            onChange={setSchedule}
            togglerLabel={t('customerProduct.suspendDialog.scheduleLabel')}
            disablePast
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>{t('common.dialog.close')}</Button>
        {schedule ? (
          <Button variant="contained" color="secondary" onClick={suspendScheduled}>
            {t('customerProduct.suspendDialog.confirmSchedule')}
          </Button>
        ) : (
          <Button variant="contained" color="primary" onClick={suspendNow}>
            {t('customerProduct.suspendDialog.confirmNow')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
