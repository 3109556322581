import dayjs, { Dayjs } from 'dayjs';
import { getDateObject } from './dateFormat';
import { expiresSoon } from './expiresSoon';
import { CustomerProductPlanType } from '../models/CustomerProductPlanType';

export type LicenseStatus = 'expired' | 'expiresSoon' | 'suspended' | 'valid';

export const getLicenseStatus = (
  suspended: boolean,
  expiry: string | number | Dayjs,
  type: CustomerProductPlanType
): LicenseStatus => {
  if (type === CustomerProductPlanType.Perpetual) {
    return suspended ? 'suspended' : 'valid';
  }
  const expirationDate = getDateObject(expiry);
  if (expirationDate.isBefore(dayjs())) return 'expired';
  if (suspended) return 'suspended';
  if (expiresSoon(expirationDate)) return 'expiresSoon';
  return 'valid';
};
