import React, { FC, useCallback, useContext } from 'react';
import { createNoParamsFetcher } from '../../contexts/Fetcher';
import { useOverviewApi } from '../../hooks/useOverviewApi';
import { ICustomerOverviewResponse } from '../../models/CustomerOverview';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { CustomerOverviewUsers } from './CustomerOverviewUsers';
import { CustomerOverviewUnassignedLicenses } from './CustomerOverviewUnassignedLicenses';
import { CustomerOverviewExpiring } from './CustomerOverviewExpiring';
import { DateFormat, dateFormat } from '../../helpers/dateFormat';
import { CustomerRoutes } from '../../routers/CustomerRoutes';
import { CustomerOverviewGetStarted } from './CustomerOverviewGetStarted';
import styled from '@mui/styles/styled';
import Close from '@mui/icons-material/Close';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { ButtonWithChevronWithLink } from '../ButtonWithChevron/ButtonWithChevron';
import { Trans, useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => {
  return createStyles({
    paper: {
      height: '100%',
      padding: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    tableContainer: {
      maxHeight: 300,
      overflowY: 'auto',
      marginBottom: theme.spacing(2),
    },
    header: {
      textAlign: 'center',
    },
  });
});

const CloseIcon = styled(Close)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(4),
  top: theme.spacing(4),
  cursor: 'pointer',
}));

const OverviewFetcher = createNoParamsFetcher(
  () => useOverviewApi().getOverview as (params: []) => Promise<ICustomerOverviewResponse>
);

const CustomerOverviewContent: FC = () => {
  const styles = useStyles();
  const { data } = useContext(OverviewFetcher.Context);
  const [showGetStarted, setShowGetStarted] = useLocalStorage('showGetStarted', true);
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const closeGetStarted = useCallback(() => {
    setShowGetStarted(false);
  }, []);

  if (!data) return null;
  return (
    <Grid container spacing={3}>
      {showGetStarted ? (
        <Grid item xs={12} lg={12}>
          <Paper>
            <Box padding={3} position="relative">
              <CustomerOverviewGetStarted />
              <CloseIcon onClick={closeGetStarted} />
            </Box>
          </Paper>
        </Grid>
      ) : null}

      <Grid item xs={12} lg={4}>
        <Paper className={styles.paper}>
          <div>
            <Trans i18nKey="customerOverview.userCount" count={data.users.length}>
              <Typography variant="h4" className={styles.header} />
              <Typography variant="subtitle1" className={styles.header} />
            </Trans>
            <div className={styles.tableContainer}>
              <CustomerOverviewUsers userLicenses={data.userLicenses} />
            </div>
          </div>

          <ButtonWithChevronWithLink to={CustomerRoutes.Users()}>
            {t('customerOverview.goToUsers')}
          </ButtonWithChevronWithLink>
        </Paper>
      </Grid>
      <Grid item xs={12} lg={4}>
        <Paper className={styles.paper}>
          <div>
            <Trans i18nKey="customerOverview.unassignedLicenseCount" count={data.unassignedLicensesTotal}>
              <Typography variant="h4" className={styles.header} />
              <Typography variant="subtitle1" className={styles.header} />
            </Trans>
            <div className={styles.tableContainer}>
              <CustomerOverviewUnassignedLicenses unassignedLicenses={data.unassignedLicenses} />
            </div>
          </div>

          <ButtonWithChevronWithLink to={CustomerRoutes.Products()}>
            {t('customerOverview.goToProducts')}
          </ButtonWithChevronWithLink>
        </Paper>
      </Grid>

      <Grid item xs={12} lg={4}>
        {data.expiringSoonLicenses.length > 0 ? (
          <Paper className={styles.paper}>
            <div>
              <Trans
                i18nKey="customerOverview.nextExpirationSummary"
                values={{
                  date: dateFormat(data.expiringSoonLicenses[0].date, DateFormat.Relative, language),
                }}
              >
                <Typography variant="h4" className={styles.header} />
                <Typography variant="subtitle1" className={styles.header} />
              </Trans>
              <div className={styles.tableContainer}>
                <CustomerOverviewExpiring expiring={data.expiringSoonLicenses} />
              </div>
            </div>

            <ButtonWithChevronWithLink to={CustomerRoutes.Products()}>
              {t('customerOverview.goToProducts')}
            </ButtonWithChevronWithLink>
          </Paper>
        ) : (
          <Paper className={styles.paper}>
            <Typography variant="h4">0</Typography>
            <Typography variant="subtitle1" className={styles.header}>
              {t('customerOverview.noExpiringLicenses')}
            </Typography>

            <ButtonWithChevronWithLink to={CustomerRoutes.Products()}>
              {t('customerOverview.goToProducts')}
            </ButtonWithChevronWithLink>
          </Paper>
        )}
      </Grid>
    </Grid>
  );
};

export const CustomerOverview: FC = () => {
  return (
    <OverviewFetcher.WAF>
      <CustomerOverviewContent />
    </OverviewFetcher.WAF>
  );
};
