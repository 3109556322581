import React, { FC } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ICustomerOverviewResponse } from '../../models/CustomerOverview';
import { useTranslation } from 'react-i18next';

interface IProps {
  userLicenses: ICustomerOverviewResponse['userLicenses'];
}

export const CustomerOverviewUsers: FC<IProps> = ({ userLicenses }) => {
  const { t } = useTranslation();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t('common.tableHeader.type')}</TableCell>
          <TableCell>{t('customerOverview.amountHeader')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>{t('customerOverview.usersWithLicenses')}</TableCell>
          <TableCell>{userLicenses.withLicenses}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('customerOverview.usersWithoutLicenses')}</TableCell>
          <TableCell>{userLicenses.withoutLicenses}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('customerOverview.unverifiedUsers')}</TableCell>
          <TableCell>{userLicenses.unverified}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
