import React, { FC, useCallback, useContext, useMemo } from 'react';
import { ProductPlansFetcher } from '../../fetchers/ProductPlansFetcher';
import { useAutoFetch } from '../../contexts/Fetcher';
import { UrlQueryDataGrid } from '../UrlQueryDataGrid/UrlQueryDataGrid';
import { GridColumns, GridRowParams } from '@mui/x-data-grid';
import { getDateValueFormatter } from '../../valueFormatters/DateValueFormatter';
import { DateFormat } from '../../helpers/dateFormat';
import { IAdminPlan } from '../../models/AdminPlan';
import { useIsRoute } from '../../hooks/useIsRoute';
import { AdminRoutes } from '../../routers/AdminRoutes';
import { AddProductPlanDialog } from './AddProductPlanDialog';
import { useCloseDialog } from '../../hooks/useCloseDialog';
import { useHistory } from 'react-router-dom';
import { EditProductPlanDialog } from './EditProductPlanDialog';
import { useIntParams } from '../../hooks/useIntParams';
import { ProductPlanFetcher } from '../../fetchers/ProductPlanFetcher';
import { CellContentTooltip } from '../CellContentTooltip/CellContentTooltip';
import { useDefaultSorting } from '../../hooks/useDefaultSorting';
import { useTranslation } from 'react-i18next';
import { BoolChip } from '../BoolChip/BoolChip';
import { CollapsedContentsWithTooltip } from '../CollapsedContentsWithTooltip/CollapsedContentsWithTooltip';
import { useIsAdminRouteAllowed } from '../RouteLink/RouteLink';
import { getBoolFilterOperator } from '../../filterOperators/boolFilterOperator';
import { IQuickFiltersProps } from '../EasyFilter/QuickFilters';
import { EasyFilter } from '../EasyFilter/EasyFilter';

interface IUrlParams {
  id?: string;
}

export const ProductPlans: FC = () => {
  const { data } = useContext(ProductPlansFetcher.Context);
  useAutoFetch(ProductPlansFetcher);
  const isAdding = useIsRoute(AdminRoutes.ProductsTabNewItem);
  const { id: planId } = useIntParams<IUrlParams>();
  const history = useHistory();
  useDefaultSorting('id', 'asc');
  const { t } = useTranslation();

  const quickFilters = useMemo<IQuickFiltersProps['quickFilters']>(
    () =>
      new Map([
        [
          t('common.quickFilter.valid'),
          {
            filterField: 'archived',
            filterOp: 'equals',
            filterVal: 'false',
            isDefault: true,
          },
        ],
      ]),
    []
  );

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'id',
        headerName: t('common.tableHeader.id'),
      },
      {
        field: 'name',
        headerName: t('common.tableHeader.name'),
        flex: 1,
        renderCell: (params) => <CellContentTooltip>{params.value}</CellContentTooltip>,
      },
      {
        field: 'type',
        headerName: t('adminProducts.plans.table.typeHeader'),
        width: 120,
      },
      {
        field: 'productCode',
        headerName: t('adminProducts.plans.table.productCode'),
        flex: 1,
      },
      {
        field: 'packages',
        headerName: t('adminProducts.plans.table.packagesHeader'),
        valueGetter: (params) => {
          const count = (params.row as IAdminPlan).packages.reduce((memo, curr) => {
            return memo + curr.amount;
          }, 0);
          return t('adminProducts.plans.table.totalPackages', { count });
        },
        width: 150,
        renderCell: (params) => {
          const items = (params.row as IAdminPlan).packages.map((pack) => ({
            amount: pack.amount,
            name: pack.package?.name,
          }));
          return <CollapsedContentsWithTooltip items={items}>{params.value}</CollapsedContentsWithTooltip>;
        },
      },
      {
        field: 'duration',
        headerName: t('adminProducts.plans.table.durationHeader'),
        valueFormatter: (params) => {
          if (typeof params.value !== 'number') return '-';
          return t('adminProducts.plans.table.duration', { count: params.value });
        },
      },
      {
        field: 'price',
        headerName: t('adminProducts.plans.table.priceHeader'),
        valueGetter: (params) => {
          const value = params.row[params.field];
          if (typeof value !== 'number') return '-';
          return Intl.NumberFormat('en-US', { style: 'currency', currency: params.row.currency }).format(value / 100);
        },
        width: 140,
      },
      {
        field: 'archived',
        headerName: t('adminProducts.plans.table.archivedHeader'),
        renderCell: BoolChip,
        filterOperators: [getBoolFilterOperator()],
      },
      {
        field: 'watermark',
        headerName: t('adminProducts.plans.table.watermarkHeader'),
        renderCell: BoolChip,
        filterOperators: [getBoolFilterOperator()],
      },
      {
        field: 'created',
        headerName: t('common.tableHeader.created'),
        valueFormatter: getDateValueFormatter(DateFormat.DateMedium),
        width: 120,
      },
      {
        field: 'updated',
        headerName: t('common.tableHeader.updated'),
        valueFormatter: getDateValueFormatter(DateFormat.DateMedium),
        width: 120,
      },
    ],
    []
  );

  const rows = useMemo(() => {
    if (!data) return null;
    return data.plans;
  }, [data]);

  const closeDialog = useCloseDialog(AdminRoutes.ProductsTab('plans'));

  const getRowLink = useCallback((params: GridRowParams) => {
    return `${AdminRoutes.ProductsTabEditItem('plans', params.row.id)}${history.location.search}`;
  }, []);
  const isGetRowLinkAllowed = useIsAdminRouteAllowed('ProductsTabEditItem', 'plans');

  if (!data || !rows) return null;
  return (
    <div>
      <EasyFilter quickFilters={quickFilters} textFilter={false} />
      <UrlQueryDataGrid
        columns={columns}
        rows={rows}
        disableExtendRowFullWidth={false}
        autoHeight={true}
        disableSelectionOnClick={true}
        getRowLink={isGetRowLinkAllowed ? getRowLink : void 0}
      />
      <AddProductPlanDialog open={isAdding} closeDialog={closeDialog} />
      <ProductPlanFetcher.WMF>
        <EditProductPlanDialog planId={planId} closeDialog={closeDialog} />
      </ProductPlanFetcher.WMF>
    </div>
  );
};
