import React, { FC } from 'react';
import { Control, FormState, UseFormHandleSubmit } from 'react-hook-form';
import { IUserDetails } from '../../models/UserDetails';
import { IUpdateUserPayload } from '../../hooks/useUsersApi';
import { TextInputController } from '../InputControllers/TextInputController';
import { useTranslation } from 'react-i18next';

interface IProps {
  user?: IUserDetails;
  onSubmit: (fields: IUpdateUserPayload) => void;
  control: Control;
  handleSubmit: UseFormHandleSubmit<IUpdateUserPayload>;
  formState: FormState<IUpdateUserPayload>;
}

export const EditUserForm: FC<IProps> = ({ user, onSubmit, control, handleSubmit, formState: { errors } }) => {
  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextInputController
        control={control}
        errors={errors}
        label={t('common.formLabel.firstName')}
        defaultValue={user?.firstName ?? ''}
        name="firstName"
      />

      <TextInputController
        control={control}
        errors={errors}
        label={t('common.formLabel.lastName')}
        defaultValue={user?.lastName ?? ''}
        name="lastName"
      />

      <TextInputController
        control={control}
        errors={errors}
        label={t('common.formLabel.email')}
        defaultValue={user?.email ?? ''}
        name="email"
        inputType="email"
      />

      <TextInputController
        control={control}
        errors={errors}
        label={t('common.formLabel.phoneNumber')}
        defaultValue={user?.phoneNumber ?? ''}
        name="phoneNumber"
      />
    </form>
  );
};
