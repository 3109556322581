import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useDate } from './useDate';
import { DateFormat } from '../helpers/dateFormat';
import { useTranslation } from 'react-i18next';

dayjs.extend(relativeTime);

export const useDateFormat = (date: string | number | dayjs.Dayjs, format: DateFormat): string => {
  const dateObject = useDate(date);
  const {
    i18n: { language },
  } = useTranslation();

  if (format === DateFormat.Relative) {
    return dateObject.locale(language).fromNow();
  }
  return dateObject.locale(language).format(format);
};
