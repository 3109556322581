import React, { FC, useCallback, useContext, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IAssignLicensePayload } from '../../hooks/useUsersApi';
import { CustomerUserFetcher, CustomerUserPackagesFetcher } from './CustomerUserFetchers';
import { useCustomerUsersApi } from '../../hooks/useCustomerUsersApi';
import { AssignLicenses } from '../AssignLicenses/AssignLicenses';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { useTranslation } from 'react-i18next';

interface IProps {
  open: boolean;
  closeDialog: (resetQuery?: boolean) => void;
}

export const CustomerUserAssignLicensesDialog: FC<IProps> = ({ open, closeDialog }) => {
  const { data: userData } = useContext(CustomerUserFetcher.Context);
  const { data, onRefresh } = useContext(CustomerUserPackagesFetcher.Context);
  const assignLicensesPayloadRef = useRef<IAssignLicensePayload[]>([]);
  const { assignLicense } = useCustomerUsersApi();
  const [isAnyLicenseToSubmit, setAnyLicenseToSubmit] = useState(false);
  const handleError = useApiErrorHandler();
  const { t } = useTranslation();

  const updateLicensesPayload = useCallback((data: IAssignLicensePayload[]) => {
    assignLicensesPayloadRef.current = data;
    setAnyLicenseToSubmit(data.length > 0);
  }, []);

  const submitAssignLicenses = useCallback(async () => {
    if (!userData || assignLicensesPayloadRef.current.length === 0) return;
    try {
      for (const payload of assignLicensesPayloadRef.current) {
        await assignLicense([userData.customerId, userData.id], payload);
      }
      await onRefresh();
      closeDialog(true);
    } catch (e) {
      handleError(e);
    }
  }, [userData?.customerId, userData?.id, assignLicense, closeDialog]);

  const onClose = useCallback(() => {
    closeDialog();
  }, []);

  if (!data || !userData) return null;
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>{t('customerUser.assignLicensesDialog.title')}</DialogTitle>
      <DialogContent>
        <AssignLicenses availableLicenses={data.unassignedLicenses} onFormPayloadUpdate={updateLicensesPayload} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('common.dialog.close')}</Button>
        <Button variant="contained" color="primary" onClick={submitAssignLicenses} disabled={!isAnyLicenseToSubmit}>
          {t('common.dialog.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
