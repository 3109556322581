import React, { FC, useCallback, useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { EditUserForm } from '../EditUserDialog/EditUserForm';
import { useForm } from 'react-hook-form';
import { IUpdateUserPayload } from '../../hooks/useUsersApi';
import { useCustomersApi } from '../../hooks/useCustomersApi';
import { CustomerFetcher, CustomerUsersFetcher } from './CustomerFetchers';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { useTranslation } from 'react-i18next';
import { NotificationContext } from '../../contexts/NotificationContext';
import { joiResolver } from '@hookform/resolvers/joi';
import { userFormSchema } from '../../formSchemas/userFormSchema';

interface IProps {
  open: boolean;
  closeDialog: (resetQuery?: boolean) => void;
}

export const CustomerNewUser: FC<IProps> = ({ open, closeDialog }) => {
  const { addUser } = useCustomersApi();
  const { control, handleSubmit, formState } = useForm({
    resolver: joiResolver(userFormSchema),
  });
  const { onRefresh: refreshUsersList } = useContext(CustomerUsersFetcher.Context);
  const { data: customerData } = useContext(CustomerFetcher.Context);
  const handleError = useApiErrorHandler();
  const { t } = useTranslation();
  const { addNotification } = useContext(NotificationContext);

  const onClose = useCallback(() => {
    closeDialog();
  }, [closeDialog]);

  const onSubmit = useCallback(async (fields: IUpdateUserPayload) => {
    if (!customerData) return;
    try {
      await addUser([customerData.customer.id], fields);
      addNotification({
        severity: 'success',
        message: t('editUserDialog.add.invitationSent'),
      });
      await refreshUsersList();
      closeDialog(true);
    } catch (e) {
      handleError(e);
    }
  }, []);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('customer.users.addDialog.title')}</DialogTitle>
      <DialogContent>
        <EditUserForm onSubmit={onSubmit} control={control} handleSubmit={handleSubmit} formState={formState} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('common.dialog.close')}</Button>
        <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
          {t('customer.users.addDialog.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
