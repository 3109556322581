import React, { FC, useCallback, useContext } from 'react';
import { PageHeader } from '../PageHeader/PageHeader';
import { CustomerRoutes } from '../../routers/CustomerRoutes';
import { CustomerProductsFetcher } from '../../fetchers/ProductsFetcher';
import { useIntParams } from '../../hooks/useIntParams';
import { createFetcher, useAutoFetch } from '../../contexts/Fetcher';
import { useUsersApi } from '../../hooks/useUsersApi';
import { UsersTable } from '../Users/Users';
import { useTranslation } from 'react-i18next';

interface IUrlParams {
  productId: string;
}

export const ProductUsersFetcher = createFetcher(() => {
  const { getCustomerUsers } = useUsersApi();
  const { productId } = useIntParams<IUrlParams>();

  return useCallback(async () => {
    return await getCustomerUsers([], { product: productId });
  }, [productId]);
});

const ProductUsersContent: FC = () => {
  const { data } = useContext(ProductUsersFetcher.Context);
  if (!data) return null;
  return <UsersTable users={data.users} />;
};

export const ProductUsers: FC = () => {
  const { data: productsData } = useContext(CustomerProductsFetcher.Context);
  useAutoFetch(CustomerProductsFetcher);
  const { productId } = useIntParams<IUrlParams>();
  const { t } = useTranslation();

  if (!productsData) return null;
  const product = productsData.customerProducts.find((p) => p.customerProduct.customerProduct.id === productId);
  if (!product) return null;

  return (
    <ProductUsersFetcher.WAF>
      <PageHeader
        title="Users"
        crumbs={[
          {
            url: CustomerRoutes.Products(),
            title: t('products.header'),
          },
          {
            title: product.customerProduct.plan.name,
          },
        ]}
      />
      <ProductUsersContent />
    </ProductUsersFetcher.WAF>
  );
};
