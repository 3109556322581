import React, { FC, useCallback, useContext, useEffect } from 'react';
import { useCustomersApi } from '../../hooks/useCustomersApi';
import { useHistory, useParams, Link as RouterLink } from 'react-router-dom';
import { PageHeader } from '../PageHeader/PageHeader';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { AdminRoutes } from '../../routers/AdminRoutes';
import { CustomerProducts } from './CustomerProducts';
import { combineComponents } from '../../contexts/combineComponents';
import { DateFormat } from '../../helpers/dateFormat';
import { NotificationContext } from '../../contexts/NotificationContext';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { ConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialog';
import { useQueryParam } from '../../hooks/useQueryParam';
import { DangerousButton } from '../CustomButtons/DangerousButton';
import { CustomerUsers } from './CustomerUsers';
import { CustomerTickets } from './CustomerTickets';
import { CustomerInvoices } from './CustomerInvoices';
import { useQueryUrl } from '../../hooks/useQueryUrl';
import { EditCustomerDialog } from '../EditCustomerDialog/EditCustomerDialog';
import {
  CustomerAvailableProductsFetcher,
  CustomerFetcher,
  CustomerInvoicesFetcher,
  CustomerProductsFetcher,
  CustomerTicketsFetcher,
  CustomerUsersFetcher,
} from './CustomerFetchers';
import { useAutoFetch } from '../../contexts/Fetcher';
import { countries } from '../../helpers/countries';
import { CustomerLive } from './CustomerLive';
import { Trans, useTranslation } from 'react-i18next';
import { Date } from '../Date/Date';
import { ConditionalTooltip } from '../ConditionalTooltip/ConditionalTooltip';
import { AdminRouteLink, useIsAdminRouteAllowed, useIsEndpointAllowed } from '../RouteLink/RouteLink';
import { CustomersEndpoints } from '../../hooks/api/endpoints/customers';
import { AccountSingleData } from '../AccountData/AccountSingleData';
import Box from '@mui/system/Box';

export type CustomerTab = 'products' | 'users' | 'tickets' | 'invoices' | 'live';
export interface ICustomerUrlParams {
  customerId: string;
  tab?: CustomerTab;
}

const Providers = combineComponents(
  CustomerUsersFetcher.WMF,
  CustomerTicketsFetcher.WMF,
  CustomerAvailableProductsFetcher.WMF
);

const useStyles = makeStyles((theme) =>
  createStyles({
    buttons: {
      display: 'flex',
      gap: theme.spacing(1),
    },
  })
);

const CustomerContent: FC = () => {
  const styles = useStyles();
  const { data, onDataUpdate } = useContext(CustomerFetcher.Context);
  const { data: productsData } = useContext(CustomerProductsFetcher.Context);
  const { customerId, tab } = useParams<ICustomerUrlParams>();
  const history = useHistory();
  const id = parseInt(customerId, 10);
  const { reInvite, deleteCustomer, archiveCustomer, unArchiveCustomer, reassignZendeskId } = useCustomersApi();
  const { addNotification } = useContext(NotificationContext);
  const handleApiError = useApiErrorHandler();
  const dialogAction = useQueryParam('dialog');
  const { t } = useTranslation();
  const isEndpointAllowed = useIsEndpointAllowed();

  const editDialogUrl = useQueryUrl('dialog', 'edit');
  const deleteDialogUrl = useQueryUrl('dialog', 'delete');
  const archiveDialogUrl = useQueryUrl('dialog', 'archive');
  const noDialogUrl = useQueryUrl('dialog', null);

  const isProductsAllowed = useIsAdminRouteAllowed('CustomerTab', void 0, 'products');
  const isUsersAllowed = useIsAdminRouteAllowed('CustomerTab', void 0, 'users');
  const isTicketsAllowed = useIsAdminRouteAllowed('CustomerTab', void 0, 'tickets');
  const isInvoicesAllowed = useIsAdminRouteAllowed('CustomerTab', void 0, 'invoices');
  const isLiveAllowed = useIsAdminRouteAllowed('CustomerTab', void 0, 'live');

  const closeDialog = useCallback(() => {
    history.push(noDialogUrl);
  }, [noDialogUrl]);

  const setActiveTab = useCallback((tab: CustomerTab, replaceState = false) => {
    history[replaceState ? 'replace' : 'push'](AdminRoutes.CustomerTab(id, tab));
  }, []);

  const onTabChange = useCallback(
    (_, tab) => {
      setActiveTab(tab);
    },
    [setActiveTab]
  );

  useAutoFetch(CustomerFetcher, void 0, data?.customer.id !== id);

  useEffect(() => {
    if (!tab) {
      setActiveTab('products', true);
    }
  }, [tab]);

  const onReInvite = useCallback(async () => {
    try {
      await reInvite([id]);
      addNotification({
        severity: 'success',
        message: t('customer.reInviteSucceeded'),
      });
    } catch (e) {
      handleApiError(e);
    }
  }, [reInvite, id]);

  const onReassignZendeskId = useCallback(async () => {
    if (!data) return;
    try {
      const updatedData = await reassignZendeskId([id]);
      onDataUpdate({ ...data, customer: updatedData });
      addNotification({
        severity: 'success',
        message: t('customer.reassignZendeskIdSucceeded'),
      });
    } catch (e) {
      handleApiError(e);
    }
  }, [reassignZendeskId, onDataUpdate, data, id]);

  const onDelete = useCallback(async () => {
    try {
      await deleteCustomer([id]);
      history.push(AdminRoutes.Customers());
    } catch (e) {
      handleApiError(e);
    }
  }, [deleteCustomer, id]);

  const onArchive = useCallback(async () => {
    if (!data) return;
    try {
      const newCustomerData = await archiveCustomer([id]);
      onDataUpdate({ ...data, customer: newCustomerData });
      closeDialog();
    } catch (e) {
      handleApiError(e);
    }
  }, [archiveCustomer, id, data]);

  const onUnArchive = useCallback(async () => {
    if (!data) return;
    try {
      const newCustomerData = await unArchiveCustomer([id]);
      onDataUpdate({ ...data, customer: newCustomerData });
      closeDialog();
    } catch (e) {
      handleApiError(e);
    }
  }, [unArchiveCustomer, id, data]);

  if (!data) return null;
  return (
    <div>
      <PageHeader
        title={`${data.customer.firstName} ${data.customer.lastName}`}
        crumbs={[
          {
            url: AdminRoutes.Customers(),
            title: t('layout.adminNavigation.customers'),
          },
        ]}
      >
        <div className={styles.buttons}>
          <Button
            component={AdminRouteLink}
            route="Events"
            params={[]}
            query={{ filterField: 'accountId', filterOp: 'equals', filterVal: id }}
            variant="outlined"
            color="secondary"
          >
            {t('common.button.history')}
          </Button>

          {isEndpointAllowed('PUT', CustomersEndpoints.CustomerArchive.template) ? (
            <Button component={RouterLink} to={archiveDialogUrl} variant="outlined">
              {data.customer.archived ? t('common.button.unArchive') : t('common.button.archive')}
            </Button>
          ) : null}

          {isEndpointAllowed('PUT', CustomersEndpoints.Customer.template) ? (
            <Button component={RouterLink} to={editDialogUrl} variant="contained" color="primary">
              {t('common.button.edit')}
            </Button>
          ) : null}

          {productsData && isEndpointAllowed('DELETE', CustomersEndpoints.Customer.template) ? (
            <ConditionalTooltip
              enabled={productsData.customerProducts.length > 0}
              title={t('customer.cannotDelete') as string}
            >
              <span>
                <DangerousButton
                  component={RouterLink}
                  to={deleteDialogUrl}
                  variant="contained"
                  disabled={productsData.customerProducts.length > 0}
                >
                  {t('common.button.delete')}
                </DangerousButton>
              </span>
            </ConditionalTooltip>
          ) : null}
        </div>
      </PageHeader>

      <Grid container spacing={1}>
        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('common.tableHeader.id')}>{data.customer.id}</AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('common.tableHeader.name')}>
            {data.customer.firstName} {data.customer.lastName}
          </AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('common.tableHeader.email')}>{data.customer.email}</AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('common.tableHeader.phone')}>{data.customer.phoneNumber}</AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('customer.pixotopeRepresentative')}>
            {data.createdBy ? (
              <Link
                component={AdminRouteLink}
                route="Admin"
                params={[data.createdBy.id]}
                underline="hover"
                showFallback
              >
                {data.createdBy.firstName} {data.createdBy.lastName}
              </Link>
            ) : (
              '-'
            )}
          </AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('customer.hubSpot')}>{data.customer.hubSpotId}</AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('customer.zenDesk')}>
            {data.customer.serviceDeskAccount}{' '}
            {isEndpointAllowed('POST', CustomersEndpoints.ReassignZendeskId.template) ? (
              <Button onClick={onReassignZendeskId} variant="contained" color="secondary" size="small">
                {t('customer.reassignZendeskId')}
              </Button>
            ) : null}
          </AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('customer.lastUpdated')}>
            <Date date={data.customer.updated} format={DateFormat.DateWithFullTime} />
          </AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('common.tableHeader.company')}>{data.customer.company}</AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('customer.address')}>{data.customer.address}</AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('customer.billingAddress')}>{data.customer.billingAddress}</AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('customer.country')}>
            {data.customer.billingAddressCountry}{' '}
            {countries.find((country) => country.code === data.customer.billingAddressCountry)?.name}
          </AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('common.tableHeader.status')}>
            {data.customer.verified ? (
              <Chip label={t('common.accountStatus.verified')} color="primary" />
            ) : isEndpointAllowed('POST', CustomersEndpoints.CustomerReInvite.template) ? (
              <Button onClick={onReInvite} variant="contained" color="secondary">
                {t('common.accountStatus.unverified')} / {t('customer.reInvite')}
              </Button>
            ) : (
              <Chip label={t('common.accountStatus.unverified')} color="secondary" />
            )}
          </AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('customer.mfaTitle')}>
            {data.customer.MFAEnabled ? (
              <Chip label={t('customer.mfaEnabled')} color="success" />
            ) : (
              <Chip label={t('customer.mfaDisabled')} color="warning" />
            )}
          </AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('customer.notes')}>{data.customer.notes}</AccountSingleData>
        </Grid>
      </Grid>

      <Box mt={3}>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={onTabChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {isProductsAllowed ? <Tab label={t('customer.products.tabTitle')} value="products" /> : null}
          {isUsersAllowed ? <Tab label={t('customer.users.tabTitle')} value="users" /> : null}
          {isTicketsAllowed ? <Tab label={t('customer.tickets.tabTitle')} value="tickets" /> : null}
          {isInvoicesAllowed ? <Tab label={t('customer.invoices.tabTitle')} value="invoices" /> : null}
          {isLiveAllowed ? <Tab label={t('customer.live.tabTitle')} value="live" /> : null}
        </Tabs>
      </Box>

      <Providers>
        {tab === 'products' ? <CustomerProducts /> : null}
        {tab === 'users' ? <CustomerUsers /> : null}
        {tab === 'tickets' ? <CustomerTickets /> : null}
        {/*
          CustomerInvoicesFetcher is excluded from Providers to prevent crashing the whole tabs content in a
          case when customers don't not exist in stripe (which is a frequent case)
        */}
        {tab === 'invoices' ? (
          <CustomerInvoicesFetcher.WMF>
            <CustomerInvoices />
          </CustomerInvoicesFetcher.WMF>
        ) : null}
        {tab === 'live' ? <CustomerLive /> : null}
      </Providers>

      <ConfirmationDialog
        open={dialogAction === 'delete'}
        title={t('customer.deleteDialog.title')}
        message={t('customer.deleteDialog.message', data.customer)}
        confirm={onDelete}
        abort={closeDialog}
        dangerous
        confirmLabel={t('common.button.delete')}
      />
      <EditCustomerDialog open={dialogAction === 'edit'} closeDialog={closeDialog} />
      <ConfirmationDialog
        open={dialogAction === 'archive'}
        title={
          data.customer.archived ? t('customer.archiveDialog.titleUnArchive') : t('customer.archiveDialog.titleArchive')
        }
        confirm={data.customer.archived ? onUnArchive : onArchive}
        abort={closeDialog}
        confirmLabel={
          data.customer.archived
            ? t('customer.archiveDialog.confirmUnArchive')
            : t('customer.archiveDialog.confirmArchive')
        }
      >
        {data.customer.archived ? (
          <Trans i18nKey="customer.archiveDialog.contentUnArchive">
            <Typography paragraph />
            <List>
              <ListItem />
              <ListItem />
              <ListItem />
            </List>
            <Typography paragraph />
          </Trans>
        ) : (
          <Trans i18nKey="customer.archiveDialog.contentArchive">
            <Typography paragraph />
            <List>
              <ListItem />
              <ListItem />
            </List>
            <Typography paragraph />
          </Trans>
        )}
      </ConfirmationDialog>
    </div>
  );
};

export const Customer: FC = () => {
  return (
    <CustomerProductsFetcher.WAF>
      <CustomerContent />
    </CustomerProductsFetcher.WAF>
  );
};
