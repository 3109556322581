import { useQueryParam } from './useQueryParam';
import { config } from '../config';
import { useEffect } from 'react';

export const usePageSize = () => {
  const pageSizeStr = useQueryParam('pageSize') ?? localStorage.pageSize ?? config.defaultRowsPerPage.toString(10);
  const pageSize = parseInt(pageSizeStr, 10);

  useEffect(() => {
    localStorage.setItem('pageSize', pageSize.toString(10));
  }, [pageSize]);

  return pageSize;
};
