import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useEnumState } from './useEnumState';
import { useQueryParam } from './useQueryParam';

export const useDefaultSorting = (field: string, order: 'asc' | 'desc' = 'desc') => {
  const [preventRender, allowRender] = useEnumState(true, false);
  const history = useHistory();

  const sortBy = useQueryParam('sortBy');
  const sortOrder = useQueryParam('sortOrder');

  useEffect(() => {
    if (preventRender && (sortBy || sortOrder)) allowRender();
  }, [!!sortBy, !!sortOrder, preventRender]);

  useEffect(() => {
    const search = new URLSearchParams(history.location.search);
    if (search.has('sortBy') || search.has('sortOrder')) return;
    search.set('sortBy', field);
    search.set('sortOrder', order);
    history.replace(`?${search.toString()}`);
  }, []);

  return preventRender;
};
