import React, { FC, useContext } from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { LicenseContext } from '../../contexts/LicenseContext';
import { useTranslation } from 'react-i18next';
import { DangerousButton } from '../CustomButtons/DangerousButton';
import { Link } from 'react-router-dom';
import { useQueryParam } from '../../hooks/useQueryParam';
import { ConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialog';
import { useQueryUrl } from '../../hooks/useQueryUrl';

interface IProps {
  onClose: () => void;
}

export const ReleaseErrorLicense: FC<IProps> = ({ onClose }) => {
  const { onOfflineForceRelease } = useContext(LicenseContext)!;
  const { t } = useTranslation();
  const isForce = useQueryParam('force') === '1';
  const forceUrl = useQueryUrl('force', '1');
  const noForceUrl = useQueryUrl('force', null);

  return (
    <>
      <DialogTitle>{t('licenses.releaseDialog.title')}</DialogTitle>
      <DialogContent dividers>
        <Typography paragraph>{t('licenses.releaseDialog.errorModeDescription')}</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          {t('common.dialog.close')}
        </Button>
        {onOfflineForceRelease ? (
          <DangerousButton component={Link} to={forceUrl}>
            {t('licenses.releaseDialog.force.button')}
          </DangerousButton>
        ) : null}
      </DialogActions>

      {onOfflineForceRelease ? (
        <ConfirmationDialog
          open={isForce}
          title={t('licenses.releaseDialog.force.title')}
          message={t('licenses.releaseDialog.force.message')}
          confirm={onOfflineForceRelease}
          abort={noForceUrl}
          dangerous
        />
      ) : null}
    </>
  );
};
