import React, { FC, useCallback } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { DangerousButton } from '../CustomButtons/DangerousButton';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface IProps {
  open: boolean;
  title: string;
  message?: string;
  confirmLabel?: string;
  abortLabel?: string;
  confirm: () => void;
  abort: string | (() => void);
  dangerous?: boolean;
}

export const ConfirmationDialog: FC<IProps> = ({
  open,
  title,
  message,
  confirmLabel,
  abortLabel,
  confirm,
  abort,
  dangerous,
  children,
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  if (!confirmLabel) confirmLabel = t('common.dialog.submit');
  if (!abortLabel) abortLabel = t('common.dialog.close');

  const onAbort = useCallback(() => {
    if (typeof abort === 'string') {
      history.push(abort);
    } else {
      abort();
    }
  }, [abort]);

  return (
    <Dialog open={open} onClose={onAbort}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{message ? <Typography paragraph>{message}</Typography> : children}</DialogContent>
      <DialogActions>
        <Button onClick={onAbort}>{abortLabel}</Button>
        {dangerous ? (
          <DangerousButton onClick={confirm}>{confirmLabel}</DangerousButton>
        ) : (
          <Button variant="contained" color="primary" onClick={confirm}>
            {confirmLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
