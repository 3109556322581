import React, { FC, useMemo } from 'react';
import { Control, FormState, UseFormHandleSubmit } from 'react-hook-form';
import { IAdminUpdatePayload } from '../../hooks/useAdminsApi';
import { TextInputController } from '../InputControllers/TextInputController';
import { useTranslation } from 'react-i18next';
import { SelectController } from '../InputControllers/SelectController';
import Grid from '@mui/material/Grid';
import { CheckboxController } from '../InputControllers/CheckboxController';

interface IProps {
  onSubmit: (fields: IAdminUpdatePayload) => void;
  control: Control;
  handleSubmit: UseFormHandleSubmit<IAdminUpdatePayload>;
  formState: FormState<IAdminUpdatePayload>;
}

export const EditAdminForm: FC<IProps> = ({ onSubmit, control, handleSubmit, formState: { errors } }) => {
  const { t } = useTranslation();

  const items = useMemo(
    () => [
      {
        value: 'admin',
        label: t('editAdminDialog.formLabel.roleAdmin'),
      },
      {
        value: 'sales',
        label: t('editAdminDialog.formLabel.roleSales'),
      },
    ],
    [t]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={5}>
          <TextInputController
            control={control}
            errors={errors}
            label={t('common.formLabel.firstName')}
            name="firstName"
            rules={{ required: true }}
          />
        </Grid>

        <Grid item xs={12} md={7}>
          <TextInputController
            control={control}
            errors={errors}
            label={t('common.formLabel.lastName')}
            name="lastName"
            rules={{ required: true }}
          />
        </Grid>

        <Grid item xs={12}>
          <SelectController
            control={control}
            errors={errors}
            items={items}
            label={t('editAdminDialog.formLabel.role')}
            name="role"
          />
        </Grid>

        <Grid item xs={12} md={7}>
          <TextInputController
            control={control}
            errors={errors}
            label={t('common.formLabel.email')}
            name="email"
            rules={{ required: true }}
          />
        </Grid>

        <Grid item xs={12} md={5}>
          <TextInputController
            control={control}
            errors={errors}
            label={t('common.formLabel.phoneNumber')}
            name="phoneNumber"
          />
        </Grid>

        <Grid item xs={12}>
          <TextInputController
            control={control}
            errors={errors}
            label={t('editAdminDialog.formLabel.notes')}
            name="notes"
            InputProps={{
              multiline: true,
              rows: 4,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <CheckboxController
            control={control}
            errors={errors}
            label={t('editAdminDialog.formLabel.enableMfa')}
            name="MFAEnabled"
          />
        </Grid>
      </Grid>
    </form>
  );
};
