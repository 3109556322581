import React, { FC } from 'react';
import styled from '@mui/styles/styled';
import { isTouchDevice } from '../../helpers/isTouchDevice';

export const StyledDiv = styled('div')(({ theme }) => ({
  textAlign: 'right',
  width: '100%',
  display: 'flex',
  justifyContent: 'right',
  alignItems: 'center',
  gap: theme.spacing(1),
  opacity: 0,
  '&>*': {
    marginLeft: -1,
  },
  '[role="row"]:hover &': {
    opacity: 1,
  },
  '&.isTouchDevice': {
    opacity: 1,
  },
}));

export const RowButtonsContainer: FC = ({ children }) => {
  return <StyledDiv className={isTouchDevice() ? 'isTouchDevice' : ''}>{children}</StyledDiv>;
};
