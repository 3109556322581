import { useCallback } from 'react';
import {
  AuthErrors,
  ICustomerAdminSignInResponse,
  ICustomerUserSignInResponse,
  ITokenResponse,
} from '../hooks/useAuth';
import { useApi } from './useApi';
import { logger } from '../helpers/logger';

export enum AuthEndpoints {
  AccountTypes = '/auth/accounts',
  SignIn = '/signin',
  SignOut = '/signout',
  SignUp = '/signup',
  Token = '/token',
  RecoverAccount = '/recover-account',
  UpdatePassword = '/update-password',
  RenewMfa = '/renew-mfa',
}

export interface IResetPasswordPayload {
  email: string;
  password: string;
  verifyPassword: string;
  token: string;
}

export function useAuthApi() {
  const { post, put } = useApi();
  const { get: getWithoutPrefix } = useApi({ addAccountTypePrefix: false });

  const getAccountTypes = useCallback(
    async (email: string) => {
      const result = await getWithoutPrefix<{ admin: boolean; customer: boolean; user: boolean }>(
        AuthEndpoints.AccountTypes,
        { params: { email } }
      );
      return result.data;
    },
    [getWithoutPrefix]
  );

  const authenticate = useCallback(
    async (email: string, password: string) => {
      try {
        const reqPayload = {
          auth: {
            username: email,
            password,
          },
        };
        const result = await post<ICustomerUserSignInResponse | ICustomerAdminSignInResponse>(
          AuthEndpoints.SignIn,
          null,
          reqPayload
        );
        return result.data;
      } catch (e) {
        if ((e as any)?.request.status === 401) return AuthErrors.Unauthorized;
        logger.error(e);
        return AuthErrors.Unexpected;
      }
    },
    [post]
  );

  const obtainToken = useCallback(
    async (refreshToken: string, auth2faCode?: string) => {
      try {
        const reqPayload = auth2faCode ? { code: auth2faCode } : null;
        const result = await post<ITokenResponse>(AuthEndpoints.Token, reqPayload, {
          headers: { Authorization: `Bearer ${refreshToken}` },
        });
        return result.data;
      } catch (e) {
        if ((e as any)?.request.status === 401) return AuthErrors.Unauthorized;
        logger.error(e);
        return AuthErrors.Unexpected;
      }
    },
    [post]
  );

  const signOut = useCallback(async (): Promise<boolean> => {
    try {
      await post(AuthEndpoints.SignOut);
      return true;
    } catch (e) {
      logger.error(e);
      return false;
    }
  }, [post]);

  const recoverAccount = useCallback(
    async (email: string) => {
      const result = await post(AuthEndpoints.RecoverAccount, { email });
      return result.data;
    },
    [post]
  );

  const accountRecoveryUpdatePassword = useCallback(
    async (payload: IResetPasswordPayload) => {
      const result = await put(AuthEndpoints.UpdatePassword, payload);
      return result.data;
    },
    [put]
  );

  const signUp = useCallback(
    async (payload: IResetPasswordPayload) => {
      const result = await post<unknown>(AuthEndpoints.SignUp, payload);
      return result.data;
    },
    [post]
  );

  const renewMfa = useCallback(
    async (refreshToken: string) => {
      try {
        await post(AuthEndpoints.RenewMfa, null, {
          headers: { Authorization: `Bearer ${refreshToken}` },
        });
        return true;
      } catch (e) {
        if ((e as any)?.request.status === 401) return AuthErrors.Unauthorized;
        logger.error(e);
        return AuthErrors.Unexpected;
      }
    },
    [post]
  );

  return {
    getAccountTypes,
    authenticate,
    obtainToken,
    signOut,
    signUp,
    recoverAccount,
    accountRecoveryUpdatePassword,
    renewMfa,
  };
}
