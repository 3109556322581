import React, { FC, ReactElement } from 'react';
import SentimentVeryDissatisfied from '@mui/icons-material/SentimentVeryDissatisfied';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useEnumState } from '../../hooks/useEnumState';
import { useTranslation } from 'react-i18next';
import styled from '@mui/styles/styled';

interface IProps {
  title: string;
  message: string | ReactElement;
}

interface IPropsWithExtra extends IProps {
  extraTitle: string;
  extraMessage?: string;
}
const isPropsWithExtra = (props: IProps): props is IPropsWithExtra => {
  return typeof (props as any).extraTitle === 'string';
};

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
});

const SadIcon = styled(SentimentVeryDissatisfied)({
  fontSize: '48px',
});

const ButtonsContainer = styled(Box)(({ theme }) => ({
  '& > *': {
    marginRight: theme.spacing(2),
  },
}));

export const ErrorPage: FC<IProps | IPropsWithExtra> = (props) => {
  const [extraVisible, showExtra, hideExtra] = useEnumState(false, true, false);
  const { t } = useTranslation();

  const { children, title, message } = props;

  return (
    <Container>
      <div>
        <Box mb={3}>
          <SadIcon />
        </Box>
        <Box mb={3}>
          <Typography variant="h4">{title}</Typography>
        </Box>
        <Box mb={4}>
          <Typography variant="h5">{message}</Typography>
        </Box>
        <ButtonsContainer mb={3}>{children}</ButtonsContainer>

        {isPropsWithExtra(props) ? (
          <Box>
            <Typography variant="h6">{props.extraTitle}</Typography>
            {props.extraMessage ? (
              <>
                {extraVisible ? <Typography variant="body1">{props.extraMessage}</Typography> : null}
                {extraVisible ? (
                  <Button size="small" onClick={hideExtra}>
                    {t('errorPage.showLess')}
                  </Button>
                ) : (
                  <Button size="small" onClick={showExtra}>
                    {t('errorPage.showMore')}
                  </Button>
                )}
              </>
            ) : null}
          </Box>
        ) : null}
      </div>
    </Container>
  );
};
