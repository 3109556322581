import { useHistory } from 'react-router-dom';
import { SyntheticEvent, useMemo } from 'react';

export type CloseDialogFunc = (
  arg1?: unknown | boolean | SyntheticEvent | number,
  arg2?: 'backdropClick' | 'escapeKeyDown' | number
) => void;

export const useCloseDialog = (url?: string, resetQueryParams: string[] = []) => {
  const history = useHistory();

  return useMemo(() => {
    function closeDialog(resetQuery: boolean): void;
    function closeDialog(event: unknown | SyntheticEvent, reason?: 'backdropClick' | 'escapeKeyDown'): void;
    function closeDialog(page: number, per: number): void;
    function closeDialog(highlight: number): void;
    function closeDialog(): void;

    function closeDialog(
      arg1?: boolean | unknown | SyntheticEvent | number,
      arg2?: 'backdropClick' | 'escapeKeyDown' | number
    ) {
      if (!url) url = history.location.pathname;
      if (arg1 === true) {
        history.push(url);
      } else if (typeof arg1 === 'number' && typeof arg2 === 'number') {
        history.push(`${url}?page=${arg1}&per=${arg2}`);
      } else if (typeof arg1 === 'number') {
        const search = new URLSearchParams(history.location.search);
        search.delete('page');
        search.set('highlight', arg1.toString(10));
        history.push(`${url}?${search}`);
      } else {
        const search = new URLSearchParams(history.location.search);

        for (const query of resetQueryParams) {
          search.delete(query);
        }

        if (search.toString()) {
          history.push(`${url}?${search.toString()}`);
        } else {
          history.push(url);
        }
      }
    }
    return closeDialog;
  }, [url]);
};
