import React, { FC } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { FreeTextFilter } from './FreeTextFilter';
import { IQuickFiltersProps, QuickFilters } from './QuickFilters';

interface IProps {
  quickFilters?: IQuickFiltersProps['quickFilters'];
  textFilter?: boolean;
}

export const EasyFilter: FC<IProps> = ({ quickFilters, textFilter = true }) => {
  return (
    <Box marginBottom={3}>
      <Grid container spacing={2}>
        {textFilter ? (
          <Grid item xs={12} md={quickFilters ? 6 : 12}>
            <FreeTextFilter />
          </Grid>
        ) : null}

        {quickFilters && [...quickFilters.values()].some(({ isVisible }) => isVisible !== false) ? (
          <Grid item xs={12} md={textFilter ? 6 : 12}>
            <QuickFilters quickFilters={quickFilters} />
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};
