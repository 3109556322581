import React, { FC, useCallback, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ICustomerUpdatePayload, useCustomersApi } from '../../hooks/useCustomersApi';
import { EditCustomerForm } from './EditCustomerForm';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { usePageSize } from '../../hooks/usePageSize';
import { CustomersFetcher } from '../../fetchers/CustomersFetcher';
import { useTranslation } from 'react-i18next';
import { NotificationContext } from '../../contexts/NotificationContext';

interface IProps {
  open: boolean;
  closeDialog: () => void;
}

export const AddCustomerDialog: FC<IProps> = ({ open, closeDialog }) => {
  const { data, onDataUpdate } = useContext(CustomersFetcher.Context);
  const { createCustomer } = useCustomersApi();
  const apiErrorHandler = useApiErrorHandler();
  const pageSize = usePageSize();
  const { t } = useTranslation();
  const { addNotification } = useContext(NotificationContext);

  const { control, handleSubmit, formState, reset } = useForm();

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open]);

  const onSubmit = useCallback(
    async (fields: ICustomerUpdatePayload) => {
      if (!data) return;
      try {
        const newData = await createCustomer([], fields, pageSize);
        addNotification({
          message: t('editCustomerDialog.add.invitationSent'),
          severity: 'success',
        });
        onDataUpdate(newData);
        closeDialog();
      } catch (e) {
        apiErrorHandler(e);
      }
    },
    [createCustomer, closeDialog]
  );

  if (!data) return null;
  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>{t('editCustomerDialog.add.title')}</DialogTitle>
      <DialogContent>
        <EditCustomerForm onSubmit={onSubmit} {...{ control, handleSubmit, formState }} />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>{t('common.dialog.close')}</Button>
        <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
          {t('editCustomerDialog.add.confirmButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
