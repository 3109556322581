import React, { FC, useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { GuestRoutes } from '../../routers/GuestRouter';
import { TextInputController } from '../InputControllers/TextInputController';
import { AuthDialog } from '../AuthDialog/AuthDialog';
import { useAccount } from '../../hooks/useAccount';
import { AccountType } from '../../types/AccountType';
import { useTranslation } from 'react-i18next';
import { AuthGoBack } from '../AuthDialog/AuthGoBack';
import { MountObserver } from '../MountObserver/MountObserver';

export const AuthEmail: FC = () => {
  const { account: user } = useAccount(AccountType.User);
  const { account: customer } = useAccount(AccountType.Customer);
  const { account: admin } = useAccount(AccountType.Admin);

  const history = useHistory();
  const { t } = useTranslation();

  const [emailFieldMounted, setEmailFieldMounted] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setFocus,
  } = useForm();

  const onSubmit = useCallback(async (data) => {
    const search = new URLSearchParams(history.location.search);
    search.set('email', data.email);
    history.push(`${GuestRoutes.Choose()}?${search.toString()}`);
  }, []);

  useEffect(() => {
    reset({ email: '' });
  }, []);

  useEffect(() => {
    if (emailFieldMounted) setFocus('email');
  }, [emailFieldMounted]);

  return (
    <AuthDialog
      title={t('auth.email.encouragement')}
      onSubmit={handleSubmit(onSubmit)}
      actions={[
        ...(user || customer || admin ? [<AuthGoBack key="goBack" to={GuestRoutes.Account()} />] : []),
        <Button key="signIn" variant="contained" color="primary" type="submit">
          {t('auth.email.submitButton')}
        </Button>,
      ]}
    >
      <MountObserver onChange={setEmailFieldMounted}>
        <TextInputController
          control={control}
          errors={errors}
          label={t('auth.inputs.email.label')}
          name="email"
          inputType="email"
          InputProps={{ autoComplete: 'email' }}
          rules={{ required: true }}
        />
      </MountObserver>
    </AuthDialog>
  );
};
