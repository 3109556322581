import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { AuthGoBack } from '../AuthDialog/AuthGoBack';
import { GuestRoutes } from '../../routers/GuestRouter';
import { Button, Grid } from '@mui/material';
import { AuthDialog } from '../AuthDialog/AuthDialog';
import { useLocation } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { CodeInput } from '../CodeInput/CodeInput';
import { AuthContext } from '../../contexts/AuthContext';
import { AuthErrors, useAuth } from '../../hooks/useAuth';
import { useRedirectAfterAuth } from '../../hooks/useRedirectAfterAuth';
import { NotificationContext } from '../../contexts/NotificationContext';
import { styled } from '@mui/styles';
import { TypographyButton, TypographySubtitle1 } from '../Typography/Typography';
import { useCountdown } from '../../hooks/useCountdown';
import { useEnumState } from '../../hooks/useEnumState';

const ErrorText = styled(TypographySubtitle1)(({ theme }) => ({
  color: theme.palette.error.main,
}));

const CountdownText = styled(TypographyButton)(({ theme }) => ({
  color: theme.palette.text.disabled,
}));

const RenewGridItem = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
});

const MIN_RENEW_INTERVAL = 60000;

const formatRemainingTime = (milliseconds: number) => {
  return `${Math.floor(milliseconds / 60000)}:${Math.floor((milliseconds % 60000) / 1000)
    .toString(10)
    .padStart(2, '0')}`;
};

export const AuthSecondFactor: FC = () => {
  const { search } = useLocation();
  const { t } = useTranslation();
  const { auth2faCache } = useContext(AuthContext);
  const { twoFactorAuth, renewMfa } = useAuth();
  useRedirectAfterAuth();
  const { addNotification } = useContext(NotificationContext);
  const [error, setError] = useState(false);
  const [lastResendTimestamp, setLastResendTimestamp] = useState(Date.now());
  const [isHoveringRenew, onHoverRenew, onLeaveRenew] = useEnumState(false, true, false);
  const remainingUntilRenew = useCountdown(lastResendTimestamp + MIN_RENEW_INTERVAL, isHoveringRenew ? 1000 : null);
  const canResend = remainingUntilRenew === 0;
  const [code, setCode] = useState('');

  useEffect(() => {
    setError(false);
  }, [code]);

  const onSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();
      const result = await twoFactorAuth(code);
      if (result === AuthErrors.Unexpected) {
        addNotification({
          severity: 'error',
          message: t('auth.2fa.unexpected'),
        });
      } else if (result === AuthErrors.Unauthorized) {
        setError(true);
      }
    },
    [code]
  );

  const onRenewMfa = useCallback(async () => {
    const result = await renewMfa();

    if (result === AuthErrors.Unexpected) {
      addNotification({
        severity: 'error',
        message: t('auth.2fa.unexpected'),
      });
    } else if (result === AuthErrors.Unauthorized) {
      addNotification({
        severity: 'error',
        message: t('auth.2fa.reSend.tooSoon'),
      });
    } else if (result === true) {
      setLastResendTimestamp(Date.now());
      addNotification({
        header: t('auth.2fa.reSend.notificationTitle'),
        severity: 'info',
        message: t('auth.2fa.reSend.notificationMessage'),
      });
    }
  }, []);

  if (!auth2faCache) {
    return null;
  }

  return (
    <AuthDialog
      title={
        <Trans i18nKey={'auth.2fa.title'} values={{ email: auth2faCache.email }}>
          Check <b>{auth2faCache.email}</b> for the 6-digit code
        </Trans>
      }
      onSubmit={onSubmit}
      actions={[
        <AuthGoBack key="goBack" to={`${GuestRoutes.SignIn()}${search}`} />,
        <Button key="signIn" variant="contained" color="primary" type="submit" disabled={code.length < 6}>
          {t('auth.2fa.submitButton')}
        </Button>,
      ]}
    >
      <CodeInput pattern="ddd-ddd" value={code} onChange={setCode} focusOnInit={true} error={error} />

      <Grid container>
        <Grid item xs={12} sm={7}>
          {error ? <ErrorText>{t('auth.2fa.invalid')}</ErrorText> : null}
        </Grid>
        <RenewGridItem item xs={12} sm={5} textAlign="right" onMouseEnter={onHoverRenew} onMouseLeave={onLeaveRenew}>
          {isHoveringRenew && !canResend ? (
            <CountdownText>{formatRemainingTime(remainingUntilRenew)}</CountdownText>
          ) : null}
          <Button onClick={onRenewMfa} variant="text" color="secondary" size="small" disabled={!canResend}>
            {t('auth.2fa.reSend.button')}
          </Button>
        </RenewGridItem>
      </Grid>
    </AuthDialog>
  );
};
