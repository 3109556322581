import React, { FC, useCallback, useContext, useMemo } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { ICronJob } from '../../models/CronJob';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AdminRoutes } from '../../routers/AdminRoutes';
import { DangerousButton } from '../CustomButtons/DangerousButton';
import { useIsRoute } from '../../hooks/useIsRoute';
import { ConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialog';
import { PendingJobType, useScheduledJobsApi } from '../../hooks/useScheduledJobsApi';
import { useIntParams } from '../../hooks/useIntParams';
import { PendingTasksFetcher } from './PendingTasks';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { useTranslation } from 'react-i18next';
import { AdminRouteLink } from '../RouteLink/RouteLink';

interface IProps {
  pendingTask?: ICronJob;
  closeDialog: () => void;
}

interface IUrlParams {
  jobType: PendingJobType;
  jobId: string;
}

export const PendingTaskDetailsDialog: FC<IProps> = ({ pendingTask, closeDialog }) => {
  const { onRefresh } = useContext(PendingTasksFetcher.Context);
  const isDeleteOpen = useIsRoute(AdminRoutes.DeleteTask);
  const { jobType } = useParams<IUrlParams>();
  const { jobId } = useIntParams<IUrlParams>();
  const { search } = useLocation();
  const history = useHistory();
  const { deleteScheduledJob } = useScheduledJobsApi();
  const handleError = useApiErrorHandler();
  const { t } = useTranslation();

  const requestFields = useMemo(() => {
    if (!pendingTask) return [];
    return Object.entries(pendingTask.request).map(([field, value]) => ({
      field,
      value,
    }));
  }, [pendingTask?.request]);

  const abortDeletion = useCallback(() => {
    history.push(`${AdminRoutes.TaskDetails(jobType, jobId)}${search}`);
  }, [jobType, jobId]);

  const onDelete = useCallback(async () => {
    try {
      await deleteScheduledJob([jobId]);
      await onRefresh();
      closeDialog();
    } catch (e) {
      handleError(e);
    }
  }, [deleteScheduledJob, jobId]);

  return (
    <Dialog open={!!pendingTask} onClose={closeDialog}>
      <DialogTitle>{t('pendingTasks.detailsDialog.title')}</DialogTitle>
      <DialogContent>
        <Table>
          <TableRow>
            <TableCell>{t('common.tableHeader.id')}:</TableCell>
            <TableCell>{pendingTask?.id}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t('pendingTasks.detailsDialog.operationLabel')}:</TableCell>
            <TableCell>{pendingTask?.operation}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t('pendingTasks.detailsDialog.ownerLabel')}:</TableCell>
            <TableCell>
              {pendingTask ? (
                <Link
                  component={AdminRouteLink}
                  route="Admin"
                  params={[pendingTask.owner]}
                  showFallback
                  underline="hover"
                >
                  {pendingTask.owner}
                </Link>
              ) : null}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t('common.tableHeader.status')}:</TableCell>
            <TableCell>{pendingTask?.status}</TableCell>
          </TableRow>

          {requestFields.map(({ field, value }) => (
            <TableRow key={field}>
              <TableCell>{field}</TableCell>
              <TableCell>{JSON.stringify(value)}</TableCell>
            </TableRow>
          ))}
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Close</Button>
        <DangerousButton component={AdminRouteLink} route="DeleteTask" params={[jobType, jobId]} keepQuery>
          {t('common.button.delete')}
        </DangerousButton>
      </DialogActions>

      <ConfirmationDialog
        open={isDeleteOpen}
        title={t('pendingTasks.deleteDialog.title')}
        message={t('pendingTasks.deleteDialog.message')}
        confirm={onDelete}
        abort={abortDeletion}
        dangerous
      />
    </Dialog>
  );
};
