import { CustomerTab } from '../components/Customer/Customer';
import { CustomerProductAction } from '../components/CustomerProduct/CustomerProduct';
import { PendingJobType } from '../hooks/useScheduledJobsApi';
import { ProductsTab } from '../components/AdminProducts/AdminProducts';
import { ReleasesApiEndpoints } from '../hooks/api/endpoints/releases';
import { RoutesEndpoints } from '../hooks/api/apiEndpoint';
import { OverviewEndpoints } from '../hooks/api/endpoints/overview';
import { AdminsEndpoints } from '../hooks/api/endpoints/admins';
import { CustomersEndpoints } from '../hooks/api/endpoints/customers';
import { UsersEndpoints } from '../hooks/api/endpoints/users';
import { ProductsEndpoints } from '../hooks/api/endpoints/products';
import { ProductPlansEndpoints } from '../hooks/api/endpoints/plans';
import { ProductPackagesEndpoints } from '../hooks/api/endpoints/packages';
import { ProductToolsEndpoints } from '../hooks/api/endpoints/tools';
import { SupportEndpoints } from '../hooks/api/endpoints/support';
import { AnalyticsEndpoints } from '../hooks/api/endpoints/analytics';
import { BalanceEndpoints } from '../hooks/api/endpoints/balance';
import { AccountEndpoints } from '../hooks/api/endpoints/account';
import { EventsEndpoints } from '../hooks/api/endpoints/events';
import { CustomerUsersEndpoints } from '../hooks/api/endpoints/customerUsers';
import { MachineEndpoints } from '../hooks/api/endpoints/machines';
import { ScheduledJobsEndpoints } from '../hooks/api/endpoints/scheduledJobs';
import { AdminLiveEndpoints } from '../hooks/api/endpoints/adminLive';

/* eslint prettier/prettier: ["error", { printWidth: 1000 }] */
export const adminRoot = '/admin';

export const AdminRoutes = {
  Overview: () => `${adminRoot}/overview`,
  Admins: () => `${adminRoot}/admins`,
  NewAdmin: () => `${adminRoot}/admins/new`,
  Admin: (adminId?: number) => `${adminRoot}/admins/${adminId ?? ':adminId'}`,
  AdminEdit: (adminId?: number) => `${adminRoot}/admins/${adminId ?? ':adminId'}/edit`,
  AdminDelete: (adminId?: number) => `${adminRoot}/admins/${adminId ?? ':adminId'}/delete`,
  Customers: () => `${adminRoot}/customers`,
  NewCustomer: () => `${adminRoot}/customers/new`,
  Customer: (customerId?: number) => `${adminRoot}/customers/${customerId ?? ':customerId'}`,
  CustomerTab: (customerId?: number, tab?: CustomerTab) => `${adminRoot}/customers/${customerId ?? ':customerId'}/${tab ?? ':tab'}`,
  CustomerNewTabItem: (customerId?: number, tab?: CustomerTab) => `${adminRoot}/customers/${customerId ?? ':customerId'}/${tab ?? ':tab'}/new`,
  CustomerDeleteTabItem: (customerId?: number, tab?: CustomerTab, itemId?: string) => `${adminRoot}/customers/${customerId ?? ':customerId'}/${tab ?? ':tab'}/${itemId ?? ':itemId'}/delete`,
  CustomerProduct: (customerId?: number, customerProductId?: number) => `${adminRoot}/customers/${customerId ?? ':customerId'}/products/${customerProductId ?? ':customerProductId'}`,
  CustomerProductLicense: (customerId?: number, customerProductId?: number, licenseId?: string) => `${adminRoot}/customers/${customerId ?? ':customerId'}/products/${customerProductId ?? ':customerProductId'}/licenses/${licenseId ? encodeURIComponent(licenseId) : ':licenseId'}`,
  CustomerProductAction: (customerId?: number, customerProductId?: number, action?: CustomerProductAction) => `${adminRoot}/customers/${customerId ?? ':customerId'}/products/${customerProductId ?? ':customerProductId'}/${action ?? ':action'}`,
  CustomerUser: (customerId?: number, userId?: number) => `${adminRoot}/customers/${customerId ?? ':customerId'}/users/${userId ?? ':userId'}`,
  CustomerUserRequestNewOffline: (customerId?: number, userId?: number) => `${adminRoot}/customers/${customerId ?? ':customerId'}/users/${userId ?? ':userId'}/offline`,
  EditCustomerUser: (customerId?: number, userId?: number) => `${adminRoot}/customers/${customerId ?? ':customerId'}/users/${userId ?? ':userId'}/edit`,
  DeleteCustomerUser: (customerId?: number, userId?: number) => `${adminRoot}/customers/${customerId ?? ':customerId'}/users/${userId ?? ':userId'}/delete`,
  CustomerUserAssignLicense: (customerId?: number, userId?: number) => `${adminRoot}/customers/${customerId ?? ':customerId'}/users/${userId ?? ':userId'}/licenses/assign`,
  CustomerUserOfflineLicense: (customerId?: number, userId?: number, licenseId?: number) => `${adminRoot}/customers/${customerId ?? ':customerId'}/users/${userId ?? ':userId'}/licenses/${licenseId ?? ':licenseId'}/offline`,
  CustomerUserReleaseLicense: (customerId?: number, userId?: number, licenseId?: number) => `${adminRoot}/customers/${customerId ?? ':customerId'}/users/${userId ?? ':userId'}/licenses/${licenseId ?? ':licenseId'}/release`,
  CustomerUserUnassignLicense: (customerId?: number, userId?: number, licenseId?: number) => `${adminRoot}/customers/${customerId ?? ':customerId'}/users/${userId ?? ':userId'}/licenses/${licenseId ?? ':licenseId'}/unassign`,
  CustomerMachine: (customerId?: number, userId?: number, machineId?: number) => `${adminRoot}/customers/${customerId ?? ':customerId'}/users/${userId ?? ':userId'}/machines/${machineId ?? ':machineId'}`,
  Users: () => `${adminRoot}/users`,
  Releases: () => `${adminRoot}/releases`,
  ReleasesProduct: (productId?: number) => `${adminRoot}/releases/${productId ?? ':productId'}`,
  NewReleaseProductVersion: (productId?: number) => `${adminRoot}/releases/${productId ?? ':productId'}/version/new`,
  ReleasesProductVersion: (productId?: number, version?: string) => `${adminRoot}/releases/${productId ?? ':productId'}/version/${version ?? ':version'}`,
  ReleasesProductVersionDelete: (productId?: number, version?: string) => `${adminRoot}/releases/${productId ?? ':productId'}/version/${version ?? ':version'}/delete`,
  Support: () => `${adminRoot}/support`,
  Events: () => `${adminRoot}/events`,
  Tasks: () => `${adminRoot}/tasks`,
  TasksTab: (jobType?: PendingJobType) => `${adminRoot}/tasks/${jobType ?? ':jobType'}`,
  TaskDetails: (jobType?: PendingJobType, jobId?: number) => `${adminRoot}/tasks/${jobType ?? ':jobType'}/${jobId ?? ':jobId'}`,
  DeleteTask: (jobType?: PendingJobType, jobId?: number) => `${adminRoot}/tasks/${jobType ?? ':jobType'}/${jobId ?? ':jobId'}/delete`,
  Products: () => `${adminRoot}/products`,
  ProductsTab: (tab?: ProductsTab) => `${adminRoot}/products/${tab ?? ':tab'}`,
  ProductsTabNewItem: (tab?: ProductsTab) => `${adminRoot}/products/${tab ?? ':tab'}/new`,
  ProductsTabEditItem: (tab?: ProductsTab, id?: number) => `${adminRoot}/products/${tab ?? ':tab'}/${id ?? ':id'}`,
  ProductsTabDeleteItem: (tab?: ProductsTab, id?: number) => `${adminRoot}/products/${tab ?? ':tab'}/${id ?? ':id'}/delete`,
  AnalyticsActivity: () => `${adminRoot}/analytics/activity`,
  AnalyticsActivityItem: (ip?: string) => `${adminRoot}/analytics/activity/${ip ?? ':ip'}`,
  Balance: () => `${adminRoot}/balance`,
  Account: () => `${adminRoot}/account`,
  AccountEdit: () => `${adminRoot}/account/edit`,
  AllLiveStacks: () => `${adminRoot}/live`,
};

export const AdminRoutesEndpoints: RoutesEndpoints<typeof AdminRoutes> = {
  Overview: [
    {
      method: ['GET'],
      path: OverviewEndpoints.Overview.template,
    },
  ],
  Admins: [{ method: ['GET'], path: AdminsEndpoints.Admins.template }],
  NewAdmin: [{ method: ['POST'], path: AdminsEndpoints.InviteAdmin.template }],
  Admin: [{ method: ['GET'], path: AdminsEndpoints.Admin.template }],
  AdminEdit: [{ method: ['PUT'], path: AdminsEndpoints.Admin.template }],
  AdminDelete: [{ method: ['DELETE'], path: AdminsEndpoints.Admin.template }],
  Customers: [{ method: ['GET'], path: CustomersEndpoints.Customers.template }],
  NewCustomer: [{ method: ['POST'], path: CustomersEndpoints.CustomerInvite.template }],
  Customer: [{ method: ['GET'], path: CustomersEndpoints.Customers.template }],
  CustomerTab: [
    { method: ['GET'], path: CustomersEndpoints.CustomerProducts.template, routeParams: [void 0, 'products'] },
    { method: ['GET'], path: CustomersEndpoints.CustomerUsers.template, routeParams: [void 0, 'users'] },
    { method: ['GET'], path: CustomersEndpoints.CustomerTickets.template, routeParams: [void 0, 'tickets'] },
    { method: ['GET'], path: CustomersEndpoints.CustomerInvoices.template, routeParams: [void 0, 'invoices'] },
    { method: ['GET'], path: AdminLiveEndpoints.CustomerLiveStacks.template, routeParams: [void 0, 'live'] },
  ],
  CustomerNewTabItem: [
    { method: ['POST'], path: CustomersEndpoints.CustomerUsers.template, routeParams: [void 0, 'users'] },
    { method: ['POST'], path: CustomersEndpoints.CustomerInvoices.template, routeParams: [void 0, 'invoices'] },
    { method: ['GET'], path: CustomersEndpoints.CustomerAvailableProducts.template, routeParams: [void 0, 'invoices'] },
    { method: ['POST'], path: CustomersEndpoints.CustomerProducts.template, routeParams: [void 0, 'products'] },
    { method: ['GET'], path: CustomersEndpoints.CustomerAvailableProducts.template, routeParams: [void 0, 'products'] },
    { method: ['GET'], path: CustomersEndpoints.CustomerUsers.template, routeParams: [void 0, 'live'] },
    { method: ['POST'], path: AdminLiveEndpoints.CustomerLiveStacks.template, routeParams: [void 0, 'live'] },
  ],
  CustomerDeleteTabItem: [
    { method: ['DELETE'], path: CustomersEndpoints.CustomerUsers.template, routeParams: [void 0, 'users'] },
    { method: ['DELETE'], path: CustomersEndpoints.CustomerInvoices.template, routeParams: [void 0, 'invoices'] },
    { method: ['DELETE'], path: CustomersEndpoints.CustomerProducts.template, routeParams: [void 0, 'products'] },
    { method: ['DELETE'], path: CustomersEndpoints.CustomerTickets.template, routeParams: [void 0, 'tickets'] },
  ],
  CustomerProduct: [{ method: ['GET'], path: CustomersEndpoints.CustomerProduct.template }],
  CustomerProductLicense: [{ method: ['GET'], path: CustomersEndpoints.CustomerProduct.template }],
  CustomerProductAction: [
    { method: ['POST'], path: CustomersEndpoints.RenewCustomerProduct.template, routeParams: [void 0, void 0, 'renew'] },
    { method: ['POST'], path: CustomersEndpoints.SuspendCustomerProduct.template, routeParams: [void 0, void 0, 'suspend'] },
    { method: ['DELETE'], path: CustomersEndpoints.CustomerProduct.template, routeParams: [void 0, void 0, 'revoke'] },
    { method: ['POST'], path: CustomersEndpoints.ReinstateCustomerProduct.template, routeParams: [void 0, void 0, 'reinstate'] },
  ],
  CustomerUser: [
    { method: ['GET'], path: CustomersEndpoints.CustomerUsers.template },
    { method: ['GET'], path: CustomerUsersEndpoints.CustomerUser.template },
  ],
  CustomerUserRequestNewOffline: [
    { method: ['POST'], path: CustomerUsersEndpoints.PackagesOffline.template },
    { method: ['POST'], path: CustomerUsersEndpoints.PackageOffline.template },
  ],
  EditCustomerUser: [{ method: ['PUT'], path: CustomerUsersEndpoints.CustomerUser.template }],
  DeleteCustomerUser: [{ method: ['DELETE'], path: CustomerUsersEndpoints.CustomerUser.template }],
  CustomerUserAssignLicense: [{ method: ['POST'], path: CustomerUsersEndpoints.Packages.template }],
  CustomerUserOfflineLicense: [{ method: ['POST'], path: CustomerUsersEndpoints.PackageOffline.template }],
  CustomerUserReleaseLicense: [
    { method: ['DELETE'], path: CustomerUsersEndpoints.PackageOffline.template },
    { method: ['DELETE'], path: CustomerUsersEndpoints.ReleaseOnlinePackage.template },
  ],
  CustomerUserUnassignLicense: [{ method: ['DELETE'], path: CustomerUsersEndpoints.Package.template }],
  CustomerMachine: [{ method: ['GET'], path: MachineEndpoints.MachineDetails.template }],
  Users: [{ method: ['GET'], path: UsersEndpoints.Users.template }],
  Releases: [
    { method: ['GET'], path: ProductsEndpoints.Products.template },
    { method: ['GET'], path: ReleasesApiEndpoints.ProductReleases.template },
  ],
  ReleasesProduct: [{ method: ['GET'], path: ReleasesApiEndpoints.ProductReleases.template }],
  NewReleaseProductVersion: [{ method: ['POST'], path: ReleasesApiEndpoints.ProductReleaseVersion.template }],
  ReleasesProductVersion: [{ method: ['PUT'], path: ReleasesApiEndpoints.ProductReleaseVersion.template }],
  ReleasesProductVersionDelete: [{ method: ['DELETE'], path: ReleasesApiEndpoints.ProductReleaseVersion.template }],
  Support: [{ method: ['GET'], path: SupportEndpoints.Tickets.template }],
  Events: [{ method: ['GET'], path: EventsEndpoints.Events.template }],
  Tasks: [{ method: ['GET'], path: ScheduledJobsEndpoints.CronJobs.template }],
  TasksTab: [{ method: ['GET'], path: ScheduledJobsEndpoints.CronJobs.template }],
  TaskDetails: [{ method: ['GET'], path: ScheduledJobsEndpoints.CronJobs.template }],
  DeleteTask: [{ method: ['DELETE'], path: ScheduledJobsEndpoints.CronJob.template }],
  Products: [{ method: ['GET'], path: ProductsEndpoints.Products.template }],
  ProductsTab: [
    { method: ['GET'], path: ProductsEndpoints.Products.template, routeParams: ['products'] },
    { method: ['GET'], path: ProductPlansEndpoints.Plans.template, routeParams: ['plans'] },
    { method: ['GET'], path: ProductPackagesEndpoints.Packages.template, routeParams: ['packages'] },
    { method: ['GET'], path: ProductToolsEndpoints.Tools.template, routeParams: ['tools'] },
  ],
  ProductsTabNewItem: [
    { method: ['POST'], path: ProductsEndpoints.Products.template, routeParams: ['products'] },
    { method: ['POST'], path: ProductPlansEndpoints.Plans.template, routeParams: ['plans'] },
    { method: ['POST'], path: ProductPackagesEndpoints.Packages.template, routeParams: ['packages'] },
    { method: ['POST'], path: ProductToolsEndpoints.Tools.template, routeParams: ['tools'] },
  ],
  ProductsTabEditItem: [
    { method: ['PUT'], path: ProductsEndpoints.Product.template, routeParams: ['products'] },
    { method: ['PUT'], path: ProductPlansEndpoints.Plan.template, routeParams: ['plans'] },
    { method: ['PUT'], path: ProductPackagesEndpoints.Package.template, routeParams: ['packages'] },
    { method: ['PUT'], path: ProductToolsEndpoints.Tool.template, routeParams: ['tools'] },
  ],
  ProductsTabDeleteItem: [
    { method: ['DELETE'], path: ProductsEndpoints.Product.template, routeParams: ['products'] },
    { method: ['DELETE'], path: ProductPlansEndpoints.Plan.template, routeParams: ['plans'] },
    { method: ['DELETE'], path: ProductPackagesEndpoints.Package.template, routeParams: ['packages'] },
    { method: ['DELETE'], path: ProductToolsEndpoints.Tool.template, routeParams: ['tools'] },
  ],
  AnalyticsActivity: [{ method: ['GET'], path: AnalyticsEndpoints.Activity.template }],
  AnalyticsActivityItem: [{ method: ['GET'], path: AnalyticsEndpoints.AccountActivity.template }],
  Balance: [{ method: ['GET'], path: BalanceEndpoints.Balance.template }],
  Account: [{ method: ['GET'], path: AccountEndpoints.AccountData.template }],
  AccountEdit: [{ method: ['PUT'], path: AdminsEndpoints.Admin.template }],
  AllLiveStacks: [
    { method: ['GET'], path: AdminLiveEndpoints.AllLiveStacks.template },
    { method: ['GET'], path: AdminLiveEndpoints.GlobalLiveRegions.template },
  ],
};
