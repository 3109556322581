import React, { FC, useCallback } from 'react';
import { ControllerProps } from 'react-hook-form/dist/types/controller';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { StandardTextFieldProps } from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Controller } from 'react-hook-form';

interface IProps extends Omit<ControllerProps, 'render'> {
  disabled?: boolean;
  errors: FieldErrors;
  InputProps?: StandardTextFieldProps['InputProps'];
  inputType?: string;
  label: string;
}

export const CheckboxController: FC<IProps> = ({
  disabled = false,
  errors,
  InputProps,
  inputType = 'text',
  label,
  name,
  ...restProps
}) => {
  const renderFn = useCallback<ControllerProps['render']>(
    ({ field }) => {
      return (
        <FormControlLabel
          control={
            <Checkbox {...field} disabled={disabled} checked={field.value} defaultChecked={restProps.defaultValue} />
          }
          label={label}
        />
      );
    },
    [label, inputType, InputProps, errors[name]]
  );

  return <Controller name={name} render={renderFn} {...restProps} />;
};
