export const routeWithQuery = <T extends Array<any>>(
  route: (...params: T) => string,
  params: T,
  query: Record<string, string>,
  queryString?: string
) => {
  if (Object.entries(query).length === 0 && !queryString) return route(...params);
  const search = new URLSearchParams(queryString);
  for (const [key, value] of Object.entries(query)) search.set(key, value);
  return `${route(...params)}?${search.toString()}`;
};
