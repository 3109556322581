import { createFetcher } from '../contexts/Fetcher';
import { useProductPlansApi } from '../hooks/useProductPlansApi';
import { useIntParams } from '../hooks/useIntParams';
import { useCallback } from 'react';

export const ProductPlanFetcher = createFetcher(() => {
  const { id } = useIntParams<{ id?: string }>();
  const { getPlan } = useProductPlansApi();

  return useCallback(async () => {
    if (typeof id !== 'number') {
      return null;
    }
    return getPlan([id]);
  }, [id, getPlan]);
});
