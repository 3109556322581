const stackIdRegexp =
  /^(?<prefix>cloudLiveCustomerStack-)(?<customerId>\d+)(?<separator1>-)(?<userId>\d+)(?<separator2>-)(?<stackId>\d+)$/;

export class StackName {
  public prefix = '';
  public customerId = '';
  public separator1 = '';
  public userId = '';
  public separator2 = '';
  public stackId = '';
  public valid = false;

  constructor(name: string) {
    const match = name.match(stackIdRegexp);

    if (match?.groups) {
      this.valid = true;
      this.prefix = match.groups.prefix;
      this.customerId = match.groups.customerId;
      this.separator1 = match.groups.separator1;
      this.userId = match.groups.userId;
      this.separator2 = match.groups.separator2;
      this.stackId = match.groups.stackId;
    }
  }
}
