import { createFetcher } from '../contexts/Fetcher';
import { useLiveApi } from '../hooks/useLiveApi';
import { useCallback } from 'react';

export const LiveRegionsFetcher = createFetcher(() => {
  const { getRegions } = useLiveApi();
  return useCallback(() => {
    return getRegions([]);
  }, [getRegions]);
});
