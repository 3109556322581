import React, { FC, useContext, useMemo } from 'react';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { NextExpiration } from './NextExpiration';
import { DateFormat } from '../../helpers/dateFormat';
import { CountPaper } from './CountPaper';
import { AdminOverviewRelease } from './AdminOverviewRelease';
import { useTranslation } from 'react-i18next';
import { Date } from '../Date/Date';
import { AdminOverviewFetcher } from '../../fetchers/AdminOverviewFetcher';
import { AdminRouteLink } from '../RouteLink/RouteLink';
import { ButtonWithChevron } from '../ButtonWithChevron/ButtonWithChevron';
import Box from '@mui/material/Box';
import Grid, { GridSize } from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import styled from '@mui/styles/styled';

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      overflow: 'hidden',
    },
    paperHeader: {
      padding: theme.spacing(3),
    },
    expirationWrapper: {
      maxHeight: 300,
      overflow: 'auto',
      padding: theme.spacing(3),
    },
  })
);

const StyledAdminRouteLink = styled(AdminRouteLink)({
  textDecoration: 'none',
});

const AdminOverviewContent: FC = () => {
  const styles = useStyles();
  const { data } = useContext(AdminOverviewFetcher.Context);
  const { t } = useTranslation();

  const releasesGridSizeLg = useMemo(() => {
    if (!data) return 12;
    const div = 12 / data.releases.length;
    if ([3, 4, 6, 12].includes(div)) return div as GridSize;
    return 4;
  }, [data?.releases.length]);

  const releasesGridSizeMd: GridSize = releasesGridSizeLg < 12 ? 6 : 12;

  if (!data) return null;

  return (
    <div>
      <Grid container spacing={3}>
        {data.licensesExpiring.length > 0 ? (
          <Grid item xs={12}>
            <Paper className={styles.paper}>
              <div className={styles.paperHeader}>
                <Typography variant="h4">{t('adminOverview.nextExpiration')}</Typography>
                <Typography variant="h6">
                  <Date date={data.licensesExpiring[0].expiresAt} format={DateFormat.Relative} />
                </Typography>
              </div>
              <div className={styles.expirationWrapper}>
                <NextExpiration licensesExpiring={data.licensesExpiring} />
              </div>
            </Paper>
          </Grid>
        ) : null}

        <Grid item xs={12} md={4}>
          <CountPaper count={data.totalCustomers} label={t('adminOverview.customers')}>
            <Box mt={4}>
              <StyledAdminRouteLink route="Customers" params={[]}>
                <ButtonWithChevron>{t('adminOverview.goToCustomers')}</ButtonWithChevron>
              </StyledAdminRouteLink>
            </Box>
          </CountPaper>
        </Grid>

        <Grid item xs={12} md={4}>
          <CountPaper count={data.totalUsers} label={t('adminOverview.users')}>
            <Box mt={4}>
              <StyledAdminRouteLink route="Users" params={[]}>
                <ButtonWithChevron>{t('adminOverview.goToUsers')}</ButtonWithChevron>
              </StyledAdminRouteLink>
            </Box>
          </CountPaper>
        </Grid>
        <Grid item xs={12} md={4}>
          <CountPaper count={data.totalAdmins} label={t('adminOverview.admins')}>
            <Box mt={4}>
              <StyledAdminRouteLink route="Admins" params={[]}>
                <ButtonWithChevron>{t('adminOverview.goToAdmins')}</ButtonWithChevron>
              </StyledAdminRouteLink>
            </Box>
          </CountPaper>
        </Grid>

        {data.releases.map((release, idx) => {
          return (
            <Grid item xs={12} md={releasesGridSizeMd} lg={releasesGridSizeLg} key={idx}>
              <AdminOverviewRelease release={release} />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export const AdminOverview: FC = () => {
  return (
    <AdminOverviewFetcher.WAF>
      <AdminOverviewContent />
    </AdminOverviewFetcher.WAF>
  );
};
