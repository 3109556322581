import React, { FC } from 'react';
import styled from '@mui/styles/styled';
import { QuickFilter } from './QuickFilter';
import { useTranslation } from 'react-i18next';

interface IFilter {
  filterField: string;
  filterOp: string;
  filterVal: string;
  sortBy?: string;
  sortOrder?: 'asc' | 'desc';
  isDefault?: boolean;
  isVisible?: boolean;
}

export interface IQuickFiltersProps {
  quickFilters: Map<string, IFilter>;
}

const QuickFiltersContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  color: theme.palette.text.disabled,
}));

export const QuickFilters: FC<IQuickFiltersProps> = ({ quickFilters }) => {
  const { t } = useTranslation();

  return (
    <QuickFiltersContainer>
      {t('component.easyFilter.quickFiltersLabel')}:
      {Array.from(quickFilters)
        .filter(([, { isVisible }]) => isVisible !== false)
        .map(([label, filter]) => {
          return <QuickFilter key={label} label={label} {...filter} />;
        })}
    </QuickFiltersContainer>
  );
};
