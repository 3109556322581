import React, { FC, useCallback, useContext } from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { LicenseContext } from '../../contexts/LicenseContext';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { useTranslation } from 'react-i18next';

interface IProps {
  onClose: () => void;
}

export const ReleaseOnlineLicense: FC<IProps> = ({ onClose }) => {
  const { onOnlineRelease } = useContext(LicenseContext)!;
  const apiErrorHandler = useApiErrorHandler();
  const { t } = useTranslation();

  const onRelease = useCallback(async () => {
    try {
      await onOnlineRelease();
    } catch (e) {
      apiErrorHandler(e, 'Failed to release an online license');
    }
  }, []);

  return (
    <>
      <DialogTitle>{t('licenses.releaseDialog.title')}</DialogTitle>
      <DialogContent dividers>
        <Typography paragraph>{t('licenses.releaseDialog.content.online')}</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          {t('common.dialog.close')}
        </Button>
        <Button variant="contained" color="primary" onClick={onRelease}>
          {t('licenses.releaseDialog.confirmRelease')}
        </Button>
      </DialogActions>
    </>
  );
};
