import { useApi } from './useApi';
import { IProductVersions } from '../models/ProductVersions';
import { IProductDetails } from '../models/ProductDetails';
import { IAwsFileMetadata } from './useDownloadApi';
import { ApiRequest, useEndpoint, useSimpleEndpoint } from './api/apiEndpoint';
import { ReleasesApiEndpoints } from './api/endpoints/releases';

interface IReleasesResponse {
  releases: [] | [IProductVersions];
}

export interface IProductReleaseApiData {
  changelogNotes: string;
  forceUpdate: boolean;
  pinCode: number;
}

export interface ICreateVersionPayload extends IProductReleaseApiData {
  filePath?: string;
  marketingNotes: string;
  emailOption: 'none' | 'allAdminsUsers' | 'notArchived' | 'activeLicense' | 'custom';
  customEmails: string;
}

export const useReleasesApi = () => {
  const { get } = useApi();
  const {
    put: putNonSigningOut,
    post: postNonSigningOut,
    del: delNonSigningOut,
  } = useApi({ signOutUnauthorized: false });

  const getReleases = useSimpleEndpoint(
    ReleasesApiEndpoints.ProductReleases,
    get as ApiRequest<typeof get, IReleasesResponse>
  );

  const updateRelease = useEndpoint(
    (put, payload: IProductReleaseApiData) => put(payload),
    ReleasesApiEndpoints.ProductReleaseVersion,
    putNonSigningOut as ApiRequest<typeof putNonSigningOut, IReleasesResponse>
  );

  const getProductInstallers = useSimpleEndpoint(
    ReleasesApiEndpoints.ProductInstallers,
    get as ApiRequest<typeof get, { product: IProductDetails; files: IAwsFileMetadata[] }>
  );

  const createVersion = useEndpoint(
    (post, payload: ICreateVersionPayload) => post(payload),
    ReleasesApiEndpoints.ProductReleaseVersion,
    postNonSigningOut as ApiRequest<typeof postNonSigningOut, IReleasesResponse>
  );

  const unRelease = useEndpoint(
    (del, pinCode: number) => del({ data: { pinCode } }),
    ReleasesApiEndpoints.ProductReleaseVersion,
    delNonSigningOut as ApiRequest<typeof delNonSigningOut, void>
  );

  return {
    getReleases,
    updateRelease,
    getProductInstallers,
    createVersion,
    unRelease,
  };
};
