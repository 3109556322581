import React, { FC, useCallback, useContext, useMemo } from 'react';
import { createFetcher, useAutoFetch } from '../../contexts/Fetcher';
import { useReleasesApi } from '../../hooks/useReleasesApi';
import { useIntParams } from '../../hooks/useIntParams';
import { useIsRoute } from '../../hooks/useIsRoute';
import { AdminRoutes } from '../../routers/AdminRoutes';
import { DataGrid, GridColumns, GridRowIdGetter, GridRowParams } from '@mui/x-data-grid';
import { FilesizeFormatter } from '../../valueFormatters/FilesizeValueFormatter';
import { getDateValueFormatter } from '../../valueFormatters/DateValueFormatter';
import { DateFormat } from '../../helpers/dateFormat';
import { getDateAfterFilterOperator, getDateBeforeFilterOperator } from '../../filterOperators/dateFilterOperators';
import { getFileSizeGreaterThan, getFileSizeLessThan } from '../../filterOperators/fileSizeFilterOperators';
import { IAwsFileMetadata } from '../../hooks/useDownloadApi';
import { EasyFilter } from '../EasyFilter/EasyFilter';
import { useFreeTextFilter } from '../../hooks/useFreeTextFilter';
import { useTranslation } from 'react-i18next';
import { isAxiosError } from '../../helpers/isAxiosError';
import { getInstallerVersion } from '../../helpers/getInstallerVersion';

interface IUrlParams {
  productId: string;
}

const InstallersFetcher = createFetcher(() => {
  const { productId } = useIntParams<IUrlParams>();
  const { getProductInstallers } = useReleasesApi();
  return useCallback(async () => {
    try {
      const result = await getProductInstallers([productId]);
      return result;
    } catch (e) {
      if (isAxiosError(e) && e.response?.status === 404) {
        return {
          product: void 0,
          files: [] as IAwsFileMetadata[],
        };
      }
      throw e;
    }
  }, [getProductInstallers, productId]);
});

const getId: GridRowIdGetter = (row) => row.Key;

interface IProps {
  onInstallerChange: (file: IAwsFileMetadata) => void;
}

const ProductInstallersContent: FC<IProps> = ({ onInstallerChange }) => {
  const isOpen = useIsRoute(AdminRoutes.NewReleaseProductVersion);
  useAutoFetch(InstallersFetcher, isOpen);
  const { data } = useContext(InstallersFetcher.Context);
  const { t } = useTranslation();

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'version',
        headerName: t('releases.addDialog.installer.version'),
        width: 60,
      },
      {
        field: 'Key',
        headerName: t('releases.addDialog.installer.fileName'),
        width: 350,
      },
      {
        field: 'LastModified',
        headerName: t('releases.addDialog.installer.modified'),
        width: 120,
        valueFormatter: getDateValueFormatter(DateFormat.DateMedium),
        filterOperators: [getDateBeforeFilterOperator(), getDateAfterFilterOperator()],
      },
      {
        field: 'Size',
        headerName: t('releases.addDialog.installer.size'),
        width: 120,
        valueFormatter: FilesizeFormatter,
        filterOperators: [getFileSizeLessThan(), getFileSizeGreaterThan()],
      },
    ],
    []
  );

  const onRowClick = useCallback(
    (params: GridRowParams) => {
      onInstallerChange(params.row as IAwsFileMetadata);
    },
    [onInstallerChange]
  );

  const filteredRows = useFreeTextFilter(data?.files ?? [], columns).map((row) => ({
    ...row,
    version: getInstallerVersion(row.Key),
  }));

  if (!data) return null;
  return (
    <>
      <EasyFilter />

      <DataGrid
        columns={columns}
        rows={filteredRows}
        getRowId={getId}
        autoHeight={true}
        pageSize={10}
        rowsPerPageOptions={[10]}
        onRowClick={onRowClick}
      />
    </>
  );
};

export const ProductInstallers: FC<IProps> = (props) => {
  return (
    <InstallersFetcher.WMF>
      <ProductInstallersContent {...props} />
    </InstallersFetcher.WMF>
  );
};
