import React, { FC, useCallback, useContext, useEffect } from 'react';
import { ReleasesFetcher } from '../../fetchers/ReleasesFetcher';
import { useHistory, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IProductReleaseForm, ProductReleaseForm } from './ProductReleaseForm';
import { useForm, FieldValues } from 'react-hook-form';
import { IInstaller } from '../../models/Installer';
import { DangerousButton } from '../CustomButtons/DangerousButton';
import { AdminRoutes } from '../../routers/AdminRoutes';
import { useIntParams } from '../../hooks/useIntParams';
import { ConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialog';
import { useIsRoute } from '../../hooks/useIsRoute';
import { useReleasesApi } from '../../hooks/useReleasesApi';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { NotificationContext } from '../../contexts/NotificationContext';
import { useTranslation } from 'react-i18next';
import { AdminRouteLink } from '../RouteLink/RouteLink';

interface IUrlParams {
  productId: string;
  version?: string;
}

interface IProps {
  closeDialog: () => void;
}

export const EditReleaseDialog: FC<IProps> = ({ closeDialog }) => {
  const { data, onRefresh, onDataUpdate } = useContext(ReleasesFetcher.Context);
  const { version } = useParams<IUrlParams>();
  const { productId } = useIntParams<IUrlParams>();
  const { control, handleSubmit, formState, watch, reset, trigger, getValues } = useForm<FieldValues>({
    mode: 'all',
    defaultValues: {
      changelogNotes: '',
      forceUpdate: false,
      pinCode: '',
      version: '',
      filePath: '',
    },
  });

  const isValid = formState.isValid;

  const isDelete = useIsRoute(AdminRoutes.ReleasesProductVersionDelete);
  const { updateRelease, unRelease } = useReleasesApi();
  const handleError = useApiErrorHandler();
  const history = useHistory();
  const { addNotification } = useContext(NotificationContext);
  const { t } = useTranslation();

  const onSubmit = useCallback(
    async (data: IProductReleaseForm) => {
      if (!version) return;
      const payload = {
        ...data,
        pinCode: parseInt(data.pinCode),
      };
      try {
        const newData = await updateRelease([productId, version], payload);
        onDataUpdate(newData);
        addNotification({
          severity: 'success',
          message: t('releases.editDialog.updated'),
        });
      } catch (e) {
        handleError(e);
      }
    },
    [updateRelease, productId, version]
  );

  const allVersions = data?.releases[0]?.versions.reduce<IInstaller[]>(
    (memo, current) => [...memo, ...current.installer],
    []
  );

  const release = allVersions?.find((ver) => ver.version === version);
  const open = !!release;

  useEffect(() => {
    if (release)
      reset({
        changelogNotes: release.changelog,
        forceUpdate: release.forceUpdate,
        pinCode: '',
        version: release.version,
        filePath: release.filePath,
      });
  }, [release]);

  const onUnrelease = useCallback(async () => {
    const data = getValues();
    if (!version) return;
    if (!data.pinCode) {
      await trigger('pinCode');
      history.push(AdminRoutes.ReleasesProductVersion(productId, version));
    } else {
      try {
        await unRelease([productId, version], parseInt(data.pinCode));
        await onRefresh();
        closeDialog();
      } catch (e) {
        handleError(e);
      }
    }
  }, [productId, version]);

  if (!data || !release) return null;
  return (
    <>
      <Dialog open={open} onClose={closeDialog}>
        <DialogTitle>{t('releases.editDialog.title')}</DialogTitle>
        <DialogContent>
          <ProductReleaseForm
            onSubmit={onSubmit}
            control={control}
            handleSubmit={handleSubmit}
            formState={formState}
            watch={watch}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <DangerousButton
            component={AdminRouteLink}
            route="ReleasesProductVersionDelete"
            params={[productId, version!]}
            disabled={!isValid}
          >
            {t('releases.editDialog.unReleaseButton')}
          </DangerousButton>
          <Button color="primary" variant="contained" onClick={handleSubmit(onSubmit)} disabled={!isValid}>
            {t('releases.editDialog.confirm')}
          </Button>
        </DialogActions>
      </Dialog>

      <ConfirmationDialog
        open={isDelete}
        title={t('releases.unReleaseDialog.title')}
        confirm={onUnrelease}
        abort={AdminRoutes.ReleasesProductVersion(productId, version)}
        message={t('releases.unReleaseDialog.message')}
      />
    </>
  );
};
