import React, { FC, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { ControllerProps } from 'react-hook-form/dist/types/controller';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { StandardTextFieldProps } from '@mui/material/TextField';

interface IProps extends Omit<ControllerProps, 'render'> {
  disabled?: boolean;
  errors: FieldErrors;
  InputProps?: StandardTextFieldProps['InputProps'];
  inputType?: string;
  items: Array<{ value: number | string; label: string }>;
  label: string;
  native?: boolean;
}

export const SelectController: FC<IProps> = ({
  disabled = false,
  errors,
  InputProps,
  inputType = 'text',
  items,
  label,
  name,
  native = false,
  ...restProps
}) => {
  const renderFn = useCallback<ControllerProps['render']>(
    ({ field }) => (
      <FormControl fullWidth variant="filled">
        <InputLabel>{label}</InputLabel>
        <Select fullWidth {...field} error={!!errors[name]} disabled={disabled} native={native}>
          {items.map((item) =>
            native ? (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ) : (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>
    ),
    [label, inputType, InputProps, errors[name], items]
  );

  return <Controller name={name} render={renderFn} {...restProps} />;
};
