import { dateFormat, DateFormat } from '../helpers/dateFormat';
import { GridValueFormatterParams } from '@mui/x-data-grid';

import i18n from 'i18next';

export const getDateValueFormatter =
  (format: DateFormat = DateFormat.DateMedium) =>
  (params: GridValueFormatterParams) =>
    typeof params.value === 'string' || typeof params.value === 'number'
      ? dateFormat(params.value, format, i18n.language)
      : null;
