import React, { FC, useCallback, useRef } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { IUserDownloadAddonAbstract, IUserDownloadAddonMetadata } from '../../hooks/useDownloadApi';
import { useDateFormat } from '../../hooks/useDateFormat';
import { displayFilesize } from '../../helpers/displayFilesize';
import { useDownload } from '../../hooks/useDownload';
import { useInjectedAnchors } from '../../hooks/useInjectedAnchors';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { DateFormat } from '../../helpers/dateFormat';
import GetApp from '@mui/icons-material/GetApp';
import styled from '@mui/styles/styled';
import { useTranslation } from 'react-i18next';
import { AspectRatio } from '../AspectRatio/AspectRatio';
import { Changelog } from '../Changelog/Changelog';

interface IProps {
  addon: IUserDownloadAddonAbstract;
  getMeta: (addon: IUserDownloadAddonAbstract) => IUserDownloadAddonMetadata;
}

const CompatibleWith = styled('span')(({ theme }) => ({
  letterSpacing: 0.16,
  marginRight: theme.spacing(1),
}));

const AddonImage = styled('div')({
  width: '100%',
  height: '100%',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
});

const DescriptionContent = styled(Changelog)(({ theme }) => ({
  padding: theme.spacing(3),
}));

export const SingleDownloadProductAddon: FC<IProps> = ({ addon, getMeta }) => {
  const { t } = useTranslation();
  const released = useDateFormat(addon.file.LastModified, DateFormat.DateFull);
  const download = useDownload();
  const apiErrorHandler = useApiErrorHandler();
  const contentRef = useRef<HTMLDivElement>(null);
  useInjectedAnchors(contentRef);

  const addonMeta = getMeta(addon);

  const onDownload = useCallback(async () => {
    try {
      await download(addon.file.Key);
    } catch (e) {
      apiErrorHandler(e, t('downloads.downloadFailureMessage'));
    }
  }, [download, addon.file.Key]);

  return (
    <div>
      <Grid container>
        <Grid item xs={12} md={4} lg={2}>
          <Box p={3}>
            <Box marginBottom={2}>
              <Typography variant="h4">{addonMeta.title}</Typography>
            </Box>

            <Box marginBottom={2}>
              <Typography variant="body2">
                <CompatibleWith>{t('downloads.compatibleWith')}</CompatibleWith>
                {addonMeta.versions.map((version) => {
                  return <Chip key={version} component="span" label={version} color="primary" />;
                })}
              </Typography>
            </Box>

            <Box marginBottom={2}>
              <Typography variant="subtitle2">{t('downloads.releaseDate', { date: released })}</Typography>
            </Box>

            <Box marginBottom={2}>
              <Typography variant="subtitle2">
                {t('downloads.size', { size: displayFilesize(addon.file.Size) })}
              </Typography>
            </Box>

            <Button variant="contained" color="primary" onClick={onDownload} endIcon={<GetApp />}>
              {t('downloads.downloadButton')}
            </Button>
          </Box>
        </Grid>
        <Grid item container xs={12} md={8} lg={10} component={Paper}>
          <Grid item xs={12}>
            <AspectRatio aspectRatio={5 / 2}>
              <AddonImage style={{ backgroundImage: `url("${addon.image}")` }} />
            </AspectRatio>
          </Grid>
          <Grid
            item
            xs={12}
            dangerouslySetInnerHTML={{ __html: addonMeta.description }}
            ref={contentRef}
            component={DescriptionContent}
          />
        </Grid>
      </Grid>
    </div>
  );
};
