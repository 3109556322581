import { useCallback, useContext, useEffect, useRef } from 'react';
import { useAccount } from './useAccount';
import { useQueryParam } from './useQueryParam';
import { AccountContext } from '../contexts/AccountContext';
import { AuthContext } from '../contexts/AuthContext';
import { useHistory, useLocation } from 'react-router-dom';
import { accountTypePrefix } from './useApi';
import { LoadingContext } from '../contexts/LoadingContext';
import { useSupportApi } from './useSupportApi';
import { useApiErrorHandler } from './useApiErrorHandler';
import { AccountType } from '../types/AccountType';
import { adminRoot } from '../routers/AdminRoutes';
import { CustomerRoutes } from '../routers/CustomerRoutes';
import { UserRoutes } from '../routers/UserRoutes';
import { usePostRedirect } from './usePostRedirect';

export const useRedirectAfterAuth = () => {
  const accountType = useContext(AccountContext)!;
  const { account } = useAccount(accountType);
  const { search } = useLocation();
  const queryEmail = useQueryParam('email');
  const { auth2faCache } = useContext(AuthContext);
  const history = useHistory();
  const { startLoading, endLoading } = useContext(LoadingContext);
  const { zenDeskSSO } = useSupportApi();
  const handleApiError = useApiErrorHandler();
  const postRedirect = usePostRedirect();

  const emailRef = useRef(queryEmail || auth2faCache?.email);
  emailRef.current = queryEmail || auth2faCache?.email || emailRef.current;

  const performZendeskRedirect = useCallback(
    async (url: string, brand_id?: string, timestamp?: string) => {
      startLoading();
      try {
        const { endpoint, body } = await zenDeskSSO([], {
          url,
          brand_id,
          timestamp,
        });
        postRedirect(endpoint, body);
        return;
      } catch (e) {
        handleApiError(e);
      }
      endLoading();
    },
    [zenDeskSSO]
  );

  useEffect(() => {
    if (account?.email === emailRef.current) {
      const urlSearch = new URLSearchParams(search);

      if (urlSearch.has('redirect')) {
        const url = urlSearch.get('redirect')!;

        if (url.startsWith(accountTypePrefix[accountType])) {
          history.push(url);
          return;
        }
      }

      if (urlSearch.has('return_to')) {
        performZendeskRedirect(
          urlSearch.get('return_to')!,
          urlSearch.get('brand_id') ?? void 0,
          urlSearch.get('timestamp') ?? void 0
        ).then();
        return;
      }

      if (accountType === AccountType.Admin) {
        history.replace(adminRoot);
      } else if (accountType === AccountType.Customer) {
        history.replace(CustomerRoutes.Overview());
      } else {
        history.replace(UserRoutes.Overview());
      }
    }
  }, [account, accountType, search, performZendeskRedirect]);
};
