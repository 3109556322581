import React, { FC, useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { FileInput } from '../FileInput/FileInput';
import { useTranslation } from 'react-i18next';
import {
  newRequestFileExtension,
  oldRequestFileExtension,
} from '../RequestOfflineLicenseStandalone/offlineRequestConst';

interface IProps {
  onClose: () => void;
  onOfflineRequest: (file: File) => Promise<void>;
}

export const RequestOfflineLicense: FC<IProps> = ({ onClose, onOfflineRequest }) => {
  const [file, setFile] = useState<File | null>(null);
  const apiErrorHandler = useApiErrorHandler();
  const { t } = useTranslation();

  const onUpload = useCallback(async () => {
    if (!file) return;
    try {
      await onOfflineRequest(file);
    } catch (e) {
      apiErrorHandler(e, t('licenses.offlineDialog.requestFailed'));
    }
  }, [onOfflineRequest, file]);

  return (
    <>
      <DialogTitle>{t('licenses.offlineDialog.title')}</DialogTitle>
      <DialogContent dividers>
        <Typography paragraph>{t('licenses.offlineDialog.description')}</Typography>
        <Box p={1} textAlign="center">
          <img src="/offline-step2.svg" />
        </Box>
        <Typography paragraph>{t('licenses.offlineDialog.content.request')}</Typography>

        <FileInput
          value={file}
          onChange={setFile}
          label={t('licenses.offlineDialog.fileInputLabel')}
          changeLabel={t('licenses.offlineDialog.fileInputChangeLabel')}
          id="license-request-file"
          accept={[oldRequestFileExtension, newRequestFileExtension].join(',')}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          {t('common.dialog.close')}
        </Button>
        <Button variant="contained" color="primary" disabled={!file} onClick={onUpload}>
          {t('licenses.offlineDialog.proceedToLicensesButton')}
        </Button>
      </DialogActions>
    </>
  );
};
