import React, { FC, useCallback, useEffect } from 'react';
import Chip from '@mui/material/Chip';
import { useQueryParam } from '../../hooks/useQueryParam';
import { useHistory } from 'react-router-dom';
import styled from '@mui/styles/styled';

interface IProps {
  label: string;
  filterOp: string;
  filterVal: string;
  filterField: string;
  sortBy?: string;
  sortOrder?: 'asc' | 'desc';
  isDefault?: boolean;
}

const StyledChip = styled(Chip)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

export const QuickFilter: FC<IProps> = ({ label, filterOp, filterVal, filterField, sortBy, sortOrder, isDefault }) => {
  const currentFilterOp = useQueryParam('filterOp');
  const currentFilterVal = useQueryParam('filterVal');
  const currentFilterField = useQueryParam('filterField');
  const history = useHistory();

  const isActive = currentFilterField === filterField && currentFilterVal === filterVal && currentFilterOp === filterOp;

  const onDeactivate = useCallback(() => {
    const searchParams = new URLSearchParams(history.location.search);
    searchParams.delete('filterOp');
    searchParams.delete('filterVal');
    searchParams.delete('filterField');
    if (sortBy && sortOrder) {
      searchParams.delete('sortBy');
      searchParams.delete('sortOrder');
    }
    searchParams.delete('page');
    searchParams.delete('pageSize');
    history.push(`?${searchParams.toString()}`);
  }, []);

  const onActivate = useCallback(
    (historyMethod: 'push' | 'replace' = 'push') => {
      const searchParams = new URLSearchParams(history.location.search);
      searchParams.set('filterOp', filterOp);
      searchParams.set('filterVal', filterVal);
      searchParams.set('filterField', filterField);
      searchParams.delete('page');
      searchParams.delete('pageSize');

      if (sortBy && sortOrder) {
        searchParams.set('sortBy', sortBy);
        searchParams.set('sortOrder', sortOrder);
      }

      // @todo remove following when it'll be possible to use both free text and column filters
      searchParams.delete('text');

      history[historyMethod](`?${searchParams.toString()}`);
    },
    [filterOp, filterVal, filterField]
  );

  const onClick = useCallback(() => onActivate(), [onActivate]);

  useEffect(() => {
    const searchParams = new URLSearchParams(history.location.search);
    if (
      isDefault &&
      !searchParams.has('text') &&
      !searchParams.has('filterOp') &&
      !searchParams.has('filterVal') &&
      !searchParams.has('filterField') &&
      ((!sortBy && !sortOrder) || (!searchParams.has('sortBy') && !searchParams.has('sortOrder'))) &&
      !searchParams.has('page') &&
      !searchParams.has('pageSize')
    ) {
      onActivate('replace');
    }
  }, []);

  return (
    <StyledChip
      label={label}
      color="secondary"
      variant={isActive ? 'filled' : 'outlined'}
      onDelete={isActive ? onDeactivate : void 0}
      onClick={isActive ? void 0 : onClick}
    />
  );
};
