import { useApi } from './useApi';
import { IAccountResponse } from '../models/AccountResponse';
import { ApiRequest, useEndpoint, useSimpleEndpoint } from './api/apiEndpoint';
import { AccountEndpoints } from './api/endpoints/account';

export const useAccountApi = () => {
  const { get, put } = useApi();

  const getAccountData = useSimpleEndpoint(
    AccountEndpoints.AccountData,
    get as ApiRequest<typeof get, IAccountResponse>
  );

  const setMfaEnabled = useEndpoint(
    (put, payload: { MFAEnabled: boolean }) => put(payload),
    AccountEndpoints.AccountData,
    put as ApiRequest<typeof put, IAccountResponse>
  );

  return {
    getAccountData,
    setMfaEnabled,
  };
};
