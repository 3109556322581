import React, { FC, useCallback, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { RequestOfflineLicense } from '../Licenses/RequestOfflineLicense';
import { RequestOfflineChooseLicense } from './RequestOfflineChooseLicense';
import { IAdminCustomerUserResponse } from '../../models/AdminCustomerUserResponse';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { newRequestFileExtension } from './offlineRequestConst';

interface IProps {
  open: boolean;
  onClose: () => void;
  machinePackages: IAdminCustomerUserResponse['machinePackages'];
  onSubmit: (file: File, basePackage: number, addonPackages: number[]) => Promise<void>;
}

export const RequestOfflineLicenseStandalone: FC<IProps> = ({ open, onClose, machinePackages, onSubmit }) => {
  const handleApiError = useApiErrorHandler();
  const [requestFile, setRequestFile] = useState<File | null>(null);

  const canUseAddons = requestFile ? requestFile.name.endsWith(newRequestFileExtension) : false;

  const onSetFile = useCallback(async (file: File) => {
    setRequestFile(file);
  }, []);

  const onChooseLicense = useCallback(
    async (data: { basePackage: number; addonPackages: number[] }) => {
      try {
        await onSubmit(requestFile!, data.basePackage, canUseAddons ? data.addonPackages : []);
      } catch (e) {
        handleApiError(e);
      }
    },
    [onSubmit, requestFile, handleApiError]
  );

  useEffect(() => {
    setRequestFile(null);
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'md'}>
      {requestFile ? (
        <RequestOfflineChooseLicense
          onSubmit={onChooseLicense}
          onClose={onClose}
          machinePackages={machinePackages}
          showAddons={canUseAddons}
        />
      ) : (
        <RequestOfflineLicense onClose={onClose} onOfflineRequest={onSetFile} />
      )}
    </Dialog>
  );
};
