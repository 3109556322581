import React, { FC } from 'react';
import { alpha } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import { GridCellParams } from '@mui/x-data-grid';
import styled from '@mui/styles/styled';
import { Colors } from '../../colors';
import { useTranslation } from 'react-i18next';

type color = 'primary' | 'secondary' | 'default' | 'lime' | 'red' | 'orange' | 'blue' | 'dimmed';

const StyledChip = styled(Chip)(({ theme }) => ({
  '&.StyledChip-lime': {
    backgroundColor: Colors.Lime,
  },
  '&.StyledChip-red': {
    backgroundColor: Colors.Red,
    color: theme.palette.text.primary,
  },
  '&.StyledChip-orange': {
    backgroundColor: Colors.Orange,
  },
  '&.StyledChip-blue': {
    backgroundColor: Colors.Blue,
  },
  '&.StyledChip-dimmed': {
    backgroundColor: alpha(theme.palette.text.primary, 0.16),
    color: theme.palette.text.primary,
    boxShadow: `${alpha(theme.palette.text.primary, 0.3)} 0 1px 0`,
  },
}));

export const createStatusChipComponent = (possibleValues: Record<string | number, color | [string, color?]>) => {
  const StatusChip: FC<Pick<GridCellParams, 'value'>> = ({ value }) => {
    const { t } = useTranslation();

    if (typeof value === 'boolean') value = value.toString();
    const chipDef = typeof value === 'string' || typeof value === 'number' ? possibleValues[value] ?? null : null;

    const label = typeof chipDef === 'string' ? value : Array.isArray(chipDef) ? chipDef[0] : null;

    const color =
      typeof chipDef === 'string'
        ? chipDef
        : Array.isArray(chipDef) && typeof chipDef[1] === 'string'
        ? chipDef[1]
        : null;

    if (!label) return <span />;
    if (!color) return <span>{t(label)}</span>;

    if (color === 'primary' || color === 'secondary' || color === 'default') {
      return <Chip label={t(label)} color={color} />;
    }

    return <StyledChip label={t(label)} color="primary" className={`StyledChip-${color}`} />;
  };
  return (params: Pick<GridCellParams, 'value'>) => <StatusChip {...params} />;
};
