import { useCallback } from 'react';
import { useDownloadApi } from './useDownloadApi';

export const useDownload = () => {
  const { download: apiDownload } = useDownloadApi();

  const download = useCallback(
    async (url: string) => {
      const result = await apiDownload(url);
      window.open(result.url, '_blank', 'noopener,noreferrer');
    },
    [apiDownload]
  );

  return download;
};
