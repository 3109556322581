import { useApi } from './useApi';
import { IEventsResponse } from '../models/EventsResponse';
import { ApiRequest, usePaginatedGetEndpoint } from './api/apiEndpoint';
import { EventsEndpoints } from './api/endpoints/events';

export const useEventsApi = () => {
  const { get } = useApi();

  const getEvents = usePaginatedGetEndpoint(EventsEndpoints.Events, get as ApiRequest<typeof get, IEventsResponse>);

  return {
    getEvents,
  };
};
