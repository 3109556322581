import React, { FC } from 'react';
import { Redirect, Switch, useParams } from 'react-router-dom';
import { RouteWrapper } from '../components/RouteWrapper';
import { AuthPassword } from '../components/AuthPassword/AuthPassword';
import { AuthLayout } from '../layouts/AuthLayout';
import { AuthRecoverAccount } from '../components/AuthRecoverAccount/AuthRecoverAccount';
import { AccountRecoveryUpdatePassword } from '../components/AccountRecoveryUpdatePassword/AccountRecoveryUpdatePassword';
import { AccountType } from '../types/AccountType';
import { useAccount } from '../hooks/useAccount';
import { AuthEmail } from '../components/AuthEmail/AuthEmail';
import { AuthChooseAccountType } from '../components/AuthChooseAccountType';
import { AuthAccount } from '../components/AuthAccount/AuthAccount';
import { RedirectPreserveQuery } from '../components/RedirectPreserveQuery/RedirectPreserveQuery';
import { AccountContext } from '../contexts/AccountContext';
import { AuthSignUp } from '../components/AuthSignUp/AuthSignUp';
import { RedirectWithQuery } from './RedirectWithQuery';
import { NotFoundComponent } from '../components/NotFound';
import { AuthSecondFactor } from '../components/AuthSecondFactor/AuthSecondFactor';

export const guestRoot = '/auth';

/* eslint prettier/prettier: ["error", { printWidth: 1000 }] */
export const GuestRoutes = {
  Root: () => guestRoot,
  SignIn: () => `${guestRoot}/signin`,
  SignUp: (accountType?: AccountType) => `${guestRoot}/${accountType ?? ':accountType'}/signup`,
  Account: () => `${guestRoot}/account`,
  Choose: () => `${guestRoot}/choose`,
  Start: () => `/start`,

  Password: (accountType?: AccountType) => `${guestRoot}/${accountType ?? ':accountType'}`,
  SecondFactor: (accountType?: AccountType) => `${guestRoot}/${accountType ?? ':accountType'}/2fa`,
  RecoverAccount: (accountType?: AccountType) => `${guestRoot}/${accountType ?? ':accountType'}/recover-account`,
  UpdatePassword: (accountType?: AccountType) => `${guestRoot}/${accountType ?? ':accountType'}/update-password`,

  LegacyUserUpdatePassword: () => '/customer/user/update-password',
  LegacyCustomerUpdatePassword: () => '/customer/admin/update-password',
  LegacyAdminUpdatePassword: () => '/admin/update-password',

  LegacyUserSignIn: () => '/customer/user/signin',
  LegacyCustomerSignIn: () => '/customer/admin/signin',
  LegacyAdminSignIn: () => '/admin/signin',

  LegacyUserSignUp: () => '/customer/user/signup',
  LegacyCustomerSignUp: () => '/customer/admin/signup',
  LegacyAdminSignUp: () => '/admin/signup',
};

const AccountTypeProvider: FC = ({ children }) => {
  const { accountType } = useParams<{ accountType: AccountType }>();
  return <AccountContext.Provider value={accountType}>{children}</AccountContext.Provider>;
};

const providers = [AccountTypeProvider];

export const GuestRouter: FC = () => {
  const { account: user } = useAccount(AccountType.User);
  const { account: customer } = useAccount(AccountType.Customer);
  const { account: admin } = useAccount(AccountType.Admin);

  return (
    <Switch>
      <RedirectPreserveQuery exact from={GuestRoutes.Start()} to={GuestRoutes.Root()} />
      {user || customer || admin ? <RedirectPreserveQuery exact from={GuestRoutes.Root()} to={GuestRoutes.Account()} /> : <RedirectPreserveQuery exact from={GuestRoutes.Root()} to={GuestRoutes.SignIn()} />}

      <RedirectWithQuery to={GuestRoutes.UpdatePassword(AccountType.Customer)} from={GuestRoutes.LegacyCustomerUpdatePassword()} />
      <RedirectWithQuery to={GuestRoutes.SignUp(AccountType.Customer)} from={GuestRoutes.LegacyCustomerSignUp()} />
      <RedirectWithQuery to={GuestRoutes.Password(AccountType.Customer)} from={GuestRoutes.LegacyCustomerSignIn()} />
      <RedirectWithQuery to={GuestRoutes.UpdatePassword(AccountType.User)} from={GuestRoutes.LegacyUserUpdatePassword()} />
      <RedirectWithQuery to={GuestRoutes.SignUp(AccountType.User)} from={GuestRoutes.LegacyUserSignUp()} />
      <RedirectWithQuery to={GuestRoutes.Password(AccountType.User)} from={GuestRoutes.LegacyUserSignIn()} />
      <RedirectWithQuery to={GuestRoutes.UpdatePassword(AccountType.Admin)} from={GuestRoutes.LegacyAdminUpdatePassword()} />
      <RedirectWithQuery to={GuestRoutes.SignUp(AccountType.Admin)} from={GuestRoutes.LegacyAdminSignUp()} />
      <RedirectWithQuery to={GuestRoutes.Password(AccountType.Admin)} from={GuestRoutes.LegacyAdminSignIn()} />

      <RouteWrapper exact path={GuestRoutes.SignIn()} component={AuthEmail} layout={AuthLayout} />

      <RouteWrapper exact path={GuestRoutes.Account()} component={AuthAccount} layout={AuthLayout} />

      <RouteWrapper exact path={GuestRoutes.Choose()} component={AuthChooseAccountType} layout={AuthLayout} />

      <RouteWrapper exact path={GuestRoutes.Password()} component={AuthPassword} providers={providers} layout={AuthLayout} />
      <RouteWrapper exact path={GuestRoutes.SecondFactor()} component={AuthSecondFactor} providers={providers} layout={AuthLayout} />

      <RouteWrapper exact path={GuestRoutes.RecoverAccount()} component={AuthRecoverAccount} providers={providers} layout={AuthLayout} />
      <RouteWrapper exact path={GuestRoutes.UpdatePassword()} component={AccountRecoveryUpdatePassword} providers={providers} layout={AuthLayout} />
      <RouteWrapper exact path={GuestRoutes.SignUp()} component={AuthSignUp} providers={providers} layout={AuthLayout} />
      <Redirect exact from="/" to={GuestRoutes.Root()} />

      <RouteWrapper path={guestRoot} component={NotFoundComponent} layout={AuthLayout} />
    </Switch>
  );
};
