import { apiEndpoint } from '../apiEndpoint';

export const CustomersEndpoints = {
  Customers: apiEndpoint<[]>('/customers'),
  CustomerInvite: apiEndpoint<[]>('/customers/invite'),
  Customer: apiEndpoint<[id: number]>('/customers/:customer'),
  CustomerArchive: apiEndpoint<[id: number]>('/customers/:customer/archive'),
  CustomerUnArchive: apiEndpoint<[id: number]>('/customers/:customer/unarchive'),
  CustomerInvoices: apiEndpoint<[id: number]>('/customers/:customer/invoices'),
  CustomerProducts: apiEndpoint<[id: number]>('/customers/:customer/products'),
  CustomerTickets: apiEndpoint<[id: number]>('/customers/:customer/tickets'),
  CustomerUsers: apiEndpoint<[id: number]>('/customers/:customer/users'),
  CustomerReInvite: apiEndpoint<[id: number]>('/customers/:customer/reinvite'),
  CustomerAvailableProducts: apiEndpoint<[id: number]>('/customers/:customer/products/new'),
  CustomerProduct: apiEndpoint<[customerId: number, customerProductId: number]>(
    '/customers/:customer/products/:product'
  ),
  RenewCustomerProduct: apiEndpoint<[customerId: number, customerProductId: number]>(
    '/customers/:customer/products/:product/renew'
  ),
  EditCustomerProduct: apiEndpoint<[customerId: number, customerProductId: number]>(
    '/customers/:customer/products/:product'
  ),
  SuspendCustomerProduct: apiEndpoint<[customerId: number, customerProductId: number]>(
    '/customers/:customer/products/:product/suspend'
  ),
  ReinstateCustomerProduct: apiEndpoint<[customerId: number, customerProductId: number]>(
    '/customers/:customer/products/:product/reinstate'
  ),
  ReleaseCustomerProduct: apiEndpoint<[customerId: number, customerProductId: number]>(
    '/customers/:customer/products/:product/release'
  ),
  ReassignZendeskId: apiEndpoint<[customerId: number]>('/customers/:customer/reassign-zendesk'),
};
