import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import styled from '@mui/styles/styled';
import { AccountFetcher } from '../../fetchers/AccountFetcher';
import { ContactAdminButton } from './ContactAdminButton';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
});

interface IProps {
  title?: string;
  description?: string;
}

export const Nothing: FC<IProps> = ({ title, description }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <img src="/images/nothing.svg" />
      <Typography variant="h4">{title ?? t('nothing.title')}</Typography>
      <Typography variant="h5" mt={3} mb={4}>
        {description ?? t('nothing.description')}
      </Typography>
      <AccountFetcher.WAF>
        <ContactAdminButton />
      </AccountFetcher.WAF>
    </Container>
  );
};
