import React, { FC, useCallback, useContext } from 'react';
import { createNoParamsFetcher } from '../../contexts/Fetcher';
import { useSupportApi } from '../../hooks/useSupportApi';
import Button from '@mui/material/Button';
import { config } from '../../config';
import { PageHeader } from '../PageHeader/PageHeader';
import { TicketsTable } from './TicketsTable';
import { AccountContext } from '../../contexts/AccountContext';
import { AccountType } from '../../types/AccountType';
import Launch from '@mui/icons-material/Launch';
import { useDefaultSorting } from '../../hooks/useDefaultSorting';
import { useTranslation } from 'react-i18next';
import { usePostRedirect } from '../../hooks/usePostRedirect';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';

const SupportPageFetcher = createNoParamsFetcher(() => useSupportApi().getTicketsList);

const SupportPageContent: FC = () => {
  const { data } = useContext(SupportPageFetcher.Context);

  if (!data) return null;
  return <TicketsTable tickets={data.tickets} />;
};

export const SupportPage: FC = () => {
  const isAdmin = useContext(AccountContext) === AccountType.Admin;
  const { zenDeskAdmin } = useSupportApi();
  const { t } = useTranslation();
  const postRedirect = usePostRedirect();
  const handleApiError = useApiErrorHandler();

  const openZenDeskAdmin = useCallback(async () => {
    try {
      const { endpoint, body } = await zenDeskAdmin();
      postRedirect(endpoint, body);
    } catch (e) {
      handleApiError(e);
    }
  }, [zenDeskAdmin]);

  const preventRender = useDefaultSorting('id', 'desc');
  if (preventRender) return null;

  return (
    <SupportPageFetcher.WAF>
      <PageHeader title={t('support.header')}>
        {isAdmin ? (
          <Button variant="contained" color="primary" onClick={openZenDeskAdmin}>
            {t('support.zenDeskAdminButton')}
          </Button>
        ) : (
          <Button
            href={config.supportNewTicketLink}
            rel="noreferrer noopener"
            target="_blank"
            variant="contained"
            color="primary"
            endIcon={<Launch />}
          >
            {t('support.addButton')}
          </Button>
        )}
      </PageHeader>

      <SupportPageContent />
    </SupportPageFetcher.WAF>
  );
};
