import { TextField, styled } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  alignItems: 'center',
  marginTop: theme.spacing(2),
}));

const CodeCharacterField = styled(TextField)(({ theme }) => ({
  marginTop: '0',
  '& .MuiInputBase-input': {
    fontSize: '20px',
    padding: '16px 12px 15px',
    textAlign: 'center',
  },
  [theme.breakpoints.down('sm')]: {
    '& .MuiInputBase-input': {
      padding: '12px 0px 12px',
      fontSize: '15px',
    },
  },
}));

interface IProps {
  value: string;
  onChange: (value: string) => void;
  pattern: string;
  focusOnInit?: boolean;
  error?: boolean;
}

enum EPatternItem {
  Digit = 'd',
  Alpha = 'a',
  NotEmpty = '!',
  Any = '*',
  Separator = '-',
}

export const CodeInput: FC<IProps> = ({ value, onChange, pattern, focusOnInit = false, error = false }) => {
  const refs = useRef<HTMLInputElement[]>([]);
  const splittedPattern = useMemo(() => pattern.split(''), [pattern]);
  const [focusIndex, setFocusIndexState] = useState(focusOnInit ? 0 : null);

  const setFocusIndex = useCallback((val: number) => {
    setFocusIndexState(val);
  }, []);

  const { indexMap, patternMap } = useMemo(() => {
    const indexMap: Record<number, number> = {};
    const patternMap: Record<number, string> = {};
    let iter = 0;

    splittedPattern.forEach((patternItem, index) => {
      if (patternItem !== '-') {
        patternMap[iter] = patternItem;
        indexMap[index] = iter;
        ++iter;
      }
    });

    return { indexMap, patternMap };
  }, [splittedPattern]);

  const characterAtIndex = (index: number) => value.charAt(index);

  const focusNextCharacter = (currentIndex: number) => {
    const indexToFocus = currentIndex + 1;
    if (refs.current[indexToFocus]) {
      setFocusIndex(indexToFocus);
    }
  };
  const focusPreviousCharacter = (currentIndex: number) => {
    const indexToFocus = currentIndex - 1;
    if (indexToFocus >= 0) {
      setFocusIndex(indexToFocus);
    }
  };

  const validateCharacter = (index: number, character: string) => {
    if (patternMap[index] === EPatternItem.Digit && character.match(/^\d$/)) {
      return true;
    } else if (patternMap[index] === EPatternItem.Alpha && character.match(/^[a-zA-Z]$/)) {
      return true;
    } else if (patternMap[index] === EPatternItem.NotEmpty && character.match(/^\S$/)) {
      return true;
    } else if (patternMap[index] === EPatternItem.Any) {
      return true;
    }
    return false;
  };

  const setCharacterAtIndex = (update: string, index: number) => {
    if (update.length) {
      for (let i = 0; i < update.length; ++i) {
        if (!validateCharacter(index + i, update[i])) {
          update = update.substring(0, i) + update.substring(i + 1);
          --i;
        }
      }
      if (!update.length) return;
    }

    const before = value.substring(0, index);
    const after = value.substring(index + (update ? update.length : 1));
    const length = Object.keys(indexMap).length;

    const newValue = (before + update + after).substring(0, length);
    onChange(newValue);
  };

  const generateSetRef = (index: number) => (element: HTMLInputElement) => {
    refs.current[index] = element;
  };

  useEffect(() => {
    return () => {
      refs.current.length = 0;
    };
  }, [splittedPattern]);

  const forceSelect = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  }, []);

  const onKeyDownAtIndex = (index: number) => (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.ctrlKey || event.metaKey || event.key === 'Tab' || event.key === 'Enter') {
      return;
    }
    if (event.key === 'Delete') {
      setCharacterAtIndex('', index);
      focusPreviousCharacter(index);
    } else if (event.key === 'Backspace') {
      setCharacterAtIndex('', index);
      focusPreviousCharacter(index);
    } else if (event.key === 'ArrowLeft') {
      focusPreviousCharacter(index);
    } else if (event.key === 'ArrowRight' || event.key === 'Tab') {
      focusNextCharacter(index);
    } else if (event.key === 'Home') {
      refs.current[0].focus();
    } else if (event.key === 'End') {
      refs.current[refs.current.length - 1].focus();
    } else {
      return;
    }
    event.preventDefault();
  };

  const onPasteAtIndex = (index: number) => (event: React.ClipboardEvent<HTMLInputElement>) => {
    const update = event.clipboardData.getData('text');
    if (update) {
      setCharacterAtIndex(update, index);
    }
  };

  useEffect(() => {
    if (focusIndex === null) return;

    let index = focusIndex;
    while (index > 0 && !value.charAt(index - 1)) {
      --index;
    }
    const elementToFocus = refs.current[index];
    if (elementToFocus instanceof HTMLInputElement) {
      elementToFocus.focus();
    }
  }, [focusIndex, value]);

  const onFocus = (index: number) => () => {
    setFocusIndex(index);
  };

  const onInputAtIndex = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const nativeEvent = event.nativeEvent as InputEvent;

    if (nativeEvent.inputType === 'deleteContentBackward') {
      setCharacterAtIndex('', index);
      focusPreviousCharacter(index);
    } else if (nativeEvent.inputType === 'insertText' && nativeEvent.data !== null) {
      setCharacterAtIndex(nativeEvent.data, index);
      focusNextCharacter(index);
    }
  };

  return (
    <Container>
      {splittedPattern.map((patternItem, index) => {
        if (patternItem === EPatternItem.Separator) {
          return <div key={`${patternItem}-${index}`}>-</div>;
        } else if (Object.values(EPatternItem as Record<string, string>).includes(patternItem)) {
          const characterIndex = indexMap[index];

          return (
            <div key={`${patternItem}-${characterIndex}`}>
              <CodeCharacterField
                inputRef={generateSetRef(characterIndex)}
                value={characterAtIndex(characterIndex)}
                onFocus={onFocus(characterIndex)}
                onKeyDown={onKeyDownAtIndex(characterIndex)}
                onPaste={onPasteAtIndex(characterIndex)}
                onInput={onInputAtIndex(characterIndex)}
                onSelect={forceSelect}
                error={error}
                inputProps={{
                  autoComplete: 'off',
                }}
              />
            </div>
          );
        }
      })}
    </Container>
  );
};
