import React, { ElementType, FC, useMemo } from 'react';
import Star from '@mui/icons-material/Star';
import { CopyText } from '../CopyText/CopyText';
import styled from '@mui/styles/styled';

interface IProps {
  label: string;
  ip?: string;
  port?: number;
  Icon?: ElementType;
}

const ConnectionDetailContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingTop: theme.spacing(0.5),
  paddingLeft: theme.spacing(0.5),
  gap: theme.spacing(1),
}));

const ConnectionDetailName = styled('div')({
  flex: 1,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const ConnectionDetailAddress = styled('div')({
  flex: 1,
  textAlign: 'right',
  whiteSpace: 'nowrap',
});

export const ConnectionDetail: FC<IProps> = ({ label, ip, port, Icon }) => {
  const StyledIcon = useMemo(() => {
    return styled(Icon ?? Star)({
      flexBasis: 24,
      flex: 0,
      opacity: 0.56,
    });
  }, [Icon]);

  return (
    <ConnectionDetailContainer>
      <StyledIcon />
      <ConnectionDetailName>{label}</ConnectionDetailName>
      <ConnectionDetailAddress>
        {ip ? <CopyText>{`${ip}${port ? `:${port}` : ''}`}</CopyText> : null}
      </ConnectionDetailAddress>
    </ConnectionDetailContainer>
  );
};
