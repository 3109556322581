import React, { FC, useContext } from 'react';
import { createFetcher } from '../../contexts/Fetcher';
import { useInvoicesApi } from '../../hooks/useInvoicesApi';
import { PageHeader } from '../PageHeader/PageHeader';
import { InvoicesTable } from './InvoicesTable';
import { useTranslation } from 'react-i18next';

const InvoicesFetcher = createFetcher(() => useInvoicesApi().getInvoices);

const InvoicesContent: FC = () => {
  const { data } = useContext(InvoicesFetcher.Context);

  if (!data) return null;
  return <InvoicesTable invoices={data.invoices} />;
};

export const Invoices: FC = () => {
  const { t } = useTranslation();

  return (
    <InvoicesFetcher.WAF>
      <PageHeader title={t('invoices.title')} />
      <InvoicesContent />
    </InvoicesFetcher.WAF>
  );
};
