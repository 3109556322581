import React, { FC, useContext } from 'react';
import { CustomerFetcher, CustomerInvoicesFetcher } from './CustomerFetchers';
import { InvoicesTable } from '../Invoices/InvoicesTable';
import Button from '@mui/material/Button';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { AdminRoutes } from '../../routers/AdminRoutes';
import { CustomerNewInvoice } from './CustomerNewInvoice';
import { useIsRoute } from '../../hooks/useIsRoute';
import { useAutoFetch } from '../../contexts/Fetcher';
import { useTranslation } from 'react-i18next';
import { AdminRouteLink } from '../RouteLink/RouteLink';
import { useCloseDialog } from '../../hooks/useCloseDialog';

const useStyles = makeStyles((theme) =>
  createStyles({
    buttonWrapper: {
      padding: `${theme.spacing(1)} 0`,
    },
  })
);

export const CustomerInvoices: FC = () => {
  const styles = useStyles();
  const { data: customerData } = useContext(CustomerFetcher.Context);
  const { data } = useContext(CustomerInvoicesFetcher.Context);
  const isNewInvoiceDialogOpen = useIsRoute(AdminRoutes.CustomerNewTabItem);
  const { t } = useTranslation();

  useAutoFetch(CustomerInvoicesFetcher);

  const closeDialog = useCloseDialog(AdminRoutes.CustomerTab(customerData?.customer.id, 'invoices'));

  if (!data || !customerData) return null;
  return (
    <div>
      <div className={styles.buttonWrapper}>
        <Button
          component={AdminRouteLink}
          route="CustomerNewTabItem"
          params={[customerData.customer.id, 'invoices']}
          keepQuery
          variant="contained"
          color="primary"
        >
          {t('customer.invoices.addButton')}
        </Button>
      </div>
      <InvoicesTable invoices={data.invoices} />
      <CustomerNewInvoice open={isNewInvoiceDialogOpen} closeDialog={closeDialog} />
    </div>
  );
};
