import { useApi } from './useApi';
import { IAnalyticsActivity } from '../models/AnalyticsActivity';
import { AnalyticsEndpoints } from './api/endpoints/analytics';
import { ApiRequest, useEndpoint } from './api/apiEndpoint';

export type AnalyticsActivityType = 'activeNow' | 'activeToday' | 'activeWeek' | 'activeMonth';

export const useAnalyticsApi = () => {
  const { get } = useApi();

  const getActivity = useEndpoint(
    (get, type: AnalyticsActivityType, tfgFilter: boolean) => get({ params: { type, tfgFilter } }),
    AnalyticsEndpoints.Activity,
    get as ApiRequest<typeof get, IAnalyticsActivity[]>
  );

  const getAccountActivity = useEndpoint(
    (get, ip: string) => get({ params: { ip } }),
    AnalyticsEndpoints.AccountActivity,
    get as ApiRequest<typeof get, IAnalyticsActivity['ip']>
  );

  return {
    getActivity,
    getAccountActivity,
  };
};
