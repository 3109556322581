import React, { FC } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ICustomerOverviewResponse } from '../../models/CustomerOverview';
import { useTranslation } from 'react-i18next';

interface IProps {
  unassignedLicenses: ICustomerOverviewResponse['unassignedLicenses'];
}

export const CustomerOverviewUnassignedLicenses: FC<IProps> = ({ unassignedLicenses }) => {
  const { t } = useTranslation();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t('common.tableHeader.type')}</TableCell>
          <TableCell>{t('customerOverview.amountHeader')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {unassignedLicenses.map((license) =>
          license.packages.map((pack) => (
            <TableRow key={`${license.product.id}/${pack.package.id}/${pack.customerProduct.id}`}>
              <TableCell>
                {license.product.name} | {pack.package.name}
              </TableCell>
              <TableCell>{pack.amount}</TableCell>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  );
};
