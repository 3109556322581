import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useOverviewApi } from '../../hooks/useOverviewApi';
import { ProductOverview } from './ProductOverview';
import { useHistory, useParams } from 'react-router-dom';
import { UserRoutes } from '../../routers/UserRoutes';
import { ProductContent } from './ProductContent';
import { createNoParamsFetcher } from '../../contexts/Fetcher';
import { IUserOverviewResponse } from '../../models/UserOverview';
import { Nothing } from '../Nothing/Nothing';

interface IUrlParams {
  product?: string;
}

const OverviewFetcher = createNoParamsFetcher(
  () => useOverviewApi().getOverview as (params: []) => Promise<IUserOverviewResponse>
);

const OverviewContent: FC = () => {
  const { data: overview } = useContext(OverviewFetcher.Context);
  const { product: activeProductName } = useParams<IUrlParams>();
  const history = useHistory();

  const setActiveTab = useCallback((tab, replaceState = false) => {
    if (replaceState) {
      history.replace(UserRoutes.OverviewProduct(tab));
    } else {
      history.push(UserRoutes.OverviewProduct(tab));
    }
  }, []);

  const onChange = useCallback((event, tab) => {
    setActiveTab(tab);
  }, []);

  const filteredProducts = useMemo(() => {
    return (overview?.products ?? []).filter((product) => product.machines.licensesAvailable > 0);
  }, [overview]);

  useEffect(() => {
    if (!activeProductName && filteredProducts.length) {
      setActiveTab(filteredProducts[0].product.name, true);
    }
  }, [filteredProducts, activeProductName]);

  const activeProduct = filteredProducts.find((prod) => prod.product.name === activeProductName);

  return filteredProducts.length > 0 ? (
    <div>
      {activeProductName ? (
        <Tabs
          value={activeProductName}
          indicatorColor="primary"
          textColor="primary"
          onChange={onChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {filteredProducts.map((overviewProduct) => {
            return (
              <Tab
                key={overviewProduct.product.id}
                label={overviewProduct.product.name}
                value={overviewProduct.product.name}
              />
            );
          })}
        </Tabs>
      ) : null}
      {activeProduct ? <ProductOverview key={activeProduct.product.id + 'overview'} product={activeProduct} /> : null}
      {activeProduct ? <ProductContent key={activeProduct.product.id + 'content'} product={activeProduct} /> : null}
    </div>
  ) : overview ? (
    <Nothing />
  ) : null;
};

export const UserOverview: FC = () => (
  <OverviewFetcher.WAF>
    <OverviewContent />
  </OverviewFetcher.WAF>
);
