import { createFetcher } from '../contexts/Fetcher';
import { useReleasesApi } from '../hooks/useReleasesApi';
import { useIntParams } from '../hooks/useIntParams';
import { useCallback } from 'react';

interface IUrlParams {
  productId?: string;
}

export const ReleasesFetcher = createFetcher(() => {
  const { productId } = useIntParams<IUrlParams>();
  const { getReleases } = useReleasesApi();

  return useCallback(() => {
    if (typeof productId !== 'number') return Promise.resolve(null);
    return getReleases([productId]);
  }, [productId, getReleases]);
});
