export const compareBool = (a: boolean, b: boolean, descending = false) => {
  return (descending ? -1 : 1) * ((a ? 1 : 0) - (b ? 1 : 0));
};

export const compareString = (a: string, b: string, descending = false) => {
  return (descending ? -1 : 1) * a.localeCompare(b);
};

type IsTuple<T> = T extends [any, ...any] ? T : never;

export const sortItems = <Type, FieldTypes extends any[]>(
  items: Type[],
  fieldGetters: IsTuple<{
    [Index in keyof FieldTypes]: (item: Type) => FieldTypes[Index];
  }>,
  comparers: IsTuple<{
    [Index in keyof FieldTypes]: (a: FieldTypes[Index], b: FieldTypes[Index]) => number;
  }>
): Type[] => {
  const out = [...items];
  out.sort((a, b) => {
    let compareResult = 0;
    for (let i = 0; i < fieldGetters.length; ++i) {
      const fieldGetter = fieldGetters[i];
      compareResult = comparers[i](fieldGetter(a), fieldGetter(b));
      if (compareResult) return compareResult;
    }
    return compareResult;
  });
  return out;
};
