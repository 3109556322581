import { useCallback, useContext, useMemo } from 'react';
import { LoadingContext } from '../contexts/LoadingContext';
import { postRedirect } from '../helpers/postRedirect';

interface IUsePostRedirectOptions {
  indicateLoading: boolean;
}

export const usePostRedirect = (options: Partial<IUsePostRedirectOptions> = {}) => {
  const { startLoading, endLoading } = useContext(LoadingContext);

  const allOptions = useMemo<IUsePostRedirectOptions>(
    () => ({
      indicateLoading: true,
      ...options,
    }),
    [options]
  );

  const execute = useCallback(
    (endpoint: string, body?: Record<string, string>) => {
      if (allOptions.indicateLoading) {
        startLoading();
      }
      try {
        postRedirect(endpoint, body);
      } catch (e) {
        endLoading();
        throw e;
      }
    },
    [allOptions]
  );

  return execute;
};
