import React, { FC } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import styled from '@mui/styles/styled';

interface IProps {
  count: number;
  label: string;
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  overflow: 'hidden',
  textAlign: 'center',
}));

export const CountPaper: FC<IProps> = ({ count, label, children }) => {
  return (
    <StyledPaper>
      <Typography variant="h4">{count}</Typography>
      <Typography variant="body1">{label}</Typography>
      {children}
    </StyledPaper>
  );
};
