import { useApi } from './useApi';
import { IAdminPlan } from '../models/AdminPlan';
import { IPlanResponse } from '../models/PlanResponse';
import { ApiRequest, useEndpoint, useSimpleEndpoint } from './api/apiEndpoint';
import { ProductPlansEndpoints } from './api/endpoints/plans';

interface ICustomerPackageDefaultProperties {
  [key: string]: boolean;
}

interface IProductPlanPayload {
  name: string;
  type: 'perpetual' | 'subscription';
  price: number;
  currency: string;
  duration: number;
  packages: {
    data: Array<{
      amount: number;
      packageId: number;
    }>;
  };
  productCode?: string;
  productId: number;
  watermark: boolean;
  archived: boolean;
  customerPackageDefaultProperties: ICustomerPackageDefaultProperties;
}

export const useProductPlansApi = () => {
  const { del, get, post, put } = useApi();

  const getPlans = useSimpleEndpoint(
    ProductPlansEndpoints.Plans,
    get as ApiRequest<typeof get, { plans: IAdminPlan[] }>
  );

  const getPlan = useSimpleEndpoint(ProductPlansEndpoints.Plan, get as ApiRequest<typeof get, IPlanResponse>);

  const createPlan = useEndpoint(
    (post, payload: IProductPlanPayload) => post(payload),
    ProductPlansEndpoints.Plans,
    post as ApiRequest<typeof post, IAdminPlan>
  );

  const updatePlan = useEndpoint(
    (put, payload: Partial<IProductPlanPayload>) => put(payload),
    ProductPlansEndpoints.Plan,
    put as ApiRequest<typeof put, IAdminPlan>
  );

  const deletePlan = useSimpleEndpoint(ProductPlansEndpoints.Plan, del);

  return {
    getPlans,
    getPlan,
    createPlan,
    updatePlan,
    deletePlan,
  };
};
