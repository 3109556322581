import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { config } from './config';
import { isAxiosError } from './helpers/isAxiosError';

export const initializeSentry = () => {
  Sentry.init({
    enabled: location.hostname !== 'localhost',
    dsn: config.sentryDsn,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0,
    environment: location.hostname,
    beforeBreadcrumb: (breadcrumb) => {
      return {
        ...breadcrumb,
        message: breadcrumb.message ? breadcrumb.message.substring(0, 1024) : void 0,
      };
    },
  });
};

class ApiError extends Error {}

export const captureException = (exception: any) => {
  if (isAxiosError(exception)) {
    let message = `${exception.config.method?.toUpperCase() ?? 'A'} request to "${exception.config.url}" failed`;

    if (exception.response) {
      message += ` with code ${exception.response.status}`;
      if (exception.response.data?.reason) {
        message += ` and reason: ${exception.response.data.reason}`;
      }
    }
    Sentry.captureException(new ApiError(message), {
      extra: {
        params: exception.config.params,
        responseURL: exception.request.responseURL,
        response: exception.response?.data,
      },
    });
  } else {
    Sentry.captureException(exception);
  }
};
