interface ICombineCnInput {
  [className: string]: boolean;
}

export const combineCn = (input: ICombineCnInput): string => {
  const out: string[] = [];
  for (const [className, value] of Object.entries(input)) {
    if (value) out.push(className);
  }
  return out.join(' ');
};
