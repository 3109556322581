import { styled } from '@mui/material/styles';

export const Progressbar = styled('div')(({ theme }) => ({
  height: theme.spacing(0.5),
  width: '100%',
  background: theme.palette.background.paper,
  '--progress': 0,
  position: 'relative',
  '&:after': {
    content: '""',
    height: '100%',
    width: 'var(--progress)',
    background: theme.palette.secondary.main,
    display: 'block',
    transition: 'width 200ms ease-in-out',
  },
}));
