export const postRedirect = (endpoint: string, body: Record<string, string> = {}) => {
  const form = document.createElement('form');
  form.setAttribute('action', endpoint);
  form.setAttribute('method', 'POST');
  for (const [key, value] of Object.entries(body)) {
    const field = document.createElement('input');
    field.setAttribute('type', 'hidden');
    field.setAttribute('name', key);
    field.setAttribute('value', value);
    form.appendChild(field);
  }
  document.body.appendChild(form);
  form.submit();
};
