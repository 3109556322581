import React, { FC, useCallback, useEffect, useRef } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useQueryParam } from '../../hooks/useQueryParam';
import { TextInputController } from '../InputControllers/TextInputController';
import { useDebounce } from 'use-debounce';
import { useHistory } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Close from '@mui/icons-material/Close';
import styled from '@mui/styles/styled';
import { useTranslation } from 'react-i18next';

export interface IQuickFilter {
  filterField: string;
  filterOp: string;
  filterVal: string;
}

const StyledTextInputController = styled(TextInputController)({
  marginTop: 0,
});

export const FreeTextFilter: FC = () => {
  const urlValue = useQueryParam('text');
  const form = useForm<FieldValues>({ defaultValues: { text: urlValue ?? '' } });
  const history = useHistory();
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    form.reset({
      text: urlValue ?? '',
    });
  }, [urlValue]);

  const formValue: string = form.watch('text') ?? '';

  const [debouncedValue] = useDebounce(formValue, 500);

  const applyValue = useCallback((value?: string | null) => {
    const searchParams = new URLSearchParams(history.location.search);
    if ((searchParams.get('text') ?? '') === (value ?? '')) return;

    searchParams.delete('page');
    searchParams.delete('pageSize');
    if (value) {
      searchParams.set('text', value);
      // @todo remove following when it'll be possible to use both free text and column filters
      searchParams.delete('filterOp');
      searchParams.delete('filterVal');
      searchParams.delete('filterField');
    } else {
      searchParams.delete('text');
    }
    history.push(`?${searchParams.toString()}`);
  }, []);

  useEffect(() => {
    applyValue(debouncedValue);
  }, [debouncedValue]);

  const resetSearch = useCallback(() => {
    const input = inputRef.current;
    if (input) {
      input.querySelector('input')?.focus();
    }
    applyValue(null);
  }, []);

  return (
    <StyledTextInputController
      control={form.control}
      errors={form.formState.errors}
      label={t('component.easyFilter.searchPlaceholder')}
      name="text"
      variant="outlined"
      size="small"
      InputProps={{
        endAdornment: urlValue ? (
          <InputAdornment position="end">
            <IconButton onClick={resetSearch}>
              <Close />
            </IconButton>
          </InputAdornment>
        ) : null,
        ref: inputRef,
      }}
    />
  );
};
