import React, { FC, useCallback, useContext } from 'react';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IUpdateUserPayload, useUsersApi } from '../../hooks/useUsersApi';
import { EditUserForm } from './EditUserForm';
import { UsersFetcher } from '../Users/Users';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { useAutoFetch } from '../../contexts/Fetcher';
import { NotificationContext } from '../../contexts/NotificationContext';
import { useTranslation } from 'react-i18next';
import { joiResolver } from '@hookform/resolvers/joi';
import { userFormSchema } from '../../formSchemas/userFormSchema';

interface IProps {
  open: boolean;
  closeDialog: () => void;
}

export const AddUserDialog: FC<IProps> = ({ open, closeDialog }) => {
  const { data, onRefresh } = useContext(UsersFetcher.Context);
  useAutoFetch(UsersFetcher, open);
  const { createUser } = useUsersApi();
  const apiErrorHandler = useApiErrorHandler();
  const { addNotification } = useContext(NotificationContext);
  const { t } = useTranslation();

  const { control, handleSubmit, formState } = useForm({
    resolver: joiResolver(userFormSchema),
  });

  const onSubmit = useCallback(
    async (fields: IUpdateUserPayload) => {
      if (!data) return;
      try {
        await createUser([], data.customer.id, fields);
        await onRefresh();
        addNotification({
          severity: 'success',
          message: t('editUserDialog.add.invitationSent'),
        });
        closeDialog();
      } catch (e) {
        apiErrorHandler(e);
      }
    },
    [createUser, closeDialog, data?.customer.id]
  );

  if (!data) return null;
  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>{t('editUserDialog.add.title')}</DialogTitle>
      <DialogContent>
        <EditUserForm onSubmit={onSubmit} {...{ control, handleSubmit, formState }} />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>{t('common.dialog.close')}</Button>
        <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
          {t('editUserDialog.add.confirmButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
