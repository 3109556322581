import React, { FC, useEffect } from 'react';

interface IProps {
  onChange: (mounted: boolean) => void;
}

export const MountObserver: FC<IProps> = ({ children, onChange }) => {
  useEffect(() => {
    onChange(true);
    return () => {
      onChange(false);
    };
  }, []);
  return <>{children}</>;
};
