import React, { FC, useCallback, useContext, useEffect } from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory, useParams } from 'react-router-dom';
import { UserRoutes } from '../../routers/UserRoutes';
import { Retry } from '../Retry';
import { ProductVersionDownloads } from './ProductVersionDownloads';
import { DownloadsContext } from '../../contexts/DownloadsContext';
import { useQueryParam } from '../../hooks/useQueryParam';
import { PageHeader } from '../PageHeader/PageHeader';
import { useTranslation } from 'react-i18next';
import { Nothing } from '../Nothing/Nothing';
import { AccountContext } from '../../contexts/AccountContext';
import { AccountType } from '../../types/AccountType';
import { CustomerRoutes } from '../../routers/CustomerRoutes';

interface IUrlParams {
  product?: string;
  version?: string;
  productId?: string;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    accordionsContainer: {
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
    },
  })
);

export const Downloads: FC = () => {
  const styles = useStyles();
  const { downloads, fetch, isError } = useContext(DownloadsContext);
  const { t } = useTranslation();

  const accountType = useContext(AccountContext)!;
  const routes = accountType === AccountType.User ? UserRoutes : CustomerRoutes;

  const { product: activeProductId, version: expandedVersion } = useParams<IUrlParams>();
  const history = useHistory();

  const productId = parseInt(useQueryParam('productId') ?? '', 10);

  const setActiveTab = useCallback((tab, replaceState = false) => {
    if (replaceState) {
      history.replace(routes.DownloadsProduct(tab));
    } else {
      history.push(routes.DownloadsProduct(tab));
    }
  }, []);

  const onChange = useCallback((event, tab) => {
    setActiveTab(tab);
  }, []);

  useEffect(() => {
    if (!downloads) {
      fetch();
    }
  }, []);

  useEffect(() => {
    if (!downloads) return;

    if (productId) {
      const userDownload = downloads.userDownloads.find((ud) => ud.product.id === productId);
      if (userDownload) {
        setActiveTab(userDownload.product.id, true);
        return;
      }
    }
    if (!activeProductId && downloads.userDownloads.length) {
      setActiveTab(downloads.userDownloads[0].product.id, true);
    }
  }, [downloads?.userDownloads, activeProductId, productId]);

  const activeProduct = (downloads?.userDownloads ?? []).find(
    (downloads) => downloads.product.id.toString(10) === activeProductId
  );

  const onToggle = useCallback(
    (version: string, value: boolean, replaceState = false) => {
      if (!activeProduct) return;
      const historyFn = replaceState ? history.replace : history.push;
      if (value) {
        historyFn(routes.DownloadsProductVersion(activeProduct.product.id, version));
      } else {
        historyFn(routes.DownloadsProduct(activeProduct.product.id));
      }
    },
    [activeProduct?.product.id]
  );

  useEffect(() => {
    if (activeProduct && !expandedVersion && activeProduct.versions.length) {
      onToggle(activeProduct.versions[0].version, true, true);
    }
  }, [activeProduct?.product.id]);

  const filteredDownloads = downloads?.userDownloads.filter((downloads) => downloads.versions.length) ?? [];

  return isError ? (
    <Retry onRetry={fetch} status={null} />
  ) : downloads ? (
    downloads.userDownloads.length > 0 ? (
      <div>
        <PageHeader title={t('downloads.title')} />

        {filteredDownloads.length === 0 ? <Nothing title={t('downloads.noDownloads.title')} /> : null}

        {activeProductId ? (
          <Tabs
            value={activeProductId}
            indicatorColor="primary"
            textColor="primary"
            onChange={onChange}
            variant="scrollable"
            scrollButtons={'auto'}
          >
            {downloads.userDownloads
              .filter((downloads) => downloads.versions.length)
              .map((downloads) => {
                return (
                  <Tab
                    key={downloads.product.id}
                    label={downloads.product.name}
                    value={downloads.product.id.toString(10)}
                  />
                );
              })}
          </Tabs>
        ) : null}
        <div className={styles.accordionsContainer}>
          {activeProduct
            ? activeProduct.versions.map((version) => {
                return (
                  <ProductVersionDownloads
                    key={`${activeProductId}/${version.version}`}
                    product={activeProduct.product}
                    version={version}
                    expanded={expandedVersion === version.version}
                    onToggle={onToggle}
                  />
                );
              })
            : null}
        </div>
      </div>
    ) : (
      <Nothing />
    )
  ) : null;
};
