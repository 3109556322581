import { useApi } from './useApi';
import { ApiRequest, useEndpoint } from './api/apiEndpoint';
import { IUserOverviewResponse } from '../models/UserOverview';
import { ICustomerOverviewResponse } from '../models/CustomerOverview';
import { IAdminOverviewResponse } from '../models/AdminOverviewResponse';
import { OverviewEndpoints } from './api/endpoints/overview';

export const useOverviewApi = () => {
  const { get } = useApi();

  const getOverview = useEndpoint(
    (get) => get(),
    OverviewEndpoints.Overview,
    get as ApiRequest<typeof get, IUserOverviewResponse | ICustomerOverviewResponse | IAdminOverviewResponse>
  );

  return {
    getOverview,
  };
};
