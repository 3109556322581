const prefixes = ['', 'K', 'M', 'G', 'T'];

export const displayFilesize = (bytes: number): string => {
  let index = 0;
  let out = bytes;
  while (out > 1024 * 0.9) {
    out /= 1024;
    ++index;
    if (index + 1 === prefixes.length) break;
  }
  return `${Math.round(out * 100) / 100} ${prefixes[index]}B`;
};
