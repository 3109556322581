import React from 'react';
import { Typography } from '@mui/material';

export const TypographyBody1: typeof Typography = (props: React.ComponentPropsWithRef<typeof Typography>) => (
  <Typography {...props} variant="body1" />
);
export const TypographyBody2: typeof Typography = (props: React.ComponentPropsWithRef<typeof Typography>) => (
  <Typography {...props} variant="body2" />
);
export const TypographyButton: typeof Typography = (props: React.ComponentPropsWithRef<typeof Typography>) => (
  <Typography {...props} variant="button" />
);
export const TypographyCaption: typeof Typography = (props: React.ComponentPropsWithRef<typeof Typography>) => (
  <Typography {...props} variant="caption" />
);
export const TypographyH1: typeof Typography = (props: React.ComponentPropsWithRef<typeof Typography>) => (
  <Typography {...props} variant="h1" />
);
export const TypographyH2: typeof Typography = (props: React.ComponentPropsWithRef<typeof Typography>) => (
  <Typography {...props} variant="h2" />
);
export const TypographyH3: typeof Typography = (props: React.ComponentPropsWithRef<typeof Typography>) => (
  <Typography {...props} variant="h3" />
);
export const TypographyH4: typeof Typography = (props: React.ComponentPropsWithRef<typeof Typography>) => (
  <Typography {...props} variant="h4" />
);
export const TypographyH5: typeof Typography = (props: React.ComponentPropsWithRef<typeof Typography>) => (
  <Typography {...props} variant="h5" />
);
export const TypographyH6: typeof Typography = (props: React.ComponentPropsWithRef<typeof Typography>) => (
  <Typography {...props} variant="h6" />
);
export const TypographySubtitle1: typeof Typography = (props: React.ComponentPropsWithRef<typeof Typography>) => (
  <Typography {...props} variant="subtitle1" />
);
export const TypographySubtitle2: typeof Typography = (props: React.ComponentPropsWithRef<typeof Typography>) => (
  <Typography {...props} variant="subtitle2" />
);
