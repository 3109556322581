import React, { FC, useContext } from 'react';
import { ActivityFetcher } from '../../fetchers/ActivityFetcher';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import { AdminRouteLink } from '../RouteLink/RouteLink';

const useStyles = makeStyles((theme) =>
  createStyles({
    flag: {
      marginRight: theme.spacing(1.5),
      fontSize: 20,
      boxShadow: '#00000060 4px 4px 12px',
    },
  })
);

export const ActivityTable: FC = () => {
  const styles = useStyles();
  const { data } = useContext(ActivityFetcher.Context);
  const { t } = useTranslation();

  if (!data) return null;
  return (
    <Paper>
      <Box p={1}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('common.tableHeader.id')}</TableCell>
              <TableCell>{t('analytics.table.roleHeader')}</TableCell>
              <TableCell>{t('common.tableHeader.company')}</TableCell>
              <TableCell>{t('common.tableHeader.name')}</TableCell>
              <TableCell>{t('analytics.table.ipHeader')}</TableCell>
              <TableCell>{t('analytics.table.locationHeader')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((activityItem) => {
              return (
                <TableRow key={activityItem.id}>
                  <TableCell>{activityItem.id}</TableCell>
                  <TableCell>{activityItem.role}</TableCell>
                  <TableCell>{activityItem.company}</TableCell>
                  <TableCell>{activityItem.name}</TableCell>
                  <TableCell>
                    {activityItem.ip ? (
                      <Link
                        component={AdminRouteLink}
                        route="AnalyticsActivityItem"
                        params={[activityItem.ip.query]}
                        underline="hover"
                        showFallback
                      >
                        {activityItem.ip.query}
                      </Link>
                    ) : null}
                  </TableCell>
                  <TableCell>
                    {activityItem.ip ? (
                      <span>
                        <ReactCountryFlag
                          className={styles.flag}
                          countryCode={activityItem.ip.countryCode}
                          svg
                          style={{
                            width: 'auto',
                          }}
                        />
                        {activityItem.ip.city} | {activityItem.ip.country}
                      </span>
                    ) : null}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </Paper>
  );
};
