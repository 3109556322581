import React, { FC, useCallback, useContext } from 'react';
import { createFetcher } from '../../contexts/Fetcher';
import { useHistory, useLocation } from 'react-router-dom';
import { useAdminsApi } from '../../hooks/useAdminsApi';
import { PageHeader } from '../PageHeader/PageHeader';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import { createStyles, makeStyles } from '@mui/styles';
import { DateFormat } from '../../helpers/dateFormat';
import { NotificationContext } from '../../contexts/NotificationContext';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { AdminRoutes } from '../../routers/AdminRoutes';
import { matchPath } from 'react-router';
import { EditAdminDialog } from '../EditAdminDialog/EditAdminDialog';
import { ConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialog';
import { useTranslation } from 'react-i18next';
import { Date } from '../Date/Date';
import { useIntParams } from '../../hooks/useIntParams';
import { AdminRouteLink, useIsEndpointAllowed } from '../RouteLink/RouteLink';
import { AdminsEndpoints } from '../../hooks/api/endpoints/admins';
import { AccountSingleData } from '../AccountData/AccountSingleData';

interface IUrlParams {
  adminId: string;
}

export const AdminFetcher = createFetcher(() => {
  const { adminId } = useIntParams<IUrlParams>();
  const { getAdmin } = useAdminsApi();

  return useCallback(() => {
    return getAdmin([adminId]);
  }, [adminId, getAdmin]);
});

const useStyles = makeStyles((theme) =>
  createStyles({
    notes: {
      whiteSpace: 'pre-wrap',
    },
    buttons: {
      display: 'flex',
      gap: theme.spacing(1),
    },
    deleteButton: {
      backgroundColor: theme.palette.error.light,
      color: 'white',
      '&:hover': {
        backgroundColor: theme.palette.error.main,
      },
    },
  })
);

const AdminContent: FC = () => {
  const { data, onDataUpdate } = useContext(AdminFetcher.Context);
  const { reInvite, deleteAdmin, reassignZendeskId } = useAdminsApi();
  const { addNotification } = useContext(NotificationContext);
  const apiErrorHandler = useApiErrorHandler();
  const location = useLocation();
  const isEditing = !!matchPath(location.pathname, AdminRoutes.AdminEdit());
  const isDeleting = !!matchPath(location.pathname, AdminRoutes.AdminDelete());
  const history = useHistory();
  const styles = useStyles();
  const { t } = useTranslation();

  const isEndpointAllowed = useIsEndpointAllowed();
  const isReinviteAllowed = isEndpointAllowed('POST', AdminsEndpoints.ReInvite.template);
  const isReassignZendeskAllowed = isEndpointAllowed('POST', AdminsEndpoints.ReassignZendeskId.template);

  const onReInvite = useCallback(async () => {
    if (!data) return;
    try {
      await reInvite([data.id]);
      addNotification({
        message: t('admin.reInviteConfirmation'),
        severity: 'success',
      });
    } catch (e) {
      apiErrorHandler(e);
    }
  }, [data?.id, reInvite]);

  const onCloseDialog = useCallback(() => {
    if (!data) return;
    history.push(AdminRoutes.Admin(data.id));
  }, [data?.id]);

  const onConfirmDelete = useCallback(async () => {
    if (!data) return;
    try {
      await deleteAdmin([data.id]);
      history.push(AdminRoutes.Admins());
    } catch (e) {
      apiErrorHandler(e);
    }
  }, [deleteAdmin, data?.id]);

  const onReassignZendeskId = useCallback(async () => {
    if (!data) return;
    try {
      const updatedData = await reassignZendeskId([data.id]);
      onDataUpdate(updatedData);
      addNotification({
        severity: 'success',
        message: t('admin.reassignZendeskIdSucceeded'),
      });
    } catch (e) {
      apiErrorHandler(e);
    }
  }, [reassignZendeskId, onDataUpdate, data]);

  return (
    <div>
      <PageHeader
        title={`${data?.firstName ?? ''} ${data?.lastName ?? ''}`}
        crumbs={[
          {
            url: AdminRoutes.Admins(),
            title: t('layout.adminNavigation.admins'),
          },
        ]}
      >
        {data ? (
          <div className={styles.buttons}>
            <Button
              component={AdminRouteLink}
              route="Events"
              params={[]}
              query={{ filterField: 'accountId', filterOp: 'equals', filterVal: data.id }}
              variant="outlined"
              color="secondary"
            >
              {t('common.button.history')}
            </Button>
            <Button component={AdminRouteLink} route="AdminEdit" params={[data.id]} variant="contained" color="primary">
              {t('common.button.edit')}
            </Button>
            <Button
              component={AdminRouteLink}
              route="AdminDelete"
              params={[data.id]}
              variant="contained"
              className={styles.deleteButton}
            >
              {t('common.button.delete')}
            </Button>
          </div>
        ) : null}
      </PageHeader>
      <Grid container spacing={1}>
        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('admin.id')}>{data?.id ?? ''}</AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('admin.name')}>
            {data ? `${data.firstName} ${data.lastName}` : ''}
          </AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('admin.role')}>{data?.role ?? ''}</AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('admin.email')}>{data?.email ?? ''}</AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('admin.phone')}>{data?.phoneNumber ?? ''}</AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('admin.zendesk')}>
            {data?.zendesk ?? ''}{' '}
            {isReassignZendeskAllowed ? (
              <Button onClick={onReassignZendeskId} variant="contained" color="secondary" size="small">
                {t('admin.reassignZendeskId')}
              </Button>
            ) : null}
          </AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('admin.lastUpdated')}>
            {data ? <Date date={data.updated} format={DateFormat.DateWithFullTime} /> : ''}
          </AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('admin.status')}>
            {data ? (
              data.verified ? (
                <Chip label={t('common.accountStatus.verified')} color="primary" />
              ) : isReinviteAllowed ? (
                <Button variant="contained" color="secondary" onClick={onReInvite}>
                  {t('common.accountStatus.unverified')} / {t('admin.reInvite')}
                </Button>
              ) : (
                <Chip label={t('common.accountStatus.unverified')} color="secondary" />
              )
            ) : null}
          </AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('admin.mfaTitle')}>
            {data ? (
              data.MFAEnabled ? (
                <Chip label={t('admin.mfaEnabled')} color="success" />
              ) : (
                <Chip label={t('admin.mfaDisabled')} color="warning" />
              )
            ) : null}
          </AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('admin.notes')}>{data?.notes ?? ''}</AccountSingleData>
        </Grid>
      </Grid>

      <EditAdminDialog open={isEditing} closeDialog={onCloseDialog} />
      {data ? (
        <ConfirmationDialog
          open={isDeleting}
          title={t('admin.deleteDialog.title')}
          message={t('admin.deleteDialog.message', { name: `${data.firstName} ${data.lastName}` })}
          confirm={onConfirmDelete}
          abort={onCloseDialog}
          confirmLabel={t('admin.deleteDialog.confirm')}
          dangerous
        />
      ) : null}
    </div>
  );
};

export const Admin: FC = () => {
  return (
    <AdminFetcher.WAF>
      <AdminContent />
    </AdminFetcher.WAF>
  );
};
