import React, { FC, useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import { DownloadOfflineLicense } from './DownloadOfflineLicense';
import { LicenseContext } from '../../contexts/LicenseContext';
import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';

interface IProps {
  open: boolean;
  onClose: () => void;
}

export const OfflineAccessStepsDialog: FC<IProps> = ({ open, onClose }) => {
  const { offlineLicense } = useContext(LicenseContext)!;

  return (
    <Dialog open={open} onClose={onClose}>
      {offlineLicense ? (
        <DownloadOfflineLicense onClose={onClose} />
      ) : (
        <>
          <DialogTitle>Request offline license</DialogTitle>
          <DialogContent>Use the button above the table to request offline license</DialogContent>
          <DialogActions>
            <Button variant="text" onClick={onClose}>
              Close
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
