import React, { FC, useCallback, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { NotificationContext } from '../../contexts/NotificationContext';
import { AdminFetcher } from '../Admin/Admin';
import { IAdminUpdatePayload, useAdminsApi } from '../../hooks/useAdminsApi';
import { EditAdminForm } from './EditAdminForm';
import { useTranslation } from 'react-i18next';
import { compareChanges } from '../../helpers/compareChanges';

interface IProps {
  open: boolean;
  closeDialog: () => void;
}

export const EditAdminDialog: FC<IProps> = ({ open, closeDialog }) => {
  const { data, onDataUpdate } = useContext(AdminFetcher.Context);
  const { updateAdmin } = useAdminsApi();
  const { addNotification } = useContext(NotificationContext);
  const { t } = useTranslation();

  const { control, handleSubmit, formState, reset } = useForm();

  useEffect(() => {
    reset({
      role: data?.role ?? '',
      firstName: data?.firstName ?? '',
      lastName: data?.lastName ?? '',
      email: data?.email ?? '',
      phoneNumber: data?.phoneNumber ?? '',
      notes: data?.notes ?? '',
      MFAEnabled: data?.MFAEnabled ?? false,
    });
  }, [open, data]);

  const onSubmit = useCallback(
    async (fields: IAdminUpdatePayload) => {
      if (!data) return;

      const { anythingChanged: anythingToUpdate, changes: payload } = compareChanges(data, fields);
      if (anythingToUpdate) {
        const newAdminDetails = await updateAdmin([data.id], payload);
        onDataUpdate(newAdminDetails);
        closeDialog();
      } else {
        addNotification({
          severity: 'warning',
          message: t('editAdminDialog.edit.nothingChanged'),
        });
      }
    },
    [data, updateAdmin, closeDialog]
  );

  if (!data) return null;
  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>{t('editAdminDialog.edit.title')}</DialogTitle>
      <DialogContent>
        <EditAdminForm onSubmit={onSubmit} {...{ control, handleSubmit, formState }} />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>{t('common.dialog.close')}</Button>
        <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
          {t('editAdminDialog.edit.confirmButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
