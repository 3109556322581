import React, { FC, useCallback } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link, useHistory } from 'react-router-dom';
import { useQueryParam } from '../../hooks/useQueryParam';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import MailIcon from '@mui/icons-material/Mail';
import CloudIcon from '@mui/icons-material/Cloud';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { AddUserDialog } from '../EditUserDialog/AddUserDialog';
import { UsersFetcher } from '../Users/Users';
import { DefaultTheme } from '@mui/system';
import styled from '@mui/styles/styled';
import Info from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';

const Steps = styled('div')(({ theme }) => ({
  display: 'flex',
  marginBottom: 72,
  gap: theme.spacing(4),
  padding: '0 5%',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const Step = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const StepNumber = styled('span')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  width: theme.spacing(3),
  height: theme.spacing(3),
  textAlign: 'center',
  borderRadius: '50%',
  marginBottom: theme.spacing(2),
}));

const StepNumberSecondary = styled(StepNumber)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
}));

const StepInfo = styled(Info)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: theme.spacing(3),
  marginBottom: theme.spacing(2),
}));

const StepTitle = styled(Typography)({
  textAlign: 'center',
});

const StepButton = styled(Link)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const iconStyles = ({ theme }: { theme: DefaultTheme }) => ({
  marginBottom: theme.spacing(3),
  fontSize: theme.spacing(6),
});

const AddUserIcon = styled(PersonAddAlt1Icon)(iconStyles);
const AssignLicenseIcon = styled(VpnKeyIcon)(iconStyles);
const EmailInviteIcon = styled(MailIcon)(iconStyles);
const LogInAndDownloadIcon = styled(CloudIcon)(iconStyles);
const TransferIcon = styled(ImportExportIcon)(iconStyles);

export const CustomerOverviewGetStarted: FC = () => {
  const addUser = useQueryParam('addUser');
  const history = useHistory();
  const { t } = useTranslation();

  const closeDialog = useCallback(() => {
    history.push(history.location.pathname);
  }, []);

  return (
    <div>
      <Typography variant="h3" textAlign="center" marginBottom={6}>
        {t('customerOverview.getStarted.title')}
      </Typography>

      <Steps>
        <Step>
          <AddUserIcon />
          <StepNumber>1</StepNumber>
          <StepTitle variant="body2">{t('customerOverview.getStarted.addUser')}</StepTitle>

          <Button component={StepButton} to="?addUser=1" variant="contained" color="primary">
            {t('customerOverview.getStarted.addUserButton')}
          </Button>
        </Step>

        <Step>
          <AssignLicenseIcon />
          <StepNumber>2</StepNumber>
          <StepTitle variant="body2">{t('customerOverview.getStarted.assignLicense')}</StepTitle>
        </Step>

        <Step>
          <EmailInviteIcon />
          <StepNumberSecondary>3</StepNumberSecondary>
          <StepTitle variant="body2">{t('customerOverview.getStarted.receiveInvitation')}</StepTitle>
        </Step>

        <Step>
          <LogInAndDownloadIcon />
          <StepNumberSecondary>4</StepNumberSecondary>
          <StepTitle variant="body2">{t('customerOverview.getStarted.logInAndDownload')}</StepTitle>
        </Step>

        <Step>
          <TransferIcon />
          <StepInfo />
          <StepTitle variant="body2">{t('customerOverview.getStarted.transferLicenses')}</StepTitle>
        </Step>
      </Steps>

      <UsersFetcher.WMF>
        <AddUserDialog open={!!addUser} closeDialog={closeDialog} />
      </UsersFetcher.WMF>
    </div>
  );
};
