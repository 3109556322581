import { apiEndpoint } from '../apiEndpoint';

export const UsersEndpoints = {
  Users: apiEndpoint<[]>('/users'),
  User: apiEndpoint<[id: number]>('/users/:user'),
  ReInvite: apiEndpoint<[id: number]>('/users/:user/reinvite'),
  UnassignLicense: apiEndpoint<[userId: number, userPackageId: number]>('/users/:user/packages/:package'),
  AssignLicense: apiEndpoint<[userId: number]>('/users/:user/packages'),
  OfflineLicense: apiEndpoint<[userId: number, userPackageId: number]>('/users/:user/packages/:package/offline'),
  PackagesOffline: apiEndpoint<[userId: number]>('/users/:user/packages/offline'),
  OnlineLicense: apiEndpoint<[userId: number, userPackageId: number]>('/users/:user/packages/:package/license'),
};
