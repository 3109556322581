import React, { FC, useEffect, useState } from 'react';
import { ICustomerLicense, IPackage } from '../../models/CustomerLicense';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { IAssignLicensePayload } from '../../hooks/useUsersApi';
import { LicenseExpiry } from '../LicenseExpiry/LicenseExpiry';
import styled from '@mui/styles/styled';
import Add from '@mui/icons-material/Add';
import Remove from '@mui/icons-material/Remove';
import { useTranslation } from 'react-i18next';
import { Chip } from '@mui/material';
import { compareBool, compareString, sortItems } from '../../helpers/compare';

interface IProps {
  availableLicenses: ICustomerLicense[];
  onFormPayloadUpdate: (payload: IAssignLicensePayload[]) => void;
}

interface ILicenseData {
  amount: number;
  package: IPackage;
}

const prepareLicenseData = (licenses: ICustomerLicense[]) => {
  return sortItems(
    licenses.reduce<ILicenseData[]>((memo, license) => {
      return [
        ...memo,
        ...license.packages.map((pack) => {
          return {
            amount: 0,
            package: pack,
          };
        }),
      ];
    }, []),
    [(item) => item.package.package.isAddon, (item) => item.package.package.name],
    [compareBool, compareString]
  );
};

const QuantityCell = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
}));

const Quantity = styled('span')({ flexBasis: 40 });

const QuantityModifier = styled('span')(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  color: theme.palette.primary.main,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 26,
  width: 38,
  cursor: 'pointer',
  '&.Mui-disabled': {
    cursor: 'default',
    opacity: 0.5,
    pointerEvents: 'none',
  },
}));
const Plus = styled(Add)({ fontSize: 18 });
const Minus = styled(Remove)({ fontSize: 18 });

export const AssignLicenses: FC<IProps> = ({ availableLicenses, onFormPayloadUpdate }) => {
  const { t } = useTranslation();
  const [licenseData, setLicenseData] = useState(prepareLicenseData(availableLicenses));

  const getQuantityUpdater = (license: ILicenseData, diff: number) => () => {
    const index = licenseData.indexOf(license);
    setLicenseData([
      ...licenseData.slice(0, index),
      {
        ...licenseData[index],
        amount: licenseData[index].amount + diff,
      },
      ...licenseData.slice(index + 1),
    ]);
  };

  useEffect(() => {
    onFormPayloadUpdate(
      licenseData
        .filter((license) => license.amount > 0)
        .map((license) => ({
          packages: {
            amount: license.amount,
            customerProductId: license.package.customerProduct.id,
            packageId: license.package.package.id,
          },
        }))
    );
  }, [licenseData]);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t('assignLicenses.table.licenseHeader')}</TableCell>
          <TableCell>{t('assignLicenses.table.expiresHeader')}</TableCell>
          <TableCell>{t('assignLicenses.table.availableHeader')}</TableCell>
          <TableCell>{t('assignLicenses.table.addHeader')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {licenseData.map((license) => {
          return (
            <TableRow key={`${license.package.customerProduct.id}/${license.package.package.id}`}>
              <TableCell>
                {license.package.package.name}
                {' | '}
                {license.package.plan.name}{' '}
                {license.package.package.isAddon ? <Chip color="info" label={t('assignLicenses.addonChip')} /> : null}
              </TableCell>
              <TableCell>
                <LicenseExpiry expiry={license.package.expiry} type={license.package.plan.type} />
              </TableCell>
              <TableCell>{license.package.amount}</TableCell>
              <TableCell>
                <QuantityCell>
                  <Quantity>{license.amount}</Quantity>
                  <QuantityModifier
                    onClick={getQuantityUpdater(license, -1)}
                    className={license.amount <= 0 ? 'Mui-disabled' : void 0}
                  >
                    <Minus />
                  </QuantityModifier>
                  <QuantityModifier
                    onClick={getQuantityUpdater(license, 1)}
                    className={license.amount >= license.package.amount ? 'Mui-disabled' : void 0}
                  >
                    <Plus />
                  </QuantityModifier>
                </QuantityCell>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
