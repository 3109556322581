import { useApi } from '../useApi';
import { apiEndpoint, ApiRequest, useSimpleEndpoint } from './apiEndpoint';
import { IRolesResponse } from '../../models/RolesResponse';

const RolesEndpoints = {
  Roles: apiEndpoint<[]>('/roles'),
};

export const useRolesApi = () => {
  const { get } = useApi();

  const getRoles = useSimpleEndpoint(RolesEndpoints.Roles, get as ApiRequest<typeof get, IRolesResponse>);

  return {
    getRoles,
  };
};
