import { useParams } from 'react-router-dom';
import { useMemo } from 'react';

type ToNumbers<Type> = { [Key in keyof Type]: Type[Key] extends void ? number | void : number };

export const useIntParams = <T>() => {
  const params = useParams<T>();
  return useMemo<ToNumbers<T>>(() => {
    return Object.entries(params).reduce((memo, [key, value]) => {
      memo[key as keyof T] = parseInt(value, 10);
      return memo;
    }, {} as ToNumbers<T>);
  }, [params]);
};
