import React, { FC } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { IPendingCustomerProduct } from '../../models/PendingCustomerProduct';
import { DateFormat } from '../../helpers/dateFormat';
import { useTranslation } from 'react-i18next';
import { Date } from '../Date/Date';

interface IProps {
  jobs: IPendingCustomerProduct[];
}

export const ScheduledJobs: FC<IProps> = ({ jobs }) => {
  const { t } = useTranslation();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t('component.scheduledJobs.operationHeader')}</TableCell>
          <TableCell>{t('component.scheduledJobs.productHeader')}</TableCell>
          <TableCell>{t('component.scheduledJobs.planHeader')}</TableCell>
          <TableCell>{t('component.scheduledJobs.scheduleHeader')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {jobs.map((job, idx) => {
          return (
            <TableRow key={idx}>
              <TableCell>{job.operation}</TableCell>
              <TableCell>
                {typeof job.amount === 'number' ? `${job.amount} x ` : null}
                {job.product ? job.product.name : null}
              </TableCell>
              <TableCell>{job.plan?.name ?? '-'}</TableCell>
              <TableCell>
                <Tooltip title={<Date date={job.scheduled} format={DateFormat.DateMedium} />}>
                  <span>{<Date date={job.scheduled} format={DateFormat.Relative} />}</span>
                </Tooltip>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
