import React, { FC, useCallback, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { NotificationContext } from '../../contexts/NotificationContext';
import { CustomerFetcher } from '../Customer/CustomerFetchers';
import { ICustomerUpdatePayload, useCustomersApi } from '../../hooks/useCustomersApi';
import { EditCustomerForm } from './EditCustomerForm';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { useTranslation } from 'react-i18next';
import { compareChanges } from '../../helpers/compareChanges';

interface IProps {
  open: boolean;
  closeDialog: () => void;
}

export const EditCustomerDialog: FC<IProps> = ({ open, closeDialog }) => {
  const { data, onDataUpdate } = useContext(CustomerFetcher.Context);
  const { updateCustomer } = useCustomersApi();
  const { addNotification } = useContext(NotificationContext);
  const handleError = useApiErrorHandler();
  const { t } = useTranslation();

  const { control, handleSubmit, formState, reset } = useForm();

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open]);

  const onSubmit = useCallback(
    async (fields: ICustomerUpdatePayload) => {
      if (!data) return;
      const { anythingChanged: anythingToUpdate, changes: payload } = compareChanges(
        {
          ...data.customer,
          createdBy: data.createdBy?.id,
        },
        fields
      );

      if (anythingToUpdate) {
        try {
          const newCustomerDetails = await updateCustomer([data.customer.id], payload);
          onDataUpdate({
            ...data,
            customer: newCustomerDetails,
          });
          closeDialog();
        } catch (e) {
          handleError(e);
        }
      } else {
        addNotification({
          severity: 'warning',
          message: t('editCustomerDialog.edit.nothingChanged'),
        });
      }
    },
    [data, updateCustomer, closeDialog]
  );

  if (!data) return null;
  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>{t('editCustomerDialog.edit.title')}</DialogTitle>
      <DialogContent>
        <EditCustomerForm
          onSubmit={onSubmit}
          customer={{ ...data.customer, createdBy: data.createdBy.id }}
          {...{ control, handleSubmit, formState }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>{t('common.dialog.close')}</Button>
        <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
          {t('editCustomerDialog.edit.confirmButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
