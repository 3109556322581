import React, { FC, useEffect, useMemo } from 'react';
import { useAccount } from '../../hooks/useAccount';
import { AccountType } from '../../types/AccountType';
import Button from '@mui/material/Button';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { GuestRoutes } from '../../routers/GuestRouter';
import { AuthDialog } from '../AuthDialog/AuthDialog';
import styled from '@mui/styles/styled';
import { useTranslation } from 'react-i18next';

const EmailsContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

export const AuthAccount: FC = () => {
  const { account: user } = useAccount(AccountType.User);
  const { account: customer } = useAccount(AccountType.Customer);
  const { account: admin } = useAccount(AccountType.Admin);
  const { search } = useLocation();
  const history = useHistory();
  const { t } = useTranslation();

  const uniqueEmails = useMemo(() => {
    return Array.from(new Set([user, customer, admin].filter((account) => !!account).map((account) => account!.email)));
  }, [user, customer, admin]);

  useEffect(() => {
    if (uniqueEmails.length === 0) history.replace(GuestRoutes.SignIn());
  }, [uniqueEmails]);

  return (
    <AuthDialog
      title={t('auth.account.encouragement')}
      actions={
        <Button component={Link} to={`${GuestRoutes.SignIn()}${search}`} variant="text" color="primary">
          {t('auth.account.useAnother')}
        </Button>
      }
    >
      <EmailsContainer>
        {uniqueEmails.map((email) => {
          const urlSearch = new URLSearchParams(search);
          urlSearch.set('email', email);
          return (
            <Button
              key={email}
              component={Link}
              to={`${GuestRoutes.Choose()}?${urlSearch.toString()}`}
              variant="contained"
              color="primary"
            >
              {email}
            </Button>
          );
        })}
      </EmailsContainer>
    </AuthDialog>
  );
};
