import React, { FC, useContext, useEffect, useMemo, useRef } from 'react';
import { PageHeader } from '../PageHeader/PageHeader';
import { BalanceFetcher } from '../../fetchers/BalanceFetcher';
import { Chart, registerables } from 'chart.js';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { BalanceTable } from './BalanceTable';
import { IBalance } from '../../models/BalanceResponse';
import useTheme from '@mui/styles/useTheme';
import { useTranslation } from 'react-i18next';

Chart.register(...registerables);
const BalanceContent: FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { data } = useContext(BalanceFetcher.Context);
  const theme = useTheme();
  const { t } = useTranslation();

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas || !data) return;
    const chart = new Chart(canvas, {
      type: 'bar',
      data: {
        labels: data.plot.params.labels,
        datasets: [
          {
            label: t('balance.datasetTitle'),
            data: data.plot.params.values,
            backgroundColor: theme.palette.primary.main,
          },
        ],
      },
      options: {
        aspectRatio: 4,
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });

    return () => {
      chart.destroy();
    };
  }, [data]);

  const total = useMemo<IBalance[]>(() => {
    if (!data) return [];
    const totalData = new Map<string, IBalance>();

    for (const balance of [...data.balance.available, ...data.balance.pending]) {
      if (!totalData.has(balance.currency)) {
        totalData.set(balance.currency, {
          currency: balance.currency,
          amount: 0,
          sourceTypes: {
            [balance.currency]: 0,
          },
        });
      }
      const item = totalData.get(balance.currency)!;
      item.sourceTypes[balance.currency]! += balance.amount;
    }
    return Array.from(totalData.values());
  }, [data]);

  if (!data) return null;
  return (
    <div>
      <Paper>
        <Box p={1}>
          <canvas ref={canvasRef} />
        </Box>
      </Paper>

      <Box marginTop={1}>
        <Paper>
          <Box p={1}>
            <Typography variant="h6">{t('balance.available')}</Typography>
            <BalanceTable data={data.balance.available} />
          </Box>
        </Paper>
      </Box>

      <Box marginTop={1}>
        <Paper>
          <Box p={1}>
            <Typography variant="h6">{t('balance.pending')}</Typography>
            <BalanceTable data={data.balance.pending} />
          </Box>
        </Paper>
      </Box>
      <Box marginTop={1}>
        <Paper>
          <Box p={1}>
            <Typography variant="h6">{t('balance.total')}</Typography>
            <BalanceTable data={total} />
          </Box>
        </Paper>
      </Box>
    </div>
  );
};

export const Balance: FC = () => {
  const { t } = useTranslation();
  return (
    <div>
      <PageHeader title={t('balance.header')} />
      <BalanceFetcher.WAF>
        <BalanceContent />
      </BalanceFetcher.WAF>
    </div>
  );
};
