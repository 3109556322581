import styled from '@mui/styles/styled';
import { Link } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import React, { FC } from 'react';
import ChevronRight from '@mui/icons-material/ChevronRight';

const DownloadsLinkContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const DownloadsLinkChevron = styled('div')(({ theme }) => ({
  color: alpha(theme.palette.text.primary, 0.56),
  lineHeight: 0,
}));

export const ButtonWithChevron: FC = ({ children }) => {
  return (
    <DownloadsLinkContainer>
      <Button variant="text">{children}</Button>

      <DownloadsLinkChevron>
        <ChevronRight />
      </DownloadsLinkChevron>
    </DownloadsLinkContainer>
  );
};

const StyledLink = styled(Link)({
  textDecoration: 'none',
});

export const ButtonWithChevronWithLink: FC<{ to: string }> = ({ to, children }) => {
  return (
    <StyledLink to={to}>
      <ButtonWithChevron>{children}</ButtonWithChevron>
    </StyledLink>
  );
};
