import React, { ChangeEvent, FC, useCallback, useContext, useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { useCustomersApi } from '../../hooks/useCustomersApi';
import { CustomerProductFetcher } from './CustomerProduct';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { Trans, useTranslation } from 'react-i18next';

interface IProps {
  open: boolean;
  closeDialog: () => void;
}

export const CustomerProductEdit: FC<IProps> = ({ open, closeDialog }) => {
  const { editCustomerProduct } = useCustomersApi();
  const { data: customerProductData, onRefresh } = useContext(CustomerProductFetcher.Context);
  const handleError = useApiErrorHandler();
  const { t } = useTranslation();

  const [notificationSettings, setNotificationSettings] = useState({
    notification_reneval_270_days: false,
    notification_reneval_180_days: false,
    notification_reneval_90_days: false,
    notification_reneval_60_days: false,
    notification_reneval_30_days: false,
    notification_expiring_30_days: false,
    ignore_notification_until_reneval: false,
  });

  useEffect(() => {
    if (open) {
      onRefresh();
    }
  }, [open, onRefresh]);

  useEffect(() => {
    if (customerProductData) {
      setNotificationSettings({
        notification_reneval_270_days:
          customerProductData.customerProduct.properties.notification_reneval_270_days || false,
        notification_reneval_180_days:
          customerProductData.customerProduct.properties.notification_reneval_180_days || false,
        notification_reneval_90_days:
          customerProductData.customerProduct.properties.notification_reneval_90_days || false,
        notification_reneval_60_days:
          customerProductData.customerProduct.properties.notification_reneval_60_days || false,
        notification_reneval_30_days:
          customerProductData.customerProduct.properties.notification_reneval_30_days || false,
        notification_expiring_30_days:
          customerProductData.customerProduct.properties.notification_expiring_30_days || false,
        ignore_notification_until_reneval:
          customerProductData.customerProduct.properties.ignore_notification_until_reneval || false,
      });
    }
  }, [customerProductData]);

  const handleCheckboxChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setNotificationSettings((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.checked,
    }));
  }, []);

  const saveChanges = useCallback(async () => {
    if (!customerProductData) return;
    try {
      await editCustomerProduct(
        [customerProductData.customerProduct.customerId, customerProductData.customerProduct.id],
        notificationSettings.notification_reneval_270_days,
        notificationSettings.notification_reneval_180_days,
        notificationSettings.notification_reneval_90_days,
        notificationSettings.notification_reneval_60_days,
        notificationSettings.notification_reneval_30_days,
        notificationSettings.notification_expiring_30_days,
        notificationSettings.ignore_notification_until_reneval
      );
      await onRefresh();
      closeDialog();
    } catch (e) {
      handleError(e);
    }
  }, [editCustomerProduct, customerProductData, notificationSettings, closeDialog, handleError, onRefresh]);

  if (!customerProductData) return null;
  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>{t('customerProduct.notificationEditDialog.title')}</DialogTitle>
      <DialogContent>
        <Trans i18nKey="customerProduct.notificationEditDialog.message">
          <Typography paragraph />
        </Trans>
        <FormControlLabel
          control={
            <Checkbox
              checked={notificationSettings.notification_reneval_270_days}
              onChange={handleCheckboxChange}
              name="notification_reneval_270_days"
            />
          }
          label={t('customerProduct.notificationEditDialog.notification_reneval_270_days')}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={notificationSettings.notification_reneval_180_days}
              onChange={handleCheckboxChange}
              name="notification_reneval_180_days"
            />
          }
          label={t('customerProduct.notificationEditDialog.notification_reneval_180_days')}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={notificationSettings.notification_reneval_90_days}
              onChange={handleCheckboxChange}
              name="notification_reneval_90_days"
            />
          }
          label={t('customerProduct.notificationEditDialog.notification_reneval_90_days')}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={notificationSettings.notification_reneval_60_days}
              onChange={handleCheckboxChange}
              name="notification_reneval_60_days"
            />
          }
          label={t('customerProduct.notificationEditDialog.notification_reneval_60_days')}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={notificationSettings.notification_reneval_30_days}
              onChange={handleCheckboxChange}
              name="notification_reneval_30_days"
            />
          }
          label={t('customerProduct.notificationEditDialog.notification_reneval_30_days')}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={notificationSettings.notification_expiring_30_days}
              onChange={handleCheckboxChange}
              name="notification_expiring_30_days"
            />
          }
          label={t('customerProduct.notificationEditDialog.notification_expiring_30_days')}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={notificationSettings.ignore_notification_until_reneval}
              onChange={handleCheckboxChange}
              name="ignore_notification_until_reneval"
            />
          }
          label={t('customerProduct.notificationEditDialog.ignore_notification_until_reneval')}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>{t('common.dialog.close')}</Button>
        <Button variant="contained" color="primary" onClick={saveChanges}>
          {t('customerProduct.notificationEditDialog.update')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
