import { RefObject, useEffect } from 'react';
import { useEnumState } from './useEnumState';

export enum ShowMoreState {
  INITIAL,
  HIDDEN,
  VISIBLE,
}

export const useShowMore = (ref: RefObject<HTMLElement>, heightLimit: number) => {
  const [showMore, setHideMore, setShowMore] = useEnumState(
    ShowMoreState.INITIAL,
    ShowMoreState.HIDDEN,
    ShowMoreState.VISIBLE
  );

  useEffect(() => {
    const changelog = ref.current;
    if (!changelog) return;
    if (changelog.clientHeight > heightLimit) setHideMore();
  }, []);

  return {
    showMore,
    setHideMore,
    setShowMore,
  };
};
