import React, { FC } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { IOverviewProduct } from '../../models/UserOverview';
import { useTranslation } from 'react-i18next';

interface IProps {
  product: IOverviewProduct;
}

export const OverviewLicenses: FC<IProps> = ({ product }) => {
  const { t } = useTranslation();
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t('common.tableHeader.inUseOn')}</TableCell>
          <TableCell>{t('common.tableHeader.mode')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {product.machines.machines.map((machine, idx) => {
          return (
            <TableRow key={JSON.stringify({ ...machine, idx })}>
              <TableCell>{machine.name}</TableCell>
              <TableCell>{machine.mode}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
