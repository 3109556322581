import { RefObject, useLayoutEffect, useRef } from 'react';

export const useResizeObserver = (
  elementRef: RefObject<HTMLElement>,
  callback: (dimensions: { width: number; height: number }) => void,
  enabled = true
) => {
  const dimensionsRef = useRef({ width: 0, height: 0 });

  useLayoutEffect(() => {
    if (!enabled) {
      dimensionsRef.current = { height: 0, width: 0 };
      return;
    }

    const resizeListener: ResizeObserverCallback = () => {
      const height = elementRef.current?.clientHeight ?? 0;
      const width = elementRef.current?.clientWidth ?? 0;
      if (height !== dimensionsRef.current.height || width !== dimensionsRef.current.width) {
        callback({ height, width });
        dimensionsRef.current = { height, width };
      }
    };

    const element = elementRef.current;
    if (element) {
      const resizeObserver = new ResizeObserver(resizeListener);
      resizeObserver.observe(element);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [enabled, callback]);
};
