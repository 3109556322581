import { useApi } from './useApi';
import { ILiveRegionsResponse } from '../models/LiveRegionsResponse';
import { ApiRequest, useSimpleEndpoint } from './api/apiEndpoint';
import { LiveEndpoints } from './api/endpoints/live';
import { ILiveStackAccessResponse } from '../models/LiveStackAccessResponse';

export enum LiveStackStatus {
  Provisioning = 'PROVISIONING',
  Standby = 'STANDBY',
  Running = 'RUNNING',
  Starting = 'STARTING',
  Stopping = 'STOPPING',
  Destroying = 'DESTROYING',
  Unknown = 'UNKNOWN',
  Failed = 'FAILED',
}

export const LiveStackStatusTransitional = [
  LiveStackStatus.Provisioning,
  LiveStackStatus.Starting,
  LiveStackStatus.Stopping,
  LiveStackStatus.Destroying,
];
export const LiveStackStatusStable = [LiveStackStatus.Standby, LiveStackStatus.Running];
export const LiveStackStatusError = [LiveStackStatus.Failed, LiveStackStatus.Unknown];

export interface ILiveStreamUrl {
  ip: string;
  port: number;
}

export interface IDatahubUrl {
  ip: string;
  port: null;
}

export interface IRunningLiveStackDetails {
  ingress: ILiveStreamUrl;
  egress: ILiveStreamUrl;
  preview?: ILiveStreamUrl;
  datahub?: IDatahubUrl;
}

export interface ILiveStack {
  name: string;
  id: string;
  status: LiveStackStatus;
  protocol: 'SRT';
  preview: boolean;
  capacityReservation: boolean;
  region: string;
  details: null | IRunningLiveStackDetails;
  allowListCIDR: string;
}

export const useLiveApi = () => {
  const { get: silentGet } = useApi({ indicateLoading: false });
  const { get, post } = useApi();

  const getChannelsList = useSimpleEndpoint(LiveEndpoints.LiveStacks, get as ApiRequest<typeof get, ILiveStack[]>);

  const getChannelsListSilent = useSimpleEndpoint(
    LiveEndpoints.LiveStacks,
    silentGet as ApiRequest<typeof silentGet, ILiveStack[]>
  );

  const start = useSimpleEndpoint(LiveEndpoints.LiveStackStart, post as ApiRequest<typeof post, void>);

  const stop = useSimpleEndpoint(LiveEndpoints.LiveStackStop, post as ApiRequest<typeof post, void>);

  const getAccessData = useSimpleEndpoint(
    LiveEndpoints.LiveStackAccess,
    get as ApiRequest<typeof get, ILiveStackAccessResponse>
  );

  const getAccessDataSilent = useSimpleEndpoint(
    LiveEndpoints.LiveStackAccess,
    silentGet as ApiRequest<typeof silentGet, ILiveStackAccessResponse>
  );

  const getRegions = useSimpleEndpoint(LiveEndpoints.LiveRegions, get as ApiRequest<typeof get, ILiveRegionsResponse>);

  return {
    getChannelsList,
    getChannelsListSilent,
    start,
    stop,
    getAccessData,
    getAccessDataSilent,
    getRegions,
  };
};
