import React, { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import { ControllerProps } from 'react-hook-form/dist/types/controller';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { StandardTextFieldProps } from '@mui/material/TextField';
import { Autocomplete, AutocompleteProps, TextField } from '@mui/material';
import styled from '@emotion/styled';

// eslint-disable-next-line @typescript-eslint/ban-types
interface IProps<T extends {}> extends Omit<ControllerProps, 'render'> {
  disabled?: boolean;
  errors: FieldErrors;
  InputProps?: StandardTextFieldProps['InputProps'];
  inputType?: string;
  items: T[];
  label: string;
  renderOption: AutocompleteProps<T, undefined, undefined, undefined>['renderOption'];
  getOptionLabel: AutocompleteProps<T, undefined, undefined, undefined>['getOptionLabel'];
}

const StyledTextField = styled(TextField)({
  marginTop: 0,
});

// eslint-disable-next-line @typescript-eslint/ban-types
export const AutocompleteController = <T extends {}>({
  disabled = false,
  errors,
  InputProps,
  inputType = 'text',
  items,
  label,
  name,
  renderOption,
  getOptionLabel,
  ...restProps
}: IProps<T>): React.ReactElement => {
  const renderFn = useCallback<ControllerProps['render']>(
    ({ field: { onChange, ...restField } }) => (
      <FormControl fullWidth variant="filled">
        <Autocomplete
          fullWidth
          {...restField}
          disabled={disabled}
          options={items}
          renderInput={(params) => <StyledTextField {...params} label={label} error={!!errors[name]} />}
          renderOption={renderOption}
          getOptionLabel={getOptionLabel}
          onChange={(_event, option) => {
            onChange(option);
          }}
        />
      </FormControl>
    ),
    [label, inputType, InputProps, errors[name], items]
  );

  return <Controller name={name} render={renderFn} {...restProps} />;
};
