import { apiEndpoint } from '../apiEndpoint';

export const AdminLiveEndpoints = {
  AllLiveStacks: apiEndpoint<[region: string]>('/customers/live/stack/:region'),
  CustomerLiveStacks: apiEndpoint<[customerId: number, region: string]>('/customers/:customer/live/stack/:region'),
  CustomerLiveStack: apiEndpoint<[customerId: number, region: string, stackId: string]>(
    '/customers/:customer/live/stack/:region/:stack'
  ),
  CustomerLiveStackAccess: apiEndpoint<[customerId: number, region: string, stackId: string]>(
    '/customers/:customer/live/stack/:region/:stack/access'
  ),
  StartLiveChannel: apiEndpoint<[customerId: number, region: string, stackId: string]>(
    '/customers/:customer/live/stack/:region/:stack/start'
  ),
  StopLiveChannel: apiEndpoint<[customerId: number, region: string, stackId: string]>(
    '/customers/:customer/live/stack/:region/:stack/stop'
  ),
  LiveRegions: apiEndpoint<[customerId: number]>('/customers/:customer/live/regions'),
  GlobalLiveRegions: apiEndpoint<[]>('/customers/live/regions'),
};
