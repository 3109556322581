import React, { FC, useContext, useEffect, useMemo } from 'react';
import { DownloadsContext } from '../../contexts/DownloadsContext';
import { useIntParams } from '../../hooks/useIntParams';
import { Retry } from '../Retry';
import { Nothing } from '../Nothing/Nothing';
import { useParams } from 'react-router-dom';
import { ProductVersionInstallerDownloads } from '../Downloads/ProductVersionInstallerDownloads';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { PageHeader } from '../PageHeader/PageHeader';
import { UserRoutes } from '../../routers/UserRoutes';
import { useTranslation } from 'react-i18next';

interface IParams {
  product: string;
  version: string;
  installer: string;
}

export const DownloadsProductInstallerPage: FC = () => {
  const { downloads, fetch, isError } = useContext(DownloadsContext);

  const { product } = useIntParams<IParams>();
  const { version: minorVersion, installer: installerPattern } = useParams<IParams>();

  const { t } = useTranslation();

  useEffect(() => {
    if (!downloads) {
      fetch();
    }
  }, []);

  const productDownloads = downloads?.userDownloads.find((userDownloads) => userDownloads.product.id === product);

  const installers = useMemo(() => {
    const pattern = new RegExp(
      '^' + // string start
        installerPattern.replaceAll('.', '\\.') + // escape dots
        '([^\\d]|$)' // make sure next character is not a digit (not to show 22.3.10 when the pattern is 22.3.1)
    );
    return productDownloads?.versions
      .find((version) => version.version === minorVersion)
      ?.installers.filter((installer) => installer.manifestFileVersion.version.match(pattern));
  }, [productDownloads]);

  return (
    <div>
      <PageHeader
        title={`${productDownloads?.product.name ?? ''} ${installerPattern}`}
        crumbs={[
          {
            title: t('layout.userNavigation.downloads'),
            url: UserRoutes.DownloadsProductVersion(product, minorVersion),
          },
        ]}
      />
      {isError ? (
        <Retry onRetry={fetch} status={null} />
      ) : installers && installers.length ? (
        <Grid container spacing={1}>
          {installers.map((installer) => (
            <Grid item xs={12} key={installer.manifestFileVersion.version}>
              <Card>
                <ProductVersionInstallerDownloads installer={installer} />
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : downloads === null ? null : (
        <Nothing />
      )}
    </div>
  );
};
