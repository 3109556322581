import * as Sentry from '@sentry/react';

export const ifError = <Type extends any>(fn: () => Type, fallback: Type): Type => {
  try {
    return fn();
  } catch (e) {
    console.error('An inline error handler has catched an exception and returned a fallback value', { fn, fallback });
    console.error(e);
    Sentry.captureException(e, {
      extra: { fallback },
    });
    return fallback;
  }
};
