import { createContext } from 'react';
import { ILiveStackAccessResponse } from '../models/LiveStackAccessResponse';

interface ILiveStackContext {
  start?: (stackId: string) => Promise<void | unknown>;
  stop?: (stackId: string) => Promise<void | unknown>;
  remove?: (stackId: string) => Promise<void | unknown> | void;
  getAccessData?: (stackId: string, silent?: false) => Promise<ILiveStackAccessResponse>;
}

export const LiveStackContext = createContext<ILiveStackContext>({});
