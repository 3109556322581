import React, { FC, useCallback, useContext, useRef } from 'react';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import ContentCopy from '@mui/icons-material/ContentCopy';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useEnumState } from '../../hooks/useEnumState';
import { NotificationContext } from '../../contexts/NotificationContext';
import { useTranslation } from 'react-i18next';

interface IProps {
  label: string;
  value: string;
  confidential?: boolean;
  className?: string;
}

export const CopyTextField: FC<IProps> = ({ label, value, confidential = false, className }) => {
  const ref = useRef<HTMLInputElement>(null);
  const [showConfidential, setShowConfidential, setHideConfidential] = useEnumState(false, true, false);
  const { addNotification } = useContext(NotificationContext);
  const { t } = useTranslation();

  const onCopy = useCallback(() => {
    const el = ref.current;
    if (!el) return;
    el.select();
    el.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(el.value);
    addNotification({
      severity: 'success',
      message: t('component.copyTextField.copyConfirmation', { item: label }),
    });
  }, [label]);

  return (
    <TextField
      fullWidth
      label={label}
      type={confidential && !showConfidential ? 'password' : 'text'}
      defaultValue={value}
      disabled
      inputRef={ref}
      className={className}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {confidential ? (
              <IconButton onClick={showConfidential ? setHideConfidential : setShowConfidential}>
                {showConfidential ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            ) : null}

            <IconButton onClick={onCopy}>
              <ContentCopy />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
