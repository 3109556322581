import React, { FC, useCallback, useContext, useEffect } from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Link, useHistory } from 'react-router-dom';
import { matchPath } from 'react-router';
import { combineCn } from '../../helpers/combineCn';
import { FetcherInvalidatorContext } from '../../contexts/FetcherInvalidator';
import { AdminRoutes } from '../../routers/AdminRoutes';
import { AdminRouteCondition } from '../../components/RouteLink/RouteLink';

interface IProps {
  route: () => string;
  label: string;
  icon: React.ComponentType;
  match?: string[];
}

const useStyles = makeStyles((theme) => {
  return createStyles({
    active: {
      color: theme.palette.primary.main,
      '& svg': {
        color: theme.palette.primary.main,
      },
      '&:after': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: 0,
        width: 4,
        height: '100%',
        background: theme.palette.primary.main,
      },
    },
  });
});

export const DashboardDrawerItem: FC<IProps> = ({ icon: Icon, label, route, match }) => {
  const history = useHistory();
  const styles = useStyles();
  const { trigger: triggerInvalidator } = useContext(FetcherInvalidatorContext);

  const isActive =
    matchPath(history.location.pathname, route()) ||
    (match && match.some((matchUrl) => matchPath(history.location.pathname, matchUrl)));

  const onClick = useCallback(() => {
    if (isActive) triggerInvalidator();
  }, [isActive]);

  useEffect(() => {
    if (isActive) {
      const titleEl = document.head.querySelector('title');
      if (!titleEl) return;
      titleEl.innerText = `Pixotope Cloud | ${label}`;
      return () => {
        titleEl.innerText = 'Pixotope Cloud';
      };
    }
  }, [isActive]);

  return (
    <ListItem
      button
      component={Link}
      to={route()}
      className={combineCn({ [styles.active]: !!isActive })}
      onClick={onClick}
    >
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={label} />
    </ListItem>
  );
};

type IAdminProps = Omit<IProps, 'route'> & { route: keyof typeof AdminRoutes };

export const DashboardAdminDrawerItem: FC<IAdminProps> = ({ route, ...props }) => {
  return (
    <AdminRouteCondition route={route}>
      <DashboardDrawerItem route={AdminRoutes[route]} {...props} />
    </AdminRouteCondition>
  );
};
