import React, { FC, useCallback, useRef, useState } from 'react';
import { ConditionalTooltip } from '../ConditionalTooltip/ConditionalTooltip';
import { useResizeObserver } from '../../hooks/useResizeObserver';
import styled from '@mui/styles/styled';

const StyledDiv = styled('div')({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const CellContentTooltip: FC = ({ children }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [enabled, toggleEnabled] = useState(false);

  const callback = useCallback(() => {
    const element = ref.current;
    if (element) {
      if ((element.clientWidth ?? 0) < (element.scrollWidth ?? 0)) {
        toggleEnabled(true);
      } else {
        toggleEnabled(false);
      }
    }
  }, []);

  useResizeObserver(ref, callback);

  return (
    <StyledDiv ref={ref}>
      <ConditionalTooltip enabled={enabled} title={ref.current?.textContent ?? ''}>
        <span>{children}</span>
      </ConditionalTooltip>
    </StyledDiv>
  );
};
