import dayjs, { Dayjs } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import 'dayjs/locale/pl';

export enum DateFormat {
  Relative,
  DateMedium = 'D-MMM-YYYY',
  DatePicker = 'DD-MM-YYYY',
  DateFull = 'D MMMM YYYY',
  DateWithTime = 'D-MMM-YYYY, HH:mm',
  DateFullWithTime = 'D MMMM YYYY, HH:mm',
  DateFullWithTimeAndZone = 'D MMMM YYYY, HH:mm z',
  DateFullWithZone = 'D MMMM YYYY z',
  DateWithFullTime = 'D-MMM-YYYY | HH:mm:ss',
}

dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.guess();

export const getDateObject = (date: string | number | Dayjs) => {
  if (typeof date === 'object') return date;
  // convert seconds to milliseconds if the timestamp is lower than 5e9
  if (typeof date === 'number' && date < 5e9) date *= 1000;
  return dayjs(date);
};

export const dateFormat = (date: string | number | Dayjs, format: DateFormat, language: string): string => {
  const dateObject = getDateObject(date);

  if (format === DateFormat.Relative) {
    return dateObject.locale(language).fromNow();
  }
  return dateObject.locale(language).format(format);
};
