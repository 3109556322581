import React, { FC, useContext, useMemo } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { ActivityFetcher } from '../../fetchers/ActivityFetcher';
import { MapWithMarkers } from './MapWithMarkers';
import { IAnalyticsActivity } from '../../models/AnalyticsActivity';

type IAnalyticsActivityWithIP = Omit<IAnalyticsActivity, 'ip'> & { ip: NonNullable<IAnalyticsActivity['ip']> };

export const ActivityMap: FC = () => {
  const { data } = useContext(ActivityFetcher.Context);

  const markers = useMemo(() => {
    if (!data) return null;
    return (data.filter((item) => !!item.ip) as IAnalyticsActivityWithIP[]).map((activityItem) => ({
      name: activityItem.name,
      coords: [activityItem.ip.lat, activityItem.ip.lon] as [number, number],
    }));
  }, [data]);

  if (!markers) return null;
  return (
    <Paper>
      <Box p={1}>
        <MapWithMarkers markers={markers} />
      </Box>
    </Paper>
  );
};
