import React, { FC, useCallback, useContext, useMemo } from 'react';
import { LiveStackStatusTransitional, useLiveApi } from '../../hooks/useLiveApi';
import { createFetcher } from '../../contexts/Fetcher';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { PageHeader } from '../PageHeader/PageHeader';
import { LiveStackContext } from '../../contexts/LiveChannels';
import { LiveRegionsFetcher } from '../../fetchers/LiveRegionsFetcher';
import { LiveRegionSelect } from '../LiveRegionSelect/LiveRegionSelect';
import { useTranslation } from 'react-i18next';
import { LiveStack } from './LiveStack';
import { LiveRegionContext } from '../../contexts/LiveRegionContext';
import { useLoopedInterval } from '../../hooks/useLoopedInterval';

export const LivePreviewFetcher = createFetcher(() => {
  const { getChannelsList, getChannelsListSilent } = useLiveApi();
  const { region } = useContext(LiveRegionContext);

  return useCallback(
    async ({ silent = false }: { silent?: boolean }) => {
      if (!region) return [];
      return (silent ? getChannelsListSilent : getChannelsList)([region]);
    },
    [getChannelsList, getChannelsListSilent, region]
  );
});

// Refresh once every 5 minutes when all stack are stable
const REFRESH_TIMEOUT_IDLE = 5 * 60 * 1000;
// Refresh once every 10 seconds when any stack is in transitional state
const REFRESH_TIMEOUT_TRANSITIONAL = 10 * 1000;

export const LivePreviewContent: FC = () => {
  const { data, onRefresh } = useContext(LivePreviewFetcher.Context);
  const { t } = useTranslation();
  const isAnyTransitionalState = (data ?? []).some((stack) => LiveStackStatusTransitional.includes(stack.status));

  const refreshTimeout = isAnyTransitionalState ? REFRESH_TIMEOUT_TRANSITIONAL : REFRESH_TIMEOUT_IDLE;

  useLoopedInterval({
    interval: refreshTimeout,
    callback: useCallback(() => onRefresh({ silent: true }), [onRefresh]),
  });

  if (!data) return null;

  return (
    <Grid container spacing={3}>
      {data.length === 0 ? (
        <Grid item xs={12}>
          <Typography paragraph>{t('live.preview.noChannels')}</Typography>
        </Grid>
      ) : null}
      {data.map((stack) => {
        return (
          <Grid item xs={12} md={6} lg={4} key={stack.id}>
            <LiveStack stack={stack} />
          </Grid>
        );
      })}
    </Grid>
  );
};

/*
const UploadAssets: FC = () => {
  const { t } = useTranslation();
  const { data } = useContext(LivePreviewFetcher.Context);

  const isUploadOpen = useQueryParam('action') === 'upload';
  const uploadAssetUrl = useQueryUrl('action', 'upload');
  const closeDialog = useCloseDialog(void 0, ['action']);

  if (!data || data.length === 0) return null;
  return (
    <>
      <Button component={Link} to={uploadAssetUrl} variant="contained" color="secondary" endIcon={<CloudUpload />}>
        {t('live.uploadAssetsButton')}
      </Button>
      <LiveUploadAssets open={isUploadOpen} closeDialog={closeDialog} />
    </>
  );
};
*/

export const CloudLive: FC = () => {
  const { t } = useTranslation();
  const { region } = useContext(LiveRegionContext);

  const { stop, start, getAccessData, getAccessDataSilent } = useLiveApi();
  const contextVal = useMemo(
    () => ({
      stop: (stackId: string) => stop([region, stackId]),
      start: (stackId: string) => start([region, stackId]),
      getAccessData: (stackId: string, silent = false) =>
        (silent ? getAccessDataSilent : getAccessData)([region, stackId]),
    }),
    [stop, start, getAccessData, getAccessDataSilent, region]
  );

  return (
    <LivePreviewFetcher.WAF>
      <PageHeader title={t('live.preview.header')}>
        <LiveRegionsFetcher.WAF>
          <LiveRegionSelect />
        </LiveRegionsFetcher.WAF>
      </PageHeader>

      <LiveStackContext.Provider value={contextVal}>
        <LivePreviewContent />
      </LiveStackContext.Provider>
    </LivePreviewFetcher.WAF>
  );
};
