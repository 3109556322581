import React, { FC, useContext } from 'react';
import Snackbar from '@mui/material/Snackbar';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { NotificationContext } from '../../contexts/NotificationContext';
import { SingleNotification } from './SingleNotification';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: theme.spacing(3),
    },
  })
);

export const Notifications: FC = () => {
  const styles = useStyles();
  const { notifications } = useContext(NotificationContext);

  return (
    <Snackbar open={!!notifications.length}>
      <div className={styles.container}>
        {notifications.map((notification) => {
          return <SingleNotification notification={notification} key={notification.index} />;
        })}
      </div>
    </Snackbar>
  );
};
