import { useEffect, useState } from 'react';

const checkDocumentVisibility = () => document.visibilityState === 'visible';

export const useDocumentVisible = () => {
  const [isDocumentVisible, setDocumentVisible] = useState(checkDocumentVisibility());

  useEffect(() => {
    const listener = () => {
      setDocumentVisible(checkDocumentVisibility());
    };
    document.addEventListener('visibilitychange', listener);

    return () => {
      document.removeEventListener('visibilitychange', listener);
    };
  }, []);

  return isDocumentVisible;
};
