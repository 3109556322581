import React, { FC, useEffect, useState } from 'react';
import { Switch, useHistory } from 'react-router-dom';
import { RouteWrapper } from '../components/RouteWrapper';
import { DashboardLayout } from '../layouts/DashboardLayout';
import { NotFoundComponent } from '../components/NotFound';
import { AdminOverview } from '../components/AdminOverview/AdminOverview';
import { Admins } from '../components/Admins/Admins';
import { Admin } from '../components/Admin/Admin';
import { Customers } from '../components/Customers/Customers';
import { Customer } from '../components/Customer/Customer';
import { CustomerFetcher } from '../components/Customer/CustomerFetchers';
import { CustomerProduct } from '../components/CustomerProduct/CustomerProduct';
import { CustomerUserFetcher } from '../components/CustomerUser/CustomerUserFetchers';
import { CustomerUser } from '../components/CustomerUser/CustomerUser';
import { AllUsers } from '../components/AllUsers/AllUsers';
import { SupportPage } from '../components/SupportPage/SupportPage';
import { ApiEvents } from '../components/ApiEvents/ApiEvents';
import { PendingTasks } from '../components/PendingTasks/PendingTasks';
import { Releases } from '../components/Releases/Releases';
import { ProductsFetcher } from '../fetchers/ProductsFetcher';
import { AdminProducts } from '../components/AdminProducts/AdminProducts';
import { AnalyticsActivity } from '../components/AnalyticsActivity/AnalyticsActivity';
import { AnalyticsActivityAccount } from '../components/AnalyticsActivity/AnalyticsActivityAccount';
import { Balance } from '../components/Balance/Balance';
import { ViewAccount } from '../components/ViewAccount/ViewAccount';
import { MachineDetails } from '../components/MachineDetails/MachineDetails';
import { adminRoot, AdminRoutes } from './AdminRoutes';
import { useRolesApi } from '../hooks/api/useRolesApi';
import { IRolesContext, RolesContext } from '../contexts/RolesContext';
import { useAccount } from '../hooks/useAccount';
import { AccountType } from '../types/AccountType';
import { useIsAdminRouteAllowed } from '../components/RouteLink/RouteLink';
import { AllLiveStacks } from '../components/AllLiveStacks/AllLiveStacks';

/* eslint prettier/prettier: ["error", { printWidth: 1000 }] */

const ProvidersCustomerFetcher = [CustomerFetcher.WMF, CustomerUserFetcher.WMF];
const ProvidersProductsFetcher = [ProductsFetcher.WMF];

const AdminRootRedirector: FC = () => {
  const isOverviewAllowed = useIsAdminRouteAllowed('Overview');
  const isCustomersAllowed = useIsAdminRouteAllowed('Customers');
  const history = useHistory();

  const order = [...(isOverviewAllowed ? [AdminRoutes.Overview()] : []), ...(isCustomersAllowed ? [AdminRoutes.Customers()] : []), AdminRoutes.Account()];

  useEffect(() => {
    history.replace(order[0]);
  }, [isOverviewAllowed]);

  return null;
};

export const AdminRouter: FC = () => {
  const [adminRoles, setAdminRoles] = useState<IRolesContext>({ endpoints: [] });
  const { getRoles } = useRolesApi();
  const { account: admin } = useAccount(AccountType.Admin);

  useEffect(() => {
    const exec = async () => {
      const roles = await getRoles([]);
      setAdminRoles(roles);
    };

    exec().then();
  }, [admin]);

  if (!adminRoles.endpoints?.length) return null;

  return (
    <RolesContext.Provider value={adminRoles}>
      <Switch>
        <RouteWrapper exact path={adminRoot} component={AdminRootRedirector} layout={DashboardLayout} />

        <RouteWrapper exact path={AdminRoutes.Overview()} component={AdminOverview} layout={DashboardLayout} />
        <RouteWrapper exact path={AdminRoutes.Admins()} component={Admins} layout={DashboardLayout} />
        <RouteWrapper exact path={AdminRoutes.NewAdmin()} component={Admins} layout={DashboardLayout} />
        <RouteWrapper exact path={AdminRoutes.Admin()} component={Admin} layout={DashboardLayout} />
        <RouteWrapper exact path={AdminRoutes.AdminEdit()} component={Admin} layout={DashboardLayout} />
        <RouteWrapper exact path={AdminRoutes.AdminDelete()} component={Admin} layout={DashboardLayout} />
        <RouteWrapper exact path={AdminRoutes.Customers()} providers={ProvidersCustomerFetcher} component={Customers} layout={DashboardLayout} />
        <RouteWrapper exact path={AdminRoutes.NewCustomer()} providers={ProvidersCustomerFetcher} component={Customers} layout={DashboardLayout} />
        <RouteWrapper exact path={AdminRoutes.Customer()} providers={ProvidersCustomerFetcher} component={Customer} layout={DashboardLayout} />
        <RouteWrapper exact path={AdminRoutes.CustomerNewTabItem()} providers={ProvidersCustomerFetcher} component={Customer} layout={DashboardLayout} />
        <RouteWrapper exact path={AdminRoutes.CustomerUser()} providers={ProvidersCustomerFetcher} component={CustomerUser} layout={DashboardLayout} />
        <RouteWrapper exact path={AdminRoutes.CustomerUserRequestNewOffline()} providers={ProvidersCustomerFetcher} component={CustomerUser} layout={DashboardLayout} />
        <RouteWrapper exact path={AdminRoutes.EditCustomerUser()} providers={ProvidersCustomerFetcher} component={CustomerUser} layout={DashboardLayout} />
        <RouteWrapper exact path={AdminRoutes.DeleteCustomerUser()} providers={ProvidersCustomerFetcher} component={CustomerUser} layout={DashboardLayout} />
        <RouteWrapper exact path={AdminRoutes.CustomerTab()} providers={ProvidersCustomerFetcher} component={Customer} layout={DashboardLayout} />
        <RouteWrapper exact path={AdminRoutes.CustomerDeleteTabItem()} providers={ProvidersCustomerFetcher} component={Customer} layout={DashboardLayout} />
        <RouteWrapper exact path={AdminRoutes.CustomerProduct()} providers={ProvidersCustomerFetcher} component={CustomerProduct} layout={DashboardLayout} />
        <RouteWrapper exact path={AdminRoutes.CustomerProductLicense()} providers={ProvidersCustomerFetcher} component={CustomerProduct} layout={DashboardLayout} />
        <RouteWrapper exact path={AdminRoutes.CustomerProductAction()} providers={ProvidersCustomerFetcher} component={CustomerProduct} layout={DashboardLayout} />
        <RouteWrapper exact path={AdminRoutes.CustomerUserAssignLicense()} providers={ProvidersCustomerFetcher} component={CustomerUser} layout={DashboardLayout} />
        <RouteWrapper exact path={AdminRoutes.CustomerUserOfflineLicense()} providers={ProvidersCustomerFetcher} component={CustomerUser} layout={DashboardLayout} />
        <RouteWrapper exact path={AdminRoutes.CustomerUserReleaseLicense()} providers={ProvidersCustomerFetcher} component={CustomerUser} layout={DashboardLayout} />
        <RouteWrapper exact path={AdminRoutes.CustomerUserUnassignLicense()} providers={ProvidersCustomerFetcher} component={CustomerUser} layout={DashboardLayout} />

        <RouteWrapper exact path={AdminRoutes.Users()} component={AllUsers} layout={DashboardLayout} />
        <RouteWrapper exact path={AdminRoutes.Support()} component={SupportPage} layout={DashboardLayout} />
        <RouteWrapper exact path={AdminRoutes.Events()} component={ApiEvents} layout={DashboardLayout} />

        <RouteWrapper exact path={AdminRoutes.Tasks()} component={PendingTasks} layout={DashboardLayout} />
        <RouteWrapper exact path={AdminRoutes.TasksTab()} component={PendingTasks} layout={DashboardLayout} />
        <RouteWrapper exact path={AdminRoutes.TaskDetails()} component={PendingTasks} layout={DashboardLayout} />
        <RouteWrapper exact path={AdminRoutes.DeleteTask()} component={PendingTasks} layout={DashboardLayout} />

        <RouteWrapper exact path={AdminRoutes.Releases()} providers={ProvidersProductsFetcher} component={Releases} layout={DashboardLayout} />
        <RouteWrapper exact path={AdminRoutes.ReleasesProduct()} providers={ProvidersProductsFetcher} component={Releases} layout={DashboardLayout} />
        <RouteWrapper exact path={AdminRoutes.NewReleaseProductVersion()} providers={ProvidersProductsFetcher} component={Releases} layout={DashboardLayout} />
        <RouteWrapper exact path={AdminRoutes.ReleasesProductVersion()} providers={ProvidersProductsFetcher} component={Releases} layout={DashboardLayout} />
        <RouteWrapper exact path={AdminRoutes.ReleasesProductVersionDelete()} providers={ProvidersProductsFetcher} component={Releases} layout={DashboardLayout} />

        <RouteWrapper exact path={AdminRoutes.Products()} component={AdminProducts} layout={DashboardLayout} />
        <RouteWrapper exact path={AdminRoutes.ProductsTab()} component={AdminProducts} layout={DashboardLayout} />
        <RouteWrapper exact path={AdminRoutes.ProductsTabNewItem()} component={AdminProducts} layout={DashboardLayout} />
        <RouteWrapper exact path={AdminRoutes.ProductsTabEditItem()} component={AdminProducts} layout={DashboardLayout} />
        <RouteWrapper exact path={AdminRoutes.ProductsTabDeleteItem()} component={AdminProducts} layout={DashboardLayout} />

        <RouteWrapper exact path={AdminRoutes.AnalyticsActivity()} component={AnalyticsActivity} layout={DashboardLayout} />

        <RouteWrapper exact path={AdminRoutes.AnalyticsActivityItem()} component={AnalyticsActivityAccount} layout={DashboardLayout} />

        <RouteWrapper exact path={AdminRoutes.Balance()} component={Balance} layout={DashboardLayout} />

        <RouteWrapper exact path={AdminRoutes.Account()} component={ViewAccount} layout={DashboardLayout} />
        <RouteWrapper exact path={AdminRoutes.AccountEdit()} component={ViewAccount} layout={DashboardLayout} />

        <RouteWrapper exact path={AdminRoutes.CustomerMachine()} component={MachineDetails} layout={DashboardLayout} />

        <RouteWrapper exact path={AdminRoutes.AllLiveStacks()} component={AllLiveStacks} layout={DashboardLayout} />

        <RouteWrapper path={adminRoot} component={NotFoundComponent} layout={DashboardLayout} />
      </Switch>
    </RolesContext.Provider>
  );
};
