import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { createServerSidePaginatedFetcher, IServerSidePaginatedFetcherParams } from '../../contexts/Fetcher';
import { PendingJobType, useScheduledJobsApi } from '../../hooks/useScheduledJobsApi';
import { useHistory, useParams } from 'react-router-dom';
import { AdminRoutes } from '../../routers/AdminRoutes';
import { UrlQueryDataGrid } from '../UrlQueryDataGrid/UrlQueryDataGrid';
import { GridColumns, GridRowParams } from '@mui/x-data-grid';
import { getDateValueFormatter } from '../../valueFormatters/DateValueFormatter';
import { DateFormat } from '../../helpers/dateFormat';
import Link from '@mui/material/Link';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useIntParams } from '../../hooks/useIntParams';
import { PendingTaskDetailsDialog } from './PendingTaskDetailsDialog';
import { createStatusChipComponent } from '../StatusChip/StatusChip';
import { routeWithQuery } from '../../helpers/routeWithQuery';
import { useTranslation } from 'react-i18next';
import { AdminRouteLink, useIsAdminRouteAllowed } from '../RouteLink/RouteLink';

interface IUrlParams {
  jobType?: PendingJobType;
  jobId?: string;
}

export const PendingTasksFetcher = createServerSidePaginatedFetcher(() => {
  const { getScheduledJobs } = useScheduledJobsApi();
  const { jobType } = useParams<IUrlParams>();

  return useCallback(
    (params: IServerSidePaginatedFetcherParams) => {
      if (!jobType) return Promise.resolve(null);
      return getScheduledJobs([], jobType, params);
    },
    [getScheduledJobs, jobType]
  );
});

const jobTypes: PendingJobType[] = [
  'customerNewProduct',
  'deactivateOfflineLicense',
  'customerRenewProduct',
  'licenseExpiringSoon',
  'customerSuspendProduct',
];

const StatusChip = createStatusChipComponent({
  done: 'lime',
  ignored: 'lime',
  failed: 'red',
  inProgress: 'orange',
  todo: 'default',
});

const defaultSortQueryParams = {
  sortBy: 'created',
  sortOrder: 'desc',
};

const PendingTasksContent: FC = () => {
  const { data } = useContext(PendingTasksFetcher.Context);
  const { jobType } = useParams<IUrlParams>();
  const { jobId } = useIntParams<IUrlParams>();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    if (!jobType) history.replace(routeWithQuery(AdminRoutes.TasksTab, ['customerNewProduct'], defaultSortQueryParams));
  }, [jobType]);

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'owner',
        headerName: t('pendingTasks.table.ownerHeader'),
        width: 120,
        renderCell: (params) => {
          if (typeof params.value !== 'number') return null;
          return (
            <Link component={AdminRouteLink} route="Admin" params={[params.value]} showFallback underline="hover">
              {params.value}
            </Link>
          );
        },
        filterable: false,
      },
      {
        field: 'request',
        headerName: t('pendingTasks.table.requestHeader'),
        flex: 1,
        valueFormatter: (params) => JSON.stringify(params.value),
        filterable: false,
      },
      {
        field: 'scheduleTime',
        headerName: t('pendingTasks.table.scheduleTimeHeader'),
        width: 180,
        valueFormatter: getDateValueFormatter(DateFormat.DateWithFullTime),
        filterable: false,
      },
      {
        field: 'status',
        headerName: t('common.tableHeader.status'),
        filterable: false,
        width: 120,
        renderCell: StatusChip,
      },
      {
        field: 'created',
        headerName: t('common.tableHeader.created'),
        filterable: false,
        width: 130,
        valueFormatter: getDateValueFormatter(DateFormat.DateMedium),
      },
      {
        field: 'updated',
        headerName: t('common.tableHeader.updated'),
        filterable: false,
        width: 130,
        valueFormatter: getDateValueFormatter(DateFormat.DateMedium),
      },
    ],
    []
  );

  const onTabChange = useCallback((event: unknown, jobType: PendingJobType) => {
    history.push(routeWithQuery(AdminRoutes.TasksTab, [jobType], defaultSortQueryParams));
  }, []);

  const chosenJob = useMemo(() => {
    if (!data) return void 0;
    return data.cronJobs.data.find((job) => job.id === jobId);
  }, [data, jobId]);

  const getRowLink = useCallback(
    (params: GridRowParams) => {
      return `${AdminRoutes.TaskDetails(jobType, params.row.id)}${history.location.search}`;
    },
    [jobType]
  );
  const isGetRowLinkAllowed = useIsAdminRouteAllowed('TaskDetails');

  const closeDialog = useCallback(() => {
    history.push(`${AdminRoutes.TasksTab(jobType)}${history.location.search}`);
  }, [jobType]);

  if (!data) return null;
  return (
    <div>
      <Tabs
        value={jobType}
        indicatorColor="primary"
        textColor="primary"
        onChange={onTabChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        {jobTypes.map((jobType) => {
          return <Tab key={jobType} label={jobType.replace(/([a-z])([A-Z])/g, '$1 $2')} value={jobType} />;
        })}
      </Tabs>

      <UrlQueryDataGrid
        columns={columns}
        rows={data.cronJobs.data}
        disableExtendRowFullWidth={false}
        autoHeight={true}
        disableSelectionOnClick={true}
        paginationMode={'server'}
        sortingMode={'server'}
        filterMode={'server'}
        getRowLink={isGetRowLinkAllowed ? getRowLink : void 0}
        rowCount={data.cronJobs.page.data.total}
      />

      <PendingTaskDetailsDialog closeDialog={closeDialog} pendingTask={chosenJob} />
    </div>
  );
};

export const PendingTasks: FC = () => {
  return (
    <PendingTasksFetcher.WAF>
      <PendingTasksContent />
    </PendingTasksFetcher.WAF>
  );
};
