import React, { FC, useEffect, useRef, useState } from 'react';
import 'jsvectormap';
import 'jsvectormap/dist/maps/world';
import 'jsvectormap/dist/css/jsvectormap.min.css';
import useTheme from '@mui/styles/useTheme';

declare let jsVectorMap: any;

interface IProps {
  markers: Array<{
    name: string;
    coords: [number, number];
  }>;
}

export const MapWithMarkers: FC<IProps> = ({ markers }) => {
  const [randomId] = useState(`${Date.now().toString(36)}_${Math.round(1e9 * Math.random()).toString(36)}`);
  const mapRef = useRef<any>();
  const theme = useTheme();

  useEffect(() => {
    const listener = () => {
      const map = mapRef.current;
      if (!map) return;
      map.updateSize();
    };
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, []);

  useEffect(() => {
    mapRef.current = new jsVectorMap({
      selector: `#${randomId}`,
      map: 'world',
      zoomButtons: false,
      showTooltip: true,
      markerStyle: {
        initial: {
          stroke: theme.palette.background.paper,
          strokeWidth: 2,
          fill: theme.palette.primary.main,
          r: 6,
        },
        hover: {
          fill: theme.palette.primary.light,
          r: 8,
        },
      },
    });
  }, [randomId]);

  useEffect(() => {
    const map = mapRef.current;
    if (!map || !markers) return;
    map.reset();

    // @todo with jsVectorMap in version higher than 1.3.3 it'll be possible to pass an array
    for (const marker of markers) {
      map.addMarkers(marker);
    }
  }, [markers]);

  return <div style={{ height: '50vh' }} id={randomId} />;
};
