import React, { FC } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

interface IProps {
  onClose: () => void;
}

export const LicenseReleased: FC<IProps> = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <>
      <DialogTitle>{t('licenses.releaseDialog.title')}</DialogTitle>
      <DialogContent dividers>
        <Typography paragraph>{t('licenses.releaseDialog.description')}</Typography>
        <Box p={1} textAlign="center">
          <img src="/release-step2.svg" />
        </Box>
        <Typography paragraph>{t('licenses.releaseDialog.content.done')}</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose} color="primary">
          {t('common.dialog.submit')}
        </Button>
      </DialogActions>
    </>
  );
};
