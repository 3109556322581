import React, { FC, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { UserRoutes } from '../../routers/UserRoutes';
import { useDownloadApi } from '../../hooks/useDownloadApi';
import { IProductDetails } from '../../models/ProductDetails';
import { IProductContent } from '../../models/UserOverview';
import styled from '@mui/styles/styled';
import { useTranslation } from 'react-i18next';
import { ButtonWithChevronWithLink } from '../ButtonWithChevron/ButtonWithChevron';

interface IProps {
  productDetails: IProductDetails;
  content: IProductContent;
}

const Image = styled('img')({
  width: '100%',
});

const CardHeader = styled('div')({
  padding: '20px 16px',
});

const CardFooter = styled('div')({
  padding: '16px 16px 20px',
});

const FlexPaper = styled(Paper)({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const FlexSpaceFiller = styled('div')({
  flex: 1,
});

const CompatibleVersionsContainer = styled('span')(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));

export const ProductSingleContent: FC<IProps> = ({ productDetails, content }) => {
  const { t } = useTranslation();
  const [actualImageUrl, setActualImageUrl] = useState<string | null>(null);
  const { download } = useDownloadApi();

  useEffect(() => {
    (async () => {
      const result = await download(content.image);
      setActualImageUrl(result.url);
    })();
  }, [content.image]);

  const engineContentUrl = UserRoutes.DownloadsProductContent(productDetails.id, content.versions[0]);
  const examplesUrl = UserRoutes.DownloadsProductExamples(productDetails.id, content.versions[0]);

  return (
    <Grid item xs={12} md={6} lg={3}>
      <FlexPaper>
        <CardHeader>
          <Typography variant="h4" textAlign="center">
            {content.title}
          </Typography>

          <Typography variant="subtitle1" textAlign="center">
            {content.type === 'engineContent' ? t('userOverview.engineContent') : null}
            {content.type === 'demoProjects' ? t('userOverview.exampleProject') : null}
          </Typography>
        </CardHeader>

        {actualImageUrl ? <Image src={actualImageUrl} /> : null}

        <Box p={3} pb={0}>
          <Typography paragraph marginBottom={0}>
            {t('userOverview.compatibleWith')}
            <CompatibleVersionsContainer>
              {content.versions.map((version) => (
                <Chip component="span" key={version} label={version} color="primary" />
              ))}
            </CompatibleVersionsContainer>
          </Typography>
        </Box>

        <FlexSpaceFiller />

        <CardFooter>
          {content.type === 'engineContent' ? (
            <ButtonWithChevronWithLink to={engineContentUrl}>
              {t('userOverview.goToEngineContent')}
            </ButtonWithChevronWithLink>
          ) : null}
          {content.type === 'demoProjects' ? (
            <ButtonWithChevronWithLink to={examplesUrl}>{t('userOverview.goToExamples')}</ButtonWithChevronWithLink>
          ) : null}
        </CardFooter>
      </FlexPaper>
    </Grid>
  );
};
