import React, { FC } from 'react';
import { alpha, ThemeProvider, StyledEngineProvider, createTheme, adaptV4Theme } from '@mui/material/styles';
import { Colors } from '../colors';
import type {} from '@mui/x-data-grid/themeAugmentation';
import CheckBoxOutlineBlankSharpIcon from '@mui/icons-material/CheckBoxOutlineBlankSharp';
import CheckBoxSharpIcon from '@mui/icons-material/CheckBoxSharp';

const defaultTheme = createTheme();
const topBarHeight = 48;

const theme = createTheme(
  adaptV4Theme({
    palette: {
      mode: 'dark',
      primary: {
        main: Colors.Primary,
        light: '#ffcc33',
        dark: '#cc9900',
        contrastText: '#172532',
      },
      secondary: {
        main: Colors.Secondary,
        light: '#6EB5B9',
        dark: '#3B8286',
        contrastText: '#172532',
      },
      background: {
        default: Colors.Navy,
        paper: Colors.DeepNavy,
      },
      text: {
        primary: '#F7FAFD',
        secondary: '#E7E9EA',
        disabled: '#A2A7AC',
        // hint: '#A2A7AC',
      },
      error: {
        main: Colors.Red,
        light: '#FF4633',
        dark: '#CC1300',
        contrastText: '#f7fafd',
      },
      warning: {
        main: Colors.Orange,
        light: '#ffa933',
        dark: '#cc7600',
        contrastText: '#172532',
      },
      success: {
        main: Colors.Lime,
        light: '#A3DB41',
        dark: '#70A80E',
        contrastText: '#172532',
      },
      info: {
        main: Colors.Blue,
        light: '#7BBBFB',
        dark: '#4888C8',
        contrastText: '#172532',
      },
      divider: 'rgba(247,250,253,0.12)',
    },
    typography: {
      fontFamily: 'IBMPlexSans',
      body1: {
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: 12,
        },
        fontSize: 16,
      },
      h1: {
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: 40,
        },
        fontSize: 54,
      },
      h2: {
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: 32,
        },
        fontSize: 42,
      },
      h3: {
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: 24,
        },
        fontSize: 32,
        fontWeight: 300,
      },
      h4: {
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: 21,
        },
        fontSize: 28,
      },
      h5: {
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: 15,
        },
        fontSize: 20,
      },
      h6: {
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: 12,
        },
        fontSize: 16,
        fontWeight: 600,
      },
      subtitle1: {
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: 12,
        },
        fontSize: 16,
      },
      subtitle2: {
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: 10,
        },
        fontSize: 14,
        fontWeight: 600,
      },
      body2: {
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: 10,
        },
        fontSize: 14,
      },
      caption: {
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: 9,
        },
        fontSize: 12,
      },
      button: {
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: 10,
        },
        fontSize: 14,
        fontWeight: 600,
      },
      overline: {
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: 9,
        },
        fontSize: 13,
      },
    },
    overrides: {
      MuiToolbar: {
        root: {
          paddingLeft: 48,
          paddingRight: 0,
          [defaultTheme.breakpoints.up('sm')]: {
            paddingLeft: 48,
            paddingRight: 0,
          },
          [defaultTheme.breakpoints.down('sm')]: {
            paddingLeft: 12,
            paddingRight: 0,
          },
        },
      },
      MuiAppBar: {
        colorDefault: {
          backgroundColor: Colors.DeepNavy,
          color: '#F7FAFD',
        },
      },
      MuiListItem: {
        button: {
          '&:hover': {
            backgroundColor: `${Colors.Primary}1A`,
          },
        },
      },
      MuiPaper: {
        root: {
          backgroundImage: 'none',
        },
      },
      MuiDataGrid: {
        root: {
          border: 'none',
          background: Colors.DeepNavy,
        },
        row: {
          '&:nth-of-type(even)': {},
          '&:nth-of-type(odd)': {
            background: alpha(Colors.Navy, 0.3),
          },
          '&:hover': {
            background: alpha(Colors.White, 0.08),
          },
          '&.Mui-selected': {
            background: alpha(Colors.Secondary, 0.3),
          },
          '&.Mui-disabled': {
            opacity: defaultTheme.palette.action.disabledOpacity,
          },
        },
        cell: {
          border: 'none',
          '&:focus, &:focus-within': {
            outline: 'none',
          },
        },
        columnHeaders: {
          border: 'none',
          backgroundColor: Colors.DeepNavy,
        },
        columnHeader: {
          padding: '0 0 0 10px',
        },
        columnSeparator: {
          display: 'none',
        },
        filterForm: {
          alignItems: 'flex-end',
        },
        overlay: {
          height: 'calc(100% - 52px)',
        },
        columnsPanelRow: {
          '& .MuiFormControlLabel-root.Mui-disabled': {
            display: 'none',
          },
        },
      },
      MuiDrawer: {
        paper: {
          borderRight: 'none',
        },
      },
      MuiFormControl: {
        root: {
          [defaultTheme.breakpoints.down('sm')]: {
            fontSize: 12,
          },
          marginTop: 16,
        },
      },
      MuiFilledInput: {
        root: {
          backgroundColor: Colors.DeepNavy,
          '&:before': {
            borderBottomColor: alpha('#F7FAFD', 0.42),
          },
          '&:hover': {
            backgroundColor: Colors.DeepNavy,
          },
          '&.Mui-focused': {
            backgroundColor: Colors.DeepNavy,
          },
        },
      },
      MuiDialogTitle: {
        root: {
          [defaultTheme.breakpoints.down('sm')]: {
            fontSize: 21,
            lineHeight: '27px',
          },
          fontSize: 28,
          lineHeight: '36px',
          fontWeight: 400,
          padding: '48px 48px 6px',
        },
      },
      MuiDialogContent: {
        root: {
          [defaultTheme.breakpoints.down('sm')]: {
            paddingLeft: 24,
            paddingRight: 24,
            paddingBottom: 24,
          },
          paddingLeft: 48,
          paddingRight: 48,
          paddingBottom: 48,
        },
      },
      MuiDialogActions: {
        root: {
          backgroundColor: Colors.DeepNavy,
          padding: '24px 48px',
          gap: 8,
          '&>:not(:first-of-type)': {
            marginLeft: 0,
          },
          '& .MuiButton-root': {
            minWidth: '108px',
          },
          [defaultTheme.breakpoints.down('sm')]: {
            padding: '12px 24px',
          },
        },
      },
      MuiAccordionSummary: {
        root: {
          position: 'sticky',
          top: 48,
          zIndex: 1,
          [defaultTheme.breakpoints.down('sm')]: {
            top: 48,
          },
        },
        expandIconWrapper: {
          color: '#A2A7AC',
          marginLeft: 20,
          [defaultTheme.breakpoints.down('sm')]: {
            marginLeft: 10,
          },
        },
      },
      MuiTabs: {
        root: {
          marginBottom: 24,
          [defaultTheme.breakpoints.down('sm')]: {
            marginBottom: 12,
          },
          [defaultTheme.breakpoints.down('sm')]: {
            minHeight: 36,
          },
        },
      },
      MuiTab: {
        root: {
          textTransform: 'none',
          '&.Mui-selected': {
            backgroundColor: alpha(Colors.Primary, 0.16),
          },
          [defaultTheme.breakpoints.down('sm')]: {
            minHeight: 36,
            padding: '6px 8px',
          },
        },
      },
      MuiInputLabel: {
        root: {
          '&.Mui-error': {
            fontWeight: 600,
          },
        },
        shrink: {
          color: alpha('#E7E9EA', 0.5),
        },
      },
      MuiTableCell: {
        root: {
          borderBottomColor: 'rgba(247,250,253,0.12)',
        },
      },
      MuiSnackbar: {
        root: {
          [defaultTheme.breakpoints.up('sm')]: {
            bottom: 'auto',
            top: `${parseInt(defaultTheme.spacing(3).replace('px', '')) + topBarHeight}px`,
            right: defaultTheme.spacing(3),
            left: 'auto',
          },
          [defaultTheme.breakpoints.down('sm')]: {
            bottom: 'auto',
            top: `${parseInt(defaultTheme.spacing(3).replace('px', '')) + topBarHeight}px`,
            right: defaultTheme.spacing(3),
            left: 'auto',
          },
        },
      },
      MuiFormHelperText: {
        root: {
          fontWeight: 600,
        },
      },
    },
    mixins: {
      toolbar: {
        height: topBarHeight,
        minHeight: topBarHeight,
      },
    },
    props: {
      MuiAppBar: {
        color: 'inherit',
        elevation: 0,
      },
      MuiTooltip: {
        arrow: true,
      },
      MuiButtonBase: {},
      MuiDialog: {
        PaperProps: {
          style: {
            backgroundColor: Colors.Navy,
            borderTop: `4px solid ${Colors.Primary}`,
          },
        },
        BackdropProps: {
          style: {
            backgroundColor: '#0F1820b3',
          },
        },
      },
      MuiAccordionSummary: {
        style: {
          backgroundColor: Colors.Navy,
          minHeight: 80,
        },
      },
      MuiButton: {
        style: {
          textTransform: 'none',
        },
      },
      MuiCheckbox: {
        icon: <CheckBoxOutlineBlankSharpIcon />,
        checkedIcon: <CheckBoxSharpIcon />,
      },
      MuiTextField: {
        variant: 'filled',
      },
      MuiSelect: {
        variant: 'filled',
      },
      MuiChip: {
        size: 'small',
      },
    },
    shape: {
      borderRadius: 0,
    },
  })
);

export const PixoThemeProvider: FC = ({ children }) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
};
