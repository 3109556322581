import React, { FC } from 'react';
import { IAdminOverviewResponse } from '../../models/AdminOverviewResponse';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { LicenseExpiry } from '../LicenseExpiry/LicenseExpiry';
import { useTranslation } from 'react-i18next';
import { AdminRouteLink } from '../RouteLink/RouteLink';

interface IProps {
  licensesExpiring: IAdminOverviewResponse['licensesExpiring'];
}

export const NextExpiration: FC<IProps> = ({ licensesExpiring }) => {
  const { t } = useTranslation();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t('adminOverview.expirations.customer')}</TableCell>
          <TableCell>{t('adminOverview.expirations.company')}</TableCell>
          <TableCell>{t('adminOverview.expirations.representative')}</TableCell>
          <TableCell>{t('adminOverview.expirations.plan')}</TableCell>
          <TableCell>{t('adminOverview.expirations.expires')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {licensesExpiring.map((license, index) => {
          return (
            <TableRow key={`${license.customer.id}${index}`}>
              <TableCell>
                <Link
                  component={AdminRouteLink}
                  route="Customer"
                  params={[license.customer.id]}
                  underline="hover"
                  showFallback
                >
                  {license.customer.firstName} {license.customer.lastName}
                </Link>
              </TableCell>
              <TableCell>{license.customer.company}</TableCell>
              <TableCell>
                <Link
                  component={AdminRouteLink}
                  route="Admin"
                  params={[license.customer.createdBy]}
                  underline="hover"
                  showFallback
                >
                  {license.customer.createdBy}
                </Link>
              </TableCell>
              <TableCell>
                {license.plans.map((plan) => (
                  <div key={`${plan.plan.id}/${plan.customerProduct.id}`}>
                    <Link
                      component={AdminRouteLink}
                      route="CustomerProduct"
                      params={[license.customer.id, plan.customerProduct.id]}
                      underline="hover"
                      showFallback
                    >
                      {plan.plan.name}
                    </Link>
                  </div>
                ))}
              </TableCell>
              <TableCell>
                {license.plans.map((plan) => (
                  <div key={`${plan.plan.id}/${plan.customerProduct.id}`}>
                    <LicenseExpiry expiry={plan.expiresAt} type={plan.plan.type} />
                  </div>
                ))}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
