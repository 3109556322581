import React, { FC, useContext } from 'react';
import Grid from '@mui/material/Grid';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { UseFormReturn } from 'react-hook-form';
import { HiddenSubmit } from '../HiddenSubmit/HiddenSubmit';
import { TextInputController } from '../InputControllers/TextInputController';
import { ProductToolsFetcher } from '../../fetchers/ProductTools';
import { useAutoFetch } from '../../contexts/Fetcher';
import { ProductPackagesFetcher } from '../../fetchers/ProductPackagesFetcher';
import { TransferListWithAmount } from '../InputControllers/TransferListWithAmount';
import { TransferList } from '../InputControllers/TransferList';
import { useTranslation } from 'react-i18next';

interface IChosenTool {
  amount: number;
  toolId: number;
}

export interface IProductPackageFormPayload {
  name: string;
  tools: IChosenTool[];
  bundledWith: number[];
}

interface IProps {
  form: UseFormReturn;
  onSubmit: (payload: IProductPackageFormPayload) => void;
}
const useStyles = makeStyles(() =>
  createStyles({
    toolsTransferList: {
      height: 300,
    },
    packagesTransferList: {
      height: 200,
    },
  })
);

const ENABLE_BUNDLED_WITH = false;

export const ProductPackageForm: FC<IProps> = ({ form, onSubmit }) => {
  const { data: toolsData } = useContext(ProductToolsFetcher.Context);
  const { data: packagesData } = useContext(ProductPackagesFetcher.Context);
  useAutoFetch(ProductToolsFetcher);
  const styles = useStyles();
  const { t } = useTranslation();

  if (!toolsData || !packagesData) return null;
  return (
    <Grid container spacing={1} component="form" onSubmit={form.handleSubmit(onSubmit)} style={{ minWidth: 600 }}>
      <Grid item xs={12}>
        <TextInputController
          control={form.control}
          errors={form.formState.errors}
          label={t('adminProducts.packages.form.name')}
          name="name"
          rules={{ required: true }}
        />
      </Grid>
      <Grid item xs={12}>
        <TransferListWithAmount
          form={form}
          name="tools"
          label={t('adminProducts.packages.form.tools')}
          data={toolsData.tools}
          compare={(item, value: IChosenTool) => item.id === value.toolId}
          getItemKey={(item) => item.id}
          getItemLabel={(item) => item.key}
          getValueAmount={(value) => value.amount}
          valueGenerator={(item, amount) => ({ toolId: item.id, amount })}
          availableListLabel={t('adminProducts.packages.form.availableTools')}
          chosenListLabel={t('adminProducts.packages.form.chosenTools')}
          classNames={{
            itemsList: styles.toolsTransferList,
          }}
        />
      </Grid>

      {ENABLE_BUNDLED_WITH ? (
        <Grid item xs={12}>
          <TransferList
            form={form}
            name="bundledWith"
            label={t('adminProducts.packages.form.bundledWith')}
            data={packagesData.packages}
            compare={(item, value: number) => item.id === value}
            getItemKey={(item) => item.id}
            getItemLabel={(item) => item.name}
            valueGenerator={(item) => item.id}
            availableListLabel={t('adminProducts.packages.form.availableBundled')}
            chosenListLabel={t('adminProducts.packages.form.chosenBundled')}
            classNames={{
              itemsList: styles.packagesTransferList,
            }}
          />
        </Grid>
      ) : null}
      <HiddenSubmit />
    </Grid>
  );
};
