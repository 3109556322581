import { RefObject, useEffect } from 'react';

export const useInjectedAnchors = (ref: RefObject<HTMLElement>) => {
  useEffect(() => {
    const element = ref.current;
    if (!element) return;
    const anchors = element.querySelectorAll('a[href]');
    for (let i = 0; i < anchors.length; ++i) {
      const anchor = anchors[i];
      if (anchor instanceof HTMLAnchorElement) {
        anchor.target = '_blank';
        anchor.rel = 'noopener noreferrer';
      }
    }
  }, []);
};
