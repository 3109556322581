import React, { FC, useContext } from 'react';
import Grid from '@mui/material/Grid';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { UseFormReturn } from 'react-hook-form';
import { TextInputController } from '../InputControllers/TextInputController';
import { useAutoFetch } from '../../contexts/Fetcher';
import { TransferList } from '../InputControllers/TransferList';
import { ProductPlansFetcher } from '../../fetchers/ProductPlansFetcher';
import { useScrollToError } from '../../hooks/useScrollToError';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() =>
  createStyles({
    transferList: {
      height: 300,
    },
  })
);

export interface IProductFormPayload {
  name: string;
  plans: number[];
  productCode?: string;
  installerPath: string;
  docsPath: string;
}

interface IProps {
  form: UseFormReturn;
  onSubmit: (data: IProductFormPayload) => void;
  isEdit?: boolean;
}

export const ProductForm: FC<IProps> = ({ form, onSubmit, isEdit = false }) => {
  const { data: plansData } = useContext(ProductPlansFetcher.Context);
  useAutoFetch(ProductPlansFetcher);
  const styles = useStyles();
  const { t } = useTranslation();

  const { formRef } = useScrollToError(form);

  if (!plansData) return null;
  return (
    <Grid container spacing={1} component={'form'} onSubmit={form.handleSubmit(onSubmit)} ref={formRef}>
      <Grid item xs={12}>
        <TextInputController
          control={form.control}
          errors={form.formState.errors}
          rules={{ required: true }}
          label={t('adminProducts.products.form.name')}
          name="name"
        />
      </Grid>
      <Grid item xs={12}>
        <TransferList
          form={form}
          name="plans"
          label={t('adminProducts.products.form.name')}
          data={plansData.plans}
          compare={(plan, value: number) => plan.id === value}
          getItemKey={(plan) => plan.id}
          getItemLabel={(plan) =>
            plan.name + (plan.archived ? ` ${t('adminProducts.products.table.planArchived')}` : '')
          }
          valueGenerator={(plan) => plan.id}
          classNames={{ itemsList: styles.transferList }}
          isUnavailable={(plan) => plan.archived}
        />
      </Grid>

      {!isEdit ? (
        <Grid item xs={12} md={6}>
          <TextInputController
            control={form.control}
            errors={form.formState.errors}
            rules={{ required: true }}
            label={t('adminProducts.products.form.installerPath')}
            name="installerPath"
            InputProps={{
              placeholder: 'pixotope/',
            }}
          />
        </Grid>
      ) : null}

      {!isEdit ? (
        <Grid item xs={12} md={6}>
          <TextInputController
            control={form.control}
            errors={form.formState.errors}
            rules={{ required: true }}
            label={t('adminProducts.products.form.docsPath')}
            name="docsPath"
            InputProps={{
              placeholder: 'docs/pixotope/',
            }}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};
