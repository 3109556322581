import { getStringEqualsOperator } from './stringEqualsOperator';
import { GridFilterOperator } from '@mui/x-data-grid';
import { t } from 'i18next';

export const getStringDiffersOperator = (): GridFilterOperator => ({
  value: 'differs',
  label: t('filterOperator.differs'),
  InputComponent: getStringEqualsOperator().InputComponent,
  getApplyFilterFn: (filterItem) => {
    return (params) => {
      return `${params.value}` !== filterItem.value;
    };
  },
});
