import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useApi } from './useApi';
import { NotificationContext } from '../contexts/NotificationContext';
import { useTranslation } from 'react-i18next';
import { useApiErrorHandler } from './useApiErrorHandler';

export enum UploadingState {
  INIT,
  IN_PROGRESS,
  DONE,
  ERROR,
}

interface IS3UploadOptions {
  authEndpoint: string;
  onProgress?: (value: number) => void;
}

const authorizeStepProgress = 5;

export const useS3Upload = ({ authEndpoint, onProgress }: IS3UploadOptions) => {
  const [uploadingState, setUploadingState] = useState(UploadingState.INIT);
  const abortControllerRef = useRef<AbortController | undefined>();
  const { get: nonIndicatedGet } = useApi({ indicateLoading: false });
  const { addNotification } = useContext(NotificationContext);
  const { t } = useTranslation();
  const handleApiError = useApiErrorHandler();

  const authorizeUpload = useCallback(async () => {
    onProgress?.(0);
    const result = await nonIndicatedGet<string>(authEndpoint);
    onProgress?.(authorizeStepProgress);
    return result.data;
  }, [authEndpoint, nonIndicatedGet]);

  const upload = useCallback(
    async (file: File) => {
      setUploadingState(UploadingState.IN_PROGRESS);
      try {
        const uploadUrl = await authorizeUpload();

        const abortController = new AbortController();
        abortControllerRef.current = abortController;

        await axios.put(uploadUrl, file, {
          onUploadProgress: (event: ProgressEvent) => {
            const uploadProgress = event.loaded / event.total;
            onProgress?.(authorizeStepProgress + uploadProgress * (100 - authorizeStepProgress));
          },
          signal: abortController.signal,
        });
        abortControllerRef.current = void 0;
        setUploadingState(UploadingState.DONE);
        return true;
      } catch (e) {
        if ((e as any)?.message === 'canceled') {
          setUploadingState(UploadingState.INIT);
          addNotification({
            message: t('common.s3upload.aborted'),
            severity: 'info',
          });
        } else {
          setUploadingState(UploadingState.ERROR);
          handleApiError(e);
        }
        return false;
      }
    },
    [authorizeUpload, onProgress]
  );

  useEffect(() => {
    return () => {
      abortControllerRef.current?.abort();
    };
  }, []);

  const reset = useCallback(() => {
    abortControllerRef.current?.abort();
    setUploadingState(UploadingState.INIT);
  }, []);

  return {
    abortControllerRef,
    reset,
    upload,
    uploadingState,
  };
};
