import React, { FC, useCallback, useContext } from 'react';
import { CustomerUserFetcher, CustomerUserPackagesFetcher } from './CustomerUserFetchers';
import { PageHeader } from '../PageHeader/PageHeader';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import { DateFormat } from '../../helpers/dateFormat';
import { CustomerUserPackages } from './CustomerUserPackages';
import { CustomerFetcher } from '../Customer/CustomerFetchers';
import { useAutoFetch } from '../../contexts/Fetcher';
import { useHistory } from 'react-router-dom';
import { AdminRoutes } from '../../routers/AdminRoutes';
import { DangerousButton } from '../CustomButtons/DangerousButton';
import { useIsRoute } from '../../hooks/useIsRoute';
import { CustomerUserEditDialog } from './CustomerUserEditDialog';
import { useIntParams } from '../../hooks/useIntParams';
import { useCustomerUsersApi } from '../../hooks/useCustomerUsersApi';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { ConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialog';
import { ConditionalTooltip } from '../ConditionalTooltip/ConditionalTooltip';
import { NotificationContext } from '../../contexts/NotificationContext';
import { useTranslation } from 'react-i18next';
import { Date } from '../Date/Date';
import { AdminRouteLink, useIsEndpointAllowed } from '../RouteLink/RouteLink';
import { CustomerUsersEndpoints } from '../../hooks/api/endpoints/customerUsers';
import { AccountSingleData } from '../AccountData/AccountSingleData';

export interface ICustomerUserUrlParams {
  customerId: string;
  userId: string;
}

const CustomerUserContent: FC = () => {
  const history = useHistory();
  const { customerId, userId } = useIntParams<ICustomerUserUrlParams>();
  const { data: basicData, onDataUpdate: updateUserData } = useContext(CustomerUserFetcher.Context);
  const { data: customerData } = useContext(CustomerFetcher.Context);
  const { data: packagesData } = useContext(CustomerUserPackagesFetcher.Context);
  useAutoFetch(CustomerUserFetcher);
  useAutoFetch(CustomerFetcher);
  const isEditOpen = useIsRoute(AdminRoutes.EditCustomerUser);
  const isDeleteOpen = useIsRoute(AdminRoutes.DeleteCustomerUser);
  const { deleteUser: deleteUserApi, reInvite, reassignZendeskId } = useCustomerUsersApi();
  const handleError = useApiErrorHandler();
  const { addNotification } = useContext(NotificationContext);
  const { t } = useTranslation();

  const deleteUser = useCallback(async () => {
    try {
      await deleteUserApi([customerId, userId]);
      history.push(AdminRoutes.CustomerTab(customerId, 'users'));
    } catch (e) {
      handleError(e);
    }
  }, [deleteUserApi, customerId, userId]);

  const reSendInvitation = useCallback(async () => {
    try {
      await reInvite([customerId, userId]);
      addNotification({
        severity: 'success',
        message: t('customerUser.reSendInvitationSucceeded'),
      });
    } catch (e) {
      handleError(e);
    }
  }, [reInvite, customerId, userId]);

  const onReassignZendeskId = useCallback(async () => {
    try {
      const updatedUserData = await reassignZendeskId([customerId, userId]);
      updateUserData(updatedUserData);
      addNotification({
        severity: 'success',
        message: t('customerUser.reassignZendeskIdSucceeded'),
      });
    } catch (e) {
      handleError(e);
    }
  }, [reassignZendeskId, updateUserData, customerId, userId]);

  const closeDialog = useCallback(() => {
    const path = AdminRoutes.CustomerUser(customerId, userId);
    history.push(`${path}${history.location.search}`);
  }, [customerId, userId]);

  const isEndpointAllowed = useIsEndpointAllowed();
  const isReassignZendeskAllowed = isEndpointAllowed('POST', CustomerUsersEndpoints.ReassignZendeskId.template);
  const isResendAllowed = isEndpointAllowed('POST', CustomerUsersEndpoints.ReInvite.template);

  if (!basicData || !customerData) return null;
  return (
    <div>
      <PageHeader
        title={`${basicData.firstName} ${basicData.lastName}`}
        crumbs={[
          {
            url: AdminRoutes.Customers(),
            title: t('layout.adminNavigation.customers'),
          },
          {
            url: AdminRoutes.Customer(customerId),
            title: `${customerData.customer.firstName} ${customerData.customer.lastName}`,
          },
        ]}
      >
        <Button
          component={AdminRouteLink}
          route="Events"
          params={[]}
          query={{ filterField: 'accountId', filterOp: 'equals', filterVal: userId }}
          variant="outlined"
          color="secondary"
        >
          {t('common.button.history')}
        </Button>
        <Button
          component={AdminRouteLink}
          route="EditCustomerUser"
          params={[customerId, userId]}
          keepQuery
          variant="contained"
          color="primary"
        >
          {t('customerUser.editButton')}
        </Button>
        <ConditionalTooltip
          enabled={(packagesData?.machinePackages.length ?? 0) > 0}
          title={t('customerUser.cannotDeleteWhenLicesesAssigned') as string}
        >
          <div>
            <DangerousButton
              component={AdminRouteLink}
              route="DeleteCustomerUser"
              params={[customerId, userId]}
              keepQuery
              disabled={!packagesData || packagesData.machinePackages.length > 0}
            >
              {t('common.button.delete')}
            </DangerousButton>
          </div>
        </ConditionalTooltip>
      </PageHeader>

      <Grid container spacing={1}>
        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('common.tableHeader.id')}>{basicData.id}</AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('common.tableHeader.name')}>
            {basicData.firstName} {basicData.lastName}
          </AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('common.tableHeader.email')}>{basicData.email}</AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('customerUser.zenDeskLabel')}>
            {basicData.zenDeskAccount}{' '}
            {isReassignZendeskAllowed ? (
              <Button onClick={onReassignZendeskId} variant="contained" color="secondary" size="small">
                {t('customerUser.reassignZendeskId')}
              </Button>
            ) : null}
          </AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('common.tableHeader.phone')}>{basicData.phoneNumber}</AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('common.tableHeader.status')}>
            {basicData.verified ? (
              <Chip label={t('common.accountStatus.verified')} color="primary" />
            ) : isResendAllowed ? (
              <Button onClick={reSendInvitation} variant="contained" color="secondary" size="small">
                {t('common.accountStatus.unverified')} / {t('customerUser.reSendInvitation')}
              </Button>
            ) : (
              <Chip label={t('common.accountStatus.unverified')} color="secondary" />
            )}
          </AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('common.tableHeader.created')}>
            <Date date={basicData.created} format={DateFormat.DateMedium} />
          </AccountSingleData>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AccountSingleData label={t('common.tableHeader.updated')}>
            <Date date={basicData.updated} format={DateFormat.DateMedium} />
          </AccountSingleData>
        </Grid>
      </Grid>

      <CustomerUserPackages />
      <CustomerUserEditDialog open={isEditOpen} closeDialog={closeDialog} />
      <ConfirmationDialog
        open={isDeleteOpen}
        title={t('customerUser.deleteDialog.title')}
        message={t('customerUser.deleteDialog.message', basicData)}
        confirm={deleteUser}
        abort={closeDialog}
        dangerous
        confirmLabel={t('common.button.delete')}
      />
    </div>
  );
};

export const CustomerUser: FC = () => {
  return (
    <CustomerUserPackagesFetcher.WAF>
      <CustomerUserContent />
    </CustomerUserPackagesFetcher.WAF>
  );
};
