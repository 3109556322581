import React, { createContext, FC, useCallback, useMemo, useState } from 'react';
import { IUserDownloadsResponse, useDownloadApi } from '../hooks/useDownloadApi';
import { logger } from '../helpers/logger';

interface IDownloadsContext {
  downloads: IUserDownloadsResponse | null;
  fetch: () => Promise<void>;
  isError: boolean;
}

const defaultDownloadsContext: IDownloadsContext = {
  downloads: null,
  fetch: async () => void 0,
  isError: false,
};

export const DownloadsContext = createContext<IDownloadsContext>(defaultDownloadsContext);

export const DownloadsProvider: FC = ({ children }) => {
  const { getDownloads } = useDownloadApi();
  const [downloads, setDownloads] = useState<IUserDownloadsResponse | null>(null);
  const [isError, setError] = useState(false);

  const fetch = useCallback(async () => {
    try {
      setError(false);
      const result = await getDownloads();
      setDownloads(result);
    } catch (e) {
      logger.error(e);
      setError(true);
    }
  }, [getDownloads]);

  const contextValue = useMemo(
    () => ({
      downloads,
      fetch,
      isError,
    }),
    [downloads, fetch, isError]
  );

  return <DownloadsContext.Provider value={contextValue}>{children}</DownloadsContext.Provider>;
};
