import React, { FC } from 'react';
import { CustomerProductPlanType } from '../../models/CustomerProductPlanType';
import { useDate } from '../../hooks/useDate';
import dayjs from 'dayjs';
import Tooltip from '@mui/material/Tooltip';
import { DateFormat, dateFormat } from '../../helpers/dateFormat';
import { useTranslation } from 'react-i18next';
import { Date } from '../Date/Date';

interface IProps {
  expiry?: number;
  type: CustomerProductPlanType;
}

export const LicenseExpiry: FC<IProps> = ({ expiry, type }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const date = useDate(expiry ?? 0);
  const hasExpired = date.isBefore(dayjs());
  if (!expiry) {
    return <span>-</span>;
  }
  if (type === CustomerProductPlanType.Perpetual) {
    return (
      <Tooltip title={t('component.licenseExpiry.nonExpiringPerpetual') as string}>
        <span>
          {t('component.licenseExpiry.updatesUntil', { date: dateFormat(date, DateFormat.DateMedium, language) })}
        </span>
      </Tooltip>
    );
  }
  return (
    <Tooltip title={<Date date={date} format={DateFormat.DateMedium} />}>
      <span>
        {hasExpired ? t('component.licenseExpiry.expired') : <Date date={date} format={DateFormat.Relative} />}
      </span>
    </Tooltip>
  );
};
