import { GridFilterOperator } from '@mui/x-data-grid';
import { getStringEqualsOperator } from './stringEqualsOperator';
import { t } from 'i18next';

export const getLessThanOperator = (): GridFilterOperator => ({
  label: t('filterOperator.lessThan'),
  value: 'lt',
  getApplyFilterFn: (filterItem) => (params) => {
    return Number(params.value) < Number(filterItem.value);
  },
  InputComponent: getStringEqualsOperator().InputComponent,
});
