// eslint-disable-next-line @typescript-eslint/ban-types
export const compareChanges = <Type extends {}>(before: Type, after: Type) => {
  let anythingChanged = false;
  const changes: Partial<Type> = {};

  for (const field of Object.keys(after) as Array<keyof Type>) {
    const valueBefore = before[field];
    const valueAfter = after[field];
    if (!valueBefore && !valueAfter) continue;

    if (valueAfter !== valueBefore) {
      anythingChanged = true;
      changes[field] = valueAfter;
    }
  }

  return {
    anythingChanged,
    changes,
  };
};
