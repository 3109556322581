import React, { FC, useCallback, useContext, useMemo } from 'react';
import { GridColumns, GridRowParams } from '@mui/x-data-grid';
import { getDateValueFormatter } from '../../valueFormatters/DateValueFormatter';
import { UrlQueryDataGrid } from '../UrlQueryDataGrid/UrlQueryDataGrid';
import { config } from '../../config';
import { ISupportTicket } from '../../hooks/useSupportApi';
import { useTranslation } from 'react-i18next';
import { AccountContext } from '../../contexts/AccountContext';
import { AccountType } from '../../types/AccountType';

interface IProps {
  tickets: ISupportTicket[];
}

export const TicketsTable: FC<IProps> = ({ tickets }) => {
  const { t } = useTranslation();
  const account = useContext(AccountContext);

  const columns = useMemo<GridColumns>(() => {
    return [
      {
        field: 'id',
        headerName: t('common.tableHeader.id'),
      },
      {
        field: 'subject',
        headerName: t('support.table.subjectHeader'),
        flex: 1,
      },
      {
        field: 'status',
        headerName: t('common.tableHeader.status'),
        width: 120,
      },
      {
        field: 'created_at',
        headerName: t('common.tableHeader.created'),
        width: 150,
        filterable: false,
        valueFormatter: getDateValueFormatter(),
      },
      {
        field: 'updated_at',
        headerName: t('common.tableHeader.updated'),
        width: 150,
        filterable: false,
        valueFormatter: getDateValueFormatter(),
      },
    ];
  }, []);

  const getRowLink = useCallback(
    (params: GridRowParams) => {
      let url = config.customerSupportTicketLink(params.row.id);
      if (account === AccountType.Admin) {
        url = config.adminSupportTicketLink(params.row.id);
      }
      return { url, external: true };
    },
    [account]
  );

  return (
    <UrlQueryDataGrid
      columns={columns}
      rows={tickets}
      disableExtendRowFullWidth={false}
      autoHeight={true}
      disableSelectionOnClick={true}
      getRowLink={getRowLink}
    />
  );
};
