import React, { useCallback, useContext, useEffect } from 'react';
import {
  IUserDownloadAddonAbstract,
  IUserEngineContent,
  IUserExample,
  useDownloadApi,
} from '../../hooks/useDownloadApi';
import { DownloadsProductAddons } from './DownloadProductAddons';
import { DownloadProductAddonsTable } from './DownloadProductAddonsTable';
import { createFetcher } from '../../contexts/Fetcher';
import { useParams } from 'react-router-dom';
import { UserRoutes } from '../../routers/UserRoutes';
import { PageHeader } from '../PageHeader/PageHeader';
import { useIntParams } from '../../hooks/useIntParams';
import { useTranslation } from 'react-i18next';
import { DownloadsContext } from '../../contexts/DownloadsContext';
import { AccountContext } from '../../contexts/AccountContext';
import { AccountType } from '../../types/AccountType';
import { CustomerRoutes } from '../../routers/CustomerRoutes';

const getContentMeta = (content: IUserDownloadAddonAbstract) => (content as IUserEngineContent).content;
const getExampleMeta = (content: IUserDownloadAddonAbstract) => (content as IUserExample).example;

interface IUrlParams {
  product: string;
  version: string;
}

const EngineContentFetcher = createFetcher<IUserDownloadAddonAbstract[]>(() => {
  const { product, version } = useParams<IUrlParams>();
  const { getEngineContent } = useDownloadApi();
  return useCallback(
    async () => (await getEngineContent(parseInt(product, 10), version)).userEngineContent,
    [product, version, getEngineContent]
  );
});
const ExamplesFetcher = createFetcher<IUserDownloadAddonAbstract[]>(() => {
  const { product, version } = useParams<IUrlParams>();
  const { getExamples } = useDownloadApi();
  return useCallback(
    async () => (await getExamples(parseInt(product, 10), version)).userExamples,
    [product, version, getExamples]
  );
});
const ToolsFetcher = createFetcher(() => {
  const { product, version } = useParams<IUrlParams>();
  const { getTools } = useDownloadApi();
  return useCallback(async () => await getTools(parseInt(product, 10), version), [product, version, getTools]);
});
const LensFetcher = createFetcher(() => {
  const { product } = useParams<IUrlParams>();
  const { getLens } = useDownloadApi();
  return useCallback(async () => await getLens(parseInt(product, 10)), [product, getLens]);
});

const getDownloadsProductAddonsPage = (addonType: 'engineContent' | 'demoProjects' | 'tools' | 'lens') => {
  const DownloadsProductAddonsPage = () => {
    const { downloads, fetch } = useContext(DownloadsContext);
    const accountType = useContext(AccountContext)!;
    const routes = accountType === AccountType.User ? UserRoutes : CustomerRoutes;

    const { product } = useIntParams<IUrlParams>();
    const { version } = useParams<IUrlParams>();
    const { t } = useTranslation();

    useEffect(() => {
      if (!downloads) fetch();
    }, []);

    const pageTitle =
      addonType === 'engineContent'
        ? t('downloads.engineContentTitle')
        : addonType === 'demoProjects'
        ? t('downloads.examplesTitle')
        : addonType === 'tools'
        ? t('downloads.toolsTitle')
        : addonType === 'lens'
        ? t('downloads.lensTitle')
        : addonType;

    const productName = downloads?.userDownloads?.find((item) => item.product.id === product)?.product?.name;

    return (
      <div>
        <PageHeader
          title={pageTitle}
          crumbs={[
            {
              url: routes.Downloads(),
              title: t('downloads.title'),
            },
            version
              ? {
                  url: routes.DownloadsProductVersion(product, version),
                  title: t('downloads.versionBreadcrumb', { version, productName }),
                }
              : {
                  url: routes.DownloadsProduct(product),
                  title: productName ?? '',
                },
          ]}
        />

        {addonType === 'engineContent' ? (
          <DownloadsProductAddons getMeta={getContentMeta} fetcher={EngineContentFetcher} />
        ) : null}
        {addonType === 'demoProjects' ? (
          <DownloadsProductAddons getMeta={getExampleMeta} fetcher={ExamplesFetcher} />
        ) : null}
        {addonType === 'tools' ? <DownloadProductAddonsTable fetcher={ToolsFetcher} /> : null}
        {addonType === 'lens' ? <DownloadProductAddonsTable fetcher={LensFetcher} /> : null}
      </div>
    );
  };
  return DownloadsProductAddonsPage;
};

export const DownloadsProductContentPage = getDownloadsProductAddonsPage('engineContent');
export const DownloadsProductExamplesPage = getDownloadsProductAddonsPage('demoProjects');
export const DownloadsProductToolsPage = getDownloadsProductAddonsPage('tools');
export const DownloadsProductLensPage = getDownloadsProductAddonsPage('lens');
