import React, { FC, useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { CustomerProductFetcher } from './CustomerProduct';
import { dateFormat, DateFormat } from '../../helpers/dateFormat';
import { useTranslation } from 'react-i18next';
import { Date } from '../Date/Date';
import { AdminRouteLink } from '../RouteLink/RouteLink';

interface IProps {
  licenseId?: string;
  closeDialog: () => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    keyCell: {
      overflowWrap: 'anywhere',
    },
  })
);

export const CustomerProductLicenseDetails: FC<IProps> = ({ licenseId, closeDialog }) => {
  const styles = useStyles();
  const { data } = useContext(CustomerProductFetcher.Context);
  const {
    t,
    i18n: { language },
  } = useTranslation();

  if (!data) return null;
  const license = data.product.licenses.find((l) => l.id === licenseId);

  return (
    <Dialog open={!!licenseId} onClose={closeDialog}>
      <DialogTitle>{t('customerProduct.detailsDialog.title')}</DialogTitle>
      {license ? (
        <DialogContent>
          <Typography variant="h6">{t('customerProduct.detailsDialog.resourcesHeader')}</Typography>

          <Table>
            <TableBody>
              <TableRow>
                <TableCell>{t('common.tableHeader.id')}</TableCell>
                <TableCell className={styles.keyCell}>{license.id}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>{t('common.tableHeader.expires')}</TableCell>
                <TableCell>
                  {license.expiry && license.plan.type === 'perpetual'
                    ? t('customerProduct.detailsDialog.perpetualExpiry', {
                        date: dateFormat(license.expiry, DateFormat.DateMedium, language),
                      })
                    : null}

                  {license.expiry && license.plan.type === 'subscription' ? (
                    <span>
                      <Date date={license.expiry} format={DateFormat.Relative} />
                      {' | '}
                      <Date date={license.expiry} format={DateFormat.DateFullWithTimeAndZone} />
                    </span>
                  ) : null}

                  {!license.expiry ? '-' : null}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>{t('customerProduct.detailsDialog.toolKeyLabel')}</TableCell>
                <TableCell>{license.name}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>{t('common.tableHeader.type')}</TableCell>
                <TableCell>{license.plan.type}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>{t('common.tableHeader.mode')}</TableCell>
                <TableCell>{license.offlineMode ? 'offline' : license.inUseOn ? 'online' : 'not in use'}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>{t('customerProduct.detailsDialog.suspendedLabel')}</TableCell>
                <TableCell>{license.status}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>{t('common.tableHeader.created')}</TableCell>
                <TableCell>
                  <Date date={license.created} format={DateFormat.DateFullWithTimeAndZone} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <Typography variant="h6">{t('customerProduct.detailsDialog.relationshipsHeader')}</Typography>

          <Table>
            <TableBody>
              <TableRow>
                <TableCell>{t('customerProduct.detailsDialog.userPackageIdLabel')}</TableCell>
                <TableCell>{license.userPackageId}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>{t('customerProduct.detailsDialog.customerProductIdLabel')}</TableCell>
                <TableCell>
                  {data.customerProduct.id}: {data.product.plan.name}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>{t('customerProduct.detailsDialog.packageLabel')}</TableCell>
                <TableCell>{license.packageName}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>{t('customerProduct.detailsDialog.machineIdLabel')}</TableCell>
                <TableCell>
                  {license.inUseOn ? (
                    <Link
                      component={AdminRouteLink}
                      route="CustomerMachine"
                      params={[data.customerProduct.customerId, license.assignedTo?.id ?? 0, license.inUseOn.id]}
                      underline="hover"
                      showFallback
                    >
                      {license.inUseOn.id}: {license.inUseOn.name}
                    </Link>
                  ) : (
                    '-'
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
      ) : (
        <DialogContent>{t('customerProduct.detailsDialog.notFound')}</DialogContent>
      )}
      <DialogActions>
        <Button onClick={closeDialog}>{t('common.dialog.close')}</Button>
      </DialogActions>
    </Dialog>
  );
};
