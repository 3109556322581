import React, { createContext, FC, useEffect, useMemo, useState } from 'react';
import { IBackendAccountData, ICustomerUserSignInResponse, ITokenResponse } from '../hooks/useAuth';

interface IBaseAccountData {
  tokenData: ITokenResponse;
  email: string;
}

interface IUserAccountData extends IBaseAccountData {
  accountData: IBackendAccountData;
}

type ICustomerAccountData = IBaseAccountData;
type IAdminAccountData = IBaseAccountData;

interface IAuthContext {
  user: IUserAccountData | null;
  setUser: (user: IUserAccountData | null) => void;

  customer: ICustomerAccountData | null;
  setCustomer: (customer: ICustomerAccountData | null) => void;

  admin: IAdminAccountData | null;
  setAdmin: (admin: IAdminAccountData | null) => void;

  auth2faCache: null | {
    email: string;
    refreshToken: string;
    userData?: ICustomerUserSignInResponse['userAccount'];
  };
  setAuth2faCache: (data: IAuthContext['auth2faCache']) => void;
}

const noop = () => null;

const defaultAuthContext: IAuthContext = {
  user: null,
  setUser: noop,
  customer: null,
  setCustomer: noop,
  admin: null,
  setAdmin: noop,
  auth2faCache: null,
  setAuth2faCache: noop,
};

export const AuthContext = createContext<IAuthContext>(defaultAuthContext);

const LOCAL_STORAGE_USER_DATA_KEY = 'userData';
const LOCAL_STORAGE_CUSTOMER_DATA_KEY = 'customerData';
const LOCAL_STORAGE_ADMIN_DATA_KEY = 'adminData';
const SESSION_STORAGE_2FA_CACHE_KEY = '2faCache';

const lsUserItem = localStorage.getItem(LOCAL_STORAGE_USER_DATA_KEY);
const lsCustomerItem = localStorage.getItem(LOCAL_STORAGE_CUSTOMER_DATA_KEY);
const lsAdminItem = localStorage.getItem(LOCAL_STORAGE_ADMIN_DATA_KEY);

const ss2faCacheItem = sessionStorage.getItem(SESSION_STORAGE_2FA_CACHE_KEY);

const updateLSData = (data: unknown | null, key: string) => {
  if (data) {
    localStorage.setItem(key, JSON.stringify(data));
  } else {
    localStorage.removeItem(key);
  }
};

const updateSSData = (data: unknown | null, key: string) => {
  if (data) {
    sessionStorage.setItem(key, JSON.stringify(data));
  } else {
    sessionStorage.removeItem(key);
  }
};

export const AuthProvider: FC = ({ children }) => {
  const [user, setUser] = useState<null | IUserAccountData>(lsUserItem ? JSON.parse(lsUserItem) : null);
  const [customer, setCustomer] = useState<null | ICustomerAccountData>(
    lsCustomerItem ? JSON.parse(lsCustomerItem) : null
  );
  const [admin, setAdmin] = useState<null | IAdminAccountData>(lsAdminItem ? JSON.parse(lsAdminItem) : null);
  const [auth2faCache, setAuth2faCache] = useState<IAuthContext['auth2faCache']>(
    ss2faCacheItem ? JSON.parse(ss2faCacheItem) : null
  );

  useEffect(() => {
    updateLSData(user, LOCAL_STORAGE_USER_DATA_KEY);
  }, [user]);

  useEffect(() => {
    updateLSData(customer, LOCAL_STORAGE_CUSTOMER_DATA_KEY);
  }, [customer]);

  useEffect(() => {
    updateLSData(admin, LOCAL_STORAGE_ADMIN_DATA_KEY);
  }, [admin]);

  useEffect(() => {
    updateSSData(auth2faCache, SESSION_STORAGE_2FA_CACHE_KEY);
  }, [auth2faCache]);

  const contextValue = useMemo(
    () => ({
      user,
      setUser,
      customer,
      setCustomer,
      admin,
      setAdmin,
      auth2faCache,
      setAuth2faCache,
    }),
    [user, customer, admin, auth2faCache]
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};
