import { useMemo, useState } from 'react';

export const useEnumState = <T>(initialValue: T, ...possibleValues: T[]): [T, ...Array<() => void>] => {
  const [value, setValue] = useState(initialValue);
  const setters = useMemo(() => {
    const out: Array<() => void> = [];
    for (const possibleValue of possibleValues) {
      out.push(() => setValue(possibleValue));
    }
    return out;
  }, []);
  return [value, ...setters];
};
