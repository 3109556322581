import React, { FC, useContext } from 'react';
import { TicketsTable } from '../SupportPage/TicketsTable';
import { CustomerTicketsFetcher } from './CustomerFetchers';
import { useAutoFetch } from '../../contexts/Fetcher';

export const CustomerTickets: FC = () => {
  const { data } = useContext(CustomerTicketsFetcher.Context);

  useAutoFetch(CustomerTicketsFetcher);

  if (!data) return null;
  return <TicketsTable tickets={data.tickets} />;
};
