import React, { FC } from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export const Licenses: FC = () => {
  return (
    <SvgIcon viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 5.72727C3 3.12502 5.15875 1 7.80231 1C10.4459 1 12.6046 3.12502 12.6046 5.72727C12.6046 7.05967 12.0346 8.26422 11.127 9.12502V14.8181C11.1255 15.0951 10.948 15.3636 10.6926 15.4772C10.4372 15.5908 10.1154 15.5454 9.90332 15.3636L7.80231 13.5908L5.7013 15.3636C5.48918 15.5454 5.1674 15.5908 4.91198 15.4772C4.65512 15.3636 4.47763 15.0951 4.47763 14.8181V9.12502C3.57 8.26422 3 7.05967 3 5.72727ZM7.80231 2.45455C9.64791 2.45455 11.127 3.91051 11.127 5.72727C11.127 7.54404 9.64791 9 7.80231 9C5.95671 9 4.47763 7.54404 4.47763 5.72727C4.47763 3.91051 5.95671 2.45455 7.80231 2.45455ZM7.80231 10.4545C7.15008 10.4545 6.52382 10.3253 5.95527 10.0909V13.2273L7.31746 12.0796C7.58299 11.8523 8.02164 11.8523 8.28716 12.0796L9.64935 13.2273V10.0909C9.08079 10.3253 8.45454 10.4545 7.80231 10.4545Z"
      />
    </SvgIcon>
  );
};

export const ProductFilled: FC = () => {
  return (
    <SvgIcon viewBox="0 0 16 16">
      <path d="M8 15L7.68508 15.5399C7.87968 15.6534 8.12032 15.6534 8.31492 15.5399L8 15ZM14 11.5L14.3149 12.0399C14.5069 11.9279 14.625 11.7223 14.625 11.5H14ZM14 4.5H14.625C14.625 4.27771 14.5069 4.07214 14.3149 3.96014L14 4.5ZM8 1L8.31492 0.460138C8.12032 0.346621 7.87968 0.346621 7.68508 0.460138L8 1ZM2 4.5L1.68508 3.96014C1.49307 4.07214 1.375 4.27771 1.375 4.5H2ZM2 11.5H1.375C1.375 11.7223 1.49307 11.9279 1.68508 12.0399L2 11.5ZM7.375 8V15H8.625V8H7.375ZM8.31492 15.5399L14.3149 12.0399L13.6851 10.9601L7.68508 14.4601L8.31492 15.5399ZM14.625 11.5V4.5H13.375V11.5H14.625ZM14.3149 3.96014L8.31492 0.460138L7.68508 1.53986L13.6851 5.03986L14.3149 3.96014ZM7.68508 0.460138L1.68508 3.96014L2.31492 5.03986L8.31492 1.53986L7.68508 0.460138ZM1.375 4.5V11.5H2.625V4.5H1.375ZM1.68508 12.0399L7.68508 15.5399L8.31492 14.4601L2.31492 10.9601L1.68508 12.0399ZM1.68508 5.03986L7.68508 8.53986L8.31492 7.46014L2.31492 3.96014L1.68508 5.03986ZM13.6851 3.96014L7.68508 7.46014L8.31492 8.53986L14.3149 5.03986L13.6851 3.96014Z" />
    </SvgIcon>
  );
};
