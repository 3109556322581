import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

interface IProps<QueryParams extends unknown> {
  from: string;
  queryParams: Array<keyof QueryParams>;
  to: (params: QueryParams) => string;
}

export const LegacyRedirector = <QueryParams extends unknown>(props: IProps<QueryParams>) => {
  const history = useHistory();

  const handleUrl = useCallback(() => {
    if (history.location.pathname === props.from) {
      const search = new URLSearchParams(history.location.search);
      const params = {} as QueryParams;

      for (const queryParam of props.queryParams) {
        if (typeof queryParam !== 'string' || !search.has(queryParam)) return;
        params[queryParam as keyof QueryParams] = search.get(queryParam)! as unknown as QueryParams[keyof QueryParams];
      }

      history.replace(props.to(params));
    }
  }, []);

  useEffect(() => {
    handleUrl();
    return history.listen(handleUrl);
  }, [handleUrl]);

  return null;
};
