import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import {
  DataGrid,
  DataGridProps,
  GridFilterModel,
  GridSortDirection,
  GridSortModel,
  GridApi,
  GridColumns,
  GridRowParams,
} from '@mui/x-data-grid';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { useQueryParam } from '../../hooks/useQueryParam';
import { config } from '../../config';
import { usePageSize } from '../../hooks/usePageSize';
import styled from '@mui/styles/styled';
import { useTranslation } from 'react-i18next';

const usePromiseResolver = <DataType,>() => {
  const init = () => {
    let resolve: (data: DataType) => void;
    const promise = new Promise<DataType>((res) => {
      resolve = res;
    });
    const out = {
      resolve: (data: DataType) => {
        out.resolved = true;
        resolve(data);
      },
      promise,
      resolved: false,
    };
    return out;
  };
  return useRef(init());
};

interface IProps {
  getRowLink?: (row: GridRowParams) => string | { url: string; external: boolean };
}

const ExternalLinkStyled = styled('a')({
  all: 'inherit',
  padding: 0,
  cursor: 'pointer',
});

const LinkStyled = styled(Link)({
  all: 'inherit',
  padding: 0,
  cursor: 'pointer',
});

interface IApiRefGrabberProps {
  apiPromiseRef: React.RefObject<{ resolve: (data: GridApi) => void; promise: Promise<GridApi>; resolved: boolean }>;
  api: GridApi;
}

const ApiRefGrabber: FC<IApiRefGrabberProps> = ({ apiPromiseRef, api, children }) => {
  useEffect(() => {
    if (apiPromiseRef.current && !apiPromiseRef.current.resolved) {
      apiPromiseRef.current.resolve(api);
    }
  }, []);
  return <>{children}</>;
};

export const UrlQueryDataGrid: FC<DataGridProps & IProps> = ({ getRowLink, ...props }) => {
  const history = useHistory();
  const location = useLocation();
  const isPageParam = !!useQueryParam('page');
  const pageParam = useQueryParam('page') ?? '1';
  const sortBy = useQueryParam('sortBy');
  const sortOrder = useQueryParam('sortOrder') as GridSortDirection;
  const filterField = useQueryParam('filterField');
  const filterOp = useQueryParam('filterOp');
  const filterVal = useQueryParam('filterVal');
  const page = parseInt(pageParam, 10) - 1;
  const pageSize = usePageSize();
  const highlight = useQueryParam('highlight');
  const { t } = useTranslation();

  const apiPromiseRef = usePromiseResolver<GridApi>();

  const handleHighlight = useCallback(async () => {
    if (!apiPromiseRef.current) return;

    if (highlight && !isPageParam) {
      const api = await apiPromiseRef.current.promise;
      const index = api.getRowIndex(parseInt(highlight, 10));
      if (index === -1) return;
      const newPageNum = Math.floor(index / pageSize);
      const search = new URLSearchParams(location.search);
      search.set('page', (newPageNum + 1).toString(10));
      search.set('pageSize', pageSize.toString(10));
      history.replace(`?${search}`);
    }
  }, [isPageParam, pageSize, highlight]);

  useEffect(() => {
    handleHighlight().then();
  }, [handleHighlight]);

  const onPageChange = useCallback(
    (newPageNum: number) => {
      const search = new URLSearchParams(location.search);
      search.set('page', (newPageNum + 1).toString(10));
      search.set('pageSize', pageSize.toString(10));
      history.push(`?${search}`);
    },
    [pageSize, location]
  );

  const onSortModelChange = useCallback(
    (newSortModel) => {
      const search = new URLSearchParams(location.search);
      search.delete('page');
      if (newSortModel.length === 0) {
        search.delete('sortBy');
        search.delete('sortOrder');
      } else {
        search.set('sortBy', newSortModel[0].field);
        search.set('sortOrder', newSortModel[0].sort);
      }
      history.push(`?${search}`);
    },
    [location.search]
  );

  const sortModel = useMemo<GridSortModel>(() => {
    if (sortBy && sortOrder) return [{ field: sortBy, sort: sortOrder }];
    return [];
  }, [sortBy, sortOrder]);

  const onFilterModelChange = useCallback(
    (newFilterModel) => {
      const search = new URLSearchParams(location.search);
      search.delete('page');

      // @todo remove following when it'll be possible to use both free text and column filters
      search.delete('text');

      if (newFilterModel.items.length === 0 /*|| typeof newFilterModel.items[0].value === 'undefined'*/) {
        search.delete('filterField');
        search.delete('filterOp');
        search.delete('filterVal');
      } else {
        search.set('filterField', newFilterModel.items[0].columnField);
        search.set('filterOp', newFilterModel.items[0].operatorValue);
        if (newFilterModel.items[0].value) {
          search.set('filterVal', newFilterModel.items[0].value);
        } else {
          search.set('filterVal', '');
        }
      }
      history.push(`?${search}`);
    },
    [location.search]
  );

  const filterModel = useMemo<GridFilterModel>(() => {
    if (filterField && filterOp) {
      return {
        items: [
          {
            columnField: filterField,
            operatorValue: filterOp,
            value: filterVal,
          },
        ],
      };
    }
    return { items: [] };
  }, [filterField, filterOp, filterVal]);

  const onPageSizeChange = useCallback((value: number) => {
    const search = new URLSearchParams(location.search);
    search.delete('page');
    search.set('pageSize', value.toString(10));
    history.push(`?${search}`);
  }, []);

  // following is a little hack to access grid api
  const columns = useMemo<GridColumns>(() => {
    return [
      {
        ...props.columns[0],
        renderCell: (params) => {
          return (
            <ApiRefGrabber apiPromiseRef={apiPromiseRef} api={params.api}>
              {props.columns[0].renderCell ? props.columns[0].renderCell(params) : params.formattedValue}
            </ApiRefGrabber>
          );
        },
      },
      ...props.columns.slice(1),
    ];
  }, [props.columns]);

  const columnsWrapped = useMemo<GridColumns>(() => {
    if (!getRowLink) return columns;
    return columns.map((column) => {
      return {
        ...column,
        renderCell: (params) => {
          const rowParams: GridRowParams = {
            row: params.row,
            id: params.id,
            columns: columns,
            getValue: params.getValue,
          };

          const rowLinkData = getRowLink(rowParams);
          const url = typeof rowLinkData === 'string' ? rowLinkData : rowLinkData.url;
          const external = typeof rowLinkData === 'string' ? false : rowLinkData.external;

          if (external) {
            return (
              <ExternalLinkStyled href={url} target="_blank" rel="noreferrer noopener">
                {column.renderCell ? column.renderCell(params) : params.formattedValue}
              </ExternalLinkStyled>
            );
          }
          return (
            <LinkStyled to={url}>{column.renderCell ? column.renderCell(params) : params.formattedValue}</LinkStyled>
          );
        },
      };
    });
  }, [getRowLink, columns]);

  return (
    <DataGrid
      {...props}
      columns={columnsWrapped}
      pageSize={pageSize}
      page={page}
      onPageChange={onPageChange}
      sortModel={sortModel}
      onSortModelChange={onSortModelChange}
      filterModel={filterModel}
      onFilterModelChange={onFilterModelChange}
      rowsPerPageOptions={config.rowsPerPageOptions}
      onPageSizeChange={onPageSizeChange}
      sortingOrder={['asc', 'desc']}
      selectionModel={highlight ? parseInt(highlight) : void 0}
      localeText={{
        noRowsLabel: t('dataGrid.noRowsLabel'),
        noResultsOverlayLabel: t('dataGrid.noResultsOverlayLabel'),
        errorOverlayDefaultLabel: t('dataGrid.errorOverlayDefaultLabel'),
        toolbarDensity: t('dataGrid.toolbarDensity'),
        toolbarDensityLabel: t('dataGrid.toolbarDensityLabel'),
        toolbarDensityCompact: t('dataGrid.toolbarDensityCompact'),
        toolbarDensityStandard: t('dataGrid.toolbarDensityStandard'),
        toolbarDensityComfortable: t('dataGrid.toolbarDensityComfortable'),
        toolbarColumns: t('dataGrid.toolbarColumns'),
        toolbarColumnsLabel: t('dataGrid.toolbarColumnsLabel'),
        toolbarFilters: t('dataGrid.toolbarFilters'),
        toolbarFiltersLabel: t('dataGrid.toolbarFiltersLabel'),
        toolbarFiltersTooltipHide: t('dataGrid.toolbarFiltersTooltipHide'),
        toolbarFiltersTooltipShow: t('dataGrid.toolbarFiltersTooltipShow'),
        toolbarFiltersTooltipActive: (count) => t('dataGrid.toolbarFiltersTooltipActive', { count }),
        toolbarExport: t('dataGrid.toolbarExport'),
        toolbarExportLabel: t('dataGrid.toolbarExportLabel'),
        toolbarExportCSV: t('dataGrid.toolbarExportCSV'),
        toolbarExportPrint: t('dataGrid.toolbarExportPrint'),
        columnsPanelTextFieldLabel: t('dataGrid.columnsPanelTextFieldLabel'),
        columnsPanelTextFieldPlaceholder: t('dataGrid.columnsPanelTextFieldPlaceholder'),
        columnsPanelDragIconLabel: t('dataGrid.columnsPanelDragIconLabel'),
        columnsPanelShowAllButton: t('dataGrid.columnsPanelShowAllButton'),
        columnsPanelHideAllButton: t('dataGrid.columnsPanelHideAllButton'),
        filterPanelAddFilter: t('dataGrid.filterPanelAddFilter'),
        filterPanelDeleteIconLabel: t('dataGrid.filterPanelDeleteIconLabel'),
        filterPanelOperators: t('dataGrid.filterPanelOperators'),
        filterPanelOperatorAnd: t('dataGrid.filterPanelOperatorAnd'),
        filterPanelOperatorOr: t('dataGrid.filterPanelOperatorOr'),
        filterPanelColumns: t('dataGrid.filterPanelColumns'),
        filterPanelInputLabel: t('dataGrid.filterPanelInputLabel'),
        filterPanelInputPlaceholder: t('dataGrid.filterPanelInputPlaceholder'),
        filterOperatorContains: t('dataGrid.filterOperatorContains'),
        filterOperatorEquals: t('dataGrid.filterOperatorEquals'),
        filterOperatorStartsWith: t('dataGrid.filterOperatorStartsWith'),
        filterOperatorEndsWith: t('dataGrid.filterOperatorEndsWith'),
        filterOperatorIs: t('dataGrid.filterOperatorIs'),
        filterOperatorNot: t('dataGrid.filterOperatorNot'),
        filterOperatorAfter: t('dataGrid.filterOperatorAfter'),
        filterOperatorOnOrAfter: t('dataGrid.filterOperatorOnOrAfter'),
        filterOperatorBefore: t('dataGrid.filterOperatorBefore'),
        filterOperatorOnOrBefore: t('dataGrid.filterOperatorOnOrBefore'),
        filterOperatorIsEmpty: t('dataGrid.filterOperatorIsEmpty'),
        filterOperatorIsNotEmpty: t('dataGrid.filterOperatorIsNotEmpty'),
        filterValueAny: t('dataGrid.filterValueAny'),
        filterValueTrue: t('dataGrid.filterValueTrue'),
        filterValueFalse: t('dataGrid.filterValueFalse'),
        columnMenuLabel: t('dataGrid.columnMenuLabel'),
        columnMenuShowColumns: t('dataGrid.columnMenuShowColumns'),
        columnMenuFilter: t('dataGrid.columnMenuFilter'),
        columnMenuHideColumn: t('dataGrid.columnMenuHideColumn'),
        columnMenuUnsort: t('dataGrid.columnMenuUnsort'),
        columnMenuSortAsc: t('dataGrid.columnMenuSortAsc'),
        columnMenuSortDesc: t('dataGrid.columnMenuSortDesc'),
        columnHeaderFiltersTooltipActive: (count) => t('dataGrid.columnHeaderFiltersTooltipActive', { count }),
        columnHeaderFiltersLabel: t('dataGrid.columnHeaderFiltersLabel'),
        columnHeaderSortIconLabel: t('dataGrid.columnHeaderSortIconLabel'),
        footerRowSelected: (count) => t('dataGrid.footerRowSelected', { count }),
        footerTotalRows: t('dataGrid.footerTotalRows'),
        footerTotalVisibleRows: (visibleCount, totalCount) =>
          t('dataGrid.footerTotalVisibleRows', { visibleCount, totalCount }),
        checkboxSelectionHeaderName: t('dataGrid.checkboxSelectionHeaderName'),
        booleanCellTrueLabel: t('dataGrid.booleanCellTrueLabel'),
        booleanCellFalseLabel: t('dataGrid.booleanCellFalseLabel'),
        actionsCellMore: t('dataGrid.actionsCellMore'),
        pinToLeft: t('dataGrid.pinToLeft'),
        pinToRight: t('dataGrid.pinToRight'),
        unpin: t('dataGrid.unpin'),
        treeDataGroupingHeaderName: t('dataGrid.treeDataGroupingHeaderName'),
        treeDataExpand: t('dataGrid.treeDataExpand'),
        treeDataCollapse: t('dataGrid.treeDataCollapse'),
        MuiTablePagination: {
          getItemAriaLabel: (type) => {
            switch (type) {
              case 'first':
                return t('dataGrid.MuiTablePagination.getItemAriaLabel', {
                  type: t('dataGrid.MuiTablePagination.pageTypeFirst'),
                });
              case 'last':
                return t('dataGrid.MuiTablePagination.getItemAriaLabel', {
                  type: t('dataGrid.MuiTablePagination.pageTypeLast'),
                });
              case 'next':
                return t('dataGrid.MuiTablePagination.getItemAriaLabel', {
                  type: t('dataGrid.MuiTablePagination.pageTypeNext'),
                });
              case 'previous':
                return t('dataGrid.MuiTablePagination.getItemAriaLabel', {
                  type: t('dataGrid.MuiTablePagination.pageTypePrevious'),
                });
            }
          },
          labelDisplayedRows: ({ from, to, count }) => {
            if (count === -1) {
              return t('dataGrid.MuiTablePagination.labelDisplayedRowsUnknown', { from, to });
            }
            return t('dataGrid.MuiTablePagination.labelDisplayedRows', { from, to, count });
          },
          labelRowsPerPage: t('dataGrid.MuiTablePagination.labelRowsPerPage'),
        },
      }}
    />
  );
};
