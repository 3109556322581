import React, { FC, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { createNoParamsServerSidePaginatedFetcher } from '../../contexts/Fetcher';
import { useEventsApi } from '../../hooks/useEventsApi';
import { PageHeader } from '../PageHeader/PageHeader';
import { useQueryParam } from '../../hooks/useQueryParam';
import { UrlQueryDataGrid } from '../UrlQueryDataGrid/UrlQueryDataGrid';
import { GridColumns, GridRowParams } from '@mui/x-data-grid';
import { getDateValueFormatter } from '../../valueFormatters/DateValueFormatter';
import { DateFormat } from '../../helpers/dateFormat';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/styles/useTheme';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Chart, registerables } from 'chart.js';
import { useHistory } from 'react-router-dom';
import { getGreaterThanOperator } from '../../filterOperators/greaterThanOperator';
import { getLessThanOperator } from '../../filterOperators/lessThanOperator';
import { getStringEqualsOperator } from '../../filterOperators/stringEqualsOperator';
import { getStringDiffersOperator } from '../../filterOperators/stringDiffersOperator';
import { getEnumIsFilterOperator, getEnumIsNotFilterOperator } from '../../filterOperators/enumFilterOperator';
import { getDateAfterFilterOperator, getDateBeforeFilterOperator } from '../../filterOperators/dateFilterOperators';
import { IQuickFiltersProps } from '../EasyFilter/QuickFilters';
import { EasyFilter } from '../EasyFilter/EasyFilter';
import { useTranslation } from 'react-i18next';

Chart.register(...registerables);

const ApiEventsFetcher = createNoParamsServerSidePaginatedFetcher(() => useEventsApi().getEvents);

const useStyles = makeStyles(() =>
  createStyles({
    canvas: {
      width: '100%',
      height: 300,
    },
  })
);

const RequestBodyRender: FC<{ body?: string }> = ({ body }) => {
  const payload = useMemo(() => {
    body = body || '';
    const tmp = body.startsWith('\u0001') ? body.substr(1) : body;
    try {
      return JSON.stringify(JSON.parse(tmp), null, 2);
    } catch (e) {
      return tmp;
    }
  }, []);

  return <pre>{payload}</pre>;
};

const ApiEventsContent: FC = () => {
  const { data } = useContext(ApiEventsFetcher.Context);
  const eventId = useQueryParam('event');
  const history = useHistory();
  const theme = useTheme();
  const { t } = useTranslation();

  const eventDetailsData =
    (eventId && data && data.data.find((event) => event.id === parseInt(eventId, 10))) || undefined;

  const quickFilters = useMemo<IQuickFiltersProps['quickFilters']>(
    () =>
      new Map([
        [
          t('events.quickFilter.failed'),
          {
            filterField: 'status',
            filterOp: 'gt',
            filterVal: '399',
          },
        ],
      ]),
    []
  );

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'ip',
        headerName: t('events.table.ipHeader'),
        width: 130,
        filterOperators: [getStringEqualsOperator(), getStringDiffersOperator()],
      },
      {
        field: 'accountId',
        headerName: t('events.table.accountHeader'),
        width: 120,
        filterOperators: [
          getStringEqualsOperator(),
          getStringDiffersOperator(),
          getGreaterThanOperator(),
          getLessThanOperator(),
        ],
      },
      {
        field: 'method',
        headerName: t('events.table.methodHeader'),
        filterOperators: [
          getEnumIsFilterOperator(['DELETE', 'GET', 'POST', 'PUT']),
          getEnumIsNotFilterOperator(['DELETE', 'GET', 'POST', 'PUT']),
        ],
      },
      {
        field: 'status',
        headerName: t('common.tableHeader.status'),
        filterOperators: [
          getStringEqualsOperator(),
          getStringDiffersOperator(),
          getLessThanOperator(),
          getGreaterThanOperator(),
        ],
      },
      {
        field: 'path',
        headerName: t('events.table.pathHeader'),
        flex: 1,
        filterOperators: [getStringEqualsOperator(), getStringDiffersOperator()],
      },
      {
        field: 'created',
        headerName: t('events.table.timestampHeader'),
        valueFormatter: getDateValueFormatter(DateFormat.DateWithFullTime),
        width: 180,
        filterOperators: [getDateBeforeFilterOperator(), getDateAfterFilterOperator()],
      },
      {
        field: 'responseTime',
        headerName: t('events.table.responseTimeHeader'),
        valueFormatter: (params) => `${params.value} ms`,
        width: 120,
        filterOperators: [getGreaterThanOperator(), getLessThanOperator()],
      },
    ],
    []
  );

  const canvasRef = useRef<HTMLCanvasElement>(null);

  const styles = useStyles();

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas || !data) return;
    const myCanvas = new Chart(canvas, {
      type: 'bar',
      data: {
        labels: data.data.map((event) => `[${event.method}] ${event.path}`),
        datasets: [
          {
            label: 'Response times',
            data: data.data.map((event) => event.responseTime),
            backgroundColor: theme.palette.primary.main,
          },
        ],
      },
      options: {
        aspectRatio: 4,
        scales: {
          y: {
            beginAtZero: true,
          },
          x: {
            display: false,
          },
        },
      },
    });

    return () => {
      myCanvas.destroy();
    };
  }, [data]);

  const getRowLink = useCallback((params: GridRowParams) => {
    const search = new URLSearchParams(history.location.search);
    search.set('event', params.id.toString());
    return `?${search}`;
  }, []);

  const onCloseDialog = useCallback(() => {
    const search = new URLSearchParams(history.location.search);
    search.delete('event');
    history.push(`?${search}`);
  }, []);

  if (!data) return null;
  return (
    <div>
      <canvas ref={canvasRef} className={styles.canvas} />

      <EasyFilter quickFilters={quickFilters} />

      <UrlQueryDataGrid
        columns={columns}
        rows={data.data}
        disableExtendRowFullWidth={false}
        autoHeight={true}
        disableSelectionOnClick={true}
        paginationMode={'server'}
        sortingMode={'server'}
        filterMode={'server'}
        getRowLink={getRowLink}
        rowCount={data.page.data.total}
      />
      <Dialog open={!!eventDetailsData} onClose={onCloseDialog}>
        <DialogTitle>{t('events.detailsDialog.title')}</DialogTitle>
        {eventDetailsData ? (
          <DialogContent>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>{t('events.table.pathHeader')}</TableCell>
                  <TableCell>
                    [{eventDetailsData.method}] {eventDetailsData.path}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t('events.table.accountHeader')}</TableCell>
                  <TableCell>
                    {eventDetailsData.accountId} ({eventDetailsData.role})
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Typography variant="h6">{t('events.detailsDialog.requestBody')}:</Typography>
            <RequestBodyRender body={eventDetailsData.requestBody} />
          </DialogContent>
        ) : null}
        <DialogActions>
          <Button onClick={onCloseDialog}>{t('common.dialog.close')}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const ApiEvents: FC = () => {
  const { t } = useTranslation();
  return (
    <ApiEventsFetcher.WAF>
      <PageHeader title={t('events.header')} />
      <ApiEventsContent />
    </ApiEventsFetcher.WAF>
  );
};
