import { useEffect, useRef } from 'react';

export const usePreviousValue = <T>(value: T) => {
  const prevValueRef = useRef<T>(value);

  useEffect(() => {
    return () => {
      prevValueRef.current = value;
    };
  }, [value]);

  return prevValueRef;
};
