import React, { FC, useCallback, useContext, useMemo } from 'react';
import { createFetcher } from '../../contexts/Fetcher';
import { useUsersApi } from '../../hooks/useUsersApi';
import { UrlQueryDataGrid } from '../UrlQueryDataGrid/UrlQueryDataGrid';
import { GridCellParams, GridColumns, GridRowData, GridRowParams } from '@mui/x-data-grid';
import { getDateValueFormatter } from '../../valueFormatters/DateValueFormatter';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { plural } from '../../helpers/plural';
import Refresh from '@mui/icons-material/Refresh';
import { NotificationContext } from '../../contexts/NotificationContext';
import { Link } from 'react-router-dom';
import { CustomerRoutes } from '../../routers/CustomerRoutes';
import { AddUserDialog } from '../EditUserDialog/AddUserDialog';
import { useQueryParam } from '../../hooks/useQueryParam';
import { PageHeader } from '../PageHeader/PageHeader';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { getEnumIsFilterOperator } from '../../filterOperators/enumFilterOperator';
import { getDateAfterFilterOperator, getDateBeforeFilterOperator } from '../../filterOperators/dateFilterOperators';
import { IQuickFiltersProps } from '../EasyFilter/QuickFilters';
import { EasyFilter } from '../EasyFilter/EasyFilter';
import { useFreeTextFilter } from '../../hooks/useFreeTextFilter';
import { groupUserPackages } from '../../helpers/groupUserPackages';
import styled from '@mui/styles/styled';
import { Colors } from '../../colors';
import { ICustomerUsersResponse } from '../../models/CustomerUsersResponse';
import { useDefaultSorting } from '../../hooks/useDefaultSorting';
import { useRouteWithQuery } from '../../hooks/useRouteWithQuery';
import { useCloseDialog } from '../../hooks/useCloseDialog';
import { useIsRoute } from '../../hooks/useIsRoute';
import { useTranslation } from 'react-i18next';
import { ConditionalTooltip } from '../ConditionalTooltip/ConditionalTooltip';

export const UsersFetcher = createFetcher(() => {
  const { getCustomerUsers } = useUsersApi();
  const product = useQueryParam('product');

  return useCallback(async () => {
    if (product) return await getCustomerUsers([], { product: parseInt(product, 10) });
    return await getCustomerUsers([]);
  }, [product]);
});

const useLicensesStyle = makeStyles((theme) =>
  createStyles({
    ul: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(1),
    },
  })
);

const NoLicenses = styled('span')(({ theme }) => ({
  color: theme.palette.text.disabled,
}));

const LicensesRender = (params: GridCellParams) => {
  const styles = useLicensesStyle();
  const { t } = useTranslation();

  const tooltipTitle = (
    <ul className={styles.ul}>
      {groupUserPackages(params.row.packages).map((pack) => {
        return (
          <li key={pack.package.id}>
            {pack.package.name}
            {' | '}
            {pack.plan.name}
            {' | '}
            {pack.userPackage.amount} {plural(pack.userPackage.amount, 'license')}
          </li>
        );
      })}
    </ul>
  );

  return (
    <div>
      {params.value === '0' ? (
        <NoLicenses>{t('users.table.noLicenses')}</NoLicenses>
      ) : (
        <Tooltip title={tooltipTitle}>
          <span>{t('users.table.licensesInTotal', { count: params.value })}</span>
        </Tooltip>
      )}
    </div>
  );
};

const StyledChip = styled(Chip)({
  '&.StyledChip-lime': {
    backgroundColor: Colors.Lime,
  },
  '&.StyledChip-orange': {
    backgroundColor: Colors.Orange,
  },
});

const StatusRender = (params: GridCellParams) => {
  const { reInviteUser } = useUsersApi();
  const { addNotification } = useContext(NotificationContext);
  const apiErrorHandler = useApiErrorHandler();
  const { t } = useTranslation();

  const onReInvite = useCallback(
    async (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      try {
        await reInviteUser([params.row.id], params.row.id);
        addNotification({
          message: t('users.reSentInvitation'),
          severity: 'success',
        });
      } catch (e) {
        apiErrorHandler(e);
      }
    },
    [reInviteUser]
  );
  const isVerified = params.value !== 'unverified';

  return (
    <ConditionalTooltip enabled={!isVerified} title={t('users.reSendInvitationTooltip') as string}>
      <StyledChip
        label={t(`common.accountStatus.${params.value}`)}
        color="primary"
        deleteIcon={<Refresh />}
        onDelete={isVerified ? void 0 : onReInvite}
        className={`StyledChip-${isVerified ? 'lime' : 'orange'}`}
      />
    </ConditionalTooltip>
  );
};

export const UsersTable: FC<{ users: ICustomerUsersResponse['users'] }> = ({ users }) => {
  const { t } = useTranslation();
  const rows = useMemo<GridRowData[]>(() => {
    return users.map((user) => ({
      id: user.user.id,
      name: `${user.user.firstName} ${user.user.lastName}`,
      licenses: user.packages
        .reduce((memo, current) => {
          return memo + current.userPackage.amount;
        }, 0)
        .toString(10),
      packages: user.packages,
      email: user.user.email,
      status: user.user.verified ? 'verified' : 'unverified',
      created: user.user.created,
      updated: user.user.updated,
    }));
  }, [users]);

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'name',
        headerName: t('common.tableHeader.name'),
        flex: 1,
      },
      {
        field: 'licenses',
        headerName: t('users.table.licensesHeader'),
        flex: 2,
        renderCell: (params) => <LicensesRender {...params} />,
      },
      {
        field: 'email',
        headerName: t('common.tableHeader.email'),
        flex: 2,
      },
      {
        field: 'status',
        headerName: t('common.tableHeader.status'),
        width: 150,
        renderCell: StatusRender,
        filterOperators: [
          getEnumIsFilterOperator({
            [t('common.accountStatus.verified')]: ['verified'],
            [t('common.accountStatus.unverified')]: ['unverified'],
          }),
        ],
      },
      {
        field: 'created',
        headerName: t('common.tableHeader.created'),
        width: 130,
        valueFormatter: getDateValueFormatter(),
        filterOperators: [getDateBeforeFilterOperator(), getDateAfterFilterOperator()],
      },
      {
        field: 'updated',
        headerName: t('common.tableHeader.updated'),
        width: 130,
        valueFormatter: getDateValueFormatter(),
        filterOperators: [getDateBeforeFilterOperator(), getDateAfterFilterOperator()],
      },
    ],
    []
  );

  const getRowLink = useCallback((param: GridRowParams) => {
    return CustomerRoutes.User(param.row.id);
  }, []);

  const filteredRows = useFreeTextFilter(rows, columns);

  return (
    <UrlQueryDataGrid
      columns={columns}
      rows={filteredRows}
      disableExtendRowFullWidth={false}
      autoHeight={true}
      getRowLink={getRowLink}
      disableSelectionOnClick={true}
    />
  );
};

const UsersContent: FC = () => {
  const { data } = useContext(UsersFetcher.Context);
  const { t } = useTranslation();

  const newUserOpen = useIsRoute(CustomerRoutes.NewUser);
  const closeDialog = useCloseDialog(CustomerRoutes.Users());

  const quickFilters = useMemo<IQuickFiltersProps['quickFilters']>(
    () =>
      new Map([
        [
          t('users.quickFilter.noLicense'),
          {
            filterField: 'licenses',
            filterOp: 'equals',
            filterVal: '0',
          },
        ],
        [
          t('common.quickFilter.unverified'),
          {
            filterField: 'status',
            filterOp: 'equals',
            filterVal: 'unverified',
          },
        ],
      ]),
    []
  );

  if (!data) return null;
  return (
    <div>
      <EasyFilter quickFilters={quickFilters} />
      <UsersTable users={data.users} />
      <AddUserDialog open={newUserOpen} closeDialog={closeDialog} />
    </div>
  );
};

export const Users = () => {
  const { t } = useTranslation();
  const preventRender = useDefaultSorting('created', 'desc');

  const newUserUrl = useRouteWithQuery(CustomerRoutes.NewUser, []);
  if (preventRender) return null;

  return (
    <UsersFetcher.WAF>
      <PageHeader title={t('users.header')}>
        <Button component={Link} to={newUserUrl} variant="contained" color="primary">
          {t('users.addButton')}
        </Button>
      </PageHeader>

      <UsersContent />
    </UsersFetcher.WAF>
  );
};
