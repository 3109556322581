import { useEffect, useRef } from 'react';
import { UseFormReturn, FieldValues } from 'react-hook-form';

export const useScrollToError = <T extends FieldValues = FieldValues>(form: UseFormReturn<T>) => {
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    const formEl = formRef.current;
    if (!formEl) return;

    const inputs = formEl.querySelectorAll('input[name],select[name],textarea[name]');

    for (const input of inputs) {
      const name = input.getAttribute('name');
      if (typeof name === 'string' && name in form.formState.errors) {
        input.scrollIntoView({ behavior: 'smooth' });
        return;
      }
    }
  }, [form.formState]);

  return {
    formRef,
  };
};
