import React, { FC } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';
import ChevronRight from '@mui/icons-material/ChevronRight';
import styled from '@mui/styles/styled';

interface IProps {
  title: string;
  crumbs?: Array<{
    url?: string;
    title: string;
  }>;
}

const LinkCrumb = styled(RouterLink)(({ theme }) => ({
  color: theme.palette.text.disabled,
  textDecoration: 'none',
}));

const PlainCrumb = styled('span')(({ theme }) => ({
  color: theme.palette.text.disabled,
}));

const CrumbsSeparator = styled(ChevronRight)(({ theme }) => ({
  fontSize: 20,
  marginRight: theme.spacing(1),
  marginLeft: theme.spacing(1),
  color: theme.palette.text.disabled,
}));

const StyledHeader = styled((props) => <Typography variant="h4" {...props} />)({
  lineHeight: 1.25,
  marginBottom: 0,
});

const StyledGridContainer = styled(Grid)(({ theme }) => ({
  paddingBottom: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const StyledButtonContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: theme.spacing(1),
}));

export const PageHeader: FC<IProps> = ({ title, children, crumbs = [] }) => {
  return (
    <StyledGridContainer container>
      <Grid item xs={12} md={children ? 6 : 12} lg={children ? 8 : 12}>
        <StyledHeader>
          {crumbs.map(({ url, title }) => (
            <span key={`${url}/${title}`}>
              {url ? <LinkCrumb to={url}>{title}</LinkCrumb> : null}
              {!url ? <PlainCrumb>{title}</PlainCrumb> : null}
              <CrumbsSeparator />
            </span>
          ))}
          {title}
        </StyledHeader>
      </Grid>
      {children ? (
        <StyledButtonContainer item xs={12} md={6} lg={4}>
          {children}
        </StyledButtonContainer>
      ) : null}
    </StyledGridContainer>
  );
};
