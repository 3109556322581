import { useApi } from './useApi';
import { useCallback } from 'react';
import { config } from '../config';
import { ApiRequest, useEndpoint, useSimpleEndpoint } from './api/apiEndpoint';
import { SupportEndpoints } from './api/endpoints/support';
import { IZendeskSSOResponse } from '../models/ZendeskSSOResponse';

export interface ISupportTicket {
  url: string;
  priority: string;
  status: string;
  assignee_id: number;
  id: number;
  allow_channelback: false;
  organization_id: number;
  subject: string;
  brand_id: number;
  allow_attachments: boolean;
  follower_ids: number[];
  sharing_agreement_ids: unknown[];
  collaborator_ids: number[];
  email_cc_ids: unknown[];
  raw_subject: string;
  is_public: boolean;
  has_incidents: boolean;
  created_at: string;
  tags: string[];
  group_id: number;
  type: string;
  requester_id: number;
  submitter_id: number;
  description: string;
  via: {
    source: {
      to: unknown;
      from: unknown;
    };
    channel: string;
  };
  followup_ids: unknown[];
  updated_at: string;
}

export interface ISupportListResponse {
  tickets: ISupportTicket[];
}

export const useSupportApi = () => {
  const { get } = useApi();

  const getTicketsList = useSimpleEndpoint(
    SupportEndpoints.Tickets,
    get as ApiRequest<typeof get, ISupportListResponse>
  );

  const zenDeskSSO = useEndpoint(
    (get, params: { url: string; brand_id?: string; timestamp?: string }) => get({ params }),
    SupportEndpoints.Zendesk,
    get as ApiRequest<typeof get, IZendeskSSOResponse>
  );

  const zenDeskAdmin = useCallback(() => zenDeskSSO([], { url: config.zenDeskAdminLink }), [zenDeskSSO]);

  return {
    getTicketsList,
    zenDeskAdmin,
    zenDeskSSO,
  };
};
