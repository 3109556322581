import { useCallback } from 'react';
import { useApi } from './useApi';
import { ICustomerProductsResponse } from '../models/CustomerProductsResponse';

enum ProductsEndpoints {
  List = '/products',
}

export const useCustomerProductsApi = () => {
  const { get } = useApi();

  const getProducts = useCallback(async () => {
    const result = await get<ICustomerProductsResponse>(ProductsEndpoints.List);
    return result.data;
  }, [get]);

  return {
    getProducts,
  };
};
