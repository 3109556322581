import styled from '@mui/styles/styled';
import React, { FC, useCallback, useRef } from 'react';
import Button from '@mui/material/Button';
import AttachFile from '@mui/icons-material/AttachFile';

const StyledLabel = styled('label')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
}));
const InvisibleInput = styled('input')({ display: 'none' });
const FileName = styled('div')({
  flex: '1',
});

interface IProps {
  value: File | null;
  onChange: (value: File | null) => void;
  accept?: string;
  label: string;
  changeLabel?: string;
  id: string;
}

export const FileInput: FC<IProps> = ({ value, onChange, accept, label, changeLabel, id }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const onFileChange = useCallback(async () => {
    const input = inputRef.current;
    if (!input || !input.files) return;
    const inputFile = input.files[0];
    if (inputFile) {
      const fileContentArrayBuffer = await inputFile.arrayBuffer();
      const fileCopy = new File([fileContentArrayBuffer], inputFile.name);

      onChange(fileCopy);
    } else {
      onChange(null);
    }
  }, [onChange]);

  return (
    <div>
      <InvisibleInput accept={accept} type="file" id={id} value={''} onChange={onFileChange} ref={inputRef} />

      <StyledLabel htmlFor={id}>
        <Button variant="outlined" component="span" startIcon={<AttachFile />}>
          {value ? changeLabel ?? label : label}
        </Button>
        <FileName>{value ? value.name : null}</FileName>
      </StyledLabel>
    </div>
  );
};
