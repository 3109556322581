import React, { FC, useCallback, useContext, useMemo } from 'react';
import { useAdminLiveApi } from '../../hooks/useAdminLiveApi';
import { composeFetcher, createFetcherWrapper } from '../../contexts/Fetcher';
import { LiveStack } from '../CloudLive/LiveStack';
import { LiveRegionContext, LiveRegionContextProvider } from '../../contexts/LiveRegionContext';
import { LiveRegionSelect } from '../LiveRegionSelect/LiveRegionSelect';
import { LiveStackContext } from '../../contexts/LiveChannels';
import { AdminRoutes } from '../../routers/AdminRoutes';
import { ILiveStack, LiveStackStatusTransitional } from '../../hooks/useLiveApi';
import { useHistory } from 'react-router-dom';
import { LiveRegionsFetcher } from '../../fetchers/LiveRegionsFetcher';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useLoopedInterval } from '../../hooks/useLoopedInterval';
import { LivePreviewFetcher } from '../CloudLive/CloudLive';

const AllLiveStacksFetcherWrapper = createFetcherWrapper(LivePreviewFetcher.Context, () => {
  const { getAllStacksList, getAllStacksListSilent } = useAdminLiveApi();
  const { region } = useContext(LiveRegionContext);

  return useCallback(
    async ({ silent = false }) => (region ? (silent ? getAllStacksListSilent : getAllStacksList)([region]) : []),
    [getAllStacksList, region]
  );
});

const AllLiveStacksFetcher = composeFetcher(LivePreviewFetcher.Context, AllLiveStacksFetcherWrapper);

const GlobalLiveRegionsFetcherWrapper = createFetcherWrapper(LiveRegionsFetcher.Context, () => {
  const { getRegions } = useAdminLiveApi();
  return useCallback(() => {
    return getRegions([]);
  }, [getRegions]);
});

const GlobalLiveRegionsFetcher = composeFetcher(LiveRegionsFetcher.Context, GlobalLiveRegionsFetcherWrapper);

const LiveStackWithContext: FC<{ stack: ILiveStack }> = ({ stack }) => {
  const history = useHistory();
  const { start, stop, getAccessData, getAccessDataSilent } = useAdminLiveApi();
  const { region } = useContext(LiveRegionContext);

  const match = stack.id.match(/(?<customerId>\d+)-(?<userId>\d+)-(?<cameraId>\d+)$/);
  const customerId = parseInt(match?.groups?.customerId ?? '0');

  const contextValue = useMemo(() => {
    if (!customerId) {
      return {};
    }
    return {
      remove: (stackId: string) => {
        history.push(AdminRoutes.CustomerDeleteTabItem(customerId, 'live', stackId));
      },
      start: (stackId: string) => start([customerId, region, stackId]),
      stop: (stackId: string) => stop([customerId, region, stackId]),
      getAccessData: (stackId: string, silent = false) =>
        (silent ? getAccessDataSilent : getAccessData)([customerId, region, stackId]),
    };
  }, [customerId, start, stop, getAccessData, region]);

  return (
    <LiveStackContext.Provider value={contextValue}>
      <LiveStack stack={stack} />
    </LiveStackContext.Provider>
  );
};

// Refresh once every 5 minutes when all stack are stable
const REFRESH_TIMEOUT_IDLE = 5 * 60 * 1000;
// Refresh once every 10 seconds when any stack is in transitional state
const REFRESH_TIMEOUT_TRANSITIONAL = 10 * 1000;

const AllLiveStacksContent: FC = () => {
  const { data, onRefresh } = useContext(AllLiveStacksFetcher.Context);

  const isAnyTransitionalState = (data ?? []).some((stack) => LiveStackStatusTransitional.includes(stack.status));

  const refreshTimeout = isAnyTransitionalState ? REFRESH_TIMEOUT_TRANSITIONAL : REFRESH_TIMEOUT_IDLE;

  useLoopedInterval({
    interval: refreshTimeout,
    callback: useCallback(() => onRefresh({ silent: true }), [onRefresh]),
  });

  if (!data) return null;

  return (
    <Grid container spacing={1}>
      {data.map((stack) => {
        return (
          <Grid item xs={12} md={6} lg={4} key={stack.id}>
            <LiveStackWithContext stack={stack} />
          </Grid>
        );
      })}
    </Grid>
  );
};

export const AllLiveStacks = () => {
  return (
    <GlobalLiveRegionsFetcher.WAF>
      <LiveRegionContextProvider>
        <LiveRegionSelect />
        <Box mt={1}>
          <AllLiveStacksFetcher.WAF>
            <AllLiveStacksContent />
          </AllLiveStacksFetcher.WAF>
        </Box>
      </LiveRegionContextProvider>
    </GlobalLiveRegionsFetcher.WAF>
  );
};
