import React, { FC } from 'react';
import { IRunningLiveStackDetails } from '../../hooks/useLiveApi';
import { useTranslation } from 'react-i18next';
import { ConnectionDetail } from './ConnectionDetail';
import FileUpload from '@mui/icons-material/FileUpload';
import FileDownload from '@mui/icons-material/FileDownload';
import Preview from '@mui/icons-material/Preview';

interface IProps {
  details: IRunningLiveStackDetails;
}

export const MediaStreams: FC<IProps> = ({ details }) => {
  const { t } = useTranslation();

  return (
    <div>
      <ConnectionDetail
        label={t('live.stack.streams.ingress')}
        ip={details.ingress.ip}
        port={details.ingress.port}
        Icon={FileUpload}
      />

      <ConnectionDetail
        label={t('live.stack.streams.egress')}
        ip={details.egress.ip}
        port={details.egress.port}
        Icon={FileDownload}
      />

      {details.preview ? (
        <ConnectionDetail
          label={t('live.stack.streams.preview')}
          ip={details.preview.ip}
          port={details.preview.port}
          Icon={Preview}
        />
      ) : null}
    </div>
  );
};
