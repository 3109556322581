import { createFetcher } from '../contexts/Fetcher';
import { AnalyticsActivityType, useAnalyticsApi } from '../hooks/useAnalyticsApi';
import { useCallback } from 'react';

export const ActivityFetcher = createFetcher(() => {
  const { getActivity } = useAnalyticsApi();
  return useCallback(
    ({ type, tfgFilter }: { type: AnalyticsActivityType; tfgFilter: boolean }) => {
      return getActivity([], type, tfgFilter);
    },
    [getActivity]
  );
});
