import React, { FC, useCallback, useContext } from 'react';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { UserFetcher } from '../User/User';
import { IUpdateUserPayload, useUsersApi } from '../../hooks/useUsersApi';
import { NotificationContext } from '../../contexts/NotificationContext';
import { EditUserForm } from './EditUserForm';
import { useTranslation } from 'react-i18next';
import { joiResolver } from '@hookform/resolvers/joi';
import { userFormSchema } from '../../formSchemas/userFormSchema';

interface IProps {
  open: boolean;
  closeDialog: () => void;
}

export const EditUserDialog: FC<IProps> = ({ open, closeDialog }) => {
  const { data, onDataUpdate } = useContext(UserFetcher.Context);
  const { updateUser } = useUsersApi();
  const { addNotification } = useContext(NotificationContext);
  const { t } = useTranslation();

  const { control, handleSubmit, formState } = useForm({
    resolver: joiResolver(userFormSchema),
  });

  const onSubmit = useCallback(
    async (fields: IUpdateUserPayload) => {
      if (!data) return;
      const payload: Partial<IUpdateUserPayload> = {};
      let anythingToUpdate = false;
      for (const [field, value] of Object.entries(fields) as [
        keyof IUpdateUserPayload,
        IUpdateUserPayload[keyof IUpdateUserPayload]
      ][]) {
        if (value !== data.user[field]) {
          anythingToUpdate = true;
          payload[field] = value;
        }
      }
      if (anythingToUpdate) {
        const newUserDetails = await updateUser([data.user.id], payload);
        onDataUpdate({
          ...data,
          user: newUserDetails,
        });
        closeDialog();
      } else {
        addNotification({
          severity: 'warning',
          message: t('editUserDialog.edit.nothingChanged'),
        });
      }
    },
    [data, updateUser, closeDialog]
  );

  if (!data) return null;
  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>{t('editUserDialog.edit.title')}</DialogTitle>
      <DialogContent>
        <EditUserForm onSubmit={onSubmit} user={data.user} {...{ control, handleSubmit, formState }} />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>{t('common.dialog.close')}</Button>
        <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
          {t('editUserDialog.edit.confirmButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
