import React, { FC, useCallback, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ProductToolForm } from './ProductToolForm';
import { IProductToolPayload, useProductToolsApi } from '../../hooks/useProductToolsApi';
import { CloseDialogFunc } from '../../hooks/useCloseDialog';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { ProductToolsFetcher } from '../../fetchers/ProductTools';
import { useTranslation } from 'react-i18next';

interface IProps {
  open: boolean;
  closeDialog: CloseDialogFunc;
}

export const AddProductToolDialog: FC<IProps> = ({ open, closeDialog }) => {
  const form = useForm();
  const handleError = useApiErrorHandler();
  const { addTool } = useProductToolsApi();
  const { onRefresh } = useContext(ProductToolsFetcher.Context);
  const { t } = useTranslation();

  useEffect(() => {
    form.reset({
      key: '',
      filter: false,
    });
  }, [open]);

  const onSubmit = useCallback(
    async (payload: IProductToolPayload) => {
      try {
        const newToolData = await addTool([], payload);
        await onRefresh();
        closeDialog(newToolData.id);
      } catch (e) {
        handleError(e);
      }
    },
    [addTool]
  );

  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>{t('adminProducts.tools.addDialog.title')}</DialogTitle>
      <DialogContent>
        <ProductToolForm form={form} onSubmit={onSubmit} />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>{t('common.dialog.close')}</Button>
        <Button variant="contained" color="primary" onClick={form.handleSubmit(onSubmit)}>
          {t('adminProducts.tools.addDialog.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
