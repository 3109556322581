import { GridApi, GridColumns, GridRowModel } from '@mui/x-data-grid';
import { useQueryParam } from './useQueryParam';

const flattify = (val: any): string => {
  if (typeof val === 'string') {
    return val;
  } else if (Array.isArray(val)) {
    return val.map((item) => flattify(item)).join(' ');
  } else if (val === null) {
    return '';
  } else if (typeof val === 'object') {
    return Object.values(val)
      .map((item) => flattify(item))
      .join(' ');
  } else {
    return JSON.stringify(val);
  }
};

export const useFreeTextFilter = <T extends GridRowModel>(rows: T[], columns: GridColumns): T[] => {
  const textFilter = useQueryParam('text');

  if (!textFilter) return rows;

  return rows.filter((row) => {
    const rowCopy = { ...row };
    for (const column of columns) {
      if (column.valueFormatter && column.field) {
        rowCopy[column.field as keyof T] =
          (column.valueFormatter({
            id: row.id,
            field: column.field,
            value: row[column.field],
            api: {} as GridApi,
          }) as T[keyof T]) ?? row[column.field as keyof T];
      }
    }
    const flatRow = flattify(rowCopy).toLowerCase();

    return flatRow.includes(textFilter.toLowerCase());
  });
};
