import React, { FC, useContext } from 'react';
import { PageHeader } from '../PageHeader/PageHeader';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { MachineFetcher } from '../../fetchers/MachineFetcher';
import { useIntParams } from '../../hooks/useIntParams';
import { DateFormat } from '../../helpers/dateFormat';
import { useTranslation } from 'react-i18next';
import { Date } from '../Date/Date';
import { AdminRouteLink } from '../RouteLink/RouteLink';

interface IUrlParams {
  customerId: string;
  userId: string;
  machineId: string;
}

const MachineDetailsContent: FC = () => {
  const { data } = useContext(MachineFetcher.Context);
  const { customerId } = useIntParams<IUrlParams>();
  const { t } = useTranslation();

  if (!data) return null;
  return (
    <div>
      <PageHeader title={t('machine.header')} />

      <Paper>
        <Box p={3}>
          <Typography variant="h4">{t('machine.resourcesHeader')}</Typography>

          <Box pt={1} pb={1}>
            <Divider />
          </Box>

          <Grid container spacing={3}>
            <Grid item xs={4} md={2}>
              {t('common.tableHeader.id')}
            </Grid>
            <Grid item xs={8} md={10}>
              {data.machine.id}
            </Grid>

            <Grid item xs={4} md={2}>
              {t('machine.fingerprintLabel')}
            </Grid>
            <Grid item xs={8} md={10}>
              {data.machine.fingerprint}
            </Grid>

            <Grid item xs={4} md={2}>
              {t('common.tableHeader.name')}
            </Grid>
            <Grid item xs={8} md={10}>
              {data.machine.name}
            </Grid>

            <Grid item xs={4} md={2}>
              {t('machine.ipLabel')}
            </Grid>
            <Grid item xs={8} md={10}>
              {data.machine.ip}
            </Grid>

            <Grid item xs={4} md={2}>
              {t('machine.hostnameLabel')}
            </Grid>
            <Grid item xs={8} md={10}>
              {data.machine.hostname}
            </Grid>

            <Grid item xs={4} md={2}>
              {t('machine.platformLabel')}
            </Grid>
            <Grid item xs={8} md={10}>
              {data.machine.platform}
            </Grid>

            <Grid item xs={4} md={2}>
              {t('machine.fileTokenLabel')}
            </Grid>
            <Grid item xs={8} md={10}>
              {data.machine.fileToken}
            </Grid>

            <Grid item xs={4} md={2}>
              {t('common.tableHeader.created')}
            </Grid>
            <Grid item xs={8} md={10}>
              <Date date={data.machine.created} format={DateFormat.DateWithFullTime} />
            </Grid>

            <Grid item xs={4} md={2}>
              {t('common.tableHeader.updated')}
            </Grid>
            <Grid item xs={8} md={10}>
              <Date date={data.machine.updated} format={DateFormat.DateWithFullTime} />
            </Grid>
          </Grid>
        </Box>
      </Paper>

      <Box marginTop={3}>
        <Paper>
          <Box p={3}>
            <Typography variant="h4">{t('machine.relationshipsHeader')}</Typography>

            <Box pt={1} pb={1}>
              <Divider />
            </Box>

            <Grid container spacing={3}>
              <Grid item xs={4} md={2}>
                {t('machine.userLabel')}
              </Grid>
              <Grid item xs={8} md={10}>
                <Link
                  component={AdminRouteLink}
                  route="CustomerUser"
                  params={[customerId, data.machine.userId]}
                  showFallback
                >
                  {data.machine.userId}
                </Link>
              </Grid>

              <Grid item xs={4} md={2}>
                {t('machine.userPackageLabel')}
              </Grid>
              <Grid item xs={8} md={10}>
                {data.machine.userPackageId}
              </Grid>

              <Grid item xs={4} md={2}>
                {t('machine.licensesLabel')}
              </Grid>
              <Grid item xs={8} md={10}>
                {data.licenses.map((license) => {
                  return (
                    <Typography key={license.id}>
                      <Link
                        component={AdminRouteLink}
                        route="CustomerProductLicense"
                        params={[customerId, license.customerProductId, license.key]}
                        showFallback
                      >
                        #{license.id} &ndash; {license.key}
                      </Link>
                    </Typography>
                  );
                })}
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    </div>
  );
};

export const MachineDetails: FC = () => {
  const { customerId, userId, machineId } = useIntParams<IUrlParams>();

  return (
    <MachineFetcher.WAF customerId={customerId} userId={userId} machineId={machineId}>
      <MachineDetailsContent />
    </MachineFetcher.WAF>
  );
};
