import React, { ChangeEvent, FC, useCallback, useContext, useEffect, useState } from 'react';
import {
  ILiveStack,
  LiveStackStatus,
  LiveStackStatusError,
  LiveStackStatusStable,
  LiveStackStatusTransitional,
} from '../../hooks/useLiveApi';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import styled from '@mui/system/styled';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import ChangeCircle from '@mui/icons-material/ChangeCircle';
import Circle from '@mui/icons-material/Circle';
import CircleOutlined from '@mui/icons-material/CircleOutlined';
import SettingsInputAntenna from '@mui/icons-material/SettingsInputAntenna';
import Shield from '@mui/icons-material/Shield';
import Security from '@mui/icons-material/Security';
import Warning from '@mui/icons-material/Warning';
import ElectricalServices from '@mui/icons-material/ElectricalServices';
import { LivePreviewFetcher } from './CloudLive';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { MediaStreams } from './MediaStreams';
import { LiveStackContext } from '../../contexts/LiveChannels';
import { useEnumState } from '../../hooks/useEnumState';
import { useTranslation } from 'react-i18next';
import { ILiveStackAccessResponse } from '../../models/LiveStackAccessResponse';
import { CopyTextField } from '../CopyTextField/CopyTextField';
import { useLoopedInterval } from '../../hooks/useLoopedInterval';
import { DataConnections } from './DataConnections';
import { LiveStackHeader } from './LiveStackHeader';

interface IProps {
  stack: ILiveStack;
}

const useStyles = makeStyles((theme) => {
  return createStyles({
    channelOverview: {
      display: 'flex',
      alignItems: 'center',
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      color: theme.palette.common.white,
      background: theme.palette.background.default,
    },
    channelStatus: {
      lineHeight: 1,
    },
    channelName: {
      flex: 1,
    },
    statusLabel: {
      fontSize: 8,
      position: 'absolute',
      bottom: 0,
      left: 27,
    },
    statusLabelActive: {
      left: 'auto',
      right: 27,
    },
  });
});

const StreamInputType = styled('div')({
  display: 'flex',
  alignItems: 'center',
});
const StreamInputTypeIcon = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
  marginRight: theme.spacing(1),
  lineHeight: 1,
}));
const StreamInputTypeText = styled((props) => <Typography {...props} variant="subtitle2" />)({
  flex: 1,
  lineHeight: 1,
});

const RdpWarning = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(1),
  gap: theme.spacing(1),
  color: theme.palette.warning.main,
}));

const ActiveStatus = styled(Circle)(({ theme }) => ({
  color: theme.palette.success.main,
}));

const StoppedStatus = styled(CircleOutlined)(({ theme }) => ({
  color: theme.palette.text.disabled,
}));

const ErrorStatus = styled(CircleOutlined)(({ theme }) => ({
  color: theme.palette.error.main,
}));

const ChangingStatus = styled(ChangeCircle)(({ theme }) => ({
  '@keyframes rotate': {
    '0%': {
      transform: 'rotate(360deg)',
    },
    '70%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(0deg)',
    },
  },
  animation: `rotate 2s infinite ease`,
  color: theme.palette.secondary.main,
}));

const NoMediaStreams = styled('div')(({ theme }) => ({
  color: theme.palette.text.disabled,
  marginTop: theme.spacing(1),
}));

const SecurityWarning = styled(Shield)(({ theme }) => ({
  color: theme.palette.warning.main,
}));

const GridDivider = () => (
  <Grid item xs={12}>
    <Divider />
  </Grid>
);

const StyledCopyTextField = styled(CopyTextField)(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

export const LiveStack: FC<IProps> = ({ stack }) => {
  const { onRefresh } = useContext(LivePreviewFetcher.Context);
  const apiErrorHandler = useApiErrorHandler();
  const styles = useStyles();
  const { start, stop, getAccessData, remove } = useContext(LiveStackContext);
  const [moreControls, showMoreControls, hideMoreControls] = useEnumState(false, true, false);
  const { t } = useTranslation();
  const [accessData, setAccessData] = useState<ILiveStackAccessResponse | null>(null);

  const switchActive = [LiveStackStatus.Running, LiveStackStatus.Starting].includes(stack.status);

  const isTransitionalStatus = LiveStackStatusTransitional.includes(stack.status);
  const isStableStatus = LiveStackStatusStable.includes(stack.status);
  const isErrorStatus = LiveStackStatusError.includes(stack.status);

  const checkRdpData = useCallback(
    async (silent = false) => {
      if (getAccessData && stack.status === LiveStackStatus.Running) {
        if (moreControls) {
          (async () => {
            const data = await getAccessData(stack.id, silent);
            setAccessData(data);
          })().then();
        }
      } else {
        setAccessData(null);
      }
    },
    [moreControls, getAccessData, stack.id, stack.status]
  );

  useEffect(() => {
    checkRdpData().then();
  }, [checkRdpData]);

  useLoopedInterval({
    enabled: moreControls && stack.status === LiveStackStatus.Running && !!accessData && !accessData.password,
    interval: 10000,
    callback: useCallback(() => checkRdpData(true), [checkRdpData]),
  });

  const onToggle = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      if (!stop || !start) return;
      const { id } = stack;
      try {
        if (stack.status === LiveStackStatus.Running && !event.target.checked) {
          await stop(id);
        } else if (stack.status === LiveStackStatus.Standby && event.target.checked) {
          await start(id);
        } else {
          return;
        }
        await onRefresh();
      } catch (e) {
        apiErrorHandler(e, 'Operation failed');
      }
    },
    [stack.id, stack.status]
  );

  const onDownloadRdpFile = useCallback(() => {
    if (!accessData) return;

    const fileContents = `auto connect:i:1
full address:s:${accessData.address}
username:s:${accessData.username}`;

    const blob = new Blob([fileContents], { type: 'application/x-rdp' });

    const temporaryAnchor = document.createElement('a');
    temporaryAnchor.href = URL.createObjectURL(blob);
    temporaryAnchor.download = `${stack.name}.rdp`;
    document.body.appendChild(temporaryAnchor);
    temporaryAnchor.click();
    document.body.removeChild(temporaryAnchor);
  }, [accessData, stack.name]);

  const onRemoveChannel = useCallback(() => {
    if (!remove) return;
    return remove(stack.id);
  }, [remove]);

  const isUnsafe = !stack.allowListCIDR || stack.allowListCIDR.match(/\/0$/);

  return (
    <Card>
      <div className={styles.channelOverview}>
        <div className={styles.channelName}>
          <LiveStackHeader stackName={stack.name} />
        </div>

        <div className={styles.channelStatus}>
          {stack.status === LiveStackStatus.Running ? <ActiveStatus /> : null}
          {stack.status === LiveStackStatus.Standby ? <StoppedStatus /> : null}
          {isErrorStatus ? <ErrorStatus /> : null}
          {isTransitionalStatus ? <ChangingStatus /> : null}
        </div>
      </div>

      <Box p={2}>
        <Grid container spacing={1}>
          <Grid item xs={8}>
            {start && stop ? (
              <Switch disabled={!isStableStatus} checked={switchActive} onChange={onToggle} color="secondary" />
            ) : null}
            {stack.status}
          </Grid>
          <Grid item xs={4} textAlign="right">
            {isStableStatus ? null : t('live.stack.pleaseWait')}
          </Grid>

          <GridDivider />

          <Grid item xs={12}>
            <StreamInputType>
              <StreamInputTypeIcon>{isUnsafe ? <SecurityWarning /> : <Security />}</StreamInputTypeIcon>
              <StreamInputTypeText>{t('live.stack.allowListCIDR')}</StreamInputTypeText>
              <span>{stack.allowListCIDR}</span>
            </StreamInputType>
          </Grid>

          <GridDivider />

          <Grid item xs={12}>
            <StreamInputType>
              <StreamInputTypeIcon>
                <SettingsInputAntenna />
              </StreamInputTypeIcon>
              <StreamInputTypeText>{t('live.stack.streams.title')}</StreamInputTypeText>
            </StreamInputType>

            {stack.details ? (
              <MediaStreams details={stack.details} />
            ) : (
              <NoMediaStreams>{t('live.stack.streams.noStreams')}</NoMediaStreams>
            )}
          </Grid>

          <GridDivider />

          <Grid item xs={12}>
            <StreamInputType>
              <StreamInputTypeIcon>
                <ElectricalServices />
              </StreamInputTypeIcon>
              <StreamInputTypeText>{t('live.stack.dataConnections.title')}</StreamInputTypeText>
            </StreamInputType>
            {stack.details ? (
              <DataConnections details={stack.details} />
            ) : (
              <NoMediaStreams>{t('live.stack.dataConnections.noConnections')}</NoMediaStreams>
            )}
          </Grid>

          {(getAccessData && stack.status === LiveStackStatus.Running) || remove ? (
            <>
              <GridDivider />

              <Grid item xs={12}>
                <Button onClick={moreControls ? hideMoreControls : showMoreControls} variant="text" fullWidth>
                  {moreControls ? t('live.preview.lessControls') : t('live.preview.moreControls')}
                </Button>

                {moreControls && accessData ? (
                  <div>
                    <StyledCopyTextField label="Address" value={accessData.address} />
                    <StyledCopyTextField label="Username" value={accessData.username} />
                    {accessData.password ? (
                      <StyledCopyTextField label="Password" value={accessData.password} confidential />
                    ) : (
                      <RdpWarning>
                        <Warning />
                        {t('live.stack.rdp.passwordNotReady')}
                      </RdpWarning>
                    )}

                    <Box mt={2}>
                      <Button variant="contained" color="primary" size="small" onClick={onDownloadRdpFile}>
                        {t('live.preview.downloadRdpButton')}
                      </Button>
                    </Box>
                  </div>
                ) : null}

                {moreControls && remove ? (
                  <Box textAlign="right">
                    <Button
                      size="small"
                      onClick={onRemoveChannel}
                      disabled={stack.status === LiveStackStatus.Destroying}
                      variant="text"
                      color="error"
                    >
                      {t('live.preview.deleteButton')}
                    </Button>
                  </Box>
                ) : null}
              </Grid>
            </>
          ) : null}
        </Grid>
      </Box>
    </Card>
  );
};
