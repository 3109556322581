import { useCallback, useEffect, useMemo, useState } from 'react';

export const useLocalStorage = <T>(key: string, initialValue: T) => {
  const serialize = useCallback((value: T): string => {
    return JSON.stringify(value);
  }, []);

  const deserialize = useCallback((value: string): T => {
    try {
      return JSON.parse(value);
    } catch (e) {
      return initialValue;
    }
  }, []);

  const [value, setValue] = useState(window.localStorage.getItem(key) ?? serialize(initialValue));

  const updateValue = useCallback(
    (value: T | undefined) => {
      if (typeof value === 'undefined') {
        window.localStorage.removeItem(key);
        setValue(serialize(initialValue));
      } else {
        const serialized = serialize(value);
        window.localStorage.setItem(key, serialized);
        setValue(serialized);
      }
    },
    [serialize]
  );

  useEffect(() => {
    setValue(window.localStorage.getItem(key) ?? serialize(initialValue));
  }, [key]);

  const deserializedValue = useMemo(() => deserialize(value), [value, deserialize]);

  return [deserializedValue, updateValue] as [T, (value: T) => void];
};
