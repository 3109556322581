import dayjs, { Dayjs } from 'dayjs';
import { getDateObject } from './dateFormat';
import { config } from '../config';

export const getExpireSoonThreshold = (soon = config.expiresSoonDays) => {
  return dayjs()
    .add(soon + 1, 'days')
    .set('hours', 0)
    .set('minutes', 0)
    .set('seconds', 0)
    .set('milliseconds', 0);
};

export const expiresSoon = (date: string | number | Dayjs, soon = config.expiresSoonDays) => {
  const dateObj = getDateObject(date);
  if (dateObj.isBefore(dayjs())) return false;
  return dateObj.isBefore(getExpireSoonThreshold(soon));
};
