import React, { FC } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import styled from '@mui/styles/styled';
import { useTranslation } from 'react-i18next';

const StyledErrorIcon = styled(ErrorOutline)({
  fontSize: 64,
});

export const UnexpectedError: FC = () => {
  const { t } = useTranslation();

  return (
    <Paper>
      <Box p={3}>
        <StyledErrorIcon />
        <div>
          <Typography variant="h4">{t('unexpectedError.title')}</Typography>
          <Typography paragraph>{t('unexpectedError.message')}</Typography>
        </div>
      </Box>
    </Paper>
  );
};
