import React, { FC, useContext } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { IUserDownloadFilesResponse } from '../../hooks/useDownloadApi';
import { SingleDownloadProductTool } from './SingleDownloadProductTool';
import { IFetcher } from '../../contexts/Fetcher';
import { useTranslation } from 'react-i18next';

interface IProps {
  fetcher: IFetcher<IUserDownloadFilesResponse>;
}

const DownloadProductAddonsTableContent: FC<IProps> = ({ fetcher }) => {
  const { data } = useContext(fetcher.Context);
  const { t } = useTranslation();

  if (!data) return null;
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t('downloads.toolsTable.modifiedHeader')}</TableCell>
          <TableCell>{t('downloads.toolsTable.fileNameHeader')}</TableCell>
          <TableCell>{t('downloads.toolsTable.sizeHeader')}</TableCell>
          <TableCell>{t('downloads.toolsTable.downloadHeader')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.s3Files.map((file) => {
          return <SingleDownloadProductTool key={file.Key} file={file} />;
        })}
      </TableBody>
    </Table>
  );
};

export const DownloadProductAddonsTable: FC<IProps> = ({ fetcher }) => {
  const Wrapper = fetcher.WAF;
  return (
    <Wrapper>
      <DownloadProductAddonsTableContent fetcher={fetcher} />
    </Wrapper>
  );
};
