import { useApi } from './useApi';
import { useCallback } from 'react';
import { IProductDetails } from '../models/ProductDetails';

enum DownloadEndpoints {
  Download = '/get',
  GetDownloads = '/downloads',
  GetEngineContent = '/downloads/content',
  GetExamples = '/downloads/examples',
  GetTools = '/downloads/tools',
  GetLens = '/downloads/lens',
}

interface IDownloadResponse {
  url: string;
}

interface IManifestFileVersion {
  forceUpdate: boolean;
  version: string;
  released: number;
  changelog: string;
  filePath: string;
  author: number;
}

export interface IAwsFileMetadata {
  Size: number;
  StorageClass: string;
  LastModified: string;
  ETag: string;
  Key: string;
}

export interface IUserDownloadsVersionInstaller {
  manifestFileVersion: IManifestFileVersion;
  file?: IAwsFileMetadata;
  lastObject: boolean;
  latest: boolean;
  capexIncompatibility: boolean;
}

export interface IUserDownloadsVersion {
  installers: IUserDownloadsVersionInstaller[];
  tools: boolean;
  content: boolean;
  examples: boolean;
  version: string;
  lens: boolean;
}

interface IUserDownloads {
  versions: IUserDownloadsVersion[];
  product: IProductDetails;
}

interface IUserDownloadsManifestFileProductVersionInstaller {
  released: number;
  author: number;
  version: string;
  forceUpdate: boolean;
  filePath: string;
  changelog: string;
}

interface IUserDownloadsManifestFileProductVersion {
  updates: never[];
  version: string;
  installer: IUserDownloadsManifestFileProductVersionInstaller[];
}

interface IUserDownloadsManifestFileProduct {
  id: string;
  docsPath: string;
  versions: IUserDownloadsManifestFileProductVersion[];
  projectPath: string;
}

interface IUserDownloadsManifestFileCoreStatus {
  author: number;
  services: Record<string, boolean>;
  created: number;
}

interface IUserDownloadsManifestFileCore {
  status: IUserDownloadsManifestFileCoreStatus[];
  pin: number;
}

interface IUserDownloadsManifestFile {
  products: IUserDownloadsManifestFileProduct[];
  core: IUserDownloadsManifestFileCore;
}

export interface IUserDownloadsResponse {
  query: unknown;
  userDownloads: IUserDownloads[];
  manifestFile: IUserDownloadsManifestFile;
}

export interface IUserDownloadAddonMetadata {
  versions: string[];
  description: string;
  products: number[];
  title: string;
  image: string;
  zipFile: string;
}

export interface IUserDownloadAddonAbstract {
  lastObject: boolean;
  file: IAwsFileMetadata;
  image: string;
}

export interface IUserEngineContent extends IUserDownloadAddonAbstract {
  content: IUserDownloadAddonMetadata;
}

export interface IUserEngineContentResponse {
  userEngineContent: IUserEngineContent[];
}

export interface IUserExample extends IUserDownloadAddonAbstract {
  example: IUserDownloadAddonMetadata;
}

export interface IUserExamplesResponse {
  userExamples: IUserExample[];
}

export interface IUserDownloadFilesResponse {
  s3Files: IAwsFileMetadata[];
  version: string;
}

export const useDownloadApi = () => {
  const { get } = useApi();

  const download = useCallback(
    async (fileName: string) => {
      const result = await get<IDownloadResponse>(`${DownloadEndpoints.Download}`, {
        params: { fileName },
      });
      return result.data;
    },
    [get]
  );

  const getDownloads = useCallback(async () => {
    const result = await get<IUserDownloadsResponse>(DownloadEndpoints.GetDownloads);
    return result.data;
  }, [get]);

  const getEngineContent = useCallback(
    async (productId: number, version: string) => {
      const result = await get<IUserEngineContentResponse>(DownloadEndpoints.GetEngineContent, {
        params: { productId, version },
      });
      return result.data;
    },
    [get]
  );

  const getExamples = useCallback(
    async (productId: number, version: string) => {
      const result = await get<IUserExamplesResponse>(DownloadEndpoints.GetExamples, {
        params: { productId, version },
      });
      return result.data;
    },
    [get]
  );

  const getTools = useCallback(
    async (productId: number, version: string) => {
      const result = await get<IUserDownloadFilesResponse>(DownloadEndpoints.GetTools, {
        params: { productId, version },
      });
      return result.data;
    },
    [get]
  );

  const getLens = useCallback(
    async (productId: number) => {
      const result = await get<IUserDownloadFilesResponse>(DownloadEndpoints.GetLens, {
        params: { productId },
      });
      return result.data;
    },
    [get]
  );

  return {
    download,
    getDownloads,
    getEngineContent,
    getExamples,
    getTools,
    getLens,
  };
};
