import React, { FC } from 'react';
import Typography from '@mui/material/Typography';
import styled from '@mui/system/styled';

const ProfileLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
}));

const ProfileValue = styled(Typography)({
  overflowWrap: 'break-word',
});

interface IProps {
  label: string;
}

export const AccountSingleData: FC<IProps> = ({ children, label }) => {
  return (
    <>
      <ProfileLabel variant="caption">{label}</ProfileLabel>
      <ProfileValue variant="body1">{children || '-'}</ProfileValue>
    </>
  );
};
