import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { FieldValues, useForm } from 'react-hook-form';
import { AuthErrors, useAuth } from '../../hooks/useAuth';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { GuestRoutes } from '../../routers/GuestRouter';
import { NotificationContext } from '../../contexts/NotificationContext';
import { AccountContext } from '../../contexts/AccountContext';
import { TextInputController } from '../InputControllers/TextInputController';
import { useQueryParam } from '../../hooks/useQueryParam';
import { useAccount } from '../../hooks/useAccount';
import { PasswordInputController } from '../InputControllers/PasswordInputController';
import { AuthDialog } from '../AuthDialog/AuthDialog';
import { AuthGoBack } from '../AuthDialog/AuthGoBack';
import { useTranslation } from 'react-i18next';
import { MountObserver } from '../MountObserver/MountObserver';
import { useRedirectAfterAuth } from '../../hooks/useRedirectAfterAuth';
import { useRouteWithQuery } from '../../hooks/useRouteWithQuery';

export const AuthPassword: FC = () => {
  const { addNotification, notifications, dismissNotification } = useContext(NotificationContext);
  const accountType = useContext(AccountContext)!;
  const { authenticate } = useAuth();
  const { search } = useLocation();
  const history = useHistory();
  const email = useQueryParam('email');
  const { t } = useTranslation();
  const [passwordFieldMounted, setPasswordFieldMounted] = useState(false);
  useRedirectAfterAuth();
  const secondFactorRedirectUrl = useRouteWithQuery(GuestRoutes.SecondFactor, [accountType], {}, true);

  const { account } = useAccount(accountType);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setFocus,
  } = useForm<FieldValues>({
    defaultValues: {
      email,
      password: '',
    },
  });

  const missingEmailFallback = useCallback(() => {
    history.replace(GuestRoutes.SignIn());
  }, []);

  const onSubmit = useCallback(
    async (data) => {
      if (!email) return missingEmailFallback();
      const result = await authenticate(email, data.password);
      if (result === AuthErrors.SecondFactorNeeded) {
        history.replace(secondFactorRedirectUrl);
      } else if (result === AuthErrors.Unauthorized) {
        addNotification({
          message: t('auth.signIn.errors.unauthorized'),
          severity: 'error',
        });
      } else if (result === AuthErrors.Unexpected) {
        addNotification({
          message: t('auth.signIn.errors.unexpected'),
          severity: 'error',
        });
      }
    },
    [email, missingEmailFallback]
  );

  useEffect(() => {
    if (account) {
      const foundNotifications = notifications.filter((notification) => {
        return notification.message === t('auth.signIn.errors.unauthorized');
      });
      foundNotifications.forEach((notification) => {
        dismissNotification(notification);
      });
    }
  }, [account, notifications, dismissNotification]);

  useEffect(() => {
    if (!email) return missingEmailFallback();
  }, [email, missingEmailFallback]);

  useEffect(() => {
    if (passwordFieldMounted) setFocus('password');
  }, [passwordFieldMounted]);

  return (
    <AuthDialog
      title={t('auth.signIn.encouragement', { accountType: t(`auth.accountType.${accountType}`) })}
      onSubmit={handleSubmit(onSubmit)}
      actions={[
        <AuthGoBack key="goBack" to={`${GuestRoutes.SignIn()}${search}`} />,
        <Button key="signIn" variant="contained" color="primary" type="submit">
          {t('auth.signIn.submitButton')}
        </Button>,
      ]}
    >
      <TextInputController
        control={control}
        errors={errors}
        label={t('auth.inputs.email.label')}
        name="email"
        disabled
      />

      <MountObserver onChange={setPasswordFieldMounted}>
        <PasswordInputController
          control={control}
          errors={errors}
          label={t('auth.inputs.password.label')}
          name="password"
          rules={{ required: true }}
          InputProps={{
            autoComplete: 'current-password',
          }}
        />
      </MountObserver>

      <Box textAlign="right">
        <Button
          component={Link}
          to={`${GuestRoutes.RecoverAccount(accountType)}${search}`}
          variant="text"
          color="secondary"
          size="small"
        >
          {t('auth.signIn.forgotPassword')}
        </Button>
      </Box>
    </AuthDialog>
  );
};
