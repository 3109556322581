import React, { FC } from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import styled from '@mui/styles/styled';
import useTheme from '@mui/styles/useTheme';
import { config } from '../../config';

interface IProps {
  title: string | React.ReactNode;
  actions: React.ReactNode | React.ReactNode[];
  onSubmit?: React.FormEventHandler;
}

const Wrapper: FC<{ onSubmit?: React.FormEventHandler }> = ({ onSubmit, children }) => {
  if (onSubmit) return <form onSubmit={onSubmit}>{children}</form>;
  return <>{children}</>;
};

const Logo = styled('img')({
  width: '100%',
  maxWidth: '318px',
});

export const AuthDialog: FC<IProps> = ({ title, children, actions, onSubmit }) => {
  const theme = useTheme();

  return (
    <Dialog
      open
      maxWidth="md"
      hideBackdrop
      fullWidth
      PaperProps={{
        style: { backgroundColor: theme.palette.background.default, maxWidth: 640 },
        elevation: 0,
      }}
    >
      <Wrapper onSubmit={onSubmit}>
        <DialogContent>
          <Box textAlign="center">
            <Logo src={config.verticalLogo} />
            <Typography variant={'h5'} textAlign="center" marginTop={6}>
              {title}
            </Typography>
          </Box>

          {children}
        </DialogContent>

        <DialogActions style={{ gap: 24 }}>{actions}</DialogActions>
      </Wrapper>
    </Dialog>
  );
};
