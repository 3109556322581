import React, { FC, useMemo } from 'react';
import { ICustomerInvoice } from '../../models/CustomerInvoice';
import { GridColumns, GridRowData } from '@mui/x-data-grid';
import { getDateValueFormatter } from '../../valueFormatters/DateValueFormatter';
import { getDateAfterFilterOperator, getDateBeforeFilterOperator } from '../../filterOperators/dateFilterOperators';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import Visibility from '@mui/icons-material/Visibility';
import { getEnumIsFilterOperator, getEnumIsNotFilterOperator } from '../../filterOperators/enumFilterOperator';
import { UrlQueryDataGrid } from '../UrlQueryDataGrid/UrlQueryDataGrid';
import { useTranslation } from 'react-i18next';

interface IProps {
  invoices: ICustomerInvoice[];
}

const statuses = {
  Pending: ['open', 'draft'],
  Paid: 'paid',
};
const statusesInv = Object.entries(statuses).reduce<Record<string, string>>((memo, [key, value]) => {
  if (Array.isArray(value)) {
    for (const singleValue of value) {
      memo[singleValue] = key;
    }
  } else {
    memo[value] = key;
  }
  return memo;
}, {});

export const InvoicesTable: FC<IProps> = ({ invoices }) => {
  const { t } = useTranslation();

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'date',
        headerName: t('invoices.table.dateHeader'),
        width: 130,
        valueFormatter: getDateValueFormatter(),
        filterOperators: [getDateBeforeFilterOperator(), getDateAfterFilterOperator()],
      },
      {
        field: 'description',
        headerName: t('invoices.table.descriptionHeader'),
        flex: 1,
      },
      {
        field: 'amount',
        headerName: t('invoices.table.amountHeader'),
        width: 180,
        valueFormatter: (params) => {
          if (typeof params.value !== 'number') return '';
          const { id, api } = params;
          if (!id) return null;
          const row = api.getRow(id);
          if (!row) return null;
          return Intl.NumberFormat('en-US', { style: 'currency', currency: row.currency }).format(params.value);
        },
      },
      {
        field: 'action',
        headerName: t('invoices.table.actionHeader'),
        width: 200,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          return (
            <div>
              {params.row.viewUrl ? (
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  href={params.row.viewUrl}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Visibility />
                  {t('invoices.table.viewButton')}
                </Button>
              ) : null}{' '}
              {params.row.pdfUrl ? (
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  href={params.row.pdfUrl}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <PictureAsPdf />
                  {t('invoices.table.pdfButton')}
                </Button>
              ) : null}
              {!params.row.pdfUrl && !params.row.viewUrl ? t('invoices.table.processing') : null}
            </div>
          );
        },
      },
      {
        field: 'status',
        headerName: t('common.tableHeader.status'),
        width: 130,
        filterOperators: [getEnumIsFilterOperator(statuses), getEnumIsNotFilterOperator(statuses)],
        valueFormatter: (params) => {
          if (typeof params.value !== 'string') return '';
          return statusesInv[params.value];
        },
        renderCell: (params) => {
          const { value } = params;
          if (typeof value !== 'string') return null;
          return <Chip label={statusesInv[value] ?? value} color={value === 'paid' ? 'primary' : 'secondary'} />;
        },
      },
    ],
    []
  );

  const rows = useMemo<GridRowData[]>(() => {
    return invoices.map((invoice) => {
      return {
        id: invoice.id,
        date: invoice.created,
        description: invoice.description,
        amount: invoice.amountDue / 100,
        currency: invoice.currency,
        status: invoice.status,
        viewUrl: invoice.hostedInvoiceUrl,
        pdfUrl: invoice.invoicePdf,
      };
    });
  }, [invoices]);

  return (
    <UrlQueryDataGrid
      columns={columns}
      rows={rows}
      disableExtendRowFullWidth={false}
      autoHeight={true}
      disableSelectionOnClick={true}
    />
  );
};
