import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AccountType } from '../../types/AccountType';
import { createFetcher } from '../../contexts/Fetcher';
import { useAuthApi } from '../../hooks/useAuthApi';
import { GuestRoutes } from '../../routers/GuestRouter';
import { useQueryParam } from '../../hooks/useQueryParam';
import { AuthDialog } from '../AuthDialog/AuthDialog';
import { AuthGoBack } from '../AuthDialog/AuthGoBack';
import { useTranslation } from 'react-i18next';

const AccountTypesFetcher = createFetcher(() => {
  const { getAccountTypes } = useAuthApi();
  return useCallback(
    ({ email }: { email: string }) => {
      return getAccountTypes(email);
    },
    [getAccountTypes]
  );
});

const AuthChooseAccountTypeContent: FC = () => {
  const { search } = useLocation();
  const { data } = useContext(AccountTypesFetcher.Context);
  const history = useHistory();
  const { t } = useTranslation();

  const supportedTypes = useMemo(() => {
    if (!data) return;
    return (Object.entries(data) as Array<[AccountType, boolean]>)
      .filter(([, supported]) => supported)
      .map(([type]) => type);
  }, [data]);

  useEffect(() => {
    if (!supportedTypes) return;
    if (supportedTypes.length === 1) {
      history.replace(`${GuestRoutes.Password(supportedTypes[0])}${history.location.search}`);
    }
  }, [supportedTypes]);

  if (!data || supportedTypes?.length === 1) return null;
  return (
    <AuthDialog
      title={
        supportedTypes?.length === 0
          ? t('auth.chooseAccountType.noExistingAccount')
          : t('auth.chooseAccountType.chooseAccountType')
      }
      actions={<AuthGoBack to={`${GuestRoutes.SignIn()}${search}`} />}
    >
      <Box textAlign="center" marginTop={3}>
        {supportedTypes?.length === 0 ? null : (
          <ButtonGroup color="primary">
            {data.user ? (
              <Button component={Link} to={`${GuestRoutes.Password(AccountType.User)}${search}`}>
                {t('auth.accountType.user')}
              </Button>
            ) : null}

            {data.customer ? (
              <Button component={Link} to={`${GuestRoutes.Password(AccountType.Customer)}${search}`}>
                {t('auth.accountType.customer')}
              </Button>
            ) : null}

            {data.admin ? (
              <Button component={Link} to={`${GuestRoutes.Password(AccountType.Admin)}${search}`}>
                {t('auth.accountType.admin')}
              </Button>
            ) : null}
          </ButtonGroup>
        )}
      </Box>
    </AuthDialog>
  );
};

export const AuthChooseAccountType: FC = () => {
  const email = useQueryParam('email') ?? '';
  return (
    <AccountTypesFetcher.WAF email={email}>
      <AuthChooseAccountTypeContent />
    </AccountTypesFetcher.WAF>
  );
};
