import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { useDocumentationApi } from '../../hooks/useDocumentationApi';
import { useHistory, useParams } from 'react-router-dom';
import { IAwsFileMetadata } from '../../hooks/useDownloadApi';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { UserRoutes } from '../../routers/UserRoutes';
import { GridCellParams, GridColumns, GridRowData } from '@mui/x-data-grid';
import GetApp from '@mui/icons-material/GetApp';
import Launch from '@mui/icons-material/Launch';
import { useDownload } from '../../hooks/useDownload';
import { UrlQueryDataGrid } from '../UrlQueryDataGrid/UrlQueryDataGrid';
import { config } from '../../config';
import { createFetcher } from '../../contexts/Fetcher';
import { getDateValueFormatter } from '../../valueFormatters/DateValueFormatter';
import { FilesizeFormatter } from '../../valueFormatters/FilesizeValueFormatter';
import { PageHeader } from '../PageHeader/PageHeader';
import { AccountContext } from '../../contexts/AccountContext';
import { AccountType } from '../../types/AccountType';
import { CustomerRoutes } from '../../routers/CustomerRoutes';
import { getDateAfterFilterOperator, getDateBeforeFilterOperator } from '../../filterOperators/dateFilterOperators';
import { useFreeTextFilter } from '../../hooks/useFreeTextFilter';
import { EasyFilter } from '../EasyFilter/EasyFilter';
import { getFilename } from '../../helpers/getFilename';
import { DateFormat } from '../../helpers/dateFormat';
import { routeWithQuery } from '../../helpers/routeWithQuery';
import { useTranslation } from 'react-i18next';
import { getFileSizeGreaterThan, getFileSizeLessThan } from '../../filterOperators/fileSizeFilterOperators';

interface IUrlParams {
  product?: string;
}

const getRowId = (row: GridRowData) => row.Key;

const DownloadButtonCell: FC<GridCellParams> = ({ row }) => {
  const download = useDownload();

  const onClick = useCallback(async () => {
    await download(row.Key);
  }, [download, row.Key]);

  return (
    <IconButton onClick={onClick} size="large">
      <GetApp />
    </IconButton>
  );
};

const DocumentationFetcher = createFetcher(() => useDocumentationApi().getDocumentationList);

const DocumentationContent: FC = () => {
  const { data: documentationList } = useContext(DocumentationFetcher.Context);
  const { product } = useParams<IUrlParams>();
  const history = useHistory();
  const accountType = useContext(AccountContext);
  const { t } = useTranslation();

  const files: IAwsFileMetadata[] | null = useMemo(() => {
    if (!product || !documentationList) return [];
    const productDocs = documentationList.productsDocs.find((pd) => pd.product.id === parseInt(product, 10));
    return productDocs?.s3Files ?? null;
  }, [documentationList, product]);

  const setActiveTab = useCallback(
    (tab, replaceState = false) => {
      const routes = accountType === AccountType.User ? UserRoutes : CustomerRoutes;
      const sortQuery = {
        sortBy: 'LastModified',
        sortOrder: 'desc',
      };

      if (replaceState) {
        history.replace(routeWithQuery(routes.DocumentationProduct, [tab], sortQuery));
      } else {
        history.push(routeWithQuery(routes.DocumentationProduct, [tab], sortQuery));
      }
    },
    [accountType]
  );

  const onChange = useCallback((event, tab) => {
    setActiveTab(tab);
  }, []);

  useEffect(() => {
    if (!product && documentationList && documentationList.productsDocs.length > 0) {
      setActiveTab(documentationList.productsDocs[0].product.id, true);
    }
  }, [product, setActiveTab, documentationList]);

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'Key',
        headerName: t('documentation.table.fileNameHeader'),
        flex: 1,
        valueFormatter: (params) => {
          return typeof params.value === 'string' ? getFilename(params.value) : null;
        },
      },
      {
        field: 'LastModified',
        headerName: t('documentation.table.modifiedHeader'),
        flex: 1,
        valueFormatter: getDateValueFormatter(DateFormat.DateFullWithTimeAndZone),
        filterOperators: [getDateBeforeFilterOperator(), getDateAfterFilterOperator()],
      },
      {
        field: 'Size',
        headerName: t('documentation.table.sizeHeader'),
        flex: 1,
        filterOperators: [getFileSizeGreaterThan(), getFileSizeLessThan()],
        valueFormatter: FilesizeFormatter,
      },
      {
        field: 'button',
        headerName: t('documentation.table.downloadHeader'),
        width: 110,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => <DownloadButtonCell {...params} />,
      },
    ],
    []
  );

  const rows = useFreeTextFilter(files ?? [], columns);

  if (!documentationList) return null;
  return (
    <div>
      {product ? (
        <Tabs
          value={product}
          indicatorColor="primary"
          textColor="primary"
          onChange={onChange}
          variant="scrollable"
          scrollButtons={'auto'}
        >
          {documentationList.productsDocs.map((pd) => {
            return <Tab key={pd.product.id} label={pd.product.name} value={pd.product.id.toString(10)} />;
          })}
        </Tabs>
      ) : null}

      <EasyFilter />

      <UrlQueryDataGrid
        columns={columns}
        rows={rows}
        getRowId={getRowId}
        disableExtendRowFullWidth={false}
        autoHeight={true}
        disableSelectionOnClick={true}
      />
    </div>
  );
};

export const Documentation: FC = () => {
  const { t } = useTranslation();
  return (
    <DocumentationFetcher.WAF>
      <PageHeader title={t('documentation.title')}>
        <Button
          variant="contained"
          href={config.helpPage}
          target="_blank"
          rel="noreferrer noopener"
          color="primary"
          endIcon={<Launch />}
        >
          {t('documentation.helpCentreButton')}
        </Button>
      </PageHeader>

      <DocumentationContent />
    </DocumentationFetcher.WAF>
  );
};
