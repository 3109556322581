import React, { FC, useCallback, useEffect } from 'react';
import { GridFilterInputValueProps } from '@mui/x-data-grid';
import { DateFormat } from '../../helpers/dateFormat';
import { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import styled from '@mui/styles/styled';
import { useTranslation } from 'react-i18next';

const StyledTextField = styled(TextField)({
  marginTop: 0,
});

export const DateFilterInput: FC<GridFilterInputValueProps> = ({ item, applyValue }) => {
  const { t } = useTranslation();
  const {
    i18n: { language },
  } = useTranslation();

  const handleDateChange = useCallback(
    (date: Dayjs | null) => {
      applyValue({ ...item, value: date ? Math.round(date.valueOf() / 1000) : '' });
    },
    [item, applyValue]
  );

  useEffect(() => {
    if (!item.value) {
      applyValue({ ...item, value: Math.round(new Date().valueOf() / 1000) });
    }
  }, [item.operatorValue]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} locale={language}>
      <DatePicker
        inputFormat={DateFormat.DatePicker as string}
        toolbarFormat={DateFormat.DatePicker as string}
        mask="__-__-____"
        label={t('common.formLabel.date')}
        value={(item.value ? new Date(parseInt(item.value, 10) * 1000) : new Date()).toString()}
        onChange={handleDateChange}
        renderInput={(props) => <StyledTextField {...props} />}
      />
    </LocalizationProvider>
  );
};
