import { IMachine } from '../models/Machine';
import { ILicenseDetails } from '../models/LicenseDetails';
import { IPlan } from '../models/Plan';
import { IPackageDetails } from '../models/PackageDetails';
import { createContext } from 'react';
import { IUserPackageDetails } from '../models/UserPackageDetails';

export interface ILicenseContext {
  machine?: IMachine;
  license: ILicenseDetails;
  plan: IPlan;
  packageDetails: IPackageDetails;
  userPackageDetails: IUserPackageDetails;
  offlineLicense?: string;
  onOfflineRelease: (file: File) => Promise<void>;
  onOfflineForceRelease?: () => Promise<void>;
  onOnlineRelease: () => Promise<void>;
  getLicenseRoute: (id: number) => string;
  releaseLicenseRoute: (id: number) => string;
  packagesOnSameLicense: Array<{
    package: IPackageDetails;
    userPackage: IUserPackageDetails;
    plan: IPlan;
  }>;
}

export const LicenseContext = createContext<ILicenseContext | null>(null);
