import React, { FC, useContext } from 'react';
import styled from '@emotion/styled';
import { Box, Card, Chip, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { LicenseContext } from '../../contexts/LicenseContext';
import { useTranslation } from 'react-i18next';

const StyledTable = styled(Table)({
  '& tbody tr:last-of-type td': {
    borderBottomColor: 'transparent',
  },
});

export const LicensesIncludedInOffline: FC = () => {
  const { t } = useTranslation();
  const { packagesOnSameLicense } = useContext(LicenseContext)!;

  return (
    <Box mb={2}>
      <Card>
        <Box p={1}>
          {t('licenses.includedInFile')}
          <StyledTable>
            <TableHead>
              <TableRow>
                <TableCell>{t('common.tableHeader.id')}</TableCell>
                <TableCell>{t('common.tableHeader.license')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {packagesOnSameLicense.map((packageItem) => (
                <TableRow key={packageItem.userPackage.id}>
                  <TableCell width={70}>{packageItem.userPackage.id}</TableCell>
                  <TableCell>
                    {packageItem.package.isAddon ? <Chip color="info" label={t('assignLicenses.addonChip')} /> : null}{' '}
                    {packageItem.package.name}
                    {' | '}
                    {packageItem.plan.name}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </StyledTable>
        </Box>
      </Card>
    </Box>
  );
};
