import { GridCellParams, GridFilterItem } from '@mui/x-data-grid';
import { getFilterEnumInput } from '../components/FilterEnumInput/FilterEnumInput';
import { t } from 'i18next';

export const parseMappedEnumIs = (value: string): null | [field: string, value: string | boolean | number] => {
  const filterMatch = value.match(/^([a-z]+)=(.*)$/i);

  if (filterMatch) {
    const [, field, jsonValue] = filterMatch;
    const value = JSON.parse(jsonValue);
    return [field, value];
  }
  return null;
};

export const getMappedEnumIsFilterOperator = (
  possibleValues: Record<string, [field: string, value: string | number | boolean]>
) => {
  const map = Object.entries(possibleValues).reduce((memo, [label, [field, value]]) => {
    memo[label] = `${field}=${JSON.stringify(value)}`;
    return memo;
  }, {} as Record<string, string>);

  return {
    label: t('filterOperator.enumIs'),
    value: 'equals-m',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      const parsedFilter = parseMappedEnumIs(filterItem.value);
      return (params: GridCellParams) => {
        if (parsedFilter) {
          const [field, value] = parsedFilter;
          return params.row[field] === value;
        }
        return false;
      };
    },
    InputComponent: getFilterEnumInput(map),
  };
};
