import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

interface IProps {
  to: string;
  children: React.ReactChild | React.ReactChild[];
}

const useStyles = makeStyles((theme) =>
  createStyles({
    link: {
      color: theme.palette.text.primary,
      textDecoration: 'none',
    },
  })
);

export const MenuLink = forwardRef<HTMLLIElement, IProps>(({ to, children }, ref) => {
  const styles = useStyles();

  return (
    <Link to={to} className={styles.link}>
      <MenuItem ref={ref}>{children}</MenuItem>
    </Link>
  );
});
