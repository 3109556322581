import { useApi } from './useApi';
import { IProductPlanPackageTool } from '../models/ProductPlanPackageTool';
import { ApiRequest, useEndpoint, useSimpleEndpoint } from './api/apiEndpoint';
import { ProductToolsEndpoints } from './api/endpoints/tools';

export interface IProductToolPayload {
  key: string;
  filter: boolean;
}

export const useProductToolsApi = () => {
  const { del, get, post, put } = useApi();

  const getProductTools = useSimpleEndpoint(
    ProductToolsEndpoints.Tools,
    get as ApiRequest<typeof get, { tools: IProductPlanPackageTool[] }>
  );

  const addTool = useEndpoint(
    (post, data: IProductToolPayload) => post(data),
    ProductToolsEndpoints.Tools,
    post as ApiRequest<typeof post, IProductPlanPackageTool>
  );

  const updateTool = useEndpoint(
    (put, data: IProductToolPayload) => put(data),
    ProductToolsEndpoints.Tool,
    put as ApiRequest<typeof put, IProductPlanPackageTool>
  );

  const deleteTool = useSimpleEndpoint(ProductToolsEndpoints.Tool, del);

  return {
    getProductTools,
    addTool,
    updateTool,
    deleteTool,
  };
};
