import { Single } from '../types/single';
import { ICustomerUsersResponse } from '../models/CustomerUsersResponse';

type UserPackages = Single<ICustomerUsersResponse['users']>['packages'];

export const groupUserPackages = (packages: UserPackages) => {
  return packages.reduce<UserPackages>((out, current) => {
    const dupIdx = out.findIndex((pack) => pack.package.id === current.package.id && pack.plan.id === current.plan.id);
    if (dupIdx >= 0) {
      const dup = out[dupIdx];
      out = [
        ...out.slice(0, dupIdx),
        {
          ...dup,
          userPackage: {
            ...dup.userPackage,
            amount: dup.userPackage.amount + current.userPackage.amount,
          },
          amount: (dup.amount ?? 0) + (current.amount ?? 0),
        },
        ...out.slice(dupIdx + 1),
      ];
    } else {
      out.push(current);
    }
    return out;
  }, []);
};
