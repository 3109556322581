import React, { FC, createContext, useState, useMemo } from 'react';
import { AccountType } from '../types/AccountType';

interface ISignInFastTrack {
  accountType: AccountType;
  email: string;
}

interface IGlobalContext {
  signInFastTrack: ISignInFastTrack | null;
  setSignInFastTrack: (value: ISignInFastTrack | null) => void;
}

export const GlobalContext = createContext<IGlobalContext>({
  signInFastTrack: null,
  setSignInFastTrack: () => undefined,
});

export const GlobalContextContainer: FC = ({ children }) => {
  const [signInFastTrack, setSignInFastTrack] = useState<ISignInFastTrack | null>(null);

  const contextValue = useMemo(
    () => ({
      signInFastTrack,
      setSignInFastTrack,
    }),
    [signInFastTrack]
  );

  return <GlobalContext.Provider value={contextValue}>{children}</GlobalContext.Provider>;
};
