import React, { FC } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { IOverviewProduct } from '../../models/UserOverview';
import { DateFormat } from '../../helpers/dateFormat';
import { useTranslation } from 'react-i18next';
import { Date } from '../Date/Date';

interface IProps {
  product: IOverviewProduct;
}

export const OverviewReleases: FC<IProps> = ({ product }) => {
  const { t } = useTranslation();
  if (!product.latestVersion) return null;

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t('userOverview.productHeader')}</TableCell>
          <TableCell>{t('userOverview.releaseDateHeader')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {product.latestVersion.installer.map((installer) => {
          return (
            <TableRow key={installer.version}>
              <TableCell>
                {product.product.name} &ndash; {installer.version}
              </TableCell>
              <TableCell>
                <Date date={installer.released} format={DateFormat.DateMedium} />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
