import React, { FC, useCallback, useContext, useMemo } from 'react';
import { CustomerFetcher, CustomerUsersFetcher } from './CustomerFetchers';
import { UrlQueryDataGrid } from '../UrlQueryDataGrid/UrlQueryDataGrid';
import { GridColumns, GridRowParams } from '@mui/x-data-grid';
import { getStringEqualsOperator } from '../../filterOperators/stringEqualsOperator';
import { getGreaterThanOperator } from '../../filterOperators/greaterThanOperator';
import { getLessThanOperator } from '../../filterOperators/lessThanOperator';
import { getDateAfterFilterOperator, getDateBeforeFilterOperator } from '../../filterOperators/dateFilterOperators';
import { getDateValueFormatter } from '../../valueFormatters/DateValueFormatter';
import { DateFormat } from '../../helpers/dateFormat';
import { getStringDiffersOperator } from '../../filterOperators/stringDiffersOperator';
import Button from '@mui/material/Button';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router-dom';
import { useIsRoute } from '../../hooks/useIsRoute';
import { AdminRoutes } from '../../routers/AdminRoutes';
import { CustomerNewUser } from './CustomerNewUser';
import { CustomerUserFetcher } from '../CustomerUser/CustomerUserFetchers';
import { useAutoFetch } from '../../contexts/Fetcher';
import { useTranslation } from 'react-i18next';
import { AdminRouteLink, useIsAdminRouteAllowed } from '../RouteLink/RouteLink';

const useStyles = makeStyles((theme) =>
  createStyles({
    buttonWrapper: {
      padding: `${theme.spacing(1)} 0`,
    },
  })
);

export const CustomerUsers: FC = () => {
  const styles = useStyles();
  const { data: customerData } = useContext(CustomerFetcher.Context);
  const { data } = useContext(CustomerUsersFetcher.Context);
  const { onDataUpdate: onUserDataUpdate } = useContext(CustomerUserFetcher.Context);
  const isCreateUserDialogOpen = useIsRoute(AdminRoutes.CustomerNewTabItem);
  const history = useHistory();
  useAutoFetch(CustomerUsersFetcher);
  const { t } = useTranslation();

  const onRowClick = useCallback(
    (params: GridRowParams) => {
      if (!data || !customerData) return;
      const user = data.data.find((user) => user.id === params.id);
      if (user) onUserDataUpdate(user);
    },
    [data]
  );

  const getRowLink = useCallback(
    (params: GridRowParams) => {
      if (!customerData) return '';
      return AdminRoutes.CustomerUser(customerData.customer.id, params.row.id);
    },
    [customerData?.customer.id]
  );
  const isGetRowLinkAllowed = useIsAdminRouteAllowed('CustomerUser');

  const closeDialog = useCallback((resetQuery = false) => {
    if (!customerData) return null;
    if (resetQuery) {
      history.push(AdminRoutes.CustomerTab(customerData.customer.id, 'users'));
    } else {
      history.push(`${AdminRoutes.CustomerTab(customerData.customer.id, 'users')}?${history.location.search}`);
    }
  }, []);

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'id',
        headerName: t('common.tableHeader.id'),
        filterOperators: [getStringEqualsOperator(), getGreaterThanOperator(), getLessThanOperator()],
      },
      {
        field: 'firstName',
        headerName: t('common.tableHeader.firstName'),
        filterOperators: [getStringEqualsOperator(), getStringDiffersOperator()],
        flex: 1,
      },
      {
        field: 'lastName',
        headerName: t('common.tableHeader.lastName'),
        filterOperators: [getStringEqualsOperator(), getStringDiffersOperator()],
        flex: 1,
      },
      {
        field: 'email',
        headerName: t('common.tableHeader.email'),
        filterOperators: [getStringEqualsOperator()],
        flex: 2,
      },
      {
        field: 'created',
        headerName: t('common.tableHeader.created'),
        valueFormatter: getDateValueFormatter(DateFormat.DateMedium),
        filterOperators: [getDateBeforeFilterOperator(), getDateAfterFilterOperator()],
        width: 130,
      },
      {
        field: 'updated',
        headerName: t('common.tableHeader.updated'),
        valueFormatter: getDateValueFormatter(DateFormat.DateMedium),
        filterOperators: [getDateBeforeFilterOperator(), getDateAfterFilterOperator()],
        width: 130,
      },
    ],
    []
  );

  if (!data || !customerData) return null;

  return (
    <div>
      <div className={styles.buttonWrapper}>
        <Button
          component={AdminRouteLink}
          route="CustomerNewTabItem"
          params={[customerData.customer.id, 'users']}
          keepQuery
          variant="contained"
          color="primary"
        >
          {t('customer.users.addButton')}
        </Button>
      </div>
      <UrlQueryDataGrid
        columns={columns}
        rows={data.data}
        disableExtendRowFullWidth={false}
        autoHeight={true}
        disableSelectionOnClick={true}
        paginationMode={'server'}
        filterMode={'server'}
        sortingMode={'server'}
        onRowClick={onRowClick}
        getRowLink={isGetRowLinkAllowed ? getRowLink : void 0}
        rowCount={data.page.data.total}
      />
      <CustomerNewUser open={isCreateUserDialogOpen} closeDialog={closeDialog} />
    </div>
  );
};
