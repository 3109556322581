import React, { FC, useCallback, useContext, useMemo } from 'react';
import { IUserDownloadsVersion } from '../../hooks/useDownloadApi';
import { ProductVersionInstallerDownloads } from './ProductVersionInstallerDownloads';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Link as RouterLink } from 'react-router-dom';
import { UserRoutes } from '../../routers/UserRoutes';
import { IProductDetails } from '../../models/ProductDetails';
import { DateFormat, dateFormat } from '../../helpers/dateFormat';
import styled from '@mui/styles/styled';
import { useTranslation } from 'react-i18next';
import { AccountContext } from '../../contexts/AccountContext';
import { AccountType } from '../../types/AccountType';
import { CustomerRoutes } from '../../routers/CustomerRoutes';

interface IProps {
  product: IProductDetails;
  version: IUserDownloadsVersion;
  expanded: boolean;
  onToggle: (version: string, expanded: boolean) => void;
}

const useStyles = makeStyles((theme) => {
  return createStyles({
    versionButtons: {
      flexGrow: 1,
      display: 'flex',
      gap: theme.spacing(1),
      justifyContent: 'flex-end',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'flex-end',
      },
    },
    summaryContainer: {
      alignItems: 'center',
    },
    accordion: {
      '&:before': {
        visibility: 'hidden',
      },
    },
    accordionExpanded: {
      '&$accordionExpanded': {
        margin: 0,
      },
    },
    latestRelease: {
      color: theme.palette.text.secondary,
    },
  });
});

const StyledAccordionDetails = styled(AccordionDetails)({
  padding: 0,
});

const AccordionHeader = styled(Typography)({
  fontSize: '24px',
  flexBasis: '33.33%',
});

const stopBtnPropagation = (e: React.MouseEvent) => {
  if (e.target !== e.currentTarget) {
    e.stopPropagation();
  }
};

export const ProductVersionDownloads: FC<IProps> = ({ product, version, expanded, onToggle }) => {
  const styles = useStyles();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const accountType = useContext(AccountContext)!;
  const routes = accountType === AccountType.User ? UserRoutes : CustomerRoutes;

  const onChange = useCallback(
    (_event, newExpanded: boolean) => {
      onToggle(version.version, newExpanded);
    },
    [onToggle, version.version]
  );

  const latestInstaller = useMemo(() => {
    return version.installers.find((i) => i.latest) ?? null;
  }, [version.installers]);

  return (
    <Accordion
      expanded={expanded}
      onChange={onChange}
      className={styles.accordion}
      classes={{
        expanded: styles.accordionExpanded,
      }}
      elevation={0}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Grid container className={styles.summaryContainer}>
          <Grid item xs={6} lg={4}>
            <AccordionHeader variant="h4">{t('downloads.version', { version: version.version })}</AccordionHeader>
          </Grid>
          <Hidden lgDown>
            <Grid item lg={3}>
              {latestInstaller && latestInstaller.file ? (
                <Typography className={styles.latestRelease}>
                  {t('downloads.latestRelease', {
                    date: dateFormat(latestInstaller.manifestFileVersion.released, DateFormat.DateFull, language),
                  })}
                </Typography>
              ) : null}
            </Grid>
          </Hidden>
          <Grid item xs={6} lg={5}>
            <div className={styles.versionButtons} onClick={stopBtnPropagation}>
              {version.lens ? (
                <Button
                  component={RouterLink}
                  to={routes.DownloadsProductLens(product.id)}
                  variant="text"
                  color="primary"
                >
                  {t('downloads.lensButton')}
                </Button>
              ) : null}
              {version.tools ? (
                <Button
                  component={RouterLink}
                  to={routes.DownloadsProductTools(product.id, version.version)}
                  variant="text"
                  color="primary"
                >
                  {t('downloads.toolsButton')}
                </Button>
              ) : null}
              {version.content ? (
                <Button
                  component={RouterLink}
                  to={routes.DownloadsProductContent(product.id, version.version)}
                  variant="text"
                  color="primary"
                >
                  {t('downloads.engineContentButton')}
                </Button>
              ) : null}
              {version.examples ? (
                <Button
                  component={RouterLink}
                  to={routes.DownloadsProductExamples(product.id, version.version)}
                  variant="outlined"
                  color="primary"
                >
                  {t('downloads.examplesButton')}
                </Button>
              ) : null}
            </div>
          </Grid>
        </Grid>
      </AccordionSummary>

      <StyledAccordionDetails>
        <div style={{ width: '100%' }}>
          {version.installers.map((installer, index) => {
            return (
              <React.Fragment key={installer.file?.Key ?? index}>
                <ProductVersionInstallerDownloads installer={installer} />
                {index < version.installers.length - 1 ? <Divider /> : null}
              </React.Fragment>
            );
          })}
        </div>
      </StyledAccordionDetails>
    </Accordion>
  );
};
