import React, { FC, useCallback, useContext, useMemo } from 'react';
import { ProductPackagesFetcher } from '../../fetchers/ProductPackagesFetcher';
import { UrlQueryDataGrid } from '../UrlQueryDataGrid/UrlQueryDataGrid';
import { GridColumns, GridRowParams } from '@mui/x-data-grid';
import { getDateValueFormatter } from '../../valueFormatters/DateValueFormatter';
import { DateFormat } from '../../helpers/dateFormat';
import { useAutoFetch } from '../../contexts/Fetcher';
import { IProductPlanPackage } from '../../models/ProductPlanPackage';
import { Single } from '../../types/single';
import { useHistory } from 'react-router-dom';
import { AdminRoutes } from '../../routers/AdminRoutes';
import { AddProductPackageDialog } from './AddProductPackageDialog';
import { useIsRoute } from '../../hooks/useIsRoute';
import { useCloseDialog } from '../../hooks/useCloseDialog';
import { useIntParams } from '../../hooks/useIntParams';
import { EditProductPackageDialog } from './EditProductPackageDialog';
import { useDefaultSorting } from '../../hooks/useDefaultSorting';
import { useTranslation } from 'react-i18next';
import { CollapsedContentsWithTooltip } from '../CollapsedContentsWithTooltip/CollapsedContentsWithTooltip';
import { useIsAdminRouteAllowed } from '../RouteLink/RouteLink';

interface IUrlParams {
  id?: string;
}

interface IProps {
  packagesType: 'packages' | 'addons';
}

export const ProductPackages: FC<IProps> = ({ packagesType }) => {
  const { data } = useContext(ProductPackagesFetcher.Context);
  useAutoFetch(ProductPackagesFetcher);
  const isAdding = useIsRoute(AdminRoutes.ProductsTabNewItem);
  const closeDialog = useCloseDialog(AdminRoutes.ProductsTab(packagesType));
  const { id } = useIntParams<IUrlParams>();
  const history = useHistory();
  useDefaultSorting('id', 'asc');
  const { t } = useTranslation();

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'id',
        headerName: t('common.tableHeader.id'),
      },
      {
        field: 'name',
        headerName: t('common.tableHeader.name'),
        flex: 1,
      },
      {
        field: 'tools',
        headerName: t('adminProducts.packages.table.toolsHeader'),
        flex: 1,
        valueGetter: (params) => {
          const count = params.row.tools.reduce(
            (memo: number, current: Single<IProductPlanPackage['tools']['data']>) => {
              return memo + current.amount;
            },
            0
          );
          return t('adminProducts.packages.table.totalTools', { count });
        },
        renderCell: (params) => {
          const items = (params.row.tools as IProductPlanPackage['tools']['data']).map((tool) => ({
            amount: tool.amount,
            name: tool.tool?.key,
          }));
          return <CollapsedContentsWithTooltip items={items}>{params.value}</CollapsedContentsWithTooltip>;
        },
      },
      {
        field: 'created',
        headerName: t('common.tableHeader.created'),
        width: 120,
        valueFormatter: getDateValueFormatter(DateFormat.DateMedium),
      },
      {
        field: 'updated',
        headerName: t('common.tableHeader.updated'),
        width: 120,
        valueFormatter: getDateValueFormatter(DateFormat.DateMedium),
      },
    ],
    []
  );

  const rows = useMemo(() => {
    if (!data) return null;
    return data.packages
      .filter((pack) => (packagesType === 'addons' ? pack.isAddon : !pack.isAddon))
      .map((pack) => ({
        id: pack.id,
        name: pack.name,
        created: pack.created,
        update: pack.updated,
        tools: pack.tools.data,
      }));
  }, [data, packagesType]);

  const getRowLink = useCallback((params: GridRowParams) => {
    if (packagesType === 'addons') {
      return `${AdminRoutes.ProductsTabEditItem('addons', params.row.id)}${history.location.search}`;
    } else {
      return `${AdminRoutes.ProductsTabEditItem('packages', params.row.id)}${history.location.search}`;
    }
  }, []);
  const isGetRowLinkAllowed = useIsAdminRouteAllowed('ProductsTabEditItem', 'packages');

  if (!data || !rows) return null;
  const pack = data.packages.find((p) => p.id === id);

  return (
    <div>
      <UrlQueryDataGrid
        columns={columns}
        rows={rows}
        disableExtendRowFullWidth={false}
        autoHeight={true}
        disableSelectionOnClick={true}
        getRowLink={isGetRowLinkAllowed ? getRowLink : void 0}
      />
      <AddProductPackageDialog open={isAdding} closeDialog={closeDialog} packagesType={packagesType} />
      <EditProductPackageDialog pack={pack} closeDialog={closeDialog} packagesType={packagesType} />
    </div>
  );
};
