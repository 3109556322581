import { useLocation } from 'react-router-dom';

export const useRouteWithQuery = <T extends any[]>(
  route: (...args: T) => string,
  routeParams: T,
  queryParams: Record<string, string | number | null> = {},
  keepQueryParams = true
) => {
  const { search } = useLocation();

  const searchParams = new URLSearchParams(keepQueryParams ? search : void 0);

  for (const [key, value] of Object.entries(queryParams)) {
    if (value !== null) {
      searchParams.set(key, value.toString());
    } else {
      searchParams.delete(key);
    }
  }
  const searchString = searchParams.toString();
  const routeString = route(...routeParams);

  return searchString ? `${routeString}?${searchString}` : routeString;
};
