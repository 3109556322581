import React, { FC, useCallback, useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import styled from '@mui/styles/styled';
import TextField from '@mui/material/TextField';
import timezonesList from 'timezones-list';
import { useEnumState } from '../../hooks/useEnumState';

const timezones = timezonesList.map((tz) => tz.tzCode);

interface ITimezoneSelectorProps {
  value: string | null;
  onChange: (value: string | null) => void;
  label?: string;
}

const StyledTextField = styled(TextField)({
  marginTop: '0',
});

export const TimezoneSelector: FC<ITimezoneSelectorProps> = ({ value, onChange, label = 'Timezone' }) => {
  const [searchText, setSearchText] = useState('');

  const [isOpen, setOpen, setClose] = useEnumState(false, true, false);

  // Below is quite hacky way to prevent the error with wrong positioning of Autocomplete
  // popper that occurred when user cleared the input after selecting the timezone.
  const [reinit, setReinit] = useState(0);
  const onRef = useCallback(
    (ref: HTMLElement) => {
      if (ref && isOpen) {
        setTimeout(() => {
          ref.querySelector('input')?.focus();
        }, 0);
      }
    },
    [isOpen]
  );

  useEffect(() => {
    if (searchText.length === 0) {
      setReinit(reinit + 1);
    }
  }, [searchText]);

  return (
    <div>
      <Autocomplete
        key={reinit.toString()}
        open={isOpen}
        onOpen={setOpen}
        onClose={setClose}
        ref={onRef}
        disablePortal={true}
        fullWidth
        renderInput={(params) => <StyledTextField label={label} {...params} />}
        options={timezones}
        value={value}
        onChange={(event, value) => onChange(value)}
        inputValue={searchText}
        onInputChange={(ev, val) => {
          setSearchText(val);
        }}
      />
    </div>
  );
};
