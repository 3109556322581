import React, { FC } from 'react';
import { ICustomerOverviewResponse } from '../../models/CustomerOverview';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { DateFormat } from '../../helpers/dateFormat';
import { useTranslation } from 'react-i18next';
import { Date } from '../Date/Date';

interface IProps {
  expiring: ICustomerOverviewResponse['expiringSoonLicenses'];
}

export const CustomerOverviewExpiring: FC<IProps> = ({ expiring }) => {
  const { t } = useTranslation();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t('customerOverview.planHeader')}</TableCell>
          <TableCell>{t('common.tableHeader.expires')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {expiring.map((expiringLicense) => (
          <TableRow key={JSON.stringify(expiringLicense)}>
            <TableCell>{expiringLicense.planName}</TableCell>
            <TableCell>
              <Tooltip title={<Date date={expiringLicense.date} format={DateFormat.DateMedium} />}>
                <span>
                  <Date date={expiringLicense.date} format={DateFormat.Relative} />
                </span>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
