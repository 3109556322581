import React, { FC, useCallback, useContext, useMemo } from 'react';
import { useLicenseApi } from '../../hooks/useLicenseApi';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { OfflineAccessStepsDialog } from './OfflineAccessStepsDialog';
import { ReleaseLicenseStepsDialog } from './ReleaseLicenseStepsDialog';
import { UserRoutes } from '../../routers/UserRoutes';
import { createFetcher } from '../../contexts/Fetcher';
import { LicenseContext } from '../../contexts/LicenseContext';
import { PageHeader } from '../PageHeader/PageHeader';
import { UrlQueryDataGrid } from '../UrlQueryDataGrid/UrlQueryDataGrid';
import { GridColumns } from '@mui/x-data-grid';
import { getLicenseMode, LicenseMode, LicenseMode as LicenseModeType } from '../../helpers/licenseMode';
import { getDateValueFormatter } from '../../valueFormatters/DateValueFormatter';
import { DateFormat, getDateObject } from '../../helpers/dateFormat';
import { useCloseDialog } from '../../hooks/useCloseDialog';
import Button from '@mui/material/Button';
import { useIntParams } from '../../hooks/useIntParams';
import { useIsRoute } from '../../hooks/useIsRoute';
import { getEnumIsFilterOperator, getEnumIsNotFilterOperator } from '../../filterOperators/enumFilterOperator';
import { LicenseExpiry } from '../LicenseExpiry/LicenseExpiry';
import { createStatusChipComponent } from '../StatusChip/StatusChip';
import { IQuickFiltersProps } from '../EasyFilter/QuickFilters';
import { EasyFilter } from '../EasyFilter/EasyFilter';
import { useFreeTextFilter } from '../../hooks/useFreeTextFilter';
import { CellContentTooltip } from '../CellContentTooltip/CellContentTooltip';
import { RowButtonsContainer } from '../RowButtonsContainer/RowButtonsContainer';
import styled from '@mui/styles/styled';
import { useTranslation } from 'react-i18next';
import { getLicenseStatus } from '../../helpers/licenseStatus';
import { LicenseModeChip } from '../LicenseModeChip/LicenseModeChip';
import { Chip, Grid } from '@mui/material';
import { useRouteWithQuery } from '../../hooks/useRouteWithQuery';
import { RequestOfflineLicenseStandalone } from '../RequestOfflineLicenseStandalone/RequestOfflineLicenseStandalone';
import { newRequestFileExtension } from '../RequestOfflineLicenseStandalone/offlineRequestConst';
import { IAdminCustomerUserResponse } from '../../models/AdminCustomerUserResponse';
import { getBoolFilterOperator } from '../../filterOperators/boolFilterOperator';
import { useDefaultSorting } from '../../hooks/useDefaultSorting';
import { routeWithQuery } from '../../helpers/routeWithQuery';
import { ifError } from '../../helpers/ifError';

interface IUrlParams {
  userPackageId?: string;
}

const LicensesFetcher = createFetcher(() => useLicenseApi().getLicenses);

const StatusRenderer = createStatusChipComponent({
  valid: ['common.licenseStatus.valid', 'lime'],
  suspended: ['common.licenseStatus.suspended', 'red'],
  expiresSoon: ['common.licenseStatus.expiresSoon', 'orange'],
  expired: ['common.licenseStatus.expired', 'default'],
});

const NotInUse = styled('span')(({ theme }) => ({
  color: theme.palette.text.disabled,
}));

const LicensesContent: FC = () => {
  const { data: licenses, onRefresh } = useContext(LicensesFetcher.Context);
  const { userPackageId } = useIntParams<IUrlParams>();
  const isOfflineDialogOpen = useIsRoute(UserRoutes.LicenseOffline);
  const isReleaseDialogOpen = useIsRoute(UserRoutes.LicenseRelease);
  const isRequestOfflineOpen = useIsRoute(UserRoutes.RequestOffline);
  const { search } = useLocation();
  const { t } = useTranslation();
  const requestOfflineUrl = useRouteWithQuery(UserRoutes.RequestOffline, []);
  useDefaultSorting('isAddon', 'asc');
  const history = useHistory();

  const closeDialog = useCloseDialog(UserRoutes.Licenses());

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'license',
        headerName: t('common.tableHeader.license'),
        flex: 1,
        renderCell: ({ value, row }) => (
          <CellContentTooltip>
            {row.isAddon ? <Chip color="info" label={t('assignLicenses.addonChip')} /> : null} {value}
          </CellContentTooltip>
        ),
      },
      {
        field: 'expiration',
        headerName: t('common.tableHeader.expires'),
        width: 190,
        renderCell: ({ row }) => {
          return <LicenseExpiry type={row.type} expiry={row.expiration} />;
        },
        valueFormatter: getDateValueFormatter(DateFormat.DateMedium),
      },
      {
        field: 'status',
        headerName: t('common.tableHeader.status'),
        width: 120,
        renderCell: StatusRenderer,
        filterOperators: [
          getEnumIsFilterOperator({
            [t('common.licenseStatus.valid')]: ['valid', 'expiresSoon'],
            [t('common.licenseStatus.expiresSoon')]: ['expiresSoon'],
            [t('common.licenseStatus.expired')]: ['expired'],
            [t('common.licenseStatus.suspended')]: ['suspended'],
          }),
          getEnumIsNotFilterOperator({
            [t('common.licenseStatus.valid')]: ['valid', 'expiresSoon'],
            [t('common.licenseStatus.expiresSoon')]: ['expiresSoon'],
            [t('common.licenseStatus.expired')]: ['expired'],
            [t('common.licenseStatus.suspended')]: ['suspended'],
          }),
        ],
      },
      {
        field: 'inUseOn',
        headerName: t('common.tableHeader.inUseOn'),
        width: 120,
        renderCell: (params) => {
          if (params.value === t('common.notInUse')) return <NotInUse>{params.value}</NotInUse>;
          return <CellContentTooltip>{params.value}</CellContentTooltip>;
        },
      },
      {
        field: 'mode',
        headerName: t('common.tableHeader.mode'),
        width: 120,
        filterOperators: [
          getEnumIsFilterOperator({
            [t('common.licenseMode.notInUse')]: [LicenseMode.NotInUse],
            [t('common.licenseMode.online')]: [LicenseMode.Online],
            [t('common.licenseMode.offline')]: [LicenseMode.OfflineDirector, LicenseMode.OfflineFile],
          }),
          getEnumIsNotFilterOperator({
            [t('common.licenseMode.notInUse')]: [LicenseMode.NotInUse],
            [t('common.licenseMode.online')]: [LicenseMode.Online],
            [t('common.licenseMode.offline')]: [LicenseMode.OfflineDirector, LicenseMode.OfflineFile],
          }),
        ],
        renderCell: LicenseModeChip,
      },
      {
        field: 'buttons',
        headerName: t('common.tableHeader.buttons'),
        renderHeader: () => ' ',
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          return (
            <RowButtonsContainer>
              {params.row.mode === LicenseModeType.OfflineFile ? (
                <Button
                  component={Link}
                  to={`${UserRoutes.LicenseOffline(params.row.id)}${search}`}
                  variant="outlined"
                  color="primary"
                >
                  {t('licenses.table.downloadButton')}
                </Button>
              ) : null}

              {params.row.mode === LicenseModeType.NotInUse ? null : (
                <Button
                  component={Link}
                  to={`${UserRoutes.LicenseRelease(params.row.id)}${search}`}
                  variant="outlined"
                  color="primary"
                >
                  {t('licenses.table.releaseButton')}
                </Button>
              )}
            </RowButtonsContainer>
          );
        },
        width: 280,
      },
      {
        field: 'isAddon',
        headerName: t('licenses.filter.isAddon'),
        hide: true,
        hideable: false,
        sortable: false,
        filterOperators: [getBoolFilterOperator()],
      },
    ],
    [search]
  );

  const userPackage = licenses?.userPackages.find((userPackage) => {
    return userPackage.package.userPackage.id === userPackageId;
  });

  const { offlineRequest, offlineRelease, onlineRelease, offlineRequestWithAddons, offlineReleaseWithAddons } =
    useLicenseApi();

  const packagesOnSameLicense = useMemo(() => {
    if (!licenses || !userPackage) return [];

    return licenses.userPackages
      .filter((userPackageItem) => userPackageItem.offlineLicense === userPackage.offlineLicense)
      .map((userPackageItem) => userPackageItem.package);
  }, [licenses?.userPackages, userPackage]);

  const onRequestOffline = useCallback(
    async (file: File, basePackage: number, addonPackages: number[]) => {
      if (file.name.endsWith(newRequestFileExtension)) {
        await offlineRequestWithAddons(file, basePackage, addonPackages);
      } else {
        await offlineRequest(basePackage, file);
      }
      await onRefresh();
      history.push(routeWithQuery(UserRoutes.LicenseOffline, [basePackage], {}, history.location.search));
    },
    [offlineRequest, onRefresh, offlineRequestWithAddons]
  );

  const onOfflineRelease = useCallback(
    async (file: File) => {
      if (!userPackageId) return;
      if (file.name.toLowerCase().endsWith('.pxrelease')) {
        await offlineReleaseWithAddons(
          file,
          packagesOnSameLicense
            .filter((packageObj) => !packageObj.package.isAddon)
            .map((packageObj) => packageObj.userPackage.id),
          packagesOnSameLicense
            .filter((packageObj) => packageObj.package.isAddon)
            .map((packageObj) => packageObj.userPackage.id)
        );
      } else {
        await offlineRelease(userPackageId, file);
      }
      await onRefresh();
    },
    [userPackageId, offlineRelease, offlineReleaseWithAddons, onRefresh, packagesOnSameLicense]
  );
  const onOnlineRelease = useCallback(async () => {
    if (!userPackageId) return;
    const bundled = userPackage?.package?.package?.tools?.bundledWith;
    const bundledIds =
      licenses?.userPackages
        ?.filter(
          (userPackage) =>
            bundled?.includes(userPackage?.license?.packageId) &&
            getLicenseMode(userPackage.machine, userPackage.license, userPackage.offlineLicense) ===
              LicenseModeType.Online
        )
        ?.map((userPackage) => userPackage?.license?.userPackageId) || [];

    await Promise.all([userPackageId, ...bundledIds].map((userPackageId) => onlineRelease(userPackageId)));
    closeDialog();
    await onRefresh();
  }, [userPackageId, onlineRelease, onRefresh]);

  const rows = useMemo(() => {
    if (!licenses) return null;
    return licenses.userPackages.map((userPackage) => {
      const expirationDate = getDateObject(userPackage.license.expiry);
      const mode = getLicenseMode(userPackage.machine, userPackage.license, userPackage.offlineLicense);

      return {
        id: ifError(() => userPackage.package.userPackage.id, 0),
        license: ifError(() => `${userPackage.package.package.name} | ${userPackage.package.plan.name}`, ''),
        offlineLicense: ifError(() => userPackage.offlineLicense, ''),
        type: ifError(() => userPackage.package.plan.type, undefined),
        expiration: ifError(() => userPackage.license.expiry, undefined),
        status: ifError(
          () => getLicenseStatus(userPackage.license.suspended, expirationDate, userPackage.package.plan.type),
          ''
        ),
        inUseOn: ifError(() => (mode === LicenseMode.NotInUse ? t('common.notInUse') : userPackage.machine?.name), ''),
        mode,
        isAddon: ifError(() => userPackage.package.package.isAddon, undefined),
      };
    });
  }, [licenses]);

  const quickFilters = useMemo<IQuickFiltersProps['quickFilters']>(
    () =>
      new Map([
        [
          t('common.quickFilter.valid'),
          {
            filterField: 'status',
            filterOp: 'equals',
            filterVal: 'valid,expiresSoon',
            isDefault: true,
          },
        ],
        [
          t('common.quickFilter.suspended'),
          {
            filterField: 'status',
            filterOp: 'equals',
            filterVal: 'suspended',
            isVisible: (rows || []).some((row) => row.status === 'suspended'),
          },
        ],
        [
          t('common.quickFilter.expired'),
          {
            filterField: 'status',
            filterOp: 'equals',
            filterVal: 'expired',
            isVisible: (rows || []).some((row) => row.status === 'expired'),
          },
        ],
        [
          t('licenses.filter.basePackages'),
          {
            filterField: 'isAddon',
            filterOp: 'equals',
            filterVal: 'false',
            isVisible: (rows || []).some((row) => row.isAddon),
          },
        ],
        [
          t('licenses.filter.addonPackages'),
          {
            filterField: 'isAddon',
            filterOp: 'equals',
            filterVal: 'true',
            isVisible: (rows || []).some((row) => row.isAddon),
          },
        ],
      ]),
    [rows]
  );

  const filteredRows = useFreeTextFilter(rows ?? [], columns);

  const packagesForOffline = useMemo<IAdminCustomerUserResponse['machinePackages']>(() => {
    if (!licenses?.userPackages) return [];
    return licenses.userPackages.map((packageData) => {
      return {
        package: {
          package: packageData.package.package,
          userPackage: packageData.package.userPackage,
          plan: packageData.package.plan,
        },
        plan: packageData.package.plan,
        license: packageData.license,
        machine: packageData.machine,
        offlineLicense: packageData.offlineLicense,
      };
    });
  }, [licenses]);

  if (!rows) return null;
  return (
    <div>
      <Grid container>
        <Grid item xs={12} md={8}>
          <EasyFilter quickFilters={quickFilters} />
        </Grid>
        <Grid item xs={12} md={4} display="flex" justifyContent="right" alignItems="center">
          <Button component={Link} to={requestOfflineUrl} variant="outlined" color="primary">
            {t('licenses.table.requestButton')}
          </Button>
        </Grid>
      </Grid>

      <UrlQueryDataGrid
        columns={columns}
        rows={filteredRows}
        disableExtendRowFullWidth={false}
        autoHeight={true}
        disableSelectionOnClick={true}
      />

      {userPackage ? (
        <LicenseContext.Provider
          value={{
            license: userPackage.license,
            offlineLicense: userPackage.offlineLicense,
            machine: userPackage.machine,
            plan: userPackage.package.plan,
            packageDetails: userPackage.package.package,
            userPackageDetails: userPackage.package.userPackage,
            onOfflineRelease,
            onOnlineRelease,
            getLicenseRoute: UserRoutes.LicenseOffline,
            releaseLicenseRoute: UserRoutes.LicenseRelease,
            packagesOnSameLicense,
          }}
        >
          <OfflineAccessStepsDialog open={isOfflineDialogOpen} onClose={closeDialog} />
          <ReleaseLicenseStepsDialog open={isReleaseDialogOpen} onClose={closeDialog} />
        </LicenseContext.Provider>
      ) : null}

      <RequestOfflineLicenseStandalone
        machinePackages={packagesForOffline}
        onSubmit={onRequestOffline}
        open={isRequestOfflineOpen}
        onClose={closeDialog}
      />
    </div>
  );
};

export const Licenses: FC = () => {
  const { t } = useTranslation();

  return (
    <LicensesFetcher.WAF>
      <PageHeader title={t('licenses.header')} />

      <LicensesContent />
    </LicensesFetcher.WAF>
  );
};
