import React, { FC, useCallback, useRef } from 'react';
import { IUserDownloadsVersionInstaller } from '../../hooks/useDownloadApi';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useDateFormat } from '../../hooks/useDateFormat';
import { displayFilesize } from '../../helpers/displayFilesize';
import { combineCn } from '../../helpers/combineCn';
import { useDownload } from '../../hooks/useDownload';
import { useInjectedAnchors } from '../../hooks/useInjectedAnchors';
import { useShowMore, ShowMoreState } from '../../hooks/useShowMore';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { DateFormat } from '../../helpers/dateFormat';
import GetApp from '@mui/icons-material/GetApp';
import styled from '@mui/styles/styled';
import { useTranslation } from 'react-i18next';
import { Changelog } from '../Changelog/Changelog';
import { Divider, Hidden } from '@mui/material';

interface IProps {
  installer: IUserDownloadsVersionInstaller;
}

const useStyles = makeStyles((theme) => {
  return createStyles({
    changelog: {
      '& a[data-toggle]': {
        display: 'none',
      },
      marginBottom: theme.spacing(3),
    },
    changelogCollapsed: {
      maxHeight: 400,
      overflow: 'hidden',
      position: 'relative',
      '&::after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: 50,
        background: `linear-gradient(to bottom, transparent, ${theme.palette.background.paper})`,
      },
    },
    showMore: {
      cursor: 'pointer',
    },
  });
});

const StyledRoot = styled(Grid)(({ theme }) => ({
  padding: `${theme.spacing(2.25)} ${theme.spacing(2)}`,
}));

export const ProductVersionInstallerDownloads: FC<IProps> = ({ installer }) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const changelogRef = useRef<HTMLDivElement>(null);
  const dateFormatted = useDateFormat(installer.manifestFileVersion.released, DateFormat.DateFull);
  const download = useDownload();
  const apiErrorHandler = useApiErrorHandler();
  useInjectedAnchors(changelogRef);
  const { showMore, setShowMore } = useShowMore(changelogRef, 400);

  const { version, changelog } = installer.manifestFileVersion;

  const onDownload = useCallback(async () => {
    if (!installer.file) {
      return;
    }
    try {
      await download(installer.file.Key);
    } catch (e) {
      apiErrorHandler(e, t('downloads.downloadFailureMessage'));
    }
  }, [download, installer.file?.Key]);

  return (
    <StyledRoot container>
      <Grid item md={3} xs={12}>
        <Box marginBottom={2}>
          <Typography variant="h5">{version}</Typography>
        </Box>
        <Box marginBottom={2}>
          <Typography variant="subtitle2">{t('downloads.releaseDate', { date: dateFormatted })}</Typography>
        </Box>

        {installer.file ? (
          <Box marginBottom={2}>
            <Typography variant="subtitle2">
              {t('downloads.size', { size: displayFilesize(installer.file?.Size || 0) })}
            </Typography>
          </Box>
        ) : null}

        {installer.file ? (
          <Button variant="contained" color="primary" onClick={onDownload} endIcon={<GetApp />}>
            {t('downloads.downloadButton')}
          </Button>
        ) : null}
      </Grid>

      <Hidden mdUp>
        <Grid item md={9} xs={12}>
          <Box pt={2} pb={2}>
            <Divider />
          </Box>
        </Grid>
      </Hidden>

      <Grid item md={9} xs={12}>
        <Changelog
          ref={changelogRef}
          className={combineCn({
            [styles.changelog]: true,
            [styles.changelogCollapsed]: showMore === ShowMoreState.HIDDEN,
          })}
          dangerouslySetInnerHTML={{ __html: changelog }}
        />
        {showMore === ShowMoreState.HIDDEN ? (
          <Link className={styles.showMore} onClick={setShowMore} underline="hover">
            {t('common.readMore')}
          </Link>
        ) : null}
      </Grid>
    </StyledRoot>
  );
};
