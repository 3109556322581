import React, { FC } from 'react';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

interface IProps {
  aspectRatio: number;
}

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      width: '100%',
      position: 'relative',
    },
    child: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  });
});

export const AspectRatio: FC<IProps> = ({ aspectRatio, children }) => {
  const styles = useStyles();

  return (
    <div className={styles.root} style={{ paddingBottom: `${(1 / aspectRatio) * 100}%` }}>
      <div className={styles.child}>{children}</div>
    </div>
  );
};
