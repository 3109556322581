import React, { FC, useCallback, useContext, useMemo } from 'react';
import { UrlQueryDataGrid } from '../UrlQueryDataGrid/UrlQueryDataGrid';
import { createNoParamsServerSidePaginatedFetcher } from '../../contexts/Fetcher';
import { useUsersApi } from '../../hooks/useUsersApi';
import { GridColumns } from '@mui/x-data-grid';
import { PageHeader } from '../PageHeader/PageHeader';
import { getDateValueFormatter } from '../../valueFormatters/DateValueFormatter';
import { DateFormat } from '../../helpers/dateFormat';
import { getDateAfterFilterOperator, getDateBeforeFilterOperator } from '../../filterOperators/dateFilterOperators';
import { getStringEqualsOperator } from '../../filterOperators/stringEqualsOperator';
import { getGreaterThanOperator } from '../../filterOperators/greaterThanOperator';
import { getLessThanOperator } from '../../filterOperators/lessThanOperator';
import { getStringDiffersOperator } from '../../filterOperators/stringDiffersOperator';
import { AdminRoutes } from '../../routers/AdminRoutes';
import { IQuickFiltersProps } from '../EasyFilter/QuickFilters';
import { EasyFilter } from '../EasyFilter/EasyFilter';
import { CellContentTooltip } from '../CellContentTooltip/CellContentTooltip';
import { createStatusChipComponent } from '../StatusChip/StatusChip';
import { useDefaultSorting } from '../../hooks/useDefaultSorting';
import { useTranslation } from 'react-i18next';
import { getStringContainsOperator } from '../../filterOperators/stringContainsOperator';
import { AdminRouteLink, useIsAdminRouteAllowed } from '../RouteLink/RouteLink';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';

const AllUsersFetcher = createNoParamsServerSidePaginatedFetcher(() => useUsersApi().getAllUsers);

const Status = createStatusChipComponent({
  unverified: ['common.accountStatus.unverified', 'orange'],
});

const AllUsersContent: FC = () => {
  const { data } = useContext(AllUsersFetcher.Context);
  const { t } = useTranslation();

  const quickFilters = useMemo<IQuickFiltersProps['quickFilters']>(
    () =>
      new Map([
        [
          t('common.quickFilter.unverified'),
          {
            filterField: 'verified',
            filterOp: 'equals',
            filterVal: 'false',
          },
        ],
      ]),
    []
  );

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'id',
        headerName: t('common.tableHeader.id'),
        filterOperators: [getStringEqualsOperator(), getGreaterThanOperator(), getLessThanOperator()],
      },
      {
        field: 'firstName',
        headerName: t('common.tableHeader.firstName'),
        flex: 1,
        filterOperators: [getStringContainsOperator(), getStringEqualsOperator(), getStringDiffersOperator()],
      },
      {
        field: 'lastName',
        headerName: t('common.tableHeader.lastName'),
        flex: 1,
        filterOperators: [getStringContainsOperator(), getStringEqualsOperator(), getStringDiffersOperator()],
      },
      {
        field: 'email',
        headerName: t('common.tableHeader.email'),
        flex: 2,
        filterOperators: [getStringContainsOperator(), getStringEqualsOperator()],
        renderCell: (params) => {
          const { archived, verified } = params.row;

          if (!verified || archived) {
            const parts: string[] = [];
            if (!verified) parts.push(t('common.accountStatus.unverified'));
            if (archived) parts.push(t('common.accountStatus.archived'));
            parts.push(params.value);
            return (
              <Tooltip title={parts.join(' | ')}>
                <span>
                  {params.value} {verified === false ? <Status {...params} value={'unverified'} /> : null}
                </span>
              </Tooltip>
            );
          } else {
            return <CellContentTooltip>{params.value}</CellContentTooltip>;
          }
        },
      },
      {
        field: 'customer',
        headerName: t('adminUsers.table.customerHeader'),
        flex: 1,
        filterOperators: [getStringContainsOperator()],
        renderCell: (params) => (
          <Tooltip title={params.row.customerCompany}>
            <Link
              component={AdminRouteLink}
              route="Customer"
              params={[params.row.customerId]}
              underline="hover"
              showFallback
            >
              {params.value}
            </Link>
          </Tooltip>
        ),
      },
      {
        field: 'created',
        headerName: t('common.tableHeader.created'),
        width: 130,
        valueFormatter: getDateValueFormatter(DateFormat.DateMedium),
        filterOperators: [getDateBeforeFilterOperator(), getDateAfterFilterOperator()],
      },
      {
        field: 'updated',
        headerName: t('common.tableHeader.updated'),
        width: 130,
        valueFormatter: getDateValueFormatter(DateFormat.DateMedium),
        filterOperators: [getDateBeforeFilterOperator(), getDateAfterFilterOperator()],
      },
    ],
    []
  );

  const getRowLink = useCallback((params) => {
    return AdminRoutes.CustomerUser(params.row.customerId, params.row.id);
  }, []);
  const isGetRowLinkAllowed = useIsAdminRouteAllowed('CustomerUser');

  const rows = useMemo(() => {
    if (!data) return [];
    return data.data.map(({ customer, user }) => ({
      ...user,
      archived: customer.archived,
      customer: `${customer.firstName} ${customer.lastName}`,
      customerCompany: customer.company,
      customerId: customer.id,
    }));
  }, [data]);

  if (!data) return null;
  return (
    <>
      <EasyFilter quickFilters={quickFilters} />
      <UrlQueryDataGrid
        columns={columns}
        rows={rows}
        disableExtendRowFullWidth={false}
        autoHeight={true}
        disableSelectionOnClick={true}
        paginationMode="server"
        filterMode="server"
        sortingMode="server"
        getRowLink={isGetRowLinkAllowed ? getRowLink : void 0}
        rowCount={data.page.data.total}
        getRowClassName={(params) => (params.row.archived ? 'Mui-disabled' : '')}
      />
    </>
  );
};

export const AllUsers: FC = () => {
  const { t } = useTranslation();
  const preventRender = useDefaultSorting('id', 'desc');
  if (preventRender) return null;

  return (
    <AllUsersFetcher.WAF>
      <PageHeader title={t('adminUsers.header')} />
      <AllUsersContent />
    </AllUsersFetcher.WAF>
  );
};
