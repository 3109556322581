import React, { FC, useCallback, useContext, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { CustomerFetcher } from '../Customer/CustomerFetchers';
import { createFetcher, useAutoFetch } from '../../contexts/Fetcher';
import { useCustomersApi } from '../../hooks/useCustomersApi';
import { UrlQueryDataGrid } from '../UrlQueryDataGrid/UrlQueryDataGrid';
import { GridColumns, GridRowData, GridRowParams } from '@mui/x-data-grid';
import { LicenseExpiry } from '../LicenseExpiry/LicenseExpiry';
import { getDateValueFormatter } from '../../valueFormatters/DateValueFormatter';
import { dateFormat, DateFormat } from '../../helpers/dateFormat';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Checkbox from '@mui/material/Checkbox';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { AdminRoutes } from '../../routers/AdminRoutes';
import { getLicenseMode, LicenseMode } from '../../helpers/licenseMode';
import { getEnumIsFilterOperator, getEnumIsNotFilterOperator } from '../../filterOperators/enumFilterOperator';
import { getDateAfterFilterOperator, getDateBeforeFilterOperator } from '../../filterOperators/dateFilterOperators';
import { PageHeader } from '../PageHeader/PageHeader';
import { CustomerProductLicenseDetails } from './CustomerProductLicenseDetails';
import { useDecodedParams } from '../../hooks/useDecodedParams';
import { DangerousButton } from '../CustomButtons/DangerousButton';
import { CustomerProductRenew } from './CustomerProductRenew';
import { CustomerProductEdit } from './CustomerProductEdit';
import { CustomerProductSuspend } from './CustomerProductSuspend';
import { ConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialog';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { useTranslation } from 'react-i18next';
import { getLicenseStatus } from '../../helpers/licenseStatus';
import { createStatusChipComponent } from '../StatusChip/StatusChip';
import { useIntParams } from '../../hooks/useIntParams';
import { AdminRouteLink, useIsAdminRouteAllowed } from '../RouteLink/RouteLink';
import { LicenseModeChip } from '../LicenseModeChip/LicenseModeChip';
import { ifError } from '../../helpers/ifError';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useFormattedNow } from '../../hooks/useFormattedNow';
import dayjs from 'dayjs';

export type CustomerProductAction = 'renew' | 'suspend' | 'revoke' | 'reinstate' | 'edit';

interface IUrlParams {
  customerId: string;
  customerProductId: string;
  licenseId?: string;
  action?: CustomerProductAction;
}

export const CustomerProductFetcher = createFetcher(() => {
  const { getCustomerProduct } = useCustomersApi();
  const { customerId, customerProductId } = useIntParams<IUrlParams>();

  return useCallback(async () => {
    return getCustomerProduct([customerId, customerProductId]);
  }, [getCustomerProduct, customerId, customerProductId]);
});

const useStyles = makeStyles((theme) =>
  createStyles({
    buttonsContainer: {
      display: 'flex',
      gap: theme.spacing(1),
    },
    checkboxesContainer: {
      display: 'flex',
      gap: theme.spacing(1),
      marginTop: theme.spacing(2),
      justifyContent: 'flex-start',
      width: '100%',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
  })
);

const Status = createStatusChipComponent({
  valid: ['common.licenseStatus.valid', 'lime'],
  expired: ['common.licenseStatus.expired', 'default'],
  expiresSoon: ['common.licenseStatus.expiresSoon', 'orange'],
  suspended: ['common.licenseStatus.suspended', 'red'],
});

const CustomerProductContent: FC = () => {
  const { data: customerData } = useContext(CustomerFetcher.Context);
  const { data, onRefresh } = useContext(CustomerProductFetcher.Context);
  const { action, customerId, licenseId } = useDecodedParams<IUrlParams>();
  const id = parseInt(customerId, 10);
  const history = useHistory();
  const styles = useStyles();
  const { search } = useLocation();
  const { reinstateCustomerProduct, revokeCustomerProduct } = useCustomersApi();
  const handleError = useApiErrorHandler();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  useAutoFetch(CustomerFetcher);

  const reinstate = useCallback(async () => {
    if (!customerData || !data) return;
    try {
      await reinstateCustomerProduct([customerData.customer.id, data.customerProduct.id]);
      await onRefresh();
      closeDialog();
    } catch (e) {
      handleError(e);
    }
  }, [reinstateCustomerProduct, customerData, data]);

  const revoke = useCallback(async () => {
    if (!customerData || !data) return;
    try {
      await revokeCustomerProduct([customerData.customer.id, data.customerProduct.id]);
      history.push(AdminRoutes.Customer(customerData.customer.id));
    } catch (e) {
      handleError(e);
    }
  }, [revokeCustomerProduct, customerData, data]);

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'package',
        headerName: t('customerProduct.table.packageHeader'),
        flex: 2,
      },
      {
        field: 'license',
        headerName: t('common.tableHeader.license'),
        flex: 3,
      },
      {
        field: 'status',
        headerName: t('common.tableHeader.status'),
        width: 110,
        filterOperators: [
          getEnumIsFilterOperator({
            [t('common.licenseStatus.valid')]: ['valid', 'expiresSoon'],
            [t('common.licenseStatus.expiresSoon')]: ['expiresSoon'],
            [t('common.licenseStatus.expired')]: ['expired'],
            [t('common.licenseStatus.suspended')]: ['suspended'],
          }),
          getEnumIsNotFilterOperator({
            [t('common.licenseStatus.valid')]: ['valid', 'expiresSoon'],
            [t('common.licenseStatus.expiresSoon')]: ['expiresSoon'],
            [t('common.licenseStatus.expired')]: ['expired'],
            [t('common.licenseStatus.suspended')]: ['suspended'],
          }),
        ],
        renderCell: Status,
      },
      {
        field: 'expires',
        headerName: t('common.tableHeader.expires'),
        renderCell: (params) => {
          return <LicenseExpiry type={params.row.planType} expiry={params.row.expires} />;
        },
        width: 190,
        filterOperators: [getDateBeforeFilterOperator(), getDateAfterFilterOperator()],
      },
      {
        field: 'created',
        headerName: t('common.tableHeader.created'),
        valueFormatter: getDateValueFormatter(DateFormat.DateMedium),
        width: 130,
        filterOperators: [getDateBeforeFilterOperator(), getDateAfterFilterOperator()],
      },
      {
        field: 'assignedTo',
        headerName: t('common.tableHeader.assignedTo'),
        flex: 1,
        renderCell: (params) => {
          if (params.value) {
            return (
              <Link
                component={AdminRouteLink}
                route="CustomerUser"
                params={[id, params.row.assigneeId]}
                underline="hover"
                showFallback
              >
                {params.value}
              </Link>
            );
          }
          return null;
        },
      },
      {
        field: 'inUseOn',
        headerName: t('common.tableHeader.inUseOn'),
        width: 140,
        renderCell: (params) => {
          if (params.value) {
            return (
              <Link
                component={AdminRouteLink}
                route="CustomerMachine"
                params={[id, params.row.assigneeId ?? 0, params.row.machineId]}
                underline="hover"
                showFallback
              >
                {params.value}
              </Link>
            );
          }
          return null;
        },
      },
      {
        field: 'mode',
        headerName: t('common.tableHeader.mode'),
        width: 100,
        filterOperators: [
          getEnumIsFilterOperator({
            'not in use': [LicenseMode.NotInUse],
            offline: [LicenseMode.OfflineDirector, LicenseMode.OfflineFile],
            online: [LicenseMode.Online],
          }),
          getEnumIsNotFilterOperator({
            'not in use': [LicenseMode.NotInUse],
            offline: [LicenseMode.OfflineDirector, LicenseMode.OfflineFile],
            online: [LicenseMode.Online],
          }),
        ],
        renderCell: LicenseModeChip,
      },
    ],
    []
  );

  const rows = useMemo<GridRowData[]>(() => {
    return (data?.product.licenses ?? []).map((license) => {
      const mode = getLicenseMode(license.inUseOn, license, void 0);
      return {
        id: ifError(() => license.id, '-'),
        package: ifError(() => license.packageName, '-'),
        license: ifError(() => license.name, '-'),
        licenseId: ifError(() => license.id, ''),
        status: ifError(() => getLicenseStatus(license.status, license.expiry, license.plan.type), '-'),
        expires: ifError(() => license.expiry, undefined),
        created: ifError(() => license.created, undefined),
        assignedTo: ifError(
          () => (license.assignedTo ? `${license.assignedTo.firstName} ${license.assignedTo.lastName}` : ''),
          ''
        ),
        assigneeId: ifError(() => license.assignedTo?.id, undefined),
        inUseOn: ifError(() => license.inUseOn?.name, undefined),
        machineId: ifError(() => license.inUseOn?.id, undefined),
        mode,
        planType: ifError(() => license.plan.type, undefined),
      };
    });
  }, [data]);

  const closeDialog = useCallback(() => {
    if (!data) return;
    history.push(`${AdminRoutes.CustomerProduct(id, data.customerProduct.id)}${history.location.search}`);
  }, [data]);

  const getRowLink = useCallback(
    (params: GridRowParams) => {
      if (!data) return '';
      return `${AdminRoutes.CustomerProductLicense(id, data.customerProduct.id, params.row.licenseId)}${search}`;
    },
    [data, search]
  );
  const isRowLinkAllowed = useIsAdminRouteAllowed('CustomerProductLicense');

  const formattedNow = useFormattedNow(DateFormat.DateFullWithTimeAndZone);
  const now = useMemo(() => dayjs().second(0), [formattedNow]);

  const currentExpiry = data ? dayjs(data.product.licenses[0].expiry * 1000) : now;

  const effectivDate = currentExpiry.isAfter(now) ? currentExpiry : now;

  const expiresAt = dateFormat(effectivDate, DateFormat.DateFullWithTimeAndZone, language);

  if (!customerData || !data) return null;
  return (
    <div>
      <PageHeader
        title={data.product.plan.name}
        crumbs={[
          {
            url: AdminRoutes.Customers(),
            title: t('layout.adminNavigation.customers'),
          },
          {
            url: AdminRoutes.CustomerTab(customerData.customer.id, 'products'),
            title: `${customerData.customer.firstName} ${customerData.customer.lastName}`,
          },
        ]}
      >
        <div className={styles.buttonsContainer}>
          <Button
            component={AdminRouteLink}
            route="CustomerProductAction"
            params={[customerData.customer.id, data.customerProduct.id, 'renew']}
            keepQuery
            color="primary"
            variant="contained"
          >
            {t('customerProduct.renewButton')}
          </Button>
          <Button
            component={AdminRouteLink}
            route="CustomerProductAction"
            params={[customerData.customer.id, data.customerProduct.id, 'edit']}
            keepQuery
            color="primary"
            variant="contained"
          >
            {t('customerProduct.editButton')}
          </Button>
          {data.product.suspended ? (
            <Button
              component={AdminRouteLink}
              route="CustomerProductAction"
              params={[customerData.customer.id, data.customerProduct.id, 'reinstate']}
              keepQuery
              variant="outlined"
              color="primary"
            >
              {t('customerProduct.reinstateButton')}
            </Button>
          ) : (
            <Button
              component={AdminRouteLink}
              route="CustomerProductAction"
              params={[customerData.customer.id, data.customerProduct.id, 'suspend']}
              keepQuery
              variant="outlined"
            >
              {t('customerProduct.suspendButton')}
            </Button>
          )}
          <DangerousButton
            component={AdminRouteLink}
            route="CustomerProductAction"
            params={[customerData.customer.id, data.customerProduct.id, 'revoke']}
            keepQuery
            disabled={data.product.licenses.some((license) => !!license.assignedTo || !!license.inUseOn)}
          >
            {t('customerProduct.revokeButton')}
          </DangerousButton>
        </div>
      </PageHeader>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className={styles.checkboxesContainer}>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                {t('common.tableHeader.expires')}: {expiresAt ?? '-'}
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox checked={!!data.customerProduct.properties.notification_expiring_30_days} disabled />
                  }
                  label={t('customerProduct.notificationEditDialog.notification_expiring_30_days')}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox checked={!!data.customerProduct.properties.notification_reneval_30_days} disabled />
                  }
                  label={t('customerProduct.notificationEditDialog.notification_reneval_30_days')}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox checked={!!data.customerProduct.properties.notification_reneval_60_days} disabled />
                  }
                  label={t('customerProduct.notificationEditDialog.notification_reneval_60_days')}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox checked={!!data.customerProduct.properties.notification_reneval_90_days} disabled />
                  }
                  label={t('customerProduct.notificationEditDialog.notification_reneval_90_days')}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox checked={!!data.customerProduct.properties.notification_reneval_180_days} disabled />
                  }
                  label={t('customerProduct.notificationEditDialog.notification_reneval_180_days')}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox checked={!!data.customerProduct.properties.notification_reneval_270_days} disabled />
                  }
                  label={t('customerProduct.notificationEditDialog.notification_reneval_270_days')}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>

      <UrlQueryDataGrid
        columns={columns}
        rows={rows}
        disableExtendRowFullWidth={false}
        autoHeight={true}
        disableSelectionOnClick={true}
        getRowLink={isRowLinkAllowed ? getRowLink : void 0}
      />

      <CustomerProductLicenseDetails closeDialog={closeDialog} licenseId={licenseId} />
      <CustomerProductRenew open={action === 'renew'} closeDialog={closeDialog} />
      <CustomerProductEdit open={action === 'edit'} closeDialog={closeDialog} />
      <CustomerProductSuspend open={action === 'suspend'} closeDialog={closeDialog} />
      <ConfirmationDialog
        open={action === 'reinstate'}
        title={t('customerProduct.reinstateDialog.title')}
        message={t('customerProduct.reinstateDialog.message')}
        confirm={reinstate}
        abort={closeDialog}
      />
      <ConfirmationDialog
        open={action === 'revoke'}
        title={t('customerProduct.revokeDialog.title')}
        message={t('customerProduct.revokeDialog.message')}
        confirm={revoke}
        abort={closeDialog}
        dangerous
      />
    </div>
  );
};

export const CustomerProduct: FC = () => {
  return (
    <CustomerProductFetcher.WAF>
      <CustomerProductContent />
    </CustomerProductFetcher.WAF>
  );
};
