import { apiEndpoint } from '../apiEndpoint';

export const CustomerUsersEndpoints = {
  CustomerUser: apiEndpoint<[customerId: number, userId: number]>('/customers/:customer/users/:user'),
  Packages: apiEndpoint<[customerId: number, userId: number]>('/customers/:customer/users/:user/packages'),
  ReInvite: apiEndpoint<[customerId: number, userId: number]>('/customers/:customer/users/:user/reinvite'),
  Package: apiEndpoint<[customerId: number, userId: number, userPackageId: number]>(
    '/customers/:customer/users/:user/packages/:package'
  ),
  PackageOffline: apiEndpoint<[customerId: number, userId: number, userPackageId: number]>(
    '/customers/:customer/users/:user/packages/:package/offline'
  ),
  ReleaseOnlinePackage: apiEndpoint<[customerId: number, userId: number, userPackageId: number]>(
    '/customers/:customer/users/:user/packages/:package/release'
  ),
  ForceReleaseOfflinePackage: apiEndpoint<[customerId: number, userId: number, userPackageId: number]>(
    '/customers/:customer/users/:user/packages/:package/release'
  ),
  ForceReleaseOfflinePackagesMultiple: apiEndpoint<[customerId: number, userId: number]>(
    '/customers/:customer/users/:user/packages/release'
  ),
  ReassignZendeskId: apiEndpoint<[customerId: number, userId: number]>(
    '/customers/:customer/users/:user/reassign-zendesk'
  ),
  PackagesOffline: apiEndpoint<[customerId: number, userId: number]>(
    '/customers/:customer/users/:user/packages/offline'
  ),
};
