import React, { FC, useMemo } from 'react';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { Trans, useTranslation } from 'react-i18next';
import { ErrorPage } from '../ErrorPage/ErrorPage';
import { Link as RouterLink } from 'react-router-dom';
import { config } from '../../config';
import { useHomeUrl } from '../../hooks/useHomeUrl';

interface IProps {
  status: number | null;
  message?: string;
  onRetry: () => void;
}

export const Retry: FC<IProps> = ({ status, message, onRetry }) => {
  const { t } = useTranslation();
  const homeUrl = useHomeUrl();

  const messageEl = useMemo(
    () => (
      <Trans i18nKey="errorPage.unexpected.message">
        <span />
        <Link underline="hover" href={`mailto:${config.supportEmail}`} />
      </Trans>
    ),
    []
  );

  const buttons = useMemo(
    () => (
      <>
        <Button variant="contained" onClick={onRetry}>
          {t('errorPage.button.retry')}
        </Button>
        <Button variant="outlined" component={RouterLink} to={homeUrl}>
          {t('errorPage.button.backHome')}
        </Button>
      </>
    ),
    [onRetry, homeUrl]
  );

  if (typeof status === 'number') {
    const extraTitle = `${status ? `${status} ` : ''}${t(`errorPage.http.${status}`)}`;
    return (
      <ErrorPage
        title={t('errorPage.unexpected.title')}
        message={messageEl}
        extraTitle={extraTitle}
        extraMessage={message}
      >
        {buttons}
      </ErrorPage>
    );
  } else {
    return (
      <ErrorPage title={t('errorPage.unexpected.title')} message={messageEl}>
        {buttons}
      </ErrorPage>
    );
  }
};
