import React, { ComponentProps, FC } from 'react';

const initialValue: FC = ({ children }) => <>{children}</>;

export const combineComponents = (...components: FC[]): FC => {
  return components.reduce((AccumulatedComponents, CurrentComponent) => {
    return ({ children }: ComponentProps<FC>): JSX.Element => {
      return (
        <AccumulatedComponents>
          <CurrentComponent>{children}</CurrentComponent>
        </AccumulatedComponents>
      );
    };
  }, initialValue);
};
