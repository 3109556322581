import React, { FC, useContext } from 'react';
import { isCustomerAccountResponse, isUserAccountResponse } from '../../models/AccountResponse';
import Button from '@mui/material/Button';
import Email from '@mui/icons-material/Email';
import { AccountFetcher } from '../../fetchers/AccountFetcher';
import { useTranslation } from 'react-i18next';

export const ContactAdminButton: FC = () => {
  const { t } = useTranslation();
  const { data } = useContext(AccountFetcher.Context);

  if (!data) return null;
  return isCustomerAccountResponse(data) ? (
    <Button
      component="a"
      variant="contained"
      color="primary"
      endIcon={<Email />}
      href={`mailto:${data.createdBy.email}`}
    >
      {t('nothing.contactAdmin')}
    </Button>
  ) : isUserAccountResponse(data) ? (
    <Button
      component="a"
      variant="contained"
      color="primary"
      endIcon={<Email />}
      href={`mailto:${data.customer.email}`}
    >
      {t('nothing.contactAdmin')}
    </Button>
  ) : null;
};
