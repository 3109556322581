import { useApi } from './useApi';
import { IAdminCustomerUserResponse } from '../models/AdminCustomerUserResponse';
import { IAssignLicensePayload, IUpdateUserPayload } from './useUsersApi';
import { IUserDetails } from '../models/UserDetails';
import { ApiRequest, useEndpoint, useSimpleEndpoint } from './api/apiEndpoint';
import { CustomerUsersEndpoints } from './api/endpoints/customerUsers';

export const useCustomerUsersApi = () => {
  const { del, get, post, put } = useApi();

  const getPackages = useSimpleEndpoint(
    CustomerUsersEndpoints.CustomerUser,
    get as ApiRequest<typeof get, IAdminCustomerUserResponse>
  );
  const assignLicense = useEndpoint(
    (post, payload: IAssignLicensePayload) => post(payload),
    CustomerUsersEndpoints.Packages,
    post as ApiRequest<typeof post, unknown>
  );

  const requestOfflineLicense = useEndpoint(
    (post, file: File) => {
      const formData = new FormData();
      formData.append('file', file);
      return post(formData);
    },
    CustomerUsersEndpoints.PackageOffline,
    post as ApiRequest<typeof post, void>
  );

  const requestOfflineLicenseWithAddons = useEndpoint(
    (post, file: File, basePackage: number, addons: number[]) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append(
        'data',
        new Blob(
          [
            JSON.stringify({
              basePackages: [basePackage],
              addonPackages: addons,
            }),
          ],
          {
            type: 'application/json',
          }
        )
      );
      return post(formData);
    },
    CustomerUsersEndpoints.PackagesOffline,
    post as ApiRequest<typeof post, void>
  );

  const releaseOfflineLicense = useEndpoint(
    (del, file: File) => {
      const formData = new FormData();
      formData.append('file', file);
      return del({ data: formData });
    },
    CustomerUsersEndpoints.PackageOffline,
    del as ApiRequest<typeof del, void>
  );

  const releaseOfflineLicenseWithAddons = useEndpoint(
    (del, file: File, basePackages: number[], addonPackages: number[]) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append(
        'data',
        new Blob(
          [
            JSON.stringify({
              basePackages,
              addonPackages,
            }),
          ],
          {
            type: 'application/json',
          }
        )
      );

      return del({ data: formData });
    },
    CustomerUsersEndpoints.PackagesOffline,
    del as ApiRequest<typeof del, void>
  );

  const releaseOnlineLicense = useSimpleEndpoint(CustomerUsersEndpoints.ReleaseOnlinePackage, del);
  const unassignPackage = useSimpleEndpoint(CustomerUsersEndpoints.Package, del);

  const updateUser = useEndpoint(
    (put, payload: Partial<IUpdateUserPayload>) => put(payload),
    CustomerUsersEndpoints.CustomerUser,
    put as ApiRequest<typeof put, IUserDetails>
  );

  const deleteUser = useSimpleEndpoint(CustomerUsersEndpoints.CustomerUser, del);
  const reInvite = useSimpleEndpoint(CustomerUsersEndpoints.ReInvite, post);
  const forceReleaseOfflineLicense = useSimpleEndpoint(CustomerUsersEndpoints.ForceReleaseOfflinePackage, del);

  const forceReleaseOfflinePackagesMultiple = useEndpoint(
    (del, basePackages: number[], addonPackages: number[]) => {
      return del({
        data: {
          basePackages,
          addonPackages,
        },
      });
    },
    CustomerUsersEndpoints.ForceReleaseOfflinePackagesMultiple,
    del as ApiRequest<typeof del, void>
  );

  const reassignZendeskId = useSimpleEndpoint(
    CustomerUsersEndpoints.ReassignZendeskId,
    post as ApiRequest<typeof post, IUserDetails>
  );

  return {
    assignLicense,
    deleteUser,
    forceReleaseOfflineLicense,
    forceReleaseOfflinePackagesMultiple,
    getPackages,
    reInvite,
    releaseOfflineLicense,
    releaseOnlineLicense,
    requestOfflineLicense,
    unassignPackage,
    updateUser,
    reassignZendeskId,
    requestOfflineLicenseWithAddons,
    releaseOfflineLicenseWithAddons,
  };
};
