import React, { FC } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { UseFormReturn } from 'react-hook-form';
import { TextInputController } from '../InputControllers/TextInputController';
import { CheckboxController } from '../InputControllers/CheckboxController';
import { IProductToolPayload } from '../../hooks/useProductToolsApi';
import { HiddenSubmit } from '../HiddenSubmit/HiddenSubmit';
import { useTranslation } from 'react-i18next';

interface IProps {
  form: UseFormReturn;
  onSubmit: (payload: IProductToolPayload) => void;
}

export const ProductToolForm: FC<IProps> = ({ onSubmit, form }) => {
  const { t } = useTranslation();

  return (
    <Grid component="form" container onSubmit={form.handleSubmit(onSubmit)}>
      <Grid item xs={12}>
        <TextInputController
          control={form.control}
          errors={form.formState.errors}
          label={t('adminProducts.tools.form.name')}
          name="key"
          rules={{ required: true }}
        />
      </Grid>
      <Grid item xs={12}>
        <CheckboxController
          control={form.control}
          errors={form.formState.errors}
          label={t('adminProducts.tools.form.filter.label')}
          name="filter"
        />
        <Typography variant="caption" display="block">
          {t('adminProducts.tools.form.filter.helper')}
        </Typography>
      </Grid>
      <HiddenSubmit />
    </Grid>
  );
};
