import { useApi } from './useApi';
import { IProductPlanPackage } from '../models/ProductPlanPackage';
import { ApiRequest, useEndpoint, useSimpleEndpoint } from './api/apiEndpoint';
import { ProductPackagesEndpoints } from './api/endpoints/packages';

interface IProductPackagePayload {
  name: string;
  tools: {
    data: Array<{
      amount: number;
      toolId: number;
    }>;
    bundledWith?: number[];
  };
  isAddon: boolean;
}

export const useProductPackagesApi = () => {
  const { del, get, post, put } = useApi();

  const getPackages = useSimpleEndpoint(
    ProductPackagesEndpoints.Packages,
    get as ApiRequest<typeof get, { packages: IProductPlanPackage[] }>
  );

  const createPackage = useEndpoint(
    (post, payload: IProductPackagePayload) => post(payload),
    ProductPackagesEndpoints.Packages,
    post as ApiRequest<typeof post, IProductPlanPackage>
  );

  const editPackage = useEndpoint(
    (put, payload: Partial<IProductPackagePayload>) => put(payload),
    ProductPackagesEndpoints.Package,
    put as ApiRequest<typeof put, IProductPlanPackage>
  );

  const deletePackage = useSimpleEndpoint(ProductPackagesEndpoints.Package, del);

  return {
    getPackages,
    createPackage,
    editPackage,
    deletePackage,
  };
};
