import React, { Component } from 'react';
import { UnexpectedError } from '../UnexpectedError/UnexpectedError';
import { logger } from '../../helpers/logger';

interface IProps {
  children: React.ReactChild;
}

interface IState {
  crashed: boolean;
}

export class ErrorBoundary extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      crashed: false,
    };
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    if (prevProps.children !== this.props.children) {
      this.setState({ crashed: false });
    }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    logger.error(error, errorInfo);
    this.setState({
      crashed: true,
    });
  }

  render() {
    if (this.state.crashed) {
      return <UnexpectedError />;
    } else {
      return this.props.children;
    }
  }
}
