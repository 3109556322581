import { useApi } from './useApi';
import { useCallback } from 'react';
import { ICustomerInvoicesResponse } from '../models/CustomerInvoicesResponse';

enum InvoicesEndpoints {
  Invoices = '/invoices',
}

export const useInvoicesApi = () => {
  const { get } = useApi();

  const getInvoices = useCallback(async () => {
    const result = await get<ICustomerInvoicesResponse>(InvoicesEndpoints.Invoices);
    return result.data;
  }, [get]);

  return {
    getInvoices,
  };
};
