import React, { ChangeEvent, FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useCustomersApi } from '../../hooks/useCustomersApi';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { CustomerFetcher } from '../Customer/CustomerFetchers';
import { CustomerProductFetcher } from './CustomerProduct';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { Trans, useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import { useScheduledJobsApi } from '../../hooks/useScheduledJobsApi';
import { TogglableDateFormPartial } from '../TogglableDateFormPartial/TogglableDateFormPartial';
import { useFormattedNow } from '../../hooks/useFormattedNow';
import { dateFormat, DateFormat } from '../../helpers/dateFormat';
import { Checkbox } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';

interface IProps {
  open: boolean;
  closeDialog: () => void;
}

export const CustomerProductRenew: FC<IProps> = ({ open, closeDialog }) => {
  const { renewCustomerProduct } = useCustomersApi();
  const { scheduleRenewProduct } = useScheduledJobsApi();
  const { data: customerData } = useContext(CustomerFetcher.Context);
  const { data: customerProductData, onRefresh } = useContext(CustomerProductFetcher.Context);
  const handleError = useApiErrorHandler();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [retro, setRetro] = useState<Dayjs | null>(null);
  const [scheduled, setScheduled] = useState<Dayjs | null>(null);
  const [disableNotification, setDisableNotification] = useState(false);

  const prevIsRetro = useRef(false);
  const prevIsScheduled = useRef(false);

  useEffect(() => {
    if (retro && scheduled) {
      if (prevIsRetro.current) {
        setRetro(null);
      } else {
        setScheduled(null);
      }
    }
    prevIsRetro.current = !!retro;
    prevIsScheduled.current = !!scheduled;
  }, [!scheduled, !retro]);

  const renewNow = useCallback(async () => {
    if (!customerData || !customerProductData) return;
    try {
      const retroActivate = retro ? Math.round(retro.valueOf() / 1000) : 0;
      await renewCustomerProduct(
        [customerData.customer.id, customerProductData.customerProduct.id],
        retroActivate,
        !disableNotification
      );
      await onRefresh();
      closeDialog();
    } catch (e) {
      handleError(e);
    }
  }, [renewCustomerProduct, customerProductData, customerData, retro, disableNotification]);

  const renewScheduled = useCallback(async () => {
    if (!scheduled || !customerData || !customerProductData) return;
    try {
      await scheduleRenewProduct(scheduled.valueOf() / 1000, {
        customerId: customerData.customer.id.toString(),
        customerProductId: customerProductData.customerProduct.id.toString(),
        sendNotifications: (!disableNotification).toString(),
      });
      await onRefresh();
      closeDialog();
    } catch (e) {
      handleError(e);
    }
  }, [scheduled, scheduleRenewProduct, customerProductData, customerData, disableNotification]);

  const formattedNow = useFormattedNow(DateFormat.DateFullWithTimeAndZone);
  const now = useMemo(() => dayjs().second(0), [formattedNow]);

  const currentExpiry = customerProductData ? dayjs(customerProductData.product.licenses[0].expiry * 1000) : now;

  const renewFrom = retro ?? scheduled ?? (currentExpiry.isAfter(now) ? currentExpiry : now);

  const formattedRenewFrom = dateFormat(renewFrom, DateFormat.DateFullWithTimeAndZone, language);

  const formattedEnd = useMemo(() => {
    if (!customerProductData?.product.plan.duration) return null;
    const {
      product: {
        plan: { duration },
      },
    } = customerProductData;
    return dateFormat(
      renewFrom.add(Math.floor(duration), 'days').add(24 * (duration % 1), 'hours'),
      DateFormat.DateFullWithTimeAndZone,
      language
    );
  }, [renewFrom, customerProductData?.product.plan.duration]);

  const onDisableNofiticationChange = useCallback((_: ChangeEvent, value: boolean) => {
    setDisableNotification(value);
  }, []);

  if (!customerData || !customerProductData) return null;
  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>{t('customerProduct.renewDialog.title')}</DialogTitle>
      <DialogContent>
        <Trans i18nKey="customerProduct.renewDialog.message">
          <Typography paragraph />
          <Typography paragraph />
        </Trans>
        <Grid container>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox checked={disableNotification} onChange={onDisableNofiticationChange} />}
              label={t('customerProduct.renewDialog.disableNotification') as string}
            />
          </Grid>
          <TogglableDateFormPartial
            value={retro}
            onChange={setRetro}
            togglerLabel={t('customerProduct.renewDialog.retroLabel')}
            disableFuture
          />

          <TogglableDateFormPartial
            value={scheduled}
            onChange={setScheduled}
            togglerLabel={t('customerProduct.renewDialog.scheduleLabel')}
            disablePast={currentExpiry.isAfter(now) ? currentExpiry : now}
          />

          <Grid item xs={12} mt={1}>
            {t('customerProduct.renewDialog.startDate')}: {formattedRenewFrom ?? '-'}
          </Grid>
          <Grid item xs={12}>
            {t('customerProduct.renewDialog.endDate')}: {formattedEnd ?? '-'}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>{t('common.dialog.close')}</Button>
        {scheduled ? (
          <Button variant="contained" color="secondary" onClick={renewScheduled}>
            {t('customerProduct.renewDialog.confirmSchedule')}
          </Button>
        ) : (
          <Button variant="contained" color="primary" onClick={renewNow}>
            {t('customerProduct.renewDialog.confirmNow')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
