import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import styled from '@mui/styles/styled';
import { useTranslation } from 'react-i18next';
import { DatePickerWithTimezoneSelection } from '../DatePickerWithTimezoneSelection/DatePickerWithTimezoneSelection';

interface IProps {
  value: Dayjs | null;
  onChange: (value: Dayjs | null) => void;
  togglerLabel: string;
  disablePast?: boolean | Dayjs;
  disableFuture?: boolean;
}

const CenterVertically = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  paddingTop: theme.spacing(2),
}));

export const TogglableDateFormPartial: FC<IProps> = ({
  value,
  onChange,
  togglerLabel,
  disablePast = false,
  disableFuture = false,
}) => {
  const [scheduleTime, setScheduleTime] = useState(
    value
      ? value
      : disablePast
      ? (typeof disablePast === 'object' ? disablePast : dayjs()).add(1, 'day').hour(0).minute(0).second(0)
      : disableFuture
      ? dayjs().hour(0).minute(0).second(0)
      : dayjs()
  );

  const { t } = useTranslation();

  const handleSetSchedule = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        onChange(scheduleTime);
      } else {
        onChange(null);
      }
    },
    [scheduleTime]
  );

  useEffect(() => {
    if (value) setScheduleTime(value);
  }, [value]);

  return (
    <>
      <Grid item xs={4}>
        <CenterVertically>
          <FormControlLabel
            control={<Checkbox checked={!!value} onChange={handleSetSchedule} />}
            label={togglerLabel}
          />
        </CenterVertically>
      </Grid>

      <Grid item xs={8}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePickerWithTimezoneSelection
            disabled={!value}
            disablePast={disablePast}
            disableFuture={disableFuture}
            date={value}
            setDate={onChange}
            label={t('common.formLabel.date')}
          />
        </LocalizationProvider>
      </Grid>
    </>
  );
};
