import { useApi } from './useApi';
import { useCallback } from 'react';
import { IAwsFileMetadata } from './useDownloadApi';
import { IProductDetails } from '../models/ProductDetails';

enum DocumentationEndpoints {
  UserDocumentationList = '/docs',
}

export interface IProductDocumentation {
  product: IProductDetails;
  s3Files: IAwsFileMetadata[];
}

export interface IDocumentationListResponse {
  productsDocs: IProductDocumentation[];
}

export const useDocumentationApi = () => {
  const { get } = useApi();

  const getDocumentationList = useCallback(async () => {
    const result = await get<IDocumentationListResponse>(DocumentationEndpoints.UserDocumentationList);
    return result.data;
  }, [get]);

  return {
    getDocumentationList,
  };
};
