import { useApi } from './useApi';
import { IProductsResponse } from '../models/ProductsResponse';
import { Single } from '../types/single';
import { ProductsEndpoints } from './api/endpoints/products';
import { ApiRequest, useEndpoint, useSimpleEndpoint } from './api/apiEndpoint';

interface IProductPayload {
  name: string;
  plans: number[];
  installerPath: string;
  productCode?: string;
  docsPath: string;
}

export const useProductsApi = () => {
  const { del, get, post, put } = useApi();

  const getProducts = useSimpleEndpoint(ProductsEndpoints.Products, get as ApiRequest<typeof get, IProductsResponse>);

  const createProduct = useEndpoint(
    (post, payload: IProductPayload) => post(payload),
    ProductsEndpoints.Products,
    post as ApiRequest<typeof post, Single<IProductsResponse['products']>>
  );

  const updateProduct = useEndpoint(
    (put, payload: IProductPayload) => put(payload),
    ProductsEndpoints.Product,
    put as ApiRequest<typeof put, Single<IProductsResponse['products']>>
  );

  const deleteProduct = useSimpleEndpoint(ProductsEndpoints.Product, del);

  return {
    getProducts,
    createProduct,
    updateProduct,
    deleteProduct,
  };
};
