import React, { ChangeEvent, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CustomerAvailableProductsFetcher, CustomerProductsFetcher } from './CustomerFetchers';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { SubmitHandler, useForm } from 'react-hook-form';
import { IPlan } from '../../models/Plan';
import { useCustomersApi } from '../../hooks/useCustomersApi';
import { useParams } from 'react-router-dom';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { TextInputController } from '../InputControllers/TextInputController';
import { SelectController } from '../InputControllers/SelectController';
import dayjs, { Dayjs } from 'dayjs';
import { useScheduledJobsApi } from '../../hooks/useScheduledJobsApi';
import { useAutoFetch } from '../../contexts/Fetcher';
import { useTranslation } from 'react-i18next';
import { TogglableDateFormPartial } from '../TogglableDateFormPartial/TogglableDateFormPartial';
import { AutocompleteController } from '../InputControllers/AutocompleteController';
import styled from '@emotion/styled';
import { useFormattedNow } from '../../hooks/useFormattedNow';
import { DateFormat, dateFormat } from '../../helpers/dateFormat';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Checkbox } from '@mui/material';
import { CheckboxController } from '../InputControllers/CheckboxController';

interface IProps {
  open: boolean;
  closeDialog: (resetQuery?: boolean) => void;
}

interface IUrlParams {
  customerId: string;
}

interface ICustomerPackageDefaultProperties {
  [key: string]: boolean;
}

interface IFormFields {
  amount: number | string;
  plan: { value: number; label: string };
  product: number;
  customerPackageDefaultProperties: ICustomerPackageDefaultProperties;
}

const InlineProductCode = styled('span')(({ theme }) => ({
  color: theme.palette.text.disabled,
  marginLeft: theme.spacing(0.5),
}));

export const CustomerNewProduct: FC<IProps> = ({ open, closeDialog }) => {
  const { onRefresh: refreshCustomerProductsList } = useContext(CustomerProductsFetcher.Context);
  const { data } = useContext(CustomerAvailableProductsFetcher.Context);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm();
  const { addProduct: apiAddProduct } = useCustomersApi();
  const { scheduleAddProduct } = useScheduledJobsApi();
  const { customerId } = useParams<IUrlParams>();
  const id = parseInt(customerId, 10);
  const handleError = useApiErrorHandler();
  const [scheduleTime, setScheduleTime] = useState<Dayjs | null>(null);
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [disableNotification, setDisableNotification] = useState(false);

  useAutoFetch(CustomerAvailableProductsFetcher, open);

  const onDisableNotificationChange = useCallback((_: ChangeEvent, value: boolean) => {
    setDisableNotification(value);
  }, []);

  useEffect(() => {
    if (!open) reset();
  }, [open]);

  const addProduct = useCallback<SubmitHandler<IFormFields>>(
    async (formData) => {
      try {
        if (scheduleTime) {
          const properties = formData.customerPackageDefaultProperties;
          await scheduleAddProduct(scheduleTime.valueOf() / 1000, {
            customerId: id.toString(),
            productId: formData.product.toString(),
            planId: formData.plan.value.toString(),
            amount: formData.amount.toString(),
            sendNotifications: (!disableNotification).toString(),
            'properties.notification_reneval_270_days': String(properties.notification_reneval_270_days),
            'properties.notification_reneval_180_days': String(properties.notification_reneval_180_days),
            'properties.notification_reneval_90_days': String(properties.notification_reneval_90_days),
            'properties.notification_reneval_60_days': String(properties.notification_reneval_60_days),
            'properties.notification_reneval_30_days': String(properties.notification_reneval_30_days),
            'properties.notification_expiring_30_days': String(properties.notification_expiring_30_days),
          });
        } else {
          await apiAddProduct(
            [id],
            formData.product,
            formData.plan.value,
            Number(formData.amount),
            !disableNotification,
            formData.customerPackageDefaultProperties
          );
        }
        await refreshCustomerProductsList();
        closeDialog(true);
      } catch (e) {
        handleError(e);
      }
    },
    [scheduleTime, disableNotification]
  );

  const selectedProduct = parseInt(watch('product'), 10);
  const selectedProductCode = watch('productCode');

  const plan = watch('plan');

  const plans = useMemo<IPlan[]>(() => {
    if (!data || !selectedProduct) {
      return [];
    }
    const product = data.products.find((product) => product.id === selectedProduct);
    if (!product) return [];
    return product.plans;
  }, [data, selectedProduct]);

  const selectedPlanDetails = useMemo(() => {
    return plan ? plans.find((planItem) => planItem.id === plan.value) : undefined;
  }, [plan, plans]);

  const now = useFormattedNow(DateFormat.DateFullWithTimeAndZone);
  const formattedStart = scheduleTime ? dateFormat(scheduleTime, DateFormat.DateFullWithTimeAndZone, language) : now;

  const formattedEnd = useMemo(() => {
    if (!selectedPlanDetails) return null;
    const date = scheduleTime ?? dayjs().second(0);

    return dateFormat(
      date.add(Math.floor(selectedPlanDetails.duration), 'days').add(24 * (selectedPlanDetails.duration % 1), 'hours'),
      DateFormat.DateFullWithTimeAndZone,
      language
    );
  }, [formattedStart, selectedPlanDetails?.duration]);

  const onClose = useCallback(() => {
    closeDialog();
  }, [closeDialog]);

  const productItems = useMemo(() => {
    if (!data) return [];
    return data.products.map((product) => ({
      value: product.id,
      label: product.name,
    }));
  }, [data?.products]);

  const planItems = useMemo(() => {
    return plans.map((plan) => ({
      value: plan.id,
      label: plan.name,
      productCode: plan.productCode,
      customerPackageDefaultProperties: plan.customerPackageDefaultProperties,
    }));
  }, [plans, selectedProductCode]);

  if (!data) return null;
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('customer.products.addDialog.title')}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(addProduct)}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <TextInputController
                control={control}
                defaultValue={1}
                errors={errors}
                label={t('common.formLabel.amount')}
                name={'amount'}
                rules={{
                  required: true,
                  min: 1,
                }}
                inputType="number"
                InputProps={{
                  inputProps: {
                    min: 1,
                  },
                }}
              />
            </Grid>
            <Grid item xs={9}>
              <SelectController
                control={control}
                errors={errors}
                items={productItems}
                label={t('common.formLabel.product')}
                name="product"
                defaultValue=""
                rules={{ required: true }}
              />
            </Grid>

            <Grid item xs={9}>
              <AutocompleteController
                control={control}
                errors={errors}
                items={planItems}
                label={`${t('common.formLabel.plan')} (${t('common.formLabel.productCode')})`}
                name="plan"
                defaultValue=""
                rules={{ required: true }}
                renderOption={(liProps, item) => {
                  return (
                    <li {...liProps} key={item.value}>
                      {item.label}
                      {item.productCode ? <InlineProductCode>({item.productCode})</InlineProductCode> : null}
                    </li>
                  );
                }}
                getOptionLabel={(item) => {
                  if (item) {
                    return item.label + (item.productCode ? ` (${item.productCode})` : '');
                  }
                  return '';
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField label={' '} disabled value={selectedPlanDetails ? selectedPlanDetails.type : ''} />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox checked={disableNotification} onChange={onDisableNotificationChange} />}
                label={t('customer.products.addDialog.disableNotification') as string}
              />
            </Grid>

            <TogglableDateFormPartial
              value={scheduleTime}
              onChange={setScheduleTime}
              togglerLabel={t('customer.products.addDialog.scheduleLabel')}
              disablePast
            />

            {formattedStart && formattedEnd ? (
              <>
                <Grid item xs={12}>
                  {t('customer.products.addDialog.startDate')}: {formattedStart}
                </Grid>
                <Grid item xs={12}>
                  {t('customer.products.addDialog.endDate')}: {formattedEnd ?? '-'}
                </Grid>
              </>
            ) : null}
            {selectedPlanDetails && (
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <CheckboxController
                    control={control}
                    errors={errors}
                    defaultValue={selectedPlanDetails?.customerPackageDefaultProperties?.notification_reneval_270_days}
                    label={t('customer.products.addDialog.notification_reneval_270_days.label')}
                    name="customerPackageDefaultProperties.notification_reneval_270_days"
                  />
                </Grid>
                <Grid item xs={12}>
                  <CheckboxController
                    control={control}
                    errors={errors}
                    defaultValue={selectedPlanDetails?.customerPackageDefaultProperties?.notification_reneval_180_days}
                    label={t('customer.products.addDialog.notification_reneval_180_days.label')}
                    name="customerPackageDefaultProperties.notification_reneval_180_days"
                  />
                </Grid>
                <Grid item xs={12}>
                  <CheckboxController
                    control={control}
                    errors={errors}
                    defaultValue={selectedPlanDetails?.customerPackageDefaultProperties?.notification_reneval_90_days}
                    label={t('customer.products.addDialog.notification_reneval_90_days.label')}
                    name="customerPackageDefaultProperties.notification_reneval_90_days"
                  />
                </Grid>
                <Grid item xs={12}>
                  <CheckboxController
                    control={control}
                    errors={errors}
                    defaultValue={selectedPlanDetails?.customerPackageDefaultProperties?.notification_reneval_60_days}
                    label={t('customer.products.addDialog.notification_reneval_60_days.label')}
                    name="customerPackageDefaultProperties.notification_reneval_60_days"
                  />
                </Grid>
                <Grid item xs={12}>
                  <CheckboxController
                    control={control}
                    errors={errors}
                    defaultValue={selectedPlanDetails?.customerPackageDefaultProperties?.notification_reneval_30_days}
                    label={t('customer.products.addDialog.notification_reneval_30_days.label')}
                    name="customerPackageDefaultProperties.notification_reneval_30_days"
                  />
                </Grid>
                <Grid item xs={12}>
                  <CheckboxController
                    control={control}
                    errors={errors}
                    defaultValue={selectedPlanDetails?.customerPackageDefaultProperties?.notification_expiring_30_days}
                    label={t('customer.products.addDialog.notification_expiring_30_days.label')}
                    name="customerPackageDefaultProperties.notification_expiring_30_days"
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>

        <Button variant="contained" color={scheduleTime ? 'secondary' : 'primary'} onClick={handleSubmit(addProduct)}>
          {scheduleTime
            ? t('customer.products.addDialog.confirmSchedule')
            : t('customer.products.addDialog.confirmNow')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
