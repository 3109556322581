import { GridCellParams, GridFilterItem, GridFilterOperator } from '@mui/x-data-grid';
import { DateFilterInput } from '../components/DateFilterInput/DateFilterInput';
import { getDateObject } from '../helpers/dateFormat';
import { t } from 'i18next';

export const getDateBeforeFilterOperator = (): GridFilterOperator => ({
  label: t('filterOperator.dateBefore'),
  value: 'lt',
  getApplyFilterFn: (filterItem: GridFilterItem) => (params: GridCellParams) => {
    return (
      (typeof params.value === 'string' || typeof params.value === 'number') &&
      getDateObject(params.value).isBefore(getDateObject(parseInt(filterItem.value, 10)))
    );
  },
  InputComponent: DateFilterInput,
});

export const getDateAfterFilterOperator = (): GridFilterOperator => ({
  label: t('filterOperator.dateAfter'),
  value: 'gt',
  getApplyFilterFn: (filterItem: GridFilterItem) => (params: GridCellParams) => {
    return (
      (typeof params.value === 'string' || typeof params.value === 'number') &&
      getDateObject(params.value).isAfter(getDateObject(parseInt(filterItem.value, 10)))
    );
  },
  InputComponent: DateFilterInput,
});
