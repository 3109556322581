import React, { FC, useContext } from 'react';
import { IUserDownloadAddonAbstract, IUserDownloadAddonMetadata } from '../../hooks/useDownloadApi';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { SingleDownloadProductAddon } from './SingleDownloadProductAddon';
import { IFetcher } from '../../contexts/Fetcher';

const useStyles = makeStyles((theme) => {
  return createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(3),
    },
  });
});

interface IProps {
  getMeta: (addon: IUserDownloadAddonAbstract) => IUserDownloadAddonMetadata;
  fetcher: IFetcher<IUserDownloadAddonAbstract[]>;
}

const AddonsList: FC<IProps> = ({ getMeta, fetcher }) => {
  const { data: addons } = useContext(fetcher.Context);
  const styles = useStyles();

  if (!addons) return null;
  return (
    <div className={styles.container}>
      {addons
        ? addons.map((addon) => {
            return <SingleDownloadProductAddon key={addon.file.Key} addon={addon} getMeta={getMeta} />;
          })
        : null}
    </div>
  );
};

export const DownloadsProductAddons: FC<IProps> = ({ getMeta, fetcher }) => {
  const Wrapper = fetcher.WAF;
  return (
    <Wrapper>
      <AddonsList getMeta={getMeta} fetcher={fetcher} />
    </Wrapper>
  );
};
