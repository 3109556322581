import React, { FC, useCallback, useState } from 'react';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import Button from '@mui/material/Button';
import { ControllerProps } from 'react-hook-form/dist/types/controller';
import CloudUpload from '@mui/icons-material/CloudUpload';

interface IProps extends Omit<ControllerProps, 'render'> {
  accept?: string;
  label: string;
}

interface IFileInputProps {
  accept?: string;
  field: ControllerRenderProps;
}

const FileInput: FC<IFileInputProps> = ({ accept, field }) => {
  const [fakePath, setFakePath] = useState('');
  return (
    <input
      {...field}
      value={fakePath}
      onChange={(e) => {
        setFakePath(e.target.value);
        field.onChange(e.target.files);
      }}
      accept={accept}
      type="file"
      hidden
    />
  );
};

export const FileInputController: FC<IProps> = ({ accept, label, name, ...restProps }) => {
  const renderFn = useCallback<ControllerProps['render']>(
    ({ field }) => {
      return (
        <Button variant="contained" component="label" startIcon={<CloudUpload />}>
          {label}
          <FileInput accept={accept} field={field} />
        </Button>
      );
    },
    [label]
  );

  return <Controller name={name} render={renderFn} {...restProps} />;
};
