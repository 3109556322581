import React, { ComponentProps, createContext, FC, useCallback, useMemo, useState } from 'react';
import Alert from '@mui/material/Alert';

export interface INotification {
  header?: string;
  message: string | React.ReactElement;
  severity: ComponentProps<typeof Alert>['severity'];
  index: number;
  action?: React.ReactElement;
}

interface INotificationContext {
  notifications: INotification[];
  addNotification: (notification: Omit<INotification, 'index'>) => INotification;
  dismissNotification: (notification: INotification) => void;
}

const defaultNotificationContext: INotificationContext = {
  notifications: [],
  addNotification: (notification) => ({ ...notification, index: -1 }),
  dismissNotification: () => null,
};

export const NotificationContext = createContext<INotificationContext>(defaultNotificationContext);

let notificationIndex = 0;

export const NotificationProvider: FC = ({ children }) => {
  const [notifications, setNotifications] = useState<INotification[]>([]);

  const addNotification = useCallback((notification: Omit<INotification, 'index'>, replace = true) => {
    const notificationWithIndex = { ...notification, index: ++notificationIndex };

    setNotifications((prevState) => {
      if (replace) {
        return [notificationWithIndex];
      } else {
        return [...prevState, notificationWithIndex];
      }
    });

    return notificationWithIndex;
  }, []);

  const dismissNotification = useCallback((notification: INotification) => {
    setNotifications((prevState) => {
      return prevState.filter((n) => n.index !== notification.index);
    });
  }, []);

  const contextValue = useMemo(
    () => ({
      notifications,
      addNotification,
      dismissNotification,
    }),
    [notifications]
  );

  return <NotificationContext.Provider value={contextValue}>{children}</NotificationContext.Provider>;
};
