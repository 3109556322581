import {
  createFetcher,
  createServerSidePaginatedFetcher,
  IServerSidePaginatedFetcherParams,
} from '../../contexts/Fetcher';
import { useCustomersApi } from '../../hooks/useCustomersApi';
import { useParams } from 'react-router-dom';
import { useCallback } from 'react';
import { ICustomerUrlParams } from './Customer';
import { useIntParams } from '../../hooks/useIntParams';

export const CustomerFetcher = createFetcher(() => {
  const { getCustomer } = useCustomersApi();
  const { customerId } = useParams<ICustomerUrlParams>();

  return useCallback(() => {
    return getCustomer(parseInt(customerId, 10));
  }, [getCustomer, customerId]);
});

export const CustomerProductsFetcher = createFetcher(() => {
  const { getCustomerProducts } = useCustomersApi();
  const { customerId } = useIntParams<ICustomerUrlParams>();

  return useCallback(() => {
    return getCustomerProducts([customerId]);
  }, [getCustomerProducts, customerId]);
});

export const CustomerUsersFetcher = createServerSidePaginatedFetcher(() => {
  const { getCustomerUsers } = useCustomersApi();
  const { customerId } = useIntParams<ICustomerUrlParams>();

  return useCallback(
    (params: IServerSidePaginatedFetcherParams) => {
      return getCustomerUsers([customerId], params);
    },
    [getCustomerUsers, customerId]
  );
});

export const CustomerTicketsFetcher = createFetcher(() => {
  const { getCustomerTickets } = useCustomersApi();
  const { customerId } = useIntParams<ICustomerUrlParams>();

  return useCallback(() => {
    return getCustomerTickets([customerId]);
  }, [getCustomerTickets, customerId]);
});

export const CustomerInvoicesFetcher = createFetcher(() => {
  const { getCustomerInvoices } = useCustomersApi();
  const { customerId } = useIntParams<ICustomerUrlParams>();

  return useCallback(() => {
    return getCustomerInvoices([customerId]);
  }, [getCustomerInvoices, customerId]);
});

export const CustomerAvailableProductsFetcher = createFetcher(() => {
  const { getAvailableProducts } = useCustomersApi();
  const { customerId } = useIntParams<ICustomerUrlParams>();

  return useCallback(() => {
    return getAvailableProducts([customerId]);
  }, [getAvailableProducts, customerId]);
});
