import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import ArrowBack from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';

interface IProps {
  to: string;
}

export const AuthGoBack: FC<IProps> = ({ to }) => {
  return (
    <Button component={Link} variant="text" color="primary" to={to} startIcon={<ArrowBack />}>
      Go back
    </Button>
  );
};
