import { useApi } from './useApi';
import { IBalanceResponse } from '../models/BalanceResponse';
import { ApiRequest, useSimpleEndpoint } from './api/apiEndpoint';
import { BalanceEndpoints } from './api/endpoints/balance';

export const useBalanceApi = () => {
  const { get } = useApi();

  const getBalance = useSimpleEndpoint(BalanceEndpoints.Balance, get as ApiRequest<typeof get, IBalanceResponse>);

  return {
    getBalance,
  };
};
