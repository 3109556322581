import React from 'react';
import { dateFormat, DateFormat } from '../../helpers/dateFormat';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

interface IProps {
  date: string | number | Dayjs;
  format: DateFormat;
}

export const Date = React.forwardRef<HTMLSpanElement, IProps>(({ date, format, children }, ref) => {
  const {
    i18n: { language },
  } = useTranslation();
  return (
    <span ref={ref}>
      {dateFormat(date, format, language)}
      {children}
    </span>
  );
});
