import React, { FC, useCallback, useEffect } from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { AnalyticsActivityType } from '../../hooks/useAnalyticsApi';
import { useQueryParam } from '../../hooks/useQueryParam';
import { useHistory } from 'react-router-dom';
import { useQueryUrl } from '../../hooks/useQueryUrl';
import { ActivityMap } from './ActivityMap';
import { ActivityFetcher } from '../../fetchers/ActivityFetcher';
import { ActivityTable } from './ActivityTable';
import { PageHeader } from '../PageHeader/PageHeader';
import { useTranslation } from 'react-i18next';

export const AnalyticsActivity: FC = () => {
  const type = useQueryParam('type') as AnalyticsActivityType | null;
  const tfgFilter = useQueryParam('tfgFilter') === 'true';
  const history = useHistory();
  const { t } = useTranslation();

  // const nowUrl = useQueryUrl('type', 'activeNow');
  // const todayUrl = useQueryUrl('type', 'activeToday');
  // const weekUrl = useQueryUrl('type', 'activeWeek');
  // const monthUrl = useQueryUrl('type', 'activeMonth');

  const tfgFilterUrl = useQueryUrl('tfgFilter', (!tfgFilter).toString());

  const toggleTfgFilter = useCallback(() => {
    history.push(tfgFilterUrl);
  }, [tfgFilterUrl]);

  useEffect(() => {
    if (!type) {
      const search = new URLSearchParams(history.location.search);
      search.set('tfgFilter', tfgFilter.toString());
      search.set('type', 'activeNow');
      history.replace(`?${search}`);
    }
  }, [type, tfgFilter]);

  return (
    <div>
      <PageHeader title={t('analytics.header')} />

      {/*<ButtonGroup>*/}
      {/*<Button color={type === 'activeNow' ? 'secondary' : void 0} component={Link} to={nowUrl}>*/}
      {/*  Active now*/}
      {/*</Button>*/}
      {/*<Button color={type === 'activeToday' ? 'secondary' : void 0} component={Link} to={todayUrl}>*/}
      {/*  Active today*/}
      {/*</Button>*/}
      {/*<Button color={type === 'activeWeek' ? 'secondary' : void 0} component={Link} to={weekUrl}>*/}
      {/*  Week*/}
      {/*</Button>*/}
      {/*<Button color={type === 'activeMonth' ? 'secondary' : void 0} component={Link} to={monthUrl}>*/}
      {/*  Month*/}
      {/*</Button>*/}
      {/*</ButtonGroup>*/}
      <Box>
        <FormControl>
          <FormControlLabel
            control={<Checkbox checked={tfgFilter} onChange={toggleTfgFilter} />}
            label={t('analytics.pixotopeFilter') as string}
          />
        </FormControl>
      </Box>

      {type ? (
        <ActivityFetcher.WAF type={type} tfgFilter={tfgFilter}>
          <ActivityMap />

          <Box marginTop={1}>
            <ActivityTable />
          </Box>
        </ActivityFetcher.WAF>
      ) : null}
    </div>
  );
};
