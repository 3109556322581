import { useCallback, useContext } from 'react';
import { NotificationContext } from '../contexts/NotificationContext';
import { logger } from '../helpers/logger';
import { captureException } from '../sentry';
import { useTranslation } from 'react-i18next';

export const useApiErrorHandler = () => {
  const { addNotification } = useContext(NotificationContext);
  const { t } = useTranslation('translation', { keyPrefix: 'apiError' });

  return useCallback((e: any, fallbackMessageKey = 'Something went wrong') => {
    logger.error(e);
    captureException(e);

    let key = e?.response?.data?.reason || fallbackMessageKey;

    const errorMessageFromSwiftExceptionRegexp = /\[[a-zA-Z]+\.\d+:\s?(.+)\]\s*\[\/[a-zA-Z/]+\.swift:\d+:\d+\]/;
    if (typeof key === 'string') {
      const match = key.match(errorMessageFromSwiftExceptionRegexp);
      key = match ? match[1] : key;
    }

    addNotification({
      message: t(key, {
        nsSeparator: '###',
        defaultValue: key,
      }),
      severity: 'error',
    });
  }, []);
};
