import React, { ComponentType, FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { Route } from 'react-router-dom';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import { combineComponents } from '../../contexts/combineComponents';
import { AccountContext } from '../../contexts/AccountContext';
import { useAccount } from '../../hooks/useAccount';
import * as Sentry from '@sentry/react';

interface IProps {
  component: ComponentType;
  layout: ComponentType;
  providers?: FC[];
  path: string;
}

export const SentryScope: FC<{ path: string }> = ({ path }) => {
  const accountType = useContext(AccountContext);
  const { account } = useAccount(accountType);

  useEffect(() => {
    Sentry.configureScope((scope) => {
      if (account) {
        scope.setUser({
          email: account.email,
        });
      } else {
        scope.setUser(null);
      }
    });
  }, [account]);

  useEffect(() => {
    Sentry.configureScope((scope) => {
      scope.setTag('path', path);
    });
  }, [path]);

  return null;
};

export const RouteWrapper: FC<Omit<Route['props'], 'component'> & IProps> = ({
  component: Component,
  layout: Layout,
  providers = [],
  ...rest
}) => {
  const CombinedProviders = useMemo(() => {
    return combineComponents(...providers);
  }, [providers]);

  const renderFn = useCallback(() => {
    return (
      <Layout>
        <ErrorBoundary>
          <CombinedProviders>
            <SentryScope path={rest.path} />
            <Component />
          </CombinedProviders>
        </ErrorBoundary>
      </Layout>
    );
  }, [Component, Layout, CombinedProviders]);

  return <Route render={renderFn} {...rest} />;
};
