import React, { FC, useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import { ReleaseOfflineLicense } from './ReleaseOfflineLicense';
import { LicenseReleased } from './LicenseReleased';
import { getLicenseMode, LicenseMode } from '../../helpers/licenseMode';
import { ReleaseOnlineLicense } from './ReleaseOnlineLicense';
import { LicenseContext } from '../../contexts/LicenseContext';
import { ReleaseErrorLicense } from './ReleaseErrorLicense';

interface IProps {
  open: boolean;
  onClose: () => void;
}

export const ReleaseLicenseStepsDialog: FC<IProps> = ({ open, onClose }) => {
  const { machine, license, offlineLicense } = useContext(LicenseContext)!;

  const licenseMode = getLicenseMode(machine, license, offlineLicense);

  return (
    <Dialog open={open} onClose={onClose}>
      {[LicenseMode.OfflineFile, LicenseMode.OfflineDirector].includes(licenseMode) ? (
        <ReleaseOfflineLicense onClose={onClose} />
      ) : null}
      {licenseMode === LicenseMode.Error ? <ReleaseErrorLicense onClose={onClose} /> : null}
      {licenseMode === LicenseMode.Online ? <ReleaseOnlineLicense onClose={onClose} /> : null}
      {licenseMode === LicenseMode.NotInUse ? <LicenseReleased onClose={onClose} /> : null}
    </Dialog>
  );
};
