import React, { createContext, FC, useCallback, useMemo, useRef, useState } from 'react';

interface ILoadingContext {
  isLoading: boolean;
  startLoading: () => void;
  endLoading: () => void;
}

const defaultLoadingContext: ILoadingContext = {
  isLoading: false,
  startLoading: () => null,
  endLoading: () => null,
};

export const LoadingContext = createContext<ILoadingContext>(defaultLoadingContext);

export const LoadingProvider: FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const loadingCounter = useRef(0);

  const startLoading = useCallback(() => {
    ++loadingCounter.current;
    if (loadingCounter.current >= 1) {
      setIsLoading(true);
    }
  }, []);

  const endLoading = useCallback(() => {
    --loadingCounter.current;
    if (loadingCounter.current <= 0) {
      setIsLoading(false);
    }
  }, []);

  const contextValue = useMemo(
    () => ({
      isLoading,
      startLoading,
      endLoading,
    }),
    [isLoading]
  );

  return <LoadingContext.Provider value={contextValue}>{children}</LoadingContext.Provider>;
};
