import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { LiveRegionsFetcher } from '../../fetchers/LiveRegionsFetcher';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { LiveRegionContext } from '../../contexts/LiveRegionContext';
import styled from '@mui/styles/styled';
import { useTranslation } from 'react-i18next';
import { ILiveRegionsResponse } from '../../models/LiveRegionsResponse';
import { Single } from '../../types/single';

const StyledFormControl = styled(FormControl)({
  marginTop: 0,
  flex: 1,
});

const useGroupedRegions = (regions: ILiveRegionsResponse['regions']) => {
  const groupedObj = useMemo(
    () =>
      regions.reduce<{
        [group: string]: Array<
          Single<ILiveRegionsResponse['regions']> & { regionGroupName: string; regionName: string }
        >;
      }>((grouped, region) => {
        const [, majorRegion, minorRegion] =
          region.location.match(/^(.+) \((.+)\)$/) ?? ([null, 'Other', region.location] as const);

        return {
          ...grouped,
          [majorRegion]: [
            ...(grouped[majorRegion] ?? []),
            {
              regionGroupName: majorRegion,
              regionName: minorRegion,
              ...region,
            },
          ],
        };
      }, {}),
    [regions]
  );

  const groupedArray = useMemo(
    () =>
      Object.entries(groupedObj).map(([key, value]) => ({
        name: key,
        locations: value,
      })),
    [groupedObj]
  );

  return groupedArray;
};

export const LiveRegionSelect: FC = () => {
  const { data } = useContext(LiveRegionsFetcher.Context);
  const { region, setRegion } = useContext(LiveRegionContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (!region && data?.defaultRegionName) {
      setRegion(data.defaultRegionName);
    }
  }, [!region, data?.defaultRegionName]);

  const groupedRegions = useGroupedRegions(data?.regions ?? []);

  const onChange = useCallback((e: SelectChangeEvent) => {
    setRegion(e.target.value);
  }, []);

  if (!data) return null;
  return (
    <StyledFormControl fullWidth variant="filled" size="small">
      <InputLabel>{t('component.liveRegionSelect.label')}</InputLabel>
      <Select value={region} onChange={onChange} fullWidth>
        {groupedRegions.map((continent) => {
          return [
            <ListSubheader key={continent.name}>{continent.name}</ListSubheader>,
            ...continent.locations.map((location) => {
              return (
                <MenuItem key={continent.name + '-' + location.name} value={location.name}>
                  {location.location}
                </MenuItem>
              );
            }),
          ];
        })}
      </Select>
    </StyledFormControl>
  );
};
