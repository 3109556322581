import { IMachine } from '../models/Machine';

export enum LicenseMode {
  NotInUse = 'not in use',
  OfflineDirector = 'offline-director',
  OfflineFile = 'offline-file',
  Online = 'online',
  Error = 'error',
}

interface ILicenseContainer {
  offlineMode?: number | boolean;
}

export const getLicenseMode = (
  machine: IMachine | undefined,
  license: ILicenseContainer,
  licensePath: string | undefined
): LicenseMode => {
  try {
    if (!machine && license.offlineMode) {
      console.error('License is in Error mode because `license.offlineMode` is set and `machine` is missing');
      return LicenseMode.Error;
    }
    if (!machine) return LicenseMode.NotInUse;
    if (license.offlineMode && licensePath) return LicenseMode.OfflineFile;
    if (license.offlineMode) return LicenseMode.OfflineDirector;
    return LicenseMode.Online;
  } catch (e) {
    console.error('License is in Error mode because `getLicenseMode` has thrown an exception');
    console.error(e);
    return LicenseMode.Error;
  }
};
