import React, { FC, useCallback, useContext, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { CloseDialogFunc } from '../../hooks/useCloseDialog';
import { IProductPackageFormPayload, ProductPackageForm } from './ProductPackageForm';
import { useForm } from 'react-hook-form';
import { useProductPackagesApi } from '../../hooks/useProductPackagesApi';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { ProductPackagesFetcher } from '../../fetchers/ProductPackagesFetcher';
import { useTranslation } from 'react-i18next';

interface IProps {
  open: boolean;
  closeDialog: CloseDialogFunc;
  packagesType: 'packages' | 'addons';
}

export const AddProductPackageDialog: FC<IProps> = ({ open, closeDialog, packagesType }) => {
  const form = useForm();
  const { createPackage } = useProductPackagesApi();
  const handleError = useApiErrorHandler();
  const { onRefresh } = useContext(ProductPackagesFetcher.Context);
  const { t } = useTranslation();

  const onSubmit = useCallback(
    async (payload: IProductPackageFormPayload) => {
      try {
        const pack = await createPackage([], {
          name: payload.name,
          tools: {
            data: payload.tools,
            bundledWith: payload.bundledWith,
          },
          isAddon: packagesType === 'addons',
        });
        await onRefresh();
        closeDialog(pack.id);
      } catch (e) {
        handleError(e);
      }
    },
    [createPackage, packagesType]
  );

  useEffect(() => {
    form.reset({
      name: '',
      bundledWith: [],
      tools: [],
    });
  }, [open]);

  return (
    <Dialog open={open} onClose={closeDialog} maxWidth="md">
      <DialogTitle>
        {packagesType === 'addons'
          ? t('adminProducts.addons.addDialog.title')
          : t('adminProducts.packages.addDialog.title')}
      </DialogTitle>
      <DialogContent>
        <ProductPackageForm form={form} onSubmit={onSubmit} />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>{t('common.dialog.close')}</Button>
        <Button variant="contained" color="primary" onClick={form.handleSubmit(onSubmit)}>
          {packagesType === 'addons'
            ? t('adminProducts.addons.addDialog.confirm')
            : t('adminProducts.packages.addDialog.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
