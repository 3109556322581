import React, { createContext, FC, useContext } from 'react';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { useAccount } from '../hooks/useAccount';
import { AccountContext } from './AccountContext';

interface ILiveRegionContext {
  region: string;
  setRegion: (value: string) => void;
}

export const LiveRegionContext = createContext<ILiveRegionContext>({
  region: '',
  setRegion: () => void 0,
});

export const LiveRegionContextProvider: FC<{ email?: string }> = ({ email, children }) => {
  const accountType = useContext(AccountContext);
  const accountData = useAccount(accountType);

  const [region, setRegion] = useLocalStorage(`liveRegion-${email ?? accountData.account?.email}`, '');

  return <LiveRegionContext.Provider value={{ region, setRegion }}>{children}</LiveRegionContext.Provider>;
};
