import React, { FC, useCallback, useContext, useEffect } from 'react';
import { INotification, NotificationContext } from '../../contexts/NotificationContext';
import Alert from '@mui/material/Alert';
import styled from '@mui/styles/styled';
import { useEnumState } from '../../hooks/useEnumState';
import { AlertTitle } from '@mui/material';

interface IProps {
  notification: INotification;
}

const AlertStyled = styled(Alert)({
  maxWidth: '360px',
});

const AutoHideProgressBar = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: 0,
  height: 3,
  background: theme.palette.action.disabled,
  '&.active': {
    transition: 'width 6s linear',
    width: '100%',
  },
}));

export const SingleNotification: FC<IProps> = ({ notification }) => {
  const { dismissNotification } = useContext(NotificationContext);
  const [autoClose, setAutoClose, stopAutoClose] = useEnumState(false, true, false);

  const onClose = useCallback(() => {
    dismissNotification(notification);
  }, [notification, dismissNotification]);

  useEffect(() => {
    setAutoClose();
  }, []);

  return (
    <AlertStyled
      severity={notification.severity}
      variant="filled"
      elevation={6}
      onClose={onClose}
      onMouseEnter={stopAutoClose}
      onMouseLeave={setAutoClose}
      action={notification.action}
    >
      <AutoHideProgressBar className={autoClose ? 'active' : ''} onTransitionEnd={onClose} />
      {notification.header ? <AlertTitle>{notification.header}</AlertTitle> : null}
      {notification.message}
    </AlertStyled>
  );
};
