import React, { FC, useCallback, useContext, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { LicenseContext } from '../../contexts/LicenseContext';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { FileInput } from '../FileInput/FileInput';
import { useTranslation } from 'react-i18next';
import { DangerousButton } from '../CustomButtons/DangerousButton';
import { Link } from 'react-router-dom';
import { useQueryParam } from '../../hooks/useQueryParam';
import { ConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialog';
import { useQueryUrl } from '../../hooks/useQueryUrl';
import { LicensesIncludedInOffline } from './LicensesIncludedInOffline';

interface IProps {
  onClose: () => void;
}

export const ReleaseOfflineLicense: FC<IProps> = ({ onClose }) => {
  const { onOfflineRelease, onOfflineForceRelease } = useContext(LicenseContext)!;
  const [file, setFile] = useState<File | null>(null);
  const apiErrorHandler = useApiErrorHandler();
  const { t } = useTranslation();
  const isForce = useQueryParam('force') === '1';
  const forceUrl = useQueryUrl('force', '1');
  const noForceUrl = useQueryUrl('force', null);

  const onUpload = useCallback(async () => {
    if (file) {
      try {
        await onOfflineRelease(file);
      } catch (e) {
        apiErrorHandler(e, t('licenses.releaseDialog.failed'));
      }
    }
  }, [file]);

  return (
    <>
      <DialogTitle>{t('licenses.releaseDialog.title')}</DialogTitle>
      <DialogContent dividers>
        <Typography paragraph>{t('licenses.releaseDialog.description')}</Typography>
        <Box p={1} textAlign="center">
          <img src="/release-step2.svg" />
        </Box>
        <Typography paragraph>{t('licenses.releaseDialog.content.upload')}</Typography>

        <LicensesIncludedInOffline />

        <FileInput
          value={file}
          onChange={setFile}
          label={t('licenses.releaseDialog.fileInputLabel')}
          id="license-release-file"
          changeLabel={t('licenses.releaseDialog.fileInputChangeLabel')}
          accept={'.release,.pxrelease'}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          {t('common.dialog.close')}
        </Button>
        {onOfflineForceRelease ? (
          <DangerousButton component={Link} to={forceUrl}>
            {t('licenses.releaseDialog.force.button')}
          </DangerousButton>
        ) : null}
        <Button variant="contained" color="primary" disabled={!file} onClick={onUpload}>
          {t('licenses.releaseDialog.confirmRelease')}
        </Button>
      </DialogActions>

      {onOfflineForceRelease ? (
        <ConfirmationDialog
          open={isForce}
          title={t('licenses.releaseDialog.force.title')}
          message={t('licenses.releaseDialog.force.message')}
          confirm={onOfflineForceRelease}
          abort={noForceUrl}
          dangerous
        />
      ) : null}
    </>
  );
};
