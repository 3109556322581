import { getFilterEnumInput } from '../components/FilterEnumInput/FilterEnumInput';
import { GridCellParams, GridFilterItem } from '@mui/x-data-grid';
import { t } from 'i18next';

type OptArr<T> = T | T[];
type PossibleValuesMap = Record<string, OptArr<string | boolean | number>>;

const getPossibleValuesMap = (possibleValues: string[] | PossibleValuesMap) => {
  return Array.isArray(possibleValues)
    ? possibleValues.reduce<PossibleValuesMap>((memo, current) => {
        memo[current] = current;
        return memo;
      }, {})
    : possibleValues;
};

export const getEnumIsFilterOperator = (possibleValues: string[] | PossibleValuesMap) => {
  const map = getPossibleValuesMap(possibleValues);

  return {
    label: t('filterOperator.enumIs'),
    value: 'equals',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (filterItem.value === '') return () => true;
      const values = filterItem.value.split(',');
      return (params: GridCellParams) => {
        if (typeof params.value === 'string' || typeof params.value === 'boolean' || typeof params.value === 'number') {
          return values.includes(params.value.toString());
        }
        return false;
      };
    },
    InputComponent: getFilterEnumInput(map),
  };
};
export const getEnumIsNotFilterOperator = (possibleValues: string[] | PossibleValuesMap) => {
  const map = getPossibleValuesMap(possibleValues);

  return {
    label: t('filterOperator.enumIsNot'),
    value: 'differs',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      const values = filterItem.value.split(',');
      return (params: GridCellParams) => {
        if (typeof params.value !== 'string') return true;
        return !values.includes(params.value);
      };
    },
    InputComponent: getFilterEnumInput(map),
  };
};
