import { useCallback, useContext, useMemo } from 'react';
import axios, { AxiosRequestConfig, AxiosResponse, AxiosInstance, AxiosRequestHeaders, AxiosError } from 'axios';
import { LoadingContext } from '../contexts/LoadingContext';
import { config } from '../config';
import { logger } from '../helpers/logger';
import { AccountContext } from '../contexts/AccountContext';
import { AccountType } from '../types/AccountType';
import { useAccount } from './useAccount';
import { GlobalContext } from '../contexts/GlobalContext';

enum HttpResponseCode {
  Unauthorized = 401,
}

export const accountTypePrefix: Record<AccountType, string> = {
  [AccountType.User]: '/customer/user',
  [AccountType.Customer]: '/customer/admin',
  [AccountType.Admin]: '/admin',
};

interface IApiConfig {
  logRequests?: boolean;
  indicateLoading?: boolean;
  signOutUnauthorized?: boolean;
  addAccountTypePrefix?: boolean;
  throwIfErrorFieldIsPresent?: boolean;
  baseURL?: string;
}

export const useApi = ({
  logRequests = true,
  indicateLoading = true,
  signOutUnauthorized = true,
  addAccountTypePrefix = true,
  throwIfErrorFieldIsPresent = true,
  baseURL,
}: IApiConfig = {}) => {
  const accountType = useContext(AccountContext)!;
  const { setSignInFastTrack } = useContext(GlobalContext);

  const { account, setAccount } = useAccount(accountType);

  const { startLoading, endLoading } = useContext(LoadingContext);

  const accessToken = account?.tokenData?.accessToken;

  const authHeader = useMemo<AxiosRequestHeaders>(() => {
    if (accessToken) {
      return { Authorization: `Bearer ${accessToken}` };
    } else {
      return {} as AxiosRequestHeaders;
    }
  }, [accessToken]);

  const createAxiosInstance = useCallback(() => {
    return axios.create({
      baseURL: baseURL || localStorage.getItem('apiUrl') || config.apiUrl,
      headers: {
        ...authHeader,
      },
    });
  }, [authHeader, baseURL]);

  const addLoggingInterceptors = useCallback((instance: AxiosInstance) => {
    const reqInterceptor = instance.interceptors.request.use((config) => {
      logger.http(`[${config.method?.toUpperCase()}] REQUEST`, config.url, config);
      return config;
    });
    const resInterceptor = instance.interceptors.response.use(
      (response) => {
        logger.http(`[${response.config.method?.toUpperCase()}] RESPONSE`, response.config.url, response);
        return response;
      },
      (error: AxiosError) => {
        logger.http(
          `[${error.response?.config.method?.toUpperCase()}] RESPONSE ERROR`,
          error.response?.config.url ?? error.config.url,
          error.response
        );
        throw error;
      }
    );
    return () => {
      instance.interceptors.request.eject(reqInterceptor);
      instance.interceptors.response.eject(resInterceptor);
    };
  }, []);

  const addLoadingInterceptors = useCallback((instance: AxiosInstance) => {
    const reqInterceptor = instance.interceptors.request.use((config) => {
      startLoading();
      return config;
    });
    const resInterceptor = instance.interceptors.response.use(
      (response) => {
        endLoading();
        return response;
      },
      (error) => {
        endLoading();
        throw error;
      }
    );
    return () => {
      instance.interceptors.request.eject(reqInterceptor);
      instance.interceptors.response.eject(resInterceptor);
    };
  }, []);

  const addAccountTypePrefixInterceptor = useCallback(
    (instance: AxiosInstance) => {
      const reqInterceptor = instance.interceptors.request.use((config) => {
        return { ...config, url: `${accountTypePrefix[accountType]}${config.url}` };
      });
      return () => {
        instance.interceptors.request.eject(reqInterceptor);
      };
    },
    [accountType]
  );

  const addSignOutUnauthorizedInterceptor = useCallback(
    (instance: AxiosInstance) => {
      const resInterceptor = instance.interceptors.response.use(
        (response) => response,
        (error) => {
          if (error?.response?.status === HttpResponseCode.Unauthorized) {
            if (account?.email) {
              setSignInFastTrack({
                accountType,
                email: account.email,
              });
            }
            setAccount(null);
          }
          throw error;
        }
      );
      return () => {
        instance.interceptors.response.eject(resInterceptor);
      };
    },
    [accountType, account]
  );

  const addThrowIfErrorPropertyIsPresentInterceptor = useCallback((instance: AxiosInstance) => {
    const resInterceptor = instance.interceptors.response.use((response) => {
      if (response.data && typeof response.data === 'object' && 'error' in response.data) {
        throw response.data.error;
      }
      return response;
    });
    return () => {
      instance.interceptors.response.eject(resInterceptor);
    };
  }, []);

  const axiosInstance = useMemo(() => {
    const instance = createAxiosInstance();
    addAccountTypePrefix && addAccountTypePrefixInterceptor(instance);
    logRequests && addLoggingInterceptors(instance);
    indicateLoading && addLoadingInterceptors(instance);
    signOutUnauthorized && addSignOutUnauthorizedInterceptor(instance);
    throwIfErrorFieldIsPresent && addThrowIfErrorPropertyIsPresentInterceptor(instance);
    return instance;
  }, [
    createAxiosInstance,
    addAccountTypePrefixInterceptor,
    addLoggingInterceptors,
    addLoadingInterceptors,
    addSignOutUnauthorizedInterceptor,
    addThrowIfErrorPropertyIsPresentInterceptor,
    logRequests,
    indicateLoading,
    signOutUnauthorized,
  ]);

  const get = useCallback(
    <T>(path: string, config?: AxiosRequestConfig) => {
      return axiosInstance.get<T, AxiosResponse<T>>(path, config);
    },
    [axiosInstance]
  );

  const post = useCallback(
    <T>(path: string, data?: any, config?: AxiosRequestConfig) => {
      return axiosInstance.post<T, AxiosResponse<T>>(path, data, config);
    },
    [axiosInstance]
  );

  const put = useCallback(
    <T>(path: string, data?: any, config?: AxiosRequestConfig) => {
      return axiosInstance.put<T, AxiosResponse<T>>(path, data, config);
    },
    [axiosInstance]
  );

  const del = useCallback(
    <T>(path: string, config?: AxiosRequestConfig) => {
      return axiosInstance.delete<T, AxiosResponse<T>>(path, config);
    },
    [axiosInstance]
  );

  return {
    get,
    post,
    put,
    del,
  };
};
