import React, { FC } from 'react';
import { IBalance } from '../../models/BalanceResponse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

interface IProps {
  data: IBalance[];
}

const useStyles = makeStyles(() =>
  createStyles({
    amountCell: {
      textAlign: 'right',
    },
  })
);

export const BalanceTable: FC<IProps> = ({ data }) => {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t('balance.source')}</TableCell>
          <TableCell className={styles.amountCell}>{t('balance.amount')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((balanceItem) =>
          Object.entries(balanceItem.sourceTypes).map(([sourceType, amount]) => (
            <TableRow key={`${balanceItem.currency}/${sourceType}`}>
              <TableCell>{sourceType}</TableCell>
              <TableCell className={styles.amountCell}>
                {typeof amount === 'number'
                  ? Intl.NumberFormat('en-US', { style: 'currency', currency: balanceItem.currency }).format(
                      amount / 100
                    )
                  : null}
              </TableCell>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  );
};
