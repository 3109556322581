import { useApi } from './useApi';
import { IMachineDetailsResponse } from '../models/MachineDetailsResponse';
import { ApiRequest, useSimpleEndpoint } from './api/apiEndpoint';
import { MachineEndpoints } from './api/endpoints/machines';

export const useMachineApi = () => {
  const { get } = useApi();

  const getMachineDetails = useSimpleEndpoint(
    MachineEndpoints.MachineDetails,
    get as ApiRequest<typeof get, IMachineDetailsResponse>
  );

  return {
    getMachineDetails,
  };
};
