import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { useForm } from 'react-hook-form';
import { TextInputController } from '../InputControllers/TextInputController';
import { CustomerAvailableProductsFetcher, CustomerFetcher, CustomerInvoicesFetcher } from './CustomerFetchers';
import { SelectController } from '../InputControllers/SelectController';
import { useCustomersApi } from '../../hooks/useCustomersApi';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { usePreviousValue } from '../../hooks/usePreviousValue';
import { useAutoFetch } from '../../contexts/Fetcher';
import { useTranslation } from 'react-i18next';
import { CloseDialogFunc } from '../../hooks/useCloseDialog';

interface IProps {
  open: boolean;
  closeDialog: CloseDialogFunc;
}

export const CustomerNewInvoice: FC<IProps> = ({ open, closeDialog }) => {
  const { data: availableProductsData } = useContext(CustomerAvailableProductsFetcher.Context);
  const { data: customerData } = useContext(CustomerFetcher.Context);
  const { onRefresh: refreshInvoices } = useContext(CustomerInvoicesFetcher.Context);
  const { addInvoice } = useCustomersApi();
  const { customer } = customerData!;
  const handleError = useApiErrorHandler();
  const { t } = useTranslation();

  const onClose = useCallback(() => {
    closeDialog();
  }, [closeDialog]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    getValues,
    setValue,
  } = useForm();

  useEffect(() => {
    if (!open) reset();
  }, [open]);

  useAutoFetch(CustomerAvailableProductsFetcher, open);

  const productsItems = useMemo(() => {
    if (!availableProductsData) return [];
    return availableProductsData.products.map((product) => {
      return {
        value: product.id,
        label: product.name,
      };
    });
  }, [availableProductsData]);

  const selectedProduct = watch('product');
  const selectedPlan = watch('plan');

  const plansItems = useMemo(() => {
    if (!availableProductsData || !selectedProduct) return [];
    const product = availableProductsData.products.find((product) => product.id === selectedProduct);
    if (!product) return [];
    return product.plans.map((plan) => ({
      value: plan.id,
      label: plan.name,
    }));
  }, [availableProductsData, selectedProduct]);

  const generateDescription = useCallback(
    (productId, planId) => {
      if (!availableProductsData) return '';
      const product = availableProductsData.products.find((product) => product.id === productId);
      if (!product) return '';
      const plan = product.plans.find((plan) => plan.id === planId);
      return plan?.name ?? '';
    },
    [availableProductsData]
  );

  const autoDescription = generateDescription(selectedProduct, selectedPlan);
  const previousAutoDescription = usePreviousValue(autoDescription);

  useEffect(() => {
    const actualDescription = getValues().description;
    if (!actualDescription || actualDescription === previousAutoDescription.current) {
      setValue('description', autoDescription);
    }
  }, [autoDescription]);

  const onSubmit = useCallback(async (data) => {
    try {
      await addInvoice([customer.id], {
        productId: data.product,
        planId: data.plan,
        amount: Number(data.amount),
        billingAddress: data.billingAddress,
        currency: 'usd',
        description: data.description,
      });
      await refreshInvoices();
      closeDialog(true);
    } catch (e) {
      handleError(e);
    }
  }, []);

  if (!customerData || !availableProductsData) return null;
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('customer.invoices.addDialog.title')}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <TextInputController
                control={control}
                errors={errors}
                label={t('common.formLabel.firstName')}
                name="firstName"
                rules={{ required: true }}
                defaultValue={customer.firstName}
                disabled
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextInputController
                control={control}
                errors={errors}
                label={t('common.formLabel.lastName')}
                name="lastName"
                rules={{ required: true }}
                defaultValue={customer.lastName}
                disabled
              />
            </Grid>

            <Grid item xs={12}>
              <TextInputController
                control={control}
                errors={errors}
                label={t('common.formLabel.email')}
                name="email"
                rules={{ required: true }}
                defaultValue={customer.email}
                disabled
              />
            </Grid>

            <Grid item xs={12}>
              <TextInputController
                control={control}
                errors={errors}
                label={t('customer.invoices.formLabel.billingAddress')}
                name="billingAddress"
                rules={{ required: true }}
                defaultValue={customer.billingAddress}
              />
            </Grid>

            <Grid item xs={2}>
              <TextInputController
                control={control}
                errors={errors}
                label={t('common.formLabel.amount')}
                name="amount"
                rules={{ required: true, min: 1 }}
                InputProps={{ inputProps: { min: 1 } }}
                inputType="number"
                defaultValue={1}
              />
            </Grid>

            <Grid item xs={5}>
              <SelectController
                control={control}
                errors={errors}
                items={productsItems}
                label={t('common.formLabel.product')}
                name={'product'}
                defaultValue=""
              />
            </Grid>

            <Grid item xs={5}>
              <SelectController
                control={control}
                errors={errors}
                items={plansItems}
                label={t('common.formLabel.plan')}
                name={'plan'}
                disabled={plansItems.length === 0}
                defaultValue=""
              />
            </Grid>

            <Grid item xs={12}>
              <TextInputController
                control={control}
                errors={errors}
                label={t('customer.invoices.formLabel.description')}
                name="description"
                defaultValue=""
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('common.dialog.close')}</Button>
        <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
          {t('customer.invoices.addDialog.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
