import { useContext } from 'react';
import { AccountContext } from '../contexts/AccountContext';
import { AccountType } from '../types/AccountType';
import { AdminRoutes } from '../routers/AdminRoutes';
import { CustomerRoutes } from '../routers/CustomerRoutes';
import { UserRoutes } from '../routers/UserRoutes';

export const useHomeUrl = () => {
  const accountType = useContext(AccountContext);

  return accountType === AccountType.Admin
    ? AdminRoutes.Overview()
    : accountType === AccountType.Customer
    ? CustomerRoutes.Overview()
    : accountType === AccountType.User
    ? UserRoutes.Overview()
    : '/';
};
