import React, { FC, useCallback, useContext, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { CloseDialogFunc } from '../../hooks/useCloseDialog';
import { useForm } from 'react-hook-form';
import { IProductFormPayload, ProductForm } from './ProductForm';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { useProductsApi } from '../../hooks/useProductsApi';
import { ProductsFetcher } from '../../fetchers/ProductsFetcher';
import { useTranslation } from 'react-i18next';

interface IProps {
  open: boolean;
  closeDialog: CloseDialogFunc;
}

export const AddProductDialog: FC<IProps> = ({ open, closeDialog }) => {
  const form = useForm();
  const handleError = useApiErrorHandler();
  const { createProduct } = useProductsApi();
  const { onRefresh } = useContext(ProductsFetcher.Context);
  const { t } = useTranslation();

  useEffect(() => {
    form.reset({
      name: '',
      plans: [],
      installerPath: '',
      docsPath: '',
    });
  }, [open]);

  const onSubmit = useCallback(
    async (payload: IProductFormPayload) => {
      try {
        const result = await createProduct([], payload);
        await onRefresh();
        closeDialog(result.id);
      } catch (e) {
        handleError(e);
      }
    },
    [createProduct]
  );

  return (
    <Dialog open={open} onClose={closeDialog} maxWidth="md">
      <DialogTitle>{t('adminProducts.products.addDialog.title')}</DialogTitle>
      <DialogContent>
        <ProductForm form={form} onSubmit={onSubmit} />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>{t('common.dialog.close')}</Button>
        <Button variant="contained" color="primary" onClick={form.handleSubmit(onSubmit)}>
          {t('adminProducts.products.addDialog.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
