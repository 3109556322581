import React, { FC, useCallback, useContext } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import CloudDownload from '@mui/icons-material/CloudDownload';
import { useDownload } from '../../hooks/useDownload';
import { LicenseContext } from '../../contexts/LicenseContext';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { useTranslation } from 'react-i18next';
import { LicensesIncludedInOffline } from './LicensesIncludedInOffline';

interface IProps {
  onClose: () => void;
}

export const DownloadOfflineLicense: FC<IProps> = ({ onClose }) => {
  const { offlineLicense, machine } = useContext(LicenseContext)!;
  const download = useDownload();
  const apiErrorHandler = useApiErrorHandler();
  const { t } = useTranslation();

  const onDownload = useCallback(async () => {
    if (!offlineLicense) return;
    try {
      await download(`${offlineLicense}.txt`);
    } catch (e) {
      apiErrorHandler(e, t('licenses.offlineDialog.downloadFailed'));
    }
  }, [offlineLicense, download]);

  return (
    <>
      <DialogTitle>{t('licenses.offlineDialog.title')}</DialogTitle>
      <DialogContent dividers>
        <Typography paragraph>{t('licenses.offlineDialog.description')}</Typography>
        <Box p={1} textAlign="center">
          <img src="/offline-step3.svg" />
        </Box>
        <Typography paragraph>{t('licenses.offlineDialog.content.ready')}</Typography>
        <Typography paragraph>
          {t('licenses.offlineDialog.userLabel')}: <strong>{machine?.userId}</strong>
        </Typography>
        <Typography paragraph>
          {t('licenses.offlineDialog.machineLabel')}: <strong>{machine?.name}</strong>
        </Typography>

        <LicensesIncludedInOffline />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          {t('common.dialog.close')}
        </Button>
        <Button variant="contained" color="primary" onClick={onDownload} startIcon={<CloudDownload />}>
          {t('licenses.offlineDialog.confirmDownload')}
        </Button>
      </DialogActions>
    </>
  );
};
