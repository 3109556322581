import React, { FC, useCallback, useContext, useMemo } from 'react';
import { createNoParamsServerSidePaginatedFetcher } from '../../contexts/Fetcher';
import { useAdminsApi } from '../../hooks/useAdminsApi';
import { PageHeader } from '../PageHeader/PageHeader';
import { UrlQueryDataGrid } from '../UrlQueryDataGrid/UrlQueryDataGrid';
import { GridColumns, GridRowParams } from '@mui/x-data-grid';
import { getDateValueFormatter } from '../../valueFormatters/DateValueFormatter';
import { DateFormat } from '../../helpers/dateFormat';
import Button from '@mui/material/Button';
import { AdminRoutes } from '../../routers/AdminRoutes';
import { AddAdminDialog } from '../EditAdminDialog/AddAdminDialog';
import { getStringEqualsOperator } from '../../filterOperators/stringEqualsOperator';
import { getStringDiffersOperator } from '../../filterOperators/stringDiffersOperator';
import { getLessThanOperator } from '../../filterOperators/lessThanOperator';
import { getGreaterThanOperator } from '../../filterOperators/greaterThanOperator';
import { getDateAfterFilterOperator, getDateBeforeFilterOperator } from '../../filterOperators/dateFilterOperators';
import { EasyFilter } from '../EasyFilter/EasyFilter';
import { IQuickFiltersProps } from '../EasyFilter/QuickFilters';
import { CellContentTooltip } from '../CellContentTooltip/CellContentTooltip';
import { useDefaultSorting } from '../../hooks/useDefaultSorting';
import { useTranslation } from 'react-i18next';
import { createStatusChipComponent } from '../StatusChip/StatusChip';
import { getStringContainsOperator } from '../../filterOperators/stringContainsOperator';
import { useCloseDialog } from '../../hooks/useCloseDialog';
import { useIsRoute } from '../../hooks/useIsRoute';
import { AdminRouteLink, useIsAdminRouteAllowed } from '../RouteLink/RouteLink';
import styled from '@mui/styles/styled';
import { getMappedEnumIsFilterOperator } from '../../filterOperators/mappedEnumFilterOperator';

export const AdminsFetcher = createNoParamsServerSidePaginatedFetcher(() => useAdminsApi().getAdmins);

const StatusRenderer = createStatusChipComponent({
  verified: ['common.accountStatus.verified', 'lime'],
  unverified: ['common.accountStatus.unverified', 'orange'],
  mfaEnabled: ['common.accountStatus.mfaEnabled', 'lime'],
});

const RoleChip = createStatusChipComponent({
  true: ['common.accountRole.sales', 'blue'],
});

const RoleChipWrapper = styled('span')(({ theme }) => ({
  marginRight: theme.spacing(0.5),
}));

const AdminsContent: FC = () => {
  const { data } = useContext(AdminsFetcher.Context);
  const isAdding = useIsRoute(AdminRoutes.NewAdmin);
  const { t } = useTranslation();

  const onCloseDialog = useCloseDialog(AdminRoutes.Admins());

  const quickFilters: IQuickFiltersProps['quickFilters'] = useMemo(
    () =>
      new Map([
        [
          t('common.quickFilter.unverified'),
          {
            filterField: 'verified',
            filterOp: 'equals',
            filterVal: 'false',
          },
        ],
        [
          t('common.quickFilter.sales'),
          {
            filterField: 'role',
            filterOp: 'equals',
            filterVal: 'sales',
          },
        ],
        [
          t('common.quickFilter.mfaEnabled'),
          {
            filterField: 'status',
            filterOp: 'equals-m',
            filterVal: 'MFAEnabled=true',
          },
        ],
      ]),
    []
  );

  const columns = useMemo<GridColumns>(() => {
    return [
      {
        field: 'id',
        headerName: t('common.tableHeader.id'),
        filterOperators: [getStringEqualsOperator(), getGreaterThanOperator(), getLessThanOperator()],
      },
      {
        field: 'firstName',
        headerName: t('common.tableHeader.firstName'),
        flex: 1,
        filterOperators: [getStringContainsOperator(), getStringEqualsOperator(), getStringDiffersOperator()],
        renderCell: (params) => <CellContentTooltip>{params.value}</CellContentTooltip>,
      },
      {
        field: 'lastName',
        headerName: t('common.tableHeader.lastName'),
        flex: 1,
        filterOperators: [getStringContainsOperator(), getStringEqualsOperator(), getStringDiffersOperator()],
        renderCell: (params) => <CellContentTooltip>{params.value}</CellContentTooltip>,
      },
      {
        field: 'email',
        headerName: t('common.tableHeader.email'),
        flex: 2,
        filterOperators: [getStringContainsOperator(), getStringEqualsOperator()],
        renderCell: (params) => (
          <CellContentTooltip>
            {params.row.role === 'sales' ? (
              <RoleChipWrapper>
                <RoleChip {...params} value={true} />
              </RoleChipWrapper>
            ) : null}
            {params.value}
          </CellContentTooltip>
        ),
      },
      {
        field: 'phoneNumber',
        headerName: t('common.tableHeader.phone'),
        width: 120,
        filterOperators: [getStringContainsOperator(), getStringEqualsOperator()],
        renderCell: (params) => <CellContentTooltip>{params.value}</CellContentTooltip>,
      },
      {
        field: 'status',
        headerName: t('common.tableHeader.status'),
        width: 120,
        renderCell: (params) => {
          return (
            <StatusRenderer
              {...params}
              value={!params.row.verified ? 'unverified' : params.row.MFAEnabled ? 'mfaEnabled' : 'verified'}
            />
          );
        },
        filterOperators: [
          getMappedEnumIsFilterOperator({
            [t('common.accountStatus.verified')]: ['verified', true],
            [t('common.accountStatus.unverified')]: ['verified', false],
            [t('common.accountStatus.mfaEnabled')]: ['MFAEnabled', true],
          }),
        ],
        sortable: false,
      },
      {
        field: 'updated',
        headerName: t('common.tableHeader.updated'),
        valueFormatter: getDateValueFormatter(DateFormat.DateMedium),
        width: 130,
        filterOperators: [getDateBeforeFilterOperator(), getDateAfterFilterOperator()],
      },
      {
        field: 'created',
        headerName: t('common.tableHeader.created'),
        valueFormatter: getDateValueFormatter(DateFormat.DateMedium),
        width: 130,
        filterOperators: [getDateBeforeFilterOperator(), getDateAfterFilterOperator()],
      },
      {
        field: 'verified',
        headerName: t('common.tableHeader.updated'),
        hide: true,
        hideable: false,
        sortable: false,
      },
      {
        field: 'role',
        headerName: t('admin.role'),
        hide: true,
        hideable: false,
        sortable: false,
      },
    ];
  }, []);

  const rows = data ? data.data : null;

  const getRowLink = useCallback((params: GridRowParams) => {
    return AdminRoutes.Admin(params.row.id);
  }, []);
  const isGetRowLinkAllowed = useIsAdminRouteAllowed('Admin');

  if (!rows || !data) return null;
  return (
    <>
      <EasyFilter quickFilters={quickFilters} />

      <UrlQueryDataGrid
        columns={columns}
        rows={rows}
        disableExtendRowFullWidth={false}
        autoHeight={true}
        disableSelectionOnClick={true}
        paginationMode={'server'}
        filterMode={'server'}
        sortingMode={'server'}
        rowCount={data.page.data.total}
        getRowLink={isGetRowLinkAllowed ? getRowLink : void 0}
      />
      <AddAdminDialog open={isAdding} closeDialog={onCloseDialog} />
    </>
  );
};

export const Admins: FC = () => {
  const { t } = useTranslation();
  const preventRender = useDefaultSorting('id', 'desc');
  if (preventRender) return null;

  return (
    <AdminsFetcher.WAF>
      <PageHeader title={t('admins.header')}>
        <Button component={AdminRouteLink} route="NewAdmin" params={[]} keepQuery variant="contained" color="primary">
          {t('admins.addButton')}
        </Button>
      </PageHeader>
      <AdminsContent />
    </AdminsFetcher.WAF>
  );
};
