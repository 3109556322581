import React from 'react';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Backspace from '@mui/icons-material/Backspace';
import PlaylistAdd from '@mui/icons-material/PlaylistAdd';
import { UseFormReturn } from 'react-hook-form';
import { combineCn } from '../../helpers/combineCn';
import { Colors } from '../../colors';
import { useTranslation } from 'react-i18next';

interface IProps<DataType extends unknown, ValueType extends unknown> {
  form: UseFormReturn;
  name: string;
  label: string;
  data: DataType[];
  compare: (dataItem: DataType, valueItem: ValueType) => boolean;
  getItemKey: (dataItem: DataType) => number | string;
  getItemLabel: (dataItem: DataType) => string;
  valueGenerator: (dataItem: DataType) => ValueType;
  availableListLabel?: string;
  chosenListLabel?: string;
  classNames?: {
    itemsList?: string;
  };
  isUnavailable?: (dataItem: DataType) => boolean;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    transferList: {
      overflow: 'auto',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      overflow: 'hidden',
    },
    listHint: {
      backgroundColor: `${Colors.Navy}3b`,
      color: theme.palette.text.primary,
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    },
  })
);

export const TransferList = <DataType extends unknown, ValueType extends unknown>({
  form,
  name,
  label,
  data,
  compare,
  getItemKey,
  getItemLabel,
  valueGenerator,
  availableListLabel,
  chosenListLabel,
  classNames = {},
  isUnavailable,
}: IProps<DataType, ValueType>): JSX.Element => {
  const { t } = useTranslation();
  const styles = useStyles();
  const chosenValues: ValueType[] = form.watch(name) ?? [];

  availableListLabel = availableListLabel ?? t('component.transferList.availableLabel');
  chosenListLabel = chosenListLabel ?? t('component.transferList.chosenLabel');

  const handleAdd = (item: DataType) => () => {
    form.setValue(name, [...chosenValues, valueGenerator(item)]);
  };

  const handleRemove = (item: DataType) => () => {
    form.setValue(
      name,
      chosenValues.filter((value) => !compare(item, value))
    );
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <FormLabel>{label}:</FormLabel>
      </Grid>
      <Grid item xs={6}>
        <Paper className={styles.paper}>
          <div className={styles.listHint}>{availableListLabel}:</div>
          <List
            className={combineCn({ [styles.transferList]: true, [classNames.itemsList ?? '']: !!classNames.itemsList })}
          >
            {data.map((item) => {
              if (chosenValues.find((value) => compare(item, value))) return null;
              if (isUnavailable?.(item)) return null;

              return (
                <ListItem key={getItemKey(item)} onClick={handleAdd(item)}>
                  <ListItemIcon>
                    <IconButton size="large">
                      <PlaylistAdd />
                    </IconButton>
                  </ListItemIcon>
                  <ListItemText>{getItemLabel(item)}</ListItemText>
                </ListItem>
              );
            })}
          </List>
        </Paper>
      </Grid>

      <Grid item xs={6}>
        <Paper className={styles.paper}>
          <div className={styles.listHint}>{chosenListLabel}:</div>
          <List
            className={combineCn({ [styles.transferList]: true, [classNames.itemsList ?? '']: !!classNames.itemsList })}
          >
            {chosenValues.map((value) => {
              const item = data.find((item) => compare(item, value));
              if (!item) return null;
              return (
                <ListItem key={getItemKey(item)} onClick={handleRemove(item)}>
                  <ListItemIcon>
                    <ListItemIcon>
                      <IconButton size="large">
                        <Backspace />
                      </IconButton>
                    </ListItemIcon>
                  </ListItemIcon>
                  <ListItemText>{getItemLabel(item)}</ListItemText>
                </ListItem>
              );
            })}
          </List>
        </Paper>
      </Grid>
    </Grid>
  );
};
