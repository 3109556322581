import React, { FC, useCallback, useRef, useState } from 'react';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import InsertInvitation from '@mui/icons-material/InsertInvitation';
import { TimezoneSelector } from '../TimezoneSelector/TimezoneSelector';
// eslint-disable-next-line no-restricted-imports
import { PickersPopper } from '@mui/x-date-pickers/internals/components/PickersPopper';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { DateFormat, dateFormat } from '../../helpers/dateFormat';
import { useTranslation } from 'react-i18next';
import { useEnumState } from '../../hooks/useEnumState';

interface IDatePickerWithTimezoneSelectionProps {
  label: string;
  date: Dayjs | null;
  setDate: (value: Dayjs | null) => void;
  disableFuture?: boolean;
  disablePast?: boolean | Dayjs;
  disabled?: boolean;
  inputFormat?: string;
}

const guessedTimezone = dayjs.tz.guess();

const noop = () => null;

export const DatePickerWithTimezoneSelection: FC<IDatePickerWithTimezoneSelectionProps> = ({
  label,
  date,
  setDate,
  disableFuture = false,
  disablePast = false,
  disabled = false,
}) => {
  const [timezone, setTimezone] = useState<string | null>(guessedTimezone);

  const createDateObject = useCallback((date: Dayjs | null, timezone: string) => {
    if (!date) {
      return null;
    }

    const formatted =
      [date.year(), date.month() + 1, date.date()].map((part) => part.toString().padStart(2, '0')).join('-') +
      'T' +
      [date.hour(), date.minute(), date.second()].map((part) => part.toString().padStart(2, '0')).join(':');

    return dayjs.tz(formatted, timezone);
  }, []);

  const onTimezoneChange = useCallback(
    (timezone: string | null) => {
      const newDate = createDateObject(date, timezone ?? guessedTimezone);

      setTimezone(timezone);
      setDate(newDate);
    },
    [date]
  );

  const onDateChange = useCallback(
    (value: Dayjs | null) => {
      date = createDateObject(value, timezone ?? guessedTimezone);

      setDate(date?.hour(0).minute(0).second(0).millisecond(0) ?? null);
    },
    [timezone]
  );

  const inputRef = useRef(null);
  const [isPopperOpen, openPopper, closePopper] = useEnumState(false, true, false);

  const {
    i18n: { language },
  } = useTranslation();

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TextField
          fullWidth
          type={'text'}
          label={label}
          inputRef={inputRef}
          InputProps={{
            endAdornment: (
              <InputAdornment position={'end'} onClick={openPopper}>
                <IconButton onClick={openPopper} disabled={disabled}>
                  <InsertInvitation />
                </IconButton>
              </InputAdornment>
            ),
            readOnly: true,
          }}
          disabled={disabled}
          value={date ? dateFormat(date, DateFormat.DateFullWithZone, language) : ''}
        />

        <PickersPopper
          role={'tooltip'}
          anchorEl={inputRef.current}
          open={isPopperOpen}
          onClose={closePopper}
          onClear={noop}
          onCancel={noop}
          onAccept={noop}
          onSetToday={noop}
          PopperProps={{
            modifiers: [
              {
                name: 'preventOverflow',
                enabled: true,
                options: {
                  altAxis: true,
                  altBoundary: true,
                  rootBoundary: 'document',
                },
              },
            ],
          }}
        >
          <CalendarPicker
            date={date}
            onChange={onDateChange}
            disableFuture={disableFuture}
            shouldDisableDate={(calendarDate) => {
              const lastDisabledDay = typeof disablePast === 'object' ? disablePast : dayjs();
              return disablePast && calendarDate.isBefore(lastDisabledDay);
            }}
          />
          <TimezoneSelector value={timezone} onChange={onTimezoneChange} />
        </PickersPopper>
      </LocalizationProvider>
    </div>
  );
};
