import React, { FC, useState } from 'react';
import { userRoot } from './routers/UserRoutes';
import { UserRouter } from './routers/UserRouter';
import { DownloadsProvider } from './contexts/DownloadsContext';
import { useAccount } from './hooks/useAccount';
import { AccountContext } from './contexts/AccountContext';
import { guestRoot, GuestRouter, GuestRoutes } from './routers/GuestRouter';
import { AccountType } from './types/AccountType';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { customerRoot } from './routers/CustomerRoutes';
import { CustomerRouter } from './routers/CustomerRouter';
import { NotFoundComponent } from './components/NotFound';
import { RouteWrapper } from './components/RouteWrapper';
import { adminRoot } from './routers/AdminRoutes';
import { AdminRouter } from './routers/AdminRouter';
import { RedirectWithQuery } from './routers/RedirectWithQuery';
import { RolesContext } from './contexts/RolesContext';
import { AuthLayout } from './layouts/AuthLayout';
import { RedirectToAuth } from './routers/RedirectToAuth';

const App: FC = () => {
  const { account: user } = useAccount(AccountType.User);
  const { account: customer } = useAccount(AccountType.Customer);
  const { account: admin } = useAccount(AccountType.Admin);

  const [userRoles] = useState({ endpoints: null });
  const [customerRoles] = useState({ endpoints: null });

  return (
    <BrowserRouter>
      {user ? (
        <AccountContext.Provider value={AccountType.User}>
          <DownloadsProvider>
            <RolesContext.Provider value={userRoles}>
              <UserRouter />
            </RolesContext.Provider>
          </DownloadsProvider>
        </AccountContext.Provider>
      ) : (
        <Switch>
          <RedirectWithQuery
            to={GuestRoutes.UpdatePassword(AccountType.User)}
            from={GuestRoutes.LegacyUserUpdatePassword()}
          />
          <RedirectToAuth from={userRoot} />
        </Switch>
      )}
      {customer ? (
        <AccountContext.Provider value={AccountType.Customer}>
          <DownloadsProvider>
            <RolesContext.Provider value={customerRoles}>
              <CustomerRouter />
            </RolesContext.Provider>
          </DownloadsProvider>
        </AccountContext.Provider>
      ) : (
        <Switch>
          <RedirectWithQuery
            to={GuestRoutes.UpdatePassword(AccountType.Customer)}
            from={GuestRoutes.LegacyCustomerUpdatePassword()}
          />
          <RedirectToAuth from={customerRoot} />
        </Switch>
      )}
      {admin ? (
        <AccountContext.Provider value={AccountType.Admin}>
          <AdminRouter />
        </AccountContext.Provider>
      ) : (
        <Switch>
          <RedirectWithQuery
            to={GuestRoutes.UpdatePassword(AccountType.Admin)}
            from={GuestRoutes.LegacyAdminUpdatePassword()}
          />
          <RedirectToAuth from={adminRoot} />
        </Switch>
      )}

      <GuestRouter />

      <Switch>
        <Route path={guestRoot} />
        {user ? <Route path={userRoot} /> : null}
        {customer ? <Route path={customerRoot} /> : null}
        {admin ? <Route path={adminRoot} /> : null}

        <RouteWrapper path={'/'} component={NotFoundComponent} layout={AuthLayout} />
      </Switch>
    </BrowserRouter>
  );
};
export default App;
