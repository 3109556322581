import React, { FC, useCallback, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  alpha,
  styled,
} from '@mui/material';
import { CloseDialogFunc } from '../../hooks/useCloseDialog';
import { useTranslation } from 'react-i18next';
import { IAdminCustomerUserResponse } from '../../models/AdminCustomerUserResponse';
import { LicenseMode, getLicenseMode } from '../../helpers/licenseMode';
import { LicenseStatus, getLicenseStatus } from '../../helpers/licenseStatus';
import { CellContentTooltip } from '../CellContentTooltip/CellContentTooltip';
import { LicenseExpiry } from '../LicenseExpiry/LicenseExpiry';
import { createStatusChipComponent } from '../StatusChip/StatusChip';
import { CustomerProductPlanType } from '../../models/CustomerProductPlanType';
import { TypographyH5 } from '../Typography/Typography';
import { ifError } from '../../helpers/ifError';

interface IProps {
  onClose: CloseDialogFunc;
  onSubmit: (data: { basePackage: number; addonPackages: number[] }) => Promise<void>;
  machinePackages: IAdminCustomerUserResponse['machinePackages'];
  showAddons: boolean;
}

const StatusRenderer = createStatusChipComponent({
  valid: ['common.licenseStatus.valid', 'lime'],
  expiresSoon: ['common.licenseStatus.expiresSoon', 'orange'],
});

interface IPackageRow {
  id: number;
  license: string;
  type?: CustomerProductPlanType;
  expires: number;
  status: LicenseStatus;
}

const StyledPackageRow = styled(TableRow)(({ theme, selected }) => ({
  cursor: 'pointer',
  backgroundColor: selected ? theme.palette.primary.main : undefined,
  outline: selected ? `1px solid ${theme.palette.primary.main}` : undefined,
  '&:hover': selected
    ? undefined
    : {
        backgroundColor: alpha(theme.palette.action.selected, theme.palette.action.selectedOpacity),
      },
}));

const BasePackagesTable: FC<{
  rows: Array<IPackageRow>;
  selectedBasePackage: number | null;
  setSelectedBasePackage: (value: number | null) => void;
}> = ({ rows, selectedBasePackage, setSelectedBasePackage }) => {
  const { t } = useTranslation();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t('common.tableHeader.id')}</TableCell>
          <TableCell>{t('customerUser.packages.licenseHeader')}</TableCell>
          <TableCell>{t('common.tableHeader.expires')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <StyledPackageRow
            key={row.id}
            onClick={() => setSelectedBasePackage(row.id)}
            selected={selectedBasePackage === row.id}
          >
            <TableCell>{row.id}</TableCell>
            <TableCell>
              <CellContentTooltip>
                {row.license} {row.status === 'expiresSoon' ? <StatusRenderer value={row.status} /> : null}
              </CellContentTooltip>
            </TableCell>
            <TableCell>{row.type ? <LicenseExpiry type={row.type} expiry={row.expires} /> : null}</TableCell>
          </StyledPackageRow>
        ))}
      </TableBody>
    </Table>
  );
};

const AddonPackagesTable: FC<{
  rows: Array<IPackageRow>;
  selectedAddonPackages: number[];
  onToggleAddonPackage: (id: number) => void;
}> = ({ rows, selectedAddonPackages, onToggleAddonPackage }) => {
  return (
    <div>
      {rows.map((row) => (
        <div key={row.id}>
          <label>
            <Checkbox checked={selectedAddonPackages.includes(row.id)} onChange={() => onToggleAddonPackage(row.id)} />
            {row.license} {row.status === 'expiresSoon' ? <StatusRenderer value={row.status} /> : null}(
            {row.type ? <LicenseExpiry type={row.type} expiry={row.expires} /> : 'error'})
          </label>
        </div>
      ))}
    </div>
  );
};

export const RequestOfflineChooseLicense: FC<IProps> = ({ onClose, onSubmit, machinePackages, showAddons }) => {
  const { t } = useTranslation();
  const [selectedBasePackage, setSelectedBasePackage] = useState<null | number>(null);
  const [selectedAddonPackages, setSelectedAddonPackages] = useState<number[]>([]);

  const onClick = useCallback(() => {
    onSubmit({
      basePackage: selectedBasePackage!,
      addonPackages: selectedAddonPackages,
    });
  }, [onSubmit, selectedBasePackage, selectedAddonPackages]);

  const validPackages = useMemo(
    () =>
      machinePackages.filter((item) => {
        const mode = getLicenseMode(item.machine, item.license, item.offlineLicense);
        const status = getLicenseStatus(item.license.suspended, item.license.expiry, item.plan.type);
        return mode === LicenseMode.NotInUse && ['expiresSoon', 'valid'].includes(status);
      }),
    [machinePackages]
  );

  const basePackages = useMemo(() => validPackages.filter((item) => !item.package.package.isAddon), [validPackages]);

  const addonPackages = useMemo(() => validPackages.filter((item) => item.package.package.isAddon), [validPackages]);

  const baseRows = useMemo<IPackageRow[]>(() => {
    return basePackages.map((item) => {
      return {
        id: ifError(() => item.package.userPackage.id, 0),
        license: ifError(() => `${item.package.package.name} | ${item.plan.name}`, ''),
        type: ifError(() => item.plan.type, undefined),
        expires: ifError(() => item.license.expiry, 0),
        status: ifError(() => getLicenseStatus(item.license.suspended, item.license.expiry, item.plan.type), 'expired'),
      };
    });
  }, [basePackages]);

  const addonRows = useMemo<IPackageRow[]>(() => {
    return addonPackages.map((item) => {
      return {
        id: ifError(() => item.package.userPackage.id, 0),
        license: ifError(() => `${item.package.package.name} | ${item.plan.name}`, ''),
        type: ifError(() => item.plan.type, undefined),
        expires: ifError(() => item.license.expiry, 0),
        status: ifError(() => getLicenseStatus(item.license.suspended, item.license.expiry, item.plan.type), 'expired'),
      };
    });
  }, [addonPackages]);

  const toggleAddonPackage = useCallback(
    (id: number) => {
      if (selectedAddonPackages.includes(id)) {
        setSelectedAddonPackages(selectedAddonPackages.filter((packageId) => packageId !== id));
      } else {
        const packageId = addonPackages.find((item) => item.package.userPackage.id === id)?.license.packageId ?? 0;
        const idsOfPackagesOfSameKind = addonPackages
          .filter((item) => {
            return item.license.packageId === packageId;
          })
          .map((item) => item.package.userPackage.id);

        setSelectedAddonPackages([
          ...selectedAddonPackages.filter((packageId) => !idsOfPackagesOfSameKind.includes(packageId)),
          id,
        ]);
      }
    },
    [selectedAddonPackages, addonPackages]
  );

  return (
    <>
      <DialogTitle>{t('licenses.offlineDialog.title')}</DialogTitle>
      <DialogContent>
        {baseRows.length === 0 ? (
          t('component.requestOffline.noAvailableLicenses')
        ) : (
          <>
            <Box p={1}>
              <TypographyH5>{t('component.requestOffline.selectPackage')}</TypographyH5>
            </Box>
            <BasePackagesTable
              rows={baseRows}
              selectedBasePackage={selectedBasePackage}
              setSelectedBasePackage={setSelectedBasePackage}
            />
          </>
        )}

        {/* Don't show addons if there are no base licenses available */}
        {showAddons && baseRows.length > 0 && addonRows.length > 0 ? (
          <>
            <Box p={1}>
              <TypographyH5>{t('component.requestOffline.chooseAddons')}</TypographyH5>
            </Box>
            <AddonPackagesTable
              rows={addonRows}
              selectedAddonPackages={selectedAddonPackages}
              onToggleAddonPackage={toggleAddonPackage}
            />
          </>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          {t('common.dialog.close')}
        </Button>
        <Button variant="contained" color="primary" disabled={!selectedBasePackage} onClick={onClick}>
          {t('component.requestOffline.confirm')}
        </Button>
      </DialogActions>
    </>
  );
};
