import dayjs, { Dayjs } from 'dayjs';
import { useMemo } from 'react';

export const useDate = (date: string | number | Dayjs): Dayjs => {
  const dateObject = useMemo(() => {
    if (typeof date === 'object') return date;
    // convert seconds to milliseconds if the timestamp is lower than 5e9
    if (typeof date === 'number' && date < 5e9) date *= 1000;
    return dayjs(date);
  }, [date]);

  return dateObject;
};
