import React, { FC, useCallback, useContext, useRef } from 'react';
import IconButton from '@mui/material/IconButton';
import ContentCopy from '@mui/icons-material/ContentCopy';
import { NotificationContext } from '../../contexts/NotificationContext';
import { useTranslation } from 'react-i18next';

export const CopyText: FC = ({ children = false }) => {
  const ref = useRef<HTMLInputElement>(null);
  const { addNotification } = useContext(NotificationContext);
  const { t } = useTranslation();

  const onCopy = useCallback(async () => {
    const el = ref.current;
    if (!el) return;
    const value = el.innerText;
    await navigator.clipboard.writeText(value);
    addNotification({
      severity: 'success',
      message: t('component.copyTextField.copyConfirmation', { item: value }),
    });
  }, []);

  return (
    <span>
      <span ref={ref}>{children}</span>

      <IconButton onClick={onCopy} size={'small'}>
        <ContentCopy fontSize={'small'} />
      </IconButton>
    </span>
  );
};
